import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";
import { SHIFT_DISCOVERY_URGENT_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";

interface UrgentShiftsCalloutProps {
  count: number;
}

export function UrgentShiftsCallout(props: UrgentShiftsCalloutProps) {
  const { count } = props;
  const history = useHistory();
  const { baseNavigationPath } = useShiftModalsDataContext();

  if (count <= 0) {
    return null;
  }

  return (
    <Callout
      title={
        <Stack direction="row" alignItems="center" gap={2} component="span">
          <span>Urgent</span>
          <span>{count}</span>
        </Stack>
      }
      iconType="time-flies"
      sx={(theme) => ({
        backgroundColor: theme.palette.shifts?.urgent.label,
        color: theme.palette.shifts?.urgent.text,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALLOUT_VIEWED, { type: "urgentShifts", count });
        history.push(`${baseNavigationPath}/${SHIFT_DISCOVERY_URGENT_PATH}`);
      }}
    />
  );
}
