import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { startNFCScan } from "@src/appV2/Nfc/utils/nfcReader";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

const NFC_SCAN_TIMEOUT_IN_MS = 20_000;

export function useNfcHashReader(options: UseQueryOptions<string> = {}): UseQueryResult<string> {
  const nfcScanner = startNFCScan();

  return useQuery({
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    retryOnMount: false,
    useErrorBoundary: false,
    queryKey: ["nfc-hash-scan"],
    queryFn: async ({ signal }) => {
      signal?.addEventListener("abort", async () => {
        await nfcScanner.cancel();
      });

      return await nfcScanner.readOne(NFC_SCAN_TIMEOUT_IN_MS);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.NFC_HASH_READ_FAILED,
    },
    ...options,
  });
}
