/* eslint-disable import/max-dependencies */
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { useGetMissingRequirementsForWorkplaces } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { formatISO } from "date-fns";
import { GroupedVirtuoso } from "react-virtuoso";

import { getMissingAndPendingDocumentsForQualification } from "../../Shift/Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { ShiftListGroupWrapper } from "../../Shift/Open/GroupWrapper";
import { ShiftListDateLabel } from "../../Shift/Open/ListDateLabel";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { LoadingMoreIndicator } from "../../Shift/Open/LoadingMoreIndicator";
import { ShiftListTopItemList } from "../../Shift/Open/TopItemList";
import { type OpenShiftWorkplace } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext";
import { WorkerShiftCard } from "../../Shift/WorkerShift/Card";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../paths";
import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { ListViewPageOpenShiftsEmptyState } from "./ListEmptyState";

interface ListViewPageOpenShiftsListProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  workplacesMap: Map<string, OpenShiftWorkplace>;
  scrollRef: React.RefObject<HTMLDivElement>;
  loadMore: () => void;
  canLoadMore: boolean;
  isLoadingMore: boolean;
}

/**
 * Shows the list of open shifts and worker shifts for the current date
 * We prioritize showing open shifts and don't wait for worker shifts to load since:
 * 1. Worker shifts typically load faster than open shifts
 * 2. Open shifts are the primary content users want to see
 * Therefore we don't show loading/error states for worker shifts
 */
export function ListViewPageOpenShiftsList(props: ListViewPageOpenShiftsListProps) {
  const {
    isError,
    isLoading,
    isSuccess,
    workplacesMap,
    scrollRef,
    loadMore,
    canLoadMore,
    isLoadingMore,
  } = props;

  const {
    groupSortedShifts,
    shiftsByDateCount,
    dateGroups,
    listRef,
    setScrolledDate,
    getItemDate,
  } = useVirtualShiftListContext();

  const { navigateToModal } = useShiftModalsDataContext();
  const { userId: workerId, licensesData } = useDefinedWorker();

  const hasShifts = groupSortedShifts.length > 0;

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    Array.from(workplacesMap.values(), (workplace) => ({
      id: workplace.id,
      type: workplace.attributes.type,
      msa: workplace.attributes.location.msa,
      state: workplace.attributes.location.state,
      region: workplace.attributes.location.region,
    })),
    licensesData?.map((l) => l.qualification),
    {
      enabled: hasShifts,
    }
  );

  if (isError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying shifts nearby",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts nearby",
          "We are on it-finding shifts that matter most",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isSuccess && !hasShifts) {
    return <ListViewPageOpenShiftsEmptyState />;
  }

  return (
    <GroupedVirtuoso
      ref={listRef}
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      groupCounts={shiftsByDateCount}
      rangeChanged={(range) => {
        const midIndex = Math.floor((range.startIndex + range.endIndex) / 2);
        const midItem = groupSortedShifts[midIndex];

        if (isDefined(midItem)) {
          const date = getItemDate(midItem);

          setScrolledDate(formatISO(date, { representation: "date" }));
        }
      }}
      components={{
        TopItemList: ShiftListTopItemList,
        Group: ShiftListGroupWrapper,
        Footer: () => (
          // always rendering footer to have consistent bottom padding
          <Box
            sx={(theme) => ({
              paddingTop: 4,
              paddingBottom: `calc(
              ${theme.spacing(3)} +
              ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
              ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
        )`,
            })}
          >
            {isLoadingMore ? <LoadingMoreIndicator sx={{ paddingBottom: 4 }} /> : undefined}
          </Box>
        ),
      }}
      groupContent={(index) => {
        const label = dateGroups[index];

        return <ShiftListDateLabel label={label} shiftCount={shiftsByDateCount[index]} />;
      }}
      endReached={() => {
        if (canLoadMore) {
          loadMore();
        }
      }}
      itemContent={(index) => {
        const item = groupSortedShifts[index];

        if ("agent" in item) {
          return <WorkerShiftCard shift={item} sx={{ marginBottom: 4 }} />;
        }

        const workplace = workplacesMap.get(item.relationships.workplace.data.id);
        if (!workplace) {
          return null;
        }

        const { missingDocuments, pendingDocuments } =
          getMissingAndPendingDocumentsForQualification(
            workplace.id,
            item.attributes.qualification,
            missingRequirements.results
          );

        return (
          <OpenShiftCard
            key={item.id}
            shift={item}
            sx={{
              marginBottom: index < groupSortedShifts.length - 1 ? 4 : 0,
            }}
            workplace={workplace}
            missingDocuments={missingDocuments}
            pendingDocuments={pendingDocuments}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, { shiftId: item.id });
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: item.id });
            }}
          />
        );
      }}
    />
  );
}
/* eslint-enable import/max-dependencies */
