import { Stack } from "@mui/material";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

import { ATeamShiftPill } from "../ATeam/Pill";
import { isDocumentInstantReview } from "../Bookability/Documents/isDocumentInstantReview";
import { ShiftDocumentsPill } from "../Bookability/Documents/Pill";
import { ShiftType } from "../constants";
import { PriorityShiftPill } from "../Priority/Pill";
import { UrgentShiftPill } from "../Urgent/Pill";
import type { OpenShift } from "./types";

interface OpenShiftCardPillsProps {
  shift: OpenShift;
  shiftType: ShiftType;
  missingDocuments: HcpRequirement[];
  pendingDocuments: HcpRequirement[];
}

export function OpenShiftCardPills(props: OpenShiftCardPillsProps) {
  const { shift, missingDocuments, pendingDocuments, shiftType } = props;

  const showUrgentPill = shiftType === ShiftType.URGENT;
  const showPriorityPill = shiftType === ShiftType.PRIORITY;
  const showATeamPill = shiftType === ShiftType.A_TEAM;

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  // Don't show missing documents pill if all missing documents are instant review
  const areMissingDocumentsInstantReview = missingDocuments.every((document) =>
    isDocumentInstantReview({
      document,
      isHelloSignEmbeddedFlowEnabled,
    })
  );
  const showMissingDocumentsPill =
    missingDocuments.length > 0 &&
    (!areMissingDocumentsInstantReview || pendingDocuments.length > 0);

  const pillsCount = [
    showUrgentPill,
    showPriorityPill,
    showATeamPill,
    showMissingDocumentsPill,
  ].filter(Boolean).length;

  const hasMultiplePills = pillsCount > 1;

  if (!pillsCount) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
      {showUrgentPill && <UrgentShiftPill compact={hasMultiplePills} />}

      {showPriorityPill && (
        <PriorityShiftPill
          priorityTillDatetimeString={shift.attributes.priorityTill}
          compact={hasMultiplePills}
        />
      )}

      {showATeamPill && (
        <ATeamShiftPill
          priorityTillDatetimeString={shift.attributes.priorityTill}
          compact={hasMultiplePills}
        />
      )}

      {showMissingDocumentsPill && (
        <ShiftDocumentsPill
          missingDocumentsCount={missingDocuments.length}
          compact={hasMultiplePills}
        />
      )}
    </Stack>
  );
}
