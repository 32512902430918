/* eslint-disable @typescript-eslint/ban-types */
import { isDefined } from "@clipboard-health/util-ts";
import { isShiftPriorityValid } from "@src/appV2/lib/utils/isShiftPriorityValid";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";

import { ShiftType } from "./constants";
import { Window } from "./Open/types";

interface ResolveShiftTypeProps {
  isUrgent: boolean | null;
  window?: string | null;
  priorityTill?: string | null;
}

export function resolveShiftType({
  isUrgent,
  window,
  priorityTill,
}: ResolveShiftTypeProps): ShiftType {
  if (isUrgent) {
    return ShiftType.URGENT;
  }

  if (window === ShiftWindow.A_TEAM || window === Window.A_TEAM) {
    return ShiftType.A_TEAM;
  }

  if (isDefined(priorityTill) && isShiftPriorityValid(priorityTill)) {
    return ShiftType.PRIORITY;
  }

  return ShiftType.REGULAR;
}
/* eslint-enable @typescript-eslint/ban-types */
