import { isNullOrUndefined } from "@clipboard-health/util-ts";
import { HelloSignErrorReason } from "@src/appV2/Accounts/DocumentDetails/hooks/hellosign/constants";
import { HelloSignWorkflowSessionStatus } from "@src/appV2/Accounts/Documents/resources/hellosign/constants";
import { type HelloSignSessionResponse } from "@src/appV2/Accounts/Documents/resources/hellosign/types";
import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { useCallback } from "react";

interface UseHelloSignHandleSuccessOrErrorProps {
  requirementId?: string;
  onSuccess: (isApproved: boolean) => void;
  onError: () => void;
}

export function useHelloSignHandleSuccessOrError(props: UseHelloSignHandleSuccessOrErrorProps) {
  const { requirementId, onSuccess, onError } = props;

  const handleHelloSignError = useCallback(
    (errorReason?: HelloSignErrorReason, helloSignSessionId?: string) => {
      onError();
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_ERROR_EVENT, {
        errorReason,
        requirementId,
        helloSignSessionId,
      });
    },
    [onError, requirementId]
  );

  const handleHelloSignSuccess = useCallback(
    (helloSignSession: HelloSignSessionResponse) => {
      if (helloSignSession.data.attributes.status === HelloSignWorkflowSessionStatus.ERRORED) {
        handleHelloSignError(HelloSignErrorReason.HELLOSIGN_ERROR);
        return;
      }

      if (
        [HelloSignWorkflowSessionStatus.SIGNED, HelloSignWorkflowSessionStatus.COMPLETED].includes(
          helloSignSession.data.attributes.status
        )
      ) {
        const documentId = helloSignSession.data.relationships.document?.data.id;
        const document = helloSignSession.included?.find((included) => included.id === documentId);

        if (isNullOrUndefined(document)) {
          handleHelloSignError(HelloSignErrorReason.MISSING_DOCUMENT);
          return;
        }

        const documentStatus = document.attributes.status;
        onSuccess(documentStatus === DocumentStatus.APPROVED);
        return;
      }

      throw new Error("Hello sign session is not in a valid state");
    },
    [handleHelloSignError, onSuccess]
  );

  return { handleHelloSignSuccess, handleHelloSignError };
}
