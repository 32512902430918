import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function WorkplaceOpenShiftsEmptyState() {
  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title
        textAlign="center"
        variant="h4"
        component="h3"
        // TODO: Add semibold to Title
        sx={(theme) => ({
          fontWeight: theme.typography.h4.fontWeight,
          textWrap: "balance",
        })}
      >
        There are currently no open shifts for this workplace
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        We&apos;ll send you a notification when new shifts are open
      </Text>
    </Stack>
  );
}
