import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Link, Stack } from "@mui/material";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";

export function SkillsAssessmentStatusUnderReviewScheduled() {
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4}>
      <Title component="h1">We&apos;re reviewing the video assessment you completed.</Title>
      <Ul>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            We&apos;ll let you know the results within 1 business day. If you passed the assessment
            you&apos;ll be able to book shifts at that time.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            Tap{" "}
            <Link
              onClick={() => {
                void openBrowser(skillsAssessmentConfig.supportArticleLink);
              }}
            >
              here
            </Link>{" "}
            to view additional details about the assessment.
          </Text>
        </Li>
      </Ul>
    </Stack>
  );
}
