// Copied from app/store/chat/model.ts ChatChannel["metadata"]
export interface PlacementChatMetadata {
  facilityName?: string;
  hcpName?: string;
  lastBooked?: string;
  placementCandidateAccessId?: string;
  placementId?: string;
  placementTitle?: string;
  managingFacilityId?: string;
}

export type ChatMessageWithText = SendBird.UserMessage | SendBird.AdminMessage;

export function isChatMessageWithText(value: unknown): value is ChatMessageWithText {
  return typeof value === "object" && value !== null && "message" in value && "customType" in value;
}

export function isSendBirdGroupChannel(value: unknown): value is SendBird.GroupChannel {
  return typeof value === "object" && value !== null && "customType" in value;
}
