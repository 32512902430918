import { LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useScrollRestoration } from "@src/appV2/lib/utils/useScrollRestoration";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import { PlacementCandidateProfileUpdateDialog } from "@src/appV2/redesign/PlacementCandidate/components/PlacementCandidateProfileUpdateDialog";
import {
  type PlacementCandidate,
  PlacementCandidateStatus,
} from "@src/appV2/redesign/PlacementCandidate/types";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEqual } from "lodash";

import {
  transformResponseIntoPlacement,
  useFetchPaginatedPlacements,
} from "../api/useFetchPaginatedPlacements";
import { type GetPlacementsForWorkplaceQuery } from "../types/fetchPlacements.schema";
import { buildPlacementsFilter } from "../utils";
import { JobsTopButtons } from "./JobsTopButtons";
import { PlacementCardItem } from "./PlacementCardItem";
import { PlacementsLoader } from "./PlacementsLoader";
import { SearchField } from "./SearchField";

interface PlacementListViewProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
  filter: GetPlacementsForWorkplaceQuery["filter"];
  setFilter: (filter: GetPlacementsForWorkplaceQuery["filter"]) => void;
  searchByWorkplaceName: string;
  setSearchByWorkplaceName: (workplaceName: string) => void;
  showApplications: boolean;
  setShowApplications: (value: boolean) => void;
  debouncedSearchByWorkplaceName: string;
}

export function PlacementListView(props: PlacementListViewProps) {
  const {
    placementCandidate,
    worker,
    filter,
    setFilter,
    searchByWorkplaceName,
    setSearchByWorkplaceName,
    showApplications,
    setShowApplications,
    debouncedSearchByWorkplaceName,
  } = props;
  const updateCandidateProfileModalState = useModalState();
  useScrollRestoration({ key: "jobs-tab", containerId: "jobs-list-container" });

  const {
    data: placementsData,
    isLoading: isPlacementsLoading,
    isSuccess: isPlacementsSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchPlacements,
  } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: placementCandidate.id,
      filter: buildPlacementsFilter({
        filter,
        showApplications,
        worker,
        searchByWorkplaceName: debouncedSearchByWorkplaceName,
      }),
    },
    {
      enabled:
        isDefined(worker.userId) &&
        (placementCandidate.status === PlacementCandidateStatus.ACTIVATED ||
          placementCandidate.status === PlacementCandidateStatus.ONBOARDING),
    }
  );
  const nonEmptyFilter = Object.fromEntries(
    Object.entries(filter ?? {}).filter(([_, value]) => value)
  );

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacements} />
      <Box
        id="jobs-list-container"
        sx={{
          overflowY: "auto",
          paddingBottom: 12,
          backgroundColor: (theme) => theme.palette.background.primary,
          flex: 1,
        }}
      >
        <JobsTopButtons
          placementCandidate={placementCandidate}
          worker={worker}
          updateCandidateProfileModalState={updateCandidateProfileModalState}
        />

        <Stack spacing={6} sx={{ paddingX: 5 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Text semibold variant="h4">
              {showApplications ? "Applications" : "Open Jobs"}
            </Text>
            <Button
              startIcon={<CbhIcon type={showApplications ? "new" : "plane"} size="xSmall" />}
              variant="outlined"
              size="small"
              sx={{
                background: (theme) => theme.palette.background.tertiary,
                "&:hover": {
                  background: (theme) => theme.palette.background.tertiary,
                },
              }}
              onClick={() => {
                setShowApplications(!showApplications);
              }}
            >
              {showApplications ? "Open Jobs" : "Applications"}
            </Button>
          </Stack>
          <Box>
            <SearchField value={searchByWorkplaceName} onChange={setSearchByWorkplaceName} />

            {!isEqual(nonEmptyFilter, {}) && (
              <Stack direction="row" justifyContent="flex-start">
                <Button
                  size="small"
                  variant="text"
                  sx={{
                    paddingX: 2,
                    textDecoration: "underline",
                    textDecorationColor: (theme) => theme.palette.border?.primary,
                    textUnderlineOffset: 6,
                  }}
                  onClick={() => {
                    setFilter({});
                    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                      placementCandidateId: placementCandidate.id,
                      action: "filters_cleared",
                      filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                    });
                  }}
                >
                  Clear filters to view all {showApplications ? "applied jobs" : "jobs"}
                </Button>
              </Stack>
            )}
          </Box>

          {isPlacementsLoading && <PlacementsLoader />}

          {isPlacementsSuccess && (placementsData.pages[0]?.data.length ?? 0) === 0 && (
            <Stack justifyContent="center" flex={1} alignItems="center">
              <Text variant="body1">
                {showApplications
                  ? "You have not applied to any jobs yet!"
                  : "No relevant jobs listed yet!"}
              </Text>
            </Stack>
          )}

          {isDefined(placementsData) && placementsData.pages.length > 0 && (
            <Stack direction="column" spacing={6}>
              {placementsData?.pages.flatMap((placementPage, pageIndex) => {
                return placementPage.data.map((placement, placementIndex) => {
                  const placementWithDetails = transformResponseIntoPlacement(
                    placement,
                    placementPage.included
                  );
                  return (
                    <PlacementCardItem
                      key={placementWithDetails.id}
                      placement={placementWithDetails}
                      placementCandidateId={placementCandidate.id}
                      pageIndex={pageIndex}
                      placementIndex={placementIndex}
                    />
                  );
                });
              })}
            </Stack>
          )}

          {hasNextPage && (
            <Stack direction="row" justifyContent="center">
              <LoadingButton
                sx={{ mb: 10 }}
                isLoading={isFetchingNextPage}
                onClick={async () => {
                  await fetchNextPage();
                  logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                    placementCandidateId: placementCandidate.id,
                    filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                    action: "load_more_clicked",
                  });
                }}
              >
                Load more
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Box>
      {updateCandidateProfileModalState.modalIsOpen && (
        <PlacementCandidateProfileUpdateDialog
          updateCandidateProfileModalState={updateCandidateProfileModalState}
          placementCandidate={placementCandidate}
          worker={worker}
        />
      )}
    </>
  );
}
