import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";
import { parseISO } from "date-fns";
import { type ComponentProps } from "react";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { formatDateRangeDuration } from "../../utils/formatDateRangeDuration";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";
import { TimeSlotIcon } from "../TimeSlotIcon";
import { type ShiftWithType, type WorkerShiftWithType } from "../types";

interface WorkerShiftSummaryInfoProps {
  shift: WorkerShiftWithType | ShiftWithType;
}

const textDefaultProps: ComponentProps<typeof Text> = {
  semibold: true,
  color: (theme) => theme.palette.text.secondary,
  variant: "body2",
};

export function WorkerShiftSummaryInfo(props: WorkerShiftSummaryInfoProps) {
  const { shift } = props;
  const { facility, name: timeSlot, start, end, agentReq, offer, finalPay, originalAmount } = shift;

  const payBreakdown = getFormattedShiftPayBreakdown(offer?.pay.value, finalPay, originalAmount);

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };

  return (
    <Stack spacing={3} direction="row" alignItems="flex-start">
      <TimeSlotIcon timeSlot={timeSlot} size="medium" />

      <Stack spacing={1}>
        <TimeRangeLabel
          semibold
          dateRange={dateRange}
          timezone={facility.tmz}
          deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
          variant="h5"
        />

        <Text {...textDefaultProps}>
          <DotSeparatedList>
            {agentReq}
            {facility.name}
            {formatDateRangeDuration(dateRange)}
          </DotSeparatedList>
        </Text>

        {isDefined(payBreakdown) && (
          <Text {...textDefaultProps}>
            <DotSeparatedList>
              {`${payBreakdown.hourlyPay} /hr`}
              {`${payBreakdown.totalPay} total`}
              {/* TODO: Determine whether to show Extra Time Pay */}
            </DotSeparatedList>
          </Text>
        )}
      </Stack>
    </Stack>
  );
}
