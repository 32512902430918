import { Image, LoadingButton, Text } from "@clipboard-health/ui-react";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { getRedesignTheme } from "@src/appV2/redesign/theming";
import { useQueryClient } from "@tanstack/react-query";

import { getPlacementCandidateQueryKey } from "../api/placementCandidate.helpers";
import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { PlacementCandidateStatus } from "../types";
import { JobsFeatureListItem } from "./JobsFeatureListItem";

interface JobsIntroductionPageProps {
  placementCandidateId: string;
  workerId: string;
}

export function JobsIntroductionPage(props: JobsIntroductionPageProps) {
  const { placementCandidateId, workerId } = props;
  const queryClient = useQueryClient();
  const { mutateAsync: updatePlacementCandidate, isLoading: isUpdatePlacementCandidateLoading } =
    useUpdatePlacementCandidate();
  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <>
        <Stack flex={1} justifyContent="flex-start" alignItems="center" spacing={8}>
          <Image alt="Clipboard Health logo" src={cbhBunnyLogo} width="48px" />

          <Text variant="h3" align="center">
            Looking for your next permanent job?
          </Text>
          <Box sx={{ paddingX: 7, width: "100%", paddingTop: 8, paddingBottom: 10 }}>
            <Stack
              spacing={8}
              justifyContent="center"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <JobsFeatureListItem icon="passkeys" text="Create your job profile" />
              <JobsFeatureListItem icon="search" text="Search and apply to jobs" />
              <JobsFeatureListItem
                icon="text-bubble"
                text="Chat directly with facilities about open positions"
              />
              <JobsFeatureListItem
                icon="new"
                text="Full-time, part-time and per diem roles available"
              />
            </Stack>
          </Box>
        </Stack>
        <Stack spacing={7} justifyContent="flex-end" alignItems="center">
          <Box sx={{ paddingX: 5 }}>
            <Text
              align="center"
              variant="body2"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              It only takes 5 minutes to create your profile and unlock local job opportunities.
            </Text>
          </Box>
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            isLoading={isUpdatePlacementCandidateLoading}
            onClick={async () => {
              if (isUpdatePlacementCandidateLoading) {
                return;
              }

              logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_STATUS_UPDATED, {
                placementCandidateId,
                status: PlacementCandidateStatus.ONBOARDING,
              });

              await updatePlacementCandidate({
                placementCandidateId,
                status: PlacementCandidateStatus.ONBOARDING,
                workerId,
              });
              await queryClient.invalidateQueries({
                queryKey: getPlacementCandidateQueryKey(workerId),
              });
            }}
          >
            Let&apos;s go
          </LoadingButton>
        </Stack>
      </>
    </ThemeProvider>
  );
}
