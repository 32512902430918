import { Box, Skeleton, Stack } from "@mui/material";

export function WorkplaceProfileModalContentSkeleton() {
  return (
    <Stack spacing={8}>
      <Stack spacing={8}>
        <Box>
          <Skeleton
            variant="text"
            width="75%"
            sx={(theme) => ({
              marginBottom: 4,
              fontSize: theme.typography.h2.fontSize,
              lineHeight: theme.typography.h2.lineHeight,
            })}
          />
          <Skeleton
            variant="text"
            width="75%"
            sx={(theme) => ({
              fontSize: theme.typography.h3.fontSize,
              lineHeight: theme.typography.h3.lineHeight,
            })}
          />
        </Box>
        <Stack direction="row" spacing={3}>
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large,
              height: theme.size?.iconButton.large,
            })}
          />
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large,
              height: theme.size?.iconButton.large,
            })}
          />
          <Skeleton
            variant="circular"
            sx={(theme) => ({
              width: theme.size?.iconButton.large,
              height: theme.size?.iconButton.large,
            })}
          />
        </Stack>
      </Stack>
      <Skeleton
        variant="rounded"
        sx={{
          width: "100%",
          height: "100%",
          aspectRatio: "1.8/1",
        }}
      />
      <Skeleton
        variant="rounded"
        sx={{
          width: "100%",
          height: "100%",
          aspectRatio: "1.8/1",
        }}
      />
    </Stack>
  );
}
