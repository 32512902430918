import { CommonDialog, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useRef } from "react";

interface Props {
  modalState: UseModalState;
}
export function SkillsAssessmentHavingIssuesDialog(props: Props) {
  const { modalState } = props;
  const phoneNumberRef = useRef<HTMLAnchorElement>(null);

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <Stack width="100%" spacing={1}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              if (phoneNumberRef.current) {
                phoneNumberRef.current.click();
              }
            }}
          >
            Call
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Go Back
          </Button>
        </Stack>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }} marginY={2}>
          Having issues?
        </Title>
      }
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        If you&apos;re experiencing technical difficulties, please call our Skills Assessment line
        at{" "}
        <a
          ref={phoneNumberRef}
          href="tel:+15593544371"
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_CLICKED_HAVING_ISSUES_CALL_BUTTON);
          }}
        >
          (559) 354 4371
        </a>
        .
      </Text>
    </CommonDialog>
  );
}
