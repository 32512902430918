import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardContent, Divider, Grid, IconButton } from "@mui/material";
import {
  SignatureContainer,
  SignatureContainerRef,
} from "@src/app/shiftSignature/signatureContainer";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useQualityBonusFlag } from "@src/appV2/Shifts/Shift/useQualityBonusFlag";
import { Shift } from "@src/lib/interface";
import { TimeRange } from "@src/lib/interface/src";
import { useRef, useState } from "react";

import { WorkplaceFeedbackData } from "./types";
import { useCreateShiftSigningOutFeedback } from "./useCreateShiftFeedback";
import { WorkplaceFeedbackQuestion } from "./workplaceFeedbackQuestion";

type WorkplaceSignoutModalProps = {
  onSubmit: (file: SelectedFile) => void;
  onStrokeStarted: () => void;
  onBackClick: () => void;
  isUploadingImage: boolean;
  clockInOut: TimeRange;
  lunchInOut: TimeRange;
  facilityEmployeeName: string;
  shift: Shift;
  disabledQuestion?: boolean;
};

export function WorkplaceSignoutModalContent(props: WorkplaceSignoutModalProps) {
  const {
    onSubmit,
    onStrokeStarted,
    onBackClick,
    isUploadingImage,
    lunchInOut,
    clockInOut,
    facilityEmployeeName,
    shift,
    disabledQuestion = false,
  } = props;
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [signature, setSignature] = useState<string>("");
  const signaturePad = useRef<SignatureContainerRef>(null);
  const [showFeedbackQuestion, setShowFeedbackQuestion] = useState(false);
  const [workplaceFeedbackData, setWorkplaceFeedbackData] = useState<WorkplaceFeedbackData>();
  const { enabled: qualityBonusEnabled } = useQualityBonusFlag();
  const { mutate: createShiftSigningOutFeedback } = useCreateShiftSigningOutFeedback();
  const { firstQuestion, secondQuestion, secondResponse, firstResponse, secondResponseType } =
    workplaceFeedbackData ?? {};
  const workerName = shift.agent?.name;
  const facilityTimeZone = shift.facility?.tmz;

  function clearSignature() {
    signaturePad?.current?.clearSignature();
    setDisableSubmit(true);
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Sticky Header */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "background.paper",
          zIndex: 2,
          py: 2,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <IconButton
          aria-label="Back"
          onClick={() => {
            clearSignature();
            onBackClick();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Text variant="h3" ml={2}>
          {isDefined(workerName)
            ? `${facilityEmployeeName}, you are signing out ${workerName}, ${shift.agentReq}.`
            : `${facilityEmployeeName}, signing out worker`}
        </Text>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: 4,
        }}
      >
        {/* Time entries */}
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                Clock-in
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(clockInOut.start)
                  ? formatTime(clockInOut.start, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Break start
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(lunchInOut.start)
                  ? formatTime(lunchInOut.start, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Break end
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(lunchInOut.end)
                  ? formatTime(lunchInOut.end, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
              <Grid item xs={6}>
                Clock-out
              </Grid>
              <Grid item xs={6} textAlign="right">
                {isDefined(clockInOut.end)
                  ? formatTime(clockInOut.end, { timeZone: facilityTimeZone })
                  : " --:--"}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider />

        {(showFeedbackQuestion || isDefined(workplaceFeedbackData)) && (
          <>
            <WorkplaceFeedbackQuestion
              onSubmit={(data: WorkplaceFeedbackData) => {
                setWorkplaceFeedbackData(data);
                setShowFeedbackQuestion(false);
              }}
              onFeedbackDataUpdate={(data: WorkplaceFeedbackData) => {
                setWorkplaceFeedbackData({ ...workplaceFeedbackData, ...data });
              }}
              feedbackData={workplaceFeedbackData}
            />
            <Divider />
          </>
        )}

        {/* Signature field */}
        {!showFeedbackQuestion && (
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Text variant="body1">Please sign below:</Text>
              <Button
                color="primary"
                size="small"
                onClick={clearSignature}
                sx={{
                  textTransform: "none",
                  minWidth: "auto",
                  p: 0,
                  color: "primary.main",
                }}
              >
                Clear
              </Button>
            </Box>
            <Box sx={{ border: "2px solid", borderColor: "divider", mb: 2 }}>
              <SignatureContainer
                ref={signaturePad}
                onChange={(updatedSignature: string) => {
                  setSignature(updatedSignature);
                  setDisableSubmit(!updatedSignature || updatedSignature === "");
                }}
                onStrokeStarted={() => {
                  onStrokeStarted();
                  if (!isDefined(workplaceFeedbackData) && !disabledQuestion) {
                    setShowFeedbackQuestion(true);
                    clearSignature();
                  }
                }}
              />
            </Box>
          </Box>
        )}

        {!showFeedbackQuestion && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ py: 1.5, mb: 2 }}
            onClick={async () => {
              const file = {
                file: signature,
                type: "png",
              };
              onSubmit(file);
              logEvent(APP_V2_USER_EVENTS.FEEDBACK_ON_HCP_WORK_QUALITY, {
                first_question: firstQuestion,
                first_response: firstResponse,
                second_question: secondQuestion,
                second_response: secondResponse,
                shiftId: shift._id,
                signedBy: facilityEmployeeName,
                type: secondResponseType,
              });
              if (qualityBonusEnabled) {
                createShiftSigningOutFeedback({
                  shiftId: shift._id ?? "",
                  question: firstQuestion ?? "",
                  response: firstResponse ?? "",
                  signedBy: facilityEmployeeName,
                  workerId: shift.agentId ?? "",
                  type: secondResponseType,
                });
              }
            }}
            disabled={disableSubmit || isUploadingImage}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default WorkplaceSignoutModalContent;
