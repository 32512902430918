import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CardContent, Stack } from "@mui/material";
import { type ReactNode } from "react";

import { Illustration, type IllustrationType } from "../../components/Illustration";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetBookabilityCriteriaCardProps {
  pill?: ReactNode;
  illustrationType: IllustrationType;
  title: ReactNode;
  description: ReactNode;
  footer?: ReactNode;
}

/**
 * This is a generic card that is used to display info about unmet bookability criteria
 * All of such cards display similar UI with some custom texts and illustration
 */
export function ShiftBottomSheetBookabilityCriteriaCard(
  props: ShiftBottomSheetBookabilityCriteriaCardProps
) {
  const { title, pill, description, illustrationType, footer } = props;
  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          px: 6,
        }}
      >
        {isDefined(pill) && <Box sx={{ width: "100%", alignSelf: "flex-start" }}>{pill}</Box>}

        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={5}
          sx={{ textAlign: "center", textWrap: "balance", width: "100%", flexGrow: 1 }}
          px={6}
        >
          <Illustration type={illustrationType} />

          <Text semibold variant="h5">
            {title}
          </Text>

          <Text color={(theme) => theme.palette.text.secondary} variant="body2">
            {description}
          </Text>
        </Stack>

        {isDefined(footer) && <Box sx={{ width: "100%", mt: "auto" }}>{footer}</Box>}
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
