import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

interface IsEmbeddedHelloSignEnabledForDocumentProps {
  document: HcpRequirement;
  isHelloSignEmbeddedFlowEnabled: boolean;
}

export function isEmbeddedHelloSignEnabledForDocument(
  props: IsEmbeddedHelloSignEnabledForDocumentProps
) {
  const { document, isHelloSignEmbeddedFlowEnabled } = props;
  return Boolean(isHelloSignEmbeddedFlowEnabled && document.helloSignConfig?.isEmbeddedFlowEnabled);
}
