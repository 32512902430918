import { z } from "zod";

export enum AgentStage {
  ONBOARDING = "ONBOARDING",
  ENROLLED = "ENROLLED",
  PROBATION = "PROBATION",
  RESTRICTED = "RESTRICTED",
  DEACTIVATED = "DEACTIVATED",
  DELETION_REQUESTED = "DELETION_REQUESTED",
  SOFT_DELETED = "SOFT_DELETED",
}

export const licenseDataSchema = z.object({
  _id: z.string(),
  state: z.string(),
  qualification: z.string(),
  multiState: z.boolean(),
  expiresAt: z.string().nullable(),
});

export const agentSentHomePayoutParameterResponseSchema = z.object({
  time: z.number().describe("hours"),
  isPayable: z.boolean(),
  payableAmount: z.number(),
});

export type AgentLicenseData = z.infer<typeof licenseDataSchema>;

export type AgentSentHomePayoutParameterResponse = z.infer<
  typeof agentSentHomePayoutParameterResponseSchema
>;

export const externalPaymentAccounts = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
      attributes: z.object({
        type: z.enum(["bank", "card"]),
        externalAccountId: z.string(),
        debitCardExpirationStatus: z.enum(["expired", "expiring", "valid", "no_expiration_date"]),
      }),
    })
  ),
});

export type ExternalPaymentAccounts = z.infer<typeof externalPaymentAccounts>;
