import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";

import { MosaicGallery, type MosaicGalleryItem } from "../../components/MosaicGallery";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetFacilityPhotosCardProps {
  workplaceId?: string;
}

export function ShiftBottomSheetFacilityPhotosCard(props: ShiftBottomSheetFacilityPhotosCardProps) {
  const { workplaceId } = props;

  const { data } = useFacilityPhotos(
    {
      facilityId: workplaceId ?? "",
    },
    {
      enabled: isDefined(workplaceId) && workplaceId !== "",
    }
  );

  const photos = data?.photosList ?? [];
  const images = photos
    .map((photo) => {
      if (!photo.url) {
        return null;
      }

      return {
        src: photo.url,
        alt: photo.name ?? `facility-photo-${photo.index}`,
      };
    })
    .filter((image): image is MosaicGalleryItem => image !== null);

  if (images.length === 0) {
    return null;
  }

  return (
    <ShiftBottomSheetInnerCard>
      <MosaicGallery images={images} />
    </ShiftBottomSheetInnerCard>
  );
}
