/* eslint-disable import/max-dependencies */
import { Stack } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { USER_EVENTS } from "@src/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { GoogleMapCustomMarkerWrapper } from "../../GeoLocation/GoogleMapCustomMarkerWrapper";
import { MapMarkerExpanded } from "../../GeoLocation/MapMarkerExpanded";
import { MapMarkerInactive } from "../../GeoLocation/MapMarkerInactive";
import { MapMarkerLocation } from "../../GeoLocation/MapMarkerLocation";
import { ShiftDiscoveryCalloutsContainer } from "../CalloutsContainer";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
} from "../paths";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";
import { DEFAULT_MAP_ZOOM, googleMapOptions } from "./constants";
import { ShiftDiscoveryMapViewEmptyStateCard } from "./EmptyStateCard";
import { ShiftDiscoveryMapViewMapControls } from "./MapControls";
import { useDebugMapViewLocationFilter } from "./mapViewDebuggingUtils/useDebugMapViewLocationFilter";
import { MapViewPageHeader } from "./PageHeader";
import type { MapViewWorkplace } from "./types";
import { useLocationFilter } from "./useLocationFilter";
import { useMapFitOpenShiftsBounds } from "./useMapFitOpenShiftsBounds";
import {
  invalidateMapViewWorkplacesData,
  useMapViewWorkplacesData,
} from "./useMapViewWorkplacesData";
import { useWorkerLocations } from "./useWorkerLocations";
import { MapViewWorkplaceClusterer } from "./WorkplaceClusterer/Clusterer";

export function ShiftDiscoveryMapViewPage() {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const queryClient = useQueryClient();

  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();
  const { generateModalPath: generateWorkplaceModalPath } = useWorkplaceModalsDataContext();

  const { locationFilter, onChangeLocationFilter, onChangeLocationFilterAfterDistance } =
    useLocationFilter();

  const {
    data: { sortedMapViewWorkplaces: workplaces, unfilteredOpenShifts },
    isLoading,
    isSuccess,
    distanceFilter,
  } = useMapViewWorkplacesData({ geoLocation: locationFilter });

  useMapFitOpenShiftsBounds(workplaces, map);

  const { workerLocation, deviceLocation, homeLocation, shouldRequestLocationPermission } =
    useWorkerLocations();

  useLogEffect(USER_EVENTS.VIEW_OPEN_SHIFT_MAP, { trigger: "pageLoad" });

  function onWorkplaceClick(workplace: MapViewWorkplace) {
    logEvent(APP_V2_USER_EVENTS.WORKPLACE_OPEN_SHIFTS_OPENED, {
      workplaceId: workplace.id,
      trigger: "Shift Discovery Map View",
    });

    history.push(
      generateWorkplaceModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
        workplaceId: workplace.id,
      })
    );
  }

  useDebugMapViewLocationFilter({
    map,
    radiusInMiles: distanceFilter,
    locationFilter,
    /**
     * Enable debugging for drawing a circle around the location filter.
     * Useful for visualizing the radius used for fetching workplaces,
     * and checking if it's correctly triggering new fetches when the user moves the map.
     */
    enabled: false,
  });

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.tertiary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <MapViewPageHeader unfilteredOpenShifts={unfilteredOpenShifts} />

      <Stack sx={{ position: "relative", flex: 1 }}>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={googleMapOptions}
          onLoad={(map) => {
            setMap(map);
            if (workerLocation) {
              map.setCenter(workerLocation);
              onChangeLocationFilter(workerLocation);
            }
          }}
          onUnmount={() => {
            setMap(undefined);
          }}
          onCenterChanged={() => {
            onChangeLocationFilterAfterDistance(distanceFilter, map?.getCenter());
          }}
        >
          {workerLocation && (
            <GoogleMapCustomMarkerWrapper position={workerLocation}>
              <MapMarkerLocation />
            </GoogleMapCustomMarkerWrapper>
          )}

          <MapViewWorkplaceClusterer workplaces={workplaces} map={map}>
            {(workplace, position) => {
              const { rating } = workplace.attributes;
              const ratingValue = rating.value ? rating.value.toFixed(1) : undefined;
              return (
                <GoogleMapCustomMarkerWrapper key={workplace.id} position={position}>
                  {workplace.shiftsCount > 0 ? (
                    <MapMarkerExpanded
                      count={workplace.shiftsCount}
                      label="shift"
                      rating={ratingValue}
                      onClick={() => {
                        logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
                          workplaceId: workplace.id,
                          rating: rating.value,
                        });
                        onWorkplaceClick(workplace);
                      }}
                    />
                  ) : (
                    <MapMarkerInactive
                      onClick={() => {
                        logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
                          workplaceId: workplace.id,
                          rating: rating.value,
                        });
                        onWorkplaceClick(workplace);
                      }}
                    />
                  )}
                </GoogleMapCustomMarkerWrapper>
              );
            }}
          </MapViewWorkplaceClusterer>
        </GoogleMap>

        <ShiftDiscoveryMapViewMapControls
          canCenterToDevice={(!!deviceLocation && !!map) || shouldRequestLocationPermission}
          canCenterToHome={!!homeLocation && !!map}
          isLoading={isLoading}
          onCenterToHome={() => {
            if (map && homeLocation) {
              map.panTo(homeLocation);
              map.setZoom(DEFAULT_MAP_ZOOM);
              void invalidateMapViewWorkplacesData(queryClient);
              onChangeLocationFilter(homeLocation);
            }
          }}
          onCenterToDevice={() => {
            if (!deviceLocation && shouldRequestLocationPermission) {
              navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
              return;
            }

            if (map && deviceLocation) {
              map.panTo(deviceLocation);
              map.setZoom(DEFAULT_MAP_ZOOM);
              void invalidateMapViewWorkplacesData(queryClient);
              onChangeLocationFilter(deviceLocation);
            }
          }}
          onZoomIn={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom + 1);
            }
          }}
          onZoomOut={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom - 1);
            }
          }}
        >
          <ShiftDiscoveryCalloutsContainer
            unfilteredOpenShifts={isSuccess ? unfilteredOpenShifts : []}
            sx={{ marginTop: 2, px: 5 }}
          />
        </ShiftDiscoveryMapViewMapControls>

        <ShiftDiscoveryMapViewEmptyStateCard
          isVisible={
            isSuccess &&
            (workplaces?.length === 0 ||
              workplaces?.every((workplace) => workplace.shiftsCount === 0))
          }
        />
      </Stack>

      <ShiftDiscoverySearchModeSwitcher />
    </Stack>
  );
}
/* eslint-enable import/max-dependencies */
