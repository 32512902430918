import { defaultAttendanceScoreFeatureFlag } from "@src/appV2/AttendanceScore/constants";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useClipboardScoreRollout() {
  return useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });
}

export function useAttendanceScorePolicyLinks() {
  return useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS, {
    defaultValue: {},
  });
}

export function useDisableSecondShiftBonus() {
  return useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_INFO, {
    defaultValue: defaultAttendanceScoreFeatureFlag,
  });
}

export function useClipboardScorePriorityAccessShiftsPerWeek() {
  return useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_PRIORITY_ACCESS_SHIFTS_PER_WEEK, {
    defaultValue: 0,
  });
}
