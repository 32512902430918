import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { useSentHomeRequests } from "@src/appV2/Agents/api/useSentHomeRequests";
import { useBookedCases } from "@src/appV2/ExperimentalHomeHealth/BookedCases/api/useBookedCases";
import { TemporaryBookedCasesWithMockServer } from "@src/appV2/ExperimentalHomeHealth/BookedCases/TemporaryBookedCasesWithMockServer";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { CaseStatus } from "@src/appV2/ExperimentalHomeHealth/types";
import { useFacilityVerificationPreferences } from "@src/appV2/Facilities/api/useFacilityVerificationPreferences";
import { useGetChatChannels } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { FacilityChatMessagesButton } from "@src/appV2/Facilities/Chat/MessagesButton";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { EnforceLocationPermissionsContainer } from "@src/appV2/LocationPermission/EnforceLocationPermissionsContainer";
import { useOpenNegotiationsCount } from "@src/appV2/Negotiations/api/useOpenNegotiationsCount";
import { NotificationCenterButton } from "@src/appV2/NotificationCenter";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/redesign/ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { PendingShiftInvitesCount } from "@src/appV2/Shifts/ShiftInvites/PendingShiftInvitesCount";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, compareAsc, isBefore, parseISO } from "date-fns";
import { sumBy } from "lodash";
import { useLocation } from "react-router-dom";

import { AppBarHeader, PageWithHeader } from "../../lib";
import { useAgentUnverifiedShifts } from "../UnverifiedShifts/api/useAgentUnverifiedShifts";
import { useFutureShifts } from "./api/useFutureShifts";
import { useNextTwoDaysShifts } from "./api/useNextTwoDaysShifts";
import { EmptyMyShiftsPlaceholder } from "./components/EmptyMyShiftsPlaceholder";
import { PendingSentHomeRequestsCard } from "./components/PendingSentHomeRequestsCard";
import { RateNegotiationsCard } from "./components/RateNegotiationsCard";
import { ShiftCard } from "./components/ShiftCard";
import { ShiftCardLoadingPlaceholder } from "./components/ShiftCardLoadingPlaceholder";
// eslint-disable-next-line import/max-dependencies
import { UnverifiedShiftsCard } from "./components/UnverifiedShiftsCard";

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function MyShiftsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const worker = useDefinedWorker();

  const {
    isLoading: isLoadingNextTwoDaysShifts,
    data: nextTwoDaysShiftsData,
    refetch: refetchNextTwoDaysShifts,
  } = useNextTwoDaysShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const {
    isLoading: isLoadingFutureShifts,
    data: futureShiftsData,
    refetch: refetchFutureShifts,
  } = useFutureShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const {
    isSuccess: isSuccessUnverifiedShifts,
    data: unverifiedShiftsData,
    refetch: refetchUnverifiedShifts,
  } = useAgentUnverifiedShifts();

  const { data: extraWorkedTimeRequestsData, refetch: refetchExtraWorkedTimeRequests } =
    useExtraWorkedTimeRequests();

  const unverifiedShiftsFacilityIds =
    (unverifiedShiftsData?.response
      .map((shift) => shift.facility.userId)
      // For some reason, TS is not recognizing that we're filtering out undefined
      // values and thinks, the resulting type is (string | undefined)[]
      .filter((facilityId) => isDefined(facilityId)) as string[]) ?? [];

  const { data: facilityVerificationPreferencesData } = useFacilityVerificationPreferences(
    { facilityIds: unverifiedShiftsFacilityIds },
    { enabled: isSuccessUnverifiedShifts && unverifiedShiftsFacilityIds.length > 0 }
  );

  const { data: openNegotiationsCount, refetch: refetchOpenNegotiationsCount } =
    useOpenNegotiationsCount();

  const { data: sentHomeRequestsData, refetch: refetchSentHomeRequests } = useSentHomeRequests();

  const {
    isLoading: isLoadingChatChannels,
    data: chatChannelsData,
    refetch: refetchChatChannels,
  } = useGetChatChannels();

  const nextTwoDaysShifts = nextTwoDaysShiftsData?.response ?? [];
  const futureShifts = futureShiftsData?.response ?? [];
  const activeNegotiationsCount = openNegotiationsCount?.totalNegotiatingShiftsCount ?? 0;
  const facilityProposalsCount = openNegotiationsCount?.negotiationsWaitingOnWorker ?? 0;
  const recentlyEndedNegotiationsCount = openNegotiationsCount?.recentlyEndedNegotiationsCount ?? 0;
  const sentHomeRequests = sentHomeRequestsData ?? [];
  const unverifiedShifts = unverifiedShiftsData?.response ?? [];
  const extraWorkedRequests = extraWorkedTimeRequestsData?.data ?? [];
  const chatChannels = chatChannelsData?.channels ?? [];

  const shifts = [...nextTwoDaysShifts, ...futureShifts].sort((a, b) => {
    return compareAsc(parseISO(a.start), parseISO(b.start));
  });

  const isLoading = isLoadingNextTwoDaysShifts || isLoadingFutureShifts || isLoadingChatChannels;

  const shiftsWithMissingTimesheets = unverifiedShifts.filter((shift) => {
    const hasReason = isDefined(shift.timecardNotAvailable?.reason);
    const hasFiles =
      isDefined(shift.timecard) &&
      isDefined(shift.timecard?.files) &&
      shift.timecard.files.length > 0;

    const verificationPreferences = facilityVerificationPreferencesData?.find((preferences) => {
      return isDefined(preferences.mongodbId) && preferences.mongodbId === shift.facility.userId;
    });
    const usesTimesheets = isDefined(verificationPreferences?.usesTimesheets);

    if (!isDefined(shift) || hasReason || hasFiles || !usesTimesheets) {
      return false;
    }

    const shiftVerificationTimeout = shift.businessrules?.value;
    return (
      !isDefined(shiftVerificationTimeout) ||
      isBefore(
        new Date(),
        addHours(parseISO(shift.end), Number.parseInt(shiftVerificationTimeout, 10))
      )
    );
  });

  const isHomeHealthEnabled = useIsHomeHealthEnabled();

  const { refetch: refetchHomeHealthOpenCases } = useBookedCases(
    {
      filter: { status: CaseStatus.OPEN },
    },
    { enabled: false }
  );

  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();

  return (
    <IonPage>
      <PageWithHeader
        containerMaxWidth="md"
        appBarHeader={
          <AppBarHeader
            title="My Shifts"
            leftCta={<NotificationCenterButton />}
            rightCta={
              isShiftDiscoveryEnabled ? null : <FacilityChatMessagesButton agentId={worker._id} />
            }
          />
        }
      >
        <EnforceLocationPermissionsContainer />
        <PullToRefresh
          onRefresh={async () => {
            await Promise.all([
              refetchNextTwoDaysShifts(),
              refetchFutureShifts(),
              refetchUnverifiedShifts(),
              refetchExtraWorkedTimeRequests(),
              refetchOpenNegotiationsCount(),
              refetchSentHomeRequests(),
              refetchChatChannels(),
              isHomeHealthEnabled ? refetchHomeHealthOpenCases() : Promise.resolve(),
            ]);
          }}
        />
        <Stack spacing={2}>
          <RateNegotiationsCard
            activeNegotiationsCount={activeNegotiationsCount}
            facilityProposalsCount={facilityProposalsCount}
            recentlyEndedNegotiationsCount={recentlyEndedNegotiationsCount}
            deepLinkNegotiationId={searchParams.get("negotiationId") ?? undefined}
          />
          {sentHomeRequests.length > 0 ? (
            <PendingSentHomeRequestsCard
              agentId={worker._id}
              pendingSentHomeRequestsCount={sentHomeRequests.length}
              totalEarnings={sumBy(sentHomeRequests, (request) => request.earnings)}
            />
          ) : null}
          {unverifiedShifts.length > 0 || extraWorkedRequests.length > 0 ? (
            <UnverifiedShiftsCard
              agentId={worker._id}
              unverifiedShiftsCount={unverifiedShifts.length}
              missingTimesheetsCount={shiftsWithMissingTimesheets.length}
              extraWorkedTimeRequestsCount={extraWorkedRequests.length}
            />
          ) : null}
          <PendingShiftInvitesCount />
          {isLoading && <ShiftCardLoadingPlaceholder />}
          {!isLoading && shifts.length === 0 && <EmptyMyShiftsPlaceholder />}
          {shifts
            .filter((shift) => {
              return (
                !isDefined(shift._id) ||
                !sentHomeRequests.some((request) => request.shift._id === shift._id)
              );
            })
            .map((shift) => (
              <ShiftCard
                key={shift._id}
                worker={worker}
                shift={shift}
                chatChannels={chatChannels}
              />
            ))}
          {isHomeHealthEnabled && <TemporaryBookedCasesWithMockServer />}
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
