import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

import { getSafeAreaPaddings } from "../../utils/getSafeAreaPaddings";

export const muiBottomNavigationOverrides: Components<Theme> = {
  MuiBottomNavigation: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginTop: "auto",
        paddingTop: theme.spacing(5),
        ...getSafeAreaPaddings({
          bottom: true,
          left: true,
          right: true,
          fallbackBottom: theme.spacing(5),
          fallbackLeft: theme.spacing(7),
          fallbackRight: theme.spacing(7),
        }),
        justifyContent: "space-between",
        backgroundColor: theme.palette.background?.tertiary,
        // override MUI's default height
        height: "fit-content",
        boxShadow: theme.shadows[4],
      }),
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: "48px",
        maxWidth: "48px",
        minWidth: "unset",
        height: "48px",
        maxHeight: "48px",
        minHeight: "unset",
        borderRadius: theme.borderRadius?.small,

        "&.Mui-selected": {
          color: theme.palette.text?.invertPrimary,
          backgroundColor: theme.palette.primary?.main,
        },

        "& svg": {
          height: "28px",
          width: "28px",
        },
      }),
    },
  },
};
