import { AgentStage } from "@src/appV2/Agents";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { Callout } from "../components/Callout";

export function AccountRestrictedCallout() {
  const { stage } = useDefinedWorker();

  const history = useHistory();

  if (stage !== AgentStage.RESTRICTED) {
    return null;
  }

  return (
    <Callout
      title="Your account is restricted"
      iconType="remove"
      sx={(theme) => ({
        backgroundColor: theme.palette.callout?.error.label,
        color: theme.palette.callout?.error.text,
      })}
      onClick={() => {
        history.push(DeprecatedGlobalAppV1Paths.RATINGS_ATTENDANCE_SCORE);
      }}
    />
  );
}
