import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { convertToGeoLocation, convertToGoogleMapsLocation } from "@src/appV2/Location";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { DialogHeader } from "@src/appV2/redesign/components/DialogHeader";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceProfileModalContentSkeleton } from "@src/appV2/redesign/Workplace/Profile/ModalContentSkeleton";
import { type WorkplaceProfile } from "@src/appV2/redesign/Workplace/types";
import { useHistory } from "react-router-dom";

import { PLACEMENT_WORKPLACE_JOBS_PATH } from "../paths";
import { WorkplaceProfileOverview } from "./WorkplaceProfileOverview";

interface WorkplaceProfileModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading?: boolean;
}

export function WorkplaceProfileModal(props: WorkplaceProfileModalProps) {
  const { modalState, workplaceId, workplace, isLoading } = props;

  const history = useHistory();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />
        {isLoading && !isDefined(workplace) && <WorkplaceProfileModalContentSkeleton />}
        {isDefined(workplace) ? (
          <WorkplaceProfileOverview
            name={workplace.name}
            type={String(workplace.type)}
            workplaceId={String(workplaceId)}
            phoneNumber={workplace.phone}
            location={convertToGoogleMapsLocation(
              convertToGeoLocation(workplace.geoLocation.coordinates)
            )}
            formattedAddress={workplace.fullAddress?.formatted}
          />
        ) : undefined}
      </DialogContent>

      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => String(theme.palette.background.tertiary)}
        onClose={() => {
          modalState.closeModal();
        }}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ maxWidth: "60%" }}
          onClick={() => {
            history.push(
              generateModalPath(PLACEMENT_WORKPLACE_JOBS_PATH, {
                workplaceId,
              })
            );
          }}
        >
          View jobs
        </Button>
      </DialogFooter>
    </FullScreenDialog>
  );
}
