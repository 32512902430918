/* eslint-disable no-nested-ternary */
import { isDefined } from "@clipboard-health/util-ts";
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { OnboardingScheduleAssessmentInstructions } from "@src/appV2/SkillsAssessment/components/Status/OnboardingScheduleAssessmentInstructions";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";

export function ScheduledAssessmentOnboardingInstructions(props: OnBoardingComponentProps) {
  const { nextScreen, previousScreen } = props;

  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_INSTRUCTIONS_PAGE_VIEWED);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={previousScreen} mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Stack
        alignItems="center"
        width="100%"
        height="100%"
        paddingY={5}
        paddingX={5}
        overflow="auto"
      >
        <OnboardingScheduleAssessmentInstructions
          onClose={() => {
            if (isDefined(nextScreen)) {
              history.push(nextScreen);
            }
          }}
        />
      </Stack>
    </StyledIonPage>
  );
}
