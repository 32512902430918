import { ModalStatus, Text, Title, useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { useAppStateContext } from "@src/appV2/App/useAppState";
import { CommonDialog } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSkillsAssessmentFeatureFlags } from "../api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "../api/useSkillsAssessments";
import { SKILLS_ASSESSMENT_BASE_PATH } from "../paths";
import {
  type AssessmentEvent,
  AssessmentType,
  type SkillsAssessmentStatus as SkillsAssessmentStatusType,
} from "../types";
import { checkIsWorkerAllowedToTakeAssessment } from "../utils/checkIsWorkerAllowedToTakeAssessment";

export function AssessmentStartTimePassedDialog() {
  const worker = useDefinedWorker();
  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const history = useHistory();
  const {
    state: { isAppActive },
  } = useAppStateContext();

  const { data: assessments, isSuccess } = useSkillsAssessments(
    {
      workerId: worker.userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    {
      enabled: isAppActive,
    }
  );

  const assessmentStatus = assessments?.included.find(
    (included) => included.type === "assessment-status"
  ) as SkillsAssessmentStatusType;

  const assessmentScheduledEvent = assessments?.included.find(
    (included) => included.type === "assessment-event"
  ) as AssessmentEvent | undefined;

  const isAllowedToTakeAssessment = isSuccess
    ? checkIsWorkerAllowedToTakeAssessment({
        assessmentType: assessmentStatus?.attributes.type,
        assessmentStatus: assessmentStatus?.attributes.status,
        assessmentScheduledFor: assessmentScheduledEvent?.attributes.scheduledFor,
        allowedEarlinessInMinutes: scheduledAssessmentConfig.allowedEarlinessInMinutes,
        retakeAllowedAfter: assessmentStatus?.attributes.retakeAllowedAfter,
      })
    : false;

  // We only show the dialog if the assessment is scheduled and not for async. assessments for now
  const isScheduledAssessment = assessmentStatus?.attributes.type === AssessmentType.SCHEDULED;
  const dialogState = useModalState(
    isAllowedToTakeAssessment && isScheduledAssessment ? ModalStatus.OPEN : ModalStatus.CLOSED
  );
  const { setModalStatus } = dialogState;

  useEffect(() => {
    if (isAllowedToTakeAssessment && isScheduledAssessment) {
      setModalStatus(ModalStatus.OPEN);
    } else {
      setModalStatus(ModalStatus.CLOSED);
    }
  }, [
    isAllowedToTakeAssessment,
    isScheduledAssessment,
    setModalStatus,

    // if isAppActive change we need to show the dialog again
    isAppActive,
  ]);

  const location = useLocation();
  // if already on the assessment page, we don't need to show the dialog
  if (location.pathname === SKILLS_ASSESSMENT_BASE_PATH) {
    return null;
  }

  if (![AgentStage.ENROLLED, AgentStage.RESTRICTED, AgentStage.ONBOARDING].includes(worker.stage)) {
    return null;
  }

  function handleCloseModal() {
    dialogState.closeModal();
    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_START_TIME_PASSED_DIALOG_CLOSED);
  }

  return (
    <CommonDialog
      modalState={dialogState}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              logEvent(
                APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_START_TIME_PASSED_DIALOG_START_NOW_CLICKED
              );
              dialogState.closeModal();
              history.push(SKILLS_ASSESSMENT_BASE_PATH);
            }}
          >
            START NOW
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              handleCloseModal();
            }}
          >
            GO BACK
          </Button>
        </>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }} marginY={2}>
          Complete your Skills Assessment
        </Title>
      }
      onClose={() => {
        handleCloseModal();
      }}
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        The scheduled start time of your assessment has passed. Continue to take it now.
      </Text>
    </CommonDialog>
  );
}
