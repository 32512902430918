import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Paper, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { DialogHeader } from "@src/appV2/redesign/components/DialogHeader";
import { type WorkplaceCommentModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useGetComment } from "@src/appV2/Reviews/api/useGetComment";
import { useWorkplaceComments } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useParams } from "react-router-dom";

import { WorkplaceReviewCommentCard } from "./Card";
import { WorkplaceReviewCommentAuthorInfo } from "./CommentAuthorInfo";
import { WorkplaceReviewCommentLikesButton } from "./LikesButton";
import { WorkplaceCommentPostReplyFooter } from "./PostReplyFooter";
import {
  updateCommentListCacheWithReaction,
  updateCommentRepliesListCacheWithReaction,
  updateSingleCommentCacheWithReaction,
} from "./queryCache";
import { WorkplaceReviewCommentRepliesAndLikesWrapper } from "./RepliesAndLikesWrapper";
import { WorkplaceReviewCommentRepliesCountIcon } from "./RepliesCountIcon";

interface WorkplaceCommentModalProps {
  modalState: UseModalState;
  workplaceId: string;
}

export function WorkplaceCommentModal(props: WorkplaceCommentModalProps) {
  const { modalState, workplaceId } = props;

  const { commentId } = useParams<WorkplaceCommentModalRoutePathParams>();

  // TODO: This modal needs to be split into a container component that handles loading states
  // and a presentational component that receives the destructured comment data
  const { data: commentData, isSuccess: isCommentSuccess } = useGetComment({
    workplaceId,
    commentId,
  });

  const { data: replies, isSuccess: isRepliesSuccess } = useWorkplaceComments({
    workplaceId,
    filter: {
      parentCommentId: commentId,
    },
  });

  const queryClient = useQueryClient();

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="tertiary">
      <DialogHeader sx={{ px: 7 }} />

      <Stack sx={{ overflow: "hidden", height: "100%" }}>
        <Paper variant="tertiary" sx={{ px: 7, paddingBottom: 8 }}>
          {/* TODO: This should be h6. https://linear.app/clipboardhealth/issue/SPRTA-916/add-h6-typography-variant */}
          <Text semibold variant="h5" sx={{ paddingBottom: 8 }}>
            {commentData?.data.attributes.text}
          </Text>

          {isCommentSuccess && (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <WorkplaceReviewCommentAuthorInfo
                workerName={commentData.data.attributes.workerInfo.name}
                workerId={commentData.data.attributes.workerInfo.id}
                createdAt={parseISO(commentData.data.attributes.createdAt)}
              />

              <WorkplaceReviewCommentRepliesAndLikesWrapper>
                <WorkplaceReviewCommentRepliesCountIcon
                  count={commentData.data.attributes.aggregates.totalReplies}
                />
                <WorkplaceReviewCommentLikesButton
                  workplaceId={workplaceId}
                  commentId={commentId}
                  isLiked={commentData.data.attributes.workerReaction === Reaction.LIKE}
                  count={commentData.data.attributes.aggregates.totalLikes}
                  onLikeChanged={(reaction) => {
                    updateSingleCommentCacheWithReaction({
                      queryClient,
                      commentId,
                      workplaceId,
                      reaction,
                    });

                    updateCommentListCacheWithReaction({
                      queryClient,
                      commentId,
                      workplaceId,
                      reaction,
                    });
                  }}
                />
              </WorkplaceReviewCommentRepliesAndLikesWrapper>
            </Stack>
          )}
        </Paper>

        <Paper variant="secondary" sx={{ px: 5, py: 7, flexGrow: 1, overflow: "auto" }}>
          {isRepliesSuccess && (
            <Stack spacing={5}>
              {replies.length === 0 && (
                <Text variant="h5" sx={{ textAlign: "center" }}>
                  {/* TODO: This should be h6. https://linear.app/clipboardhealth/issue/SPRTA-916/add-h6-typography-variant */}
                  No replies yet. Be the first to reply!
                </Text>
              )}

              {replies.map((reply) => (
                <WorkplaceReviewCommentCard
                  key={reply.id}
                  workplaceId={workplaceId}
                  comment={reply}
                  onLikeChanged={(reaction) => {
                    updateCommentRepliesListCacheWithReaction({
                      queryClient,
                      commentId: reply.id,
                      workplaceId,
                      reaction,
                      parentCommentId: commentId,
                    });
                  }}
                />
              ))}
            </Stack>
          )}
        </Paper>
      </Stack>

      <WorkplaceCommentPostReplyFooter workplaceId={workplaceId} parentId={String(commentId)} />
    </FullScreenDialog>
  );
}
