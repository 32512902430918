import {
  ToggleButtonGroup as BaseToggleButtonGroup,
  type ToggleButtonGroupProps,
} from "@mui/material";

interface FiltersToggleButtonGroupProps<V>
  extends Omit<ToggleButtonGroupProps, "value" | "onChange"> {
  value?: V;
  onChange: (value: V | undefined) => void;
}

export function FiltersToggleButtonGroup<V>({
  onChange,
  ...props
}: FiltersToggleButtonGroupProps<V>) {
  return (
    <BaseToggleButtonGroup
      size="small"
      sx={(theme) => ({
        justifyContent: "flex-start",
        gap: 3,
        flexWrap: "wrap",
        padding: 0,

        "& .MuiToggleButton-root svg": {
          marginRight: theme.spacing(3),
        },
      })}
      onChange={(_event, value: V) => {
        // Value can be null if user clicks twice on the same button.
        // In that case we want to use undefined instead.
        onChange(value ?? undefined);
      }}
      {...props}
    />
  );
}
