import { convertToGeoLocation } from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";

import { ShiftType } from "../../Shift/constants";
import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { resolveShiftType } from "../../Shift/resolveShiftType";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

export function usePriorityShiftsData() {
  const { dates: dateStrings, distance } = useShiftDiscoveryUserFiltersContext();
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const worker = useDefinedWorker();
  const coordinates = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const { shifts, ...rest } = useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      area: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: distance,
      },
    },
    enabled: worker.geoLocation?.coordinates.length === 2,
  });

  return {
    ...rest,
    shifts: shifts.filter((shift) => {
      const shiftType = resolveShiftType({
        isUrgent: shift.attributes.isUrgent,
        priorityTill: shift.attributes.priorityTill,
        window: shift.attributes.window,
      });

      return shiftType === ShiftType.PRIORITY || shiftType === ShiftType.A_TEAM;
    }),
  };
}
