import { type Components, type Theme } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonOwnProps {
    /**
     * Inverts the button colors.
     *
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    invert?: "true" | "false";
  }
}

/**
 * Use Button from src/appV2/ShiftDiscovery/components/Button.tsx
 * instead of MUI Button.
 */
export const muiButtonOverrides: Components<Theme> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      draggable: false,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.borderRadius?.xLarge,
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: "unset",
        lineHeight: "1.5rem",
        borderRadius: theme.borderRadius?.xLarge,
        fontWeight: theme.typography.fontWeightMedium,

        // These icons have negative margin by default,
        // we need to override it.
        "& .MuiButton-startIcon": {
          marginLeft: 0,
        },

        "& .MuiButton-endIcon": {
          marginRight: 0,
        },

        "&.Mui-disabled": {
          color: theme.palette.text?.disabled,

          "& svg": {
            color: theme.palette.icon?.disabled,
          },
        },
      }),
      sizeSmall: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        padding: "0.5rem 1.25rem", // 8px 20px
        height: theme.size?.button.small.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.small.iconSize,
          height: theme.size?.button.small.iconSize,
        },
      }),
      sizeMedium: ({ theme }) => ({
        padding: "0.75rem 1.25rem", // 12px 20px
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        height: theme.size?.button.medium.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.medium.iconSize,
          height: theme.size?.button.medium.iconSize,
        },
      }),
      sizeLarge: ({ theme }) => ({
        padding: "1.25rem 1.5rem", // 20px 24px
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        height: theme.size?.button.large.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.large.iconSize,
          height: theme.size?.button.large.iconSize,
        },
      }),
      contained: ({ theme }) => ({
        "@media (hover: hover)": {
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary?.dark,
          },
        },

        "@media (hover: none)": {
          "&:active": {
            backgroundColor: theme.palette.primary?.dark,
          },
        },

        "&.Mui-disabled": {
          backgroundColor: theme.palette.disabled?.tertiary,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.primary,
              backgroundColor: theme.palette.background?.tertiary,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  backgroundColor: theme.palette.background?.primary,
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  backgroundColor: theme.palette.background?.tertiary,
                },

                "&:active": {
                  backgroundColor: theme.palette.background?.primary,
                },
              },
            },
          },
        ],
      }),
      outlined: ({ theme }) => ({
        border: `1px solid ${String(theme.palette.border?.primary)}`,
        backgroundColor: "transparent",

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${String(theme.palette.border?.dark)}`,
            // Override default hover background color.
            backgroundColor: "transparent",
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile to look like default styles.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            border: `1px solid ${String(theme.palette.border?.primary)}`,
          },

          "&:active": {
            border: `1px solid ${String(theme.palette.border?.dark)}`,
          },
        },

        "&.Mui-disabled": {
          border: `1px solid ${String(theme.palette.disabled?.secondary)}`,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.invertPrimary,
              border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,
                },

                "&:active": {
                  border: `1px solid ${String(theme.palette.border?.darkInvert)}`,
                },
              },

              "&.Mui-disabled": {
                color: theme.palette.text?.invertTertiary,
                border: `1px solid ${String(theme.palette.border?.subtleInvert)}`,

                "& svg": {
                  color: theme.palette.icon?.darkInvert,
                },
              },
            },
          },
        ],
      }),
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.primary?.main,

        "&.Mui-disabled": {
          border: `1px solid ${String(theme.palette.disabled?.secondary)}`,
        },
      }),

      // Used by our custom destructive buttons
      outlinedError: ({ theme }) => ({
        color: theme.palette.text?.destructive,

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${String(theme.palette.border?.destructive)}`,
          },
        },

        "@media (hover: none)": {
          "&:active": {
            border: `1px solid ${String(theme.palette.border?.destructive)}`,
          },
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.invertDestructive,
            },
          },
        ],
      }),
    },
  },
};
