import { Box, Stack } from "@mui/material";

import { BookedShiftsIconRow } from "../../Calendar/BookedShiftsIconRow";
import { type TimeSlot } from "../Open/types";

interface ShiftDateCellAvailabilityInfoProps {
  bookedShiftTimeSlots: TimeSlot[];
  size?: "medium" | "small";
  isDateAvailable?: boolean;
  disabled?: boolean;
}

export function ShiftDateCellAvailabilityInfo(props: ShiftDateCellAvailabilityInfoProps) {
  const { bookedShiftTimeSlots, isDateAvailable, disabled, size } = props;

  const renderAvailableShiftIcon = isDateAvailable && bookedShiftTimeSlots.length <= 1;
  const maxShiftIconsCountToShow = size === "small" ? 1 : 3;

  return (
    <Stack direction="row" alignItems="center" height="1rem" spacing={1}>
      <BookedShiftsIconRow
        disabled={disabled}
        maxShiftIconsCountToShow={maxShiftIconsCountToShow}
        bookedShiftTimeSlots={bookedShiftTimeSlots}
      />

      {renderAvailableShiftIcon && (
        <Box
          aria-label="Date Cell Open Shift Indicator"
          sx={(theme) => ({
            backgroundColor: disabled
              ? theme.palette.disabled?.primary
              : theme.palette.primary.main,
            borderRadius: "50%",
            width: "0.375rem",
            height: "0.375rem",
          })}
        />
      )}
    </Stack>
  );
}
