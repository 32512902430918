import { Stack } from "@mui/material";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import { OpenGeoLocationProvider } from "@src/appV2/redesign/components/OpenGeoLocation/useOpenGeoLocation";
import { WorkplaceProfileHeading } from "@src/appV2/redesign/Workplace/Profile/Heading";
import { WorkplaceHighlightsAndReviewsCard } from "@src/appV2/redesign/Workplace/Profile/HighlightsAndReviewsCard";
import { WorkplaceProfileLocationAndGallery } from "@src/appV2/redesign/Workplace/Profile/LocationAndGallery";
import { useDistanceToWorkplace } from "@src/appV2/redesign/Workplace/useDistanceToWorkplace";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";

import { WorkplaceProfileActions } from "./WorkplaceProfileActions";

interface WorkplaceProfileOverviewSectionProps {
  name: string;
  type: string;
  workplaceId: string;
  phoneNumber?: string;
  location: Location;
  formattedAddress?: string;
}

export function WorkplaceProfileOverview(props: WorkplaceProfileOverviewSectionProps) {
  const { name, type, workplaceId, phoneNumber, location, formattedAddress } = props;

  const { data: ratingAggregates, isSuccess: ratingAggregatesIsSuccess } = useGetRatingAggregates(
    String(workplaceId)
  );

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation: {
      latitude: location.lat,
      longitude: location.lng,
    },
  });

  return (
    <>
      <Stack spacing={8}>
        <WorkplaceProfileHeading
          name={name}
          averageRating={ratingAggregatesIsSuccess ? ratingAggregates?.avgRating : undefined}
          type={type}
          distanceIsLoading={distanceIsLoading}
          distanceIsError={distanceIsError}
          distanceText={formattedDistance}
        />

        <OpenGeoLocationProvider
          location={location}
          name={name}
          formattedAddress={formattedAddress}
        >
          <WorkplaceProfileActions phoneNumber={phoneNumber} location={location} />
        </OpenGeoLocationProvider>
      </Stack>

      <WorkplaceProfileLocationAndGallery
        workplaceId={workplaceId}
        location={location}
        scrollViewOuterPaddingCompensation={7}
        scrollViewSx={(theme) => ({
          height: "max(13.5rem, 25vh)",
          boxSizing: "content-box",

          // Add padding instead of Stack spacing to ensure box shadows are fully visible
          py: 9,
          // Reduce the total spacing by using negative margin since the padding is larger
          // than needed (to make the shadows fully visible)
          my: `calc(-1 * ${theme.spacing(3)})`,

          // Style all direct children with elevation and rounded corners
          "& > *": {
            boxShadow: theme.shadows[3],
            borderRadius: theme.borderRadius?.medium,
          },
        })}
      />
      <WorkplaceHighlightsAndReviewsCard workplaceId={String(workplaceId)} />
    </>
  );
}
