import { Network } from "@capacitor/network";
import { IonButton, IonLabel } from "@ionic/react";
import { useDefinedEnv } from "@src/app/store/helperHooks/sessionHelpers";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/lib/interface/src";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";

import { USER_EVENTS } from "../../../constants/userEvents";
import { InstantShiftButton } from "../../components/shift/InstantShiftButton";
import { ShiftDetailsAlerts } from "../shiftDetails/alerts";

const RecordTimeButton: React.VFC<{
  shift?: Shift;
  shiftStateData?: ShiftStateData;
  loadShiftDetails: (shiftId: string) => void;
  uploadTimeSheet: (boolean?: boolean) => void;
  setOpenNoTimeSheetOptions: (v: boolean) => void;
  timeSheetExists: boolean;
  isTimeSheetRequired: boolean;
  isNewTimeSheetEnabled: boolean;
  shiftDetailsAlerts: ShiftDetailsAlerts;
  isSignatureSubmission: boolean;
  isNFCEnabled: boolean;
  isDigitalSignatureEnabled: boolean;
  isCaliforniaTimesheetEnabled: boolean;
  isUploadTimesheetNonIpEnabled: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  isHcfTimeclockComplianceEnabled: boolean;
}> = ({
  shift,
  shiftStateData,
  loadShiftDetails,
  uploadTimeSheet,
  setOpenNoTimeSheetOptions,
  timeSheetExists,
  isTimeSheetRequired,
  isNewTimeSheetEnabled,
  shiftDetailsAlerts,
  isSignatureSubmission,
  isNFCEnabled,
  isDigitalSignatureEnabled,
  isCaliforniaTimesheetEnabled,
  isUploadTimesheetNonIpEnabled,
  isSolveUnpaidBreaksEnabled,
  isHcfTimeclockComplianceEnabled,
}) => {
  const [timeElapsed, setTimeElapsed] = useState<boolean>(false);
  const env = useDefinedEnv();
  const [isShiftStarted, setIsShiftStarted] = useState(false);

  const timeToConvertToNonIpInMinutes = useCbhFlag(
    CbhFeatureFlag.CONVERT_TO_NONIP_AFTER_X_MINUTES,
    { defaultValue: 60 }
  );

  const isEnableUploadTimesheetNonIpDigitalSignature = useMemo(() => {
    return (
      isUploadTimesheetNonIpEnabled &&
      isNewTimeSheetEnabled &&
      isDigitalSignatureEnabled &&
      shift?.isChangedToNonInstantPay
    );
  }, [isUploadTimesheetNonIpEnabled, isNewTimeSheetEnabled, isDigitalSignatureEnabled, shift]);

  useEffect(() => {
    if (!shift) {
      return;
    }

    let diff = moment(shift.start).add(timeToConvertToNonIpInMinutes, "minutes").diff(moment());
    if (isEnableUploadTimesheetNonIpDigitalSignature && shift?.start) {
      // when digital signature shift is converted to non-IP, this condition will make always shows timesheet as enabled when shift > shift start time
      diff = moment(shift.start).diff(moment());
    }

    setTimeElapsed(diff <= 0);
    const timeout = setTimeout(() => {
      setTimeElapsed(true);
    }, diff);

    return () => clearTimeout(timeout);
  }, [
    timeToConvertToNonIpInMinutes,
    isEnableUploadTimesheetNonIpDigitalSignature,
    shift,
    timeElapsed,
  ]);

  useEffect(() => {
    if (!shift) {
      return;
    }
    const isShiftStartedDiff = moment(shift?.start).diff(moment());
    setIsShiftStarted(isShiftStartedDiff <= 0);
    const timeout = setTimeout(() => {
      setIsShiftStarted(true);
    }, isShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  if (!shift || !shiftStateData) {
    // don't render action buttons if we don't yet know what to render
    return null;
  }

  const handleUploadTimeSheet = async () => {
    // Network check
    const status = await Network.getStatus();
    if (!status.connected) {
      shiftDetailsAlerts.alertNoNetworkConnection({ isEdit: true });
      return;
    }
    const event = shift?.isInstantPay
      ? USER_EVENTS.TAPPED_TO_UPLOAD_INSTANTPAY_TIMECARD_PHOTO
      : USER_EVENTS.TAPPED_TO_UPLOAD_TIMECARD_PHOTO;
    logEvent(event, {
      instant_pay: shift?.isInstantPay === true,
    });
    uploadTimeSheet();
  };

  const isInstantPayShiftDeleted = shift?.instantPayDetails && shift?.instantPayDetails.deleted;

  const showUploadTimesheet =
    (shift.isChangedToNonInstantPay && (!isNewTimeSheetEnabled || !isDigitalSignatureEnabled)) ||
    !shift.isChangedToNonInstantPay ||
    // on digital signature shifts show Upload timesheet when shift is converted to non-IP for digital
    isEnableUploadTimesheetNonIpDigitalSignature;

  const openNoTimeSheet = () => {
    logEvent("Doesn’t Have Timecard");
    setOpenNoTimeSheetOptions(true);
  };

  let uploadTimesheetButtonText;

  if (isUploadTimesheetNonIpEnabled && shift.isChangedToNonInstantPay) {
    uploadTimesheetButtonText = "Upload Your Timesheet";
  } else if (isNewTimeSheetEnabled && isSignatureSubmission) {
    uploadTimesheetButtonText = isCaliforniaTimesheetEnabled
      ? "Get Signature"
      : "Get Workplace Signature";
  } else {
    uploadTimesheetButtonText = "Upload Your Timesheet";
  }

  if (shift?.isInstantPay && !isInstantPayShiftDeleted && !timeSheetExists) {
    return (
      <InstantShiftButton
        shift={shift}
        shiftStateData={shiftStateData}
        loadShiftDetails={loadShiftDetails}
        uploadTimeSheet={uploadTimeSheet}
        onClickNoTimeSheet={openNoTimeSheet}
        shiftDetailsAlerts={shiftDetailsAlerts}
        isNewTimeSheetEnabled={isNewTimeSheetEnabled}
        isSignatureSubmission={isSignatureSubmission}
        isNFCEnabled={isNFCEnabled}
        isDigitalSignatureEnabled={isDigitalSignatureEnabled}
        isCaliforniaTimesheetEnabled={isCaliforniaTimesheetEnabled}
        isSolveUnpaidBreaksEnabled={isSolveUnpaidBreaksEnabled}
        isHcfTimeclockComplianceEnabled={isHcfTimeclockComplianceEnabled}
      />
    );
  } else if (isTimeSheetRequired && isShiftStarted && !timeSheetExists && showUploadTimesheet) {
    return (
      <>
        {timeElapsed && (!isSignatureSubmission || isEnableUploadTimesheetNonIpDigitalSignature) ? (
          <IonLabel className="no-timesheet-select" onClick={openNoTimeSheet}>
            I don’t have a timesheet
          </IonLabel>
        ) : null}
        <IonButton
          size="large"
          expand="block"
          shape="round"
          color="primary"
          className="ion-margin"
          onClick={handleUploadTimeSheet}
          disabled={!timeElapsed}
        >
          {uploadTimesheetButtonText}
        </IonButton>
      </>
    );
  }

  return null;
};

export { RecordTimeButton };
