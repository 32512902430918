import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { parseISO } from "date-fns";

import { DialogHeader } from "../../components/DialogHeader";
import { InformationSection } from "../../components/InformationSection";
import { List, ListItemButton, ListItemIcon, ListItemText } from "../../components/List";
import { useAnchorNavigation } from "../../useAnchorNavigation";
import { WorkplaceNoteIdentifier } from "../constants";
import type { WorkplaceProfile } from "../types";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { CancellationPoliciesSection } from "./CancellationPoliciesSection";
import { ExtraTimePaySection } from "./ExtraTimePaySection";
import { PolicyInformationSection } from "./PolicyInformationSection";
import { type PolicyInformationSectionData } from "./types";

interface WorkplacePoliciesModalProps {
  modalState: UseModalState;
  workplace: WorkplaceProfile;
}

export function WorkplacePoliciesModal(props: WorkplacePoliciesModalProps) {
  const { modalState, workplace } = props;
  const { userId: workplaceId, lateCancellation, sentHomeChargeHours = 0 } = workplace;

  const { data: notes = {} } = useWorkplaceNotesParsed(String(workplaceId));
  const navigateToAnchor = useAnchorNavigation();

  const {
    data: extraTimePaySettings,
    isError: extraTimePayIsError,
    isLoading: extraTimePayIsLoading,
  } = useGetExtraTimePaySettings(String(workplaceId));

  const cancelInstructionsNote = notes[WorkplaceNoteIdentifier.CANCEL_INSTRUCTIONS];
  const mandatoryBreaksNote = notes[WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY];
  const dressCodeNote = notes[WorkplaceNoteIdentifier.DRESS_CODE_INSTRUCTIONS];
  const importantInformationNote = notes[WorkplaceNoteIdentifier.PROFILE_DESCRIPTION];

  const sections: PolicyInformationSectionData[] = [
    {
      id: "cancellation-policies",
      title: "Cancellation Policies",
      iconType: "remove",
      note: cancelInstructionsNote,
      isAvailable: isDefined(cancelInstructionsNote),
    },
    {
      id: "mandatory-breaks",
      title: "Mandatory Breaks",
      iconType: "break",
      note: mandatoryBreaksNote,
      isAvailable: isDefined(mandatoryBreaksNote),
    },
    {
      id: "extra-time-pay",
      title: "Extra Time Pay",
      iconType: "extra-pay",
      isAvailable: isDefined(extraTimePaySettings),
    },
    {
      id: "dress-code",
      title: "Dress Code",
      iconType: "dress-code",
      note: dressCodeNote,
      isAvailable: isDefined(dressCodeNote),
    },
    {
      id: "other-policies",
      title: "Other Policies",
      iconType: "info",
      note: importantInformationNote,
      isAvailable: isDefined(importantInformationNote),
    },
  ];

  const availableSections = sections.filter((section) => section.isAvailable);

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />

        <Title component="h3" variant="h3" sx={{ marginBottom: 9 }}>
          Workplace Policies
        </Title>

        <List sx={{ marginBottom: 10 }}>
          {availableSections.map((section, index) => (
            <ListItemButton
              key={section.id}
              divider={index < availableSections.length - 1}
              onClick={() => {
                navigateToAnchor(section.id);
              }}
            >
              <ListItemIcon iconType={section.iconType} />
              <ListItemText primary={section.title} />
            </ListItemButton>
          ))}
        </List>

        <Stack spacing={10} sx={{ py: 10 }}>
          {availableSections.map((section) => {
            if (section.id === "cancellation-policies") {
              return (
                <InformationSection
                  key={section.id}
                  iconType={section.iconType}
                  title={section.title}
                  id={section.id}
                >
                  <CancellationPoliciesSection
                    validAsOfDate={
                      section.note?.updatedAt ? parseISO(section.note.updatedAt) : new Date()
                    }
                    cancellationWorkplaceNote={section.note?.note}
                    lateCancellationPeriod={lateCancellation?.period ?? 0}
                    lateCancellationFeeHours={lateCancellation?.feeHours ?? 0}
                    sentHomeChargeHours={sentHomeChargeHours}
                  />
                </InformationSection>
              );
            }

            if (section.id === "extra-time-pay") {
              return (
                <InformationSection
                  key={section.id}
                  iconType={section.iconType}
                  title={section.title}
                  id={section.id}
                >
                  <ExtraTimePaySection
                    settings={extraTimePaySettings}
                    isError={extraTimePayIsError}
                    isLoading={extraTimePayIsLoading}
                  />
                </InformationSection>
              );
            }

            return (
              <PolicyInformationSection
                key={section.id}
                id={section.id}
                iconType={section.iconType}
                title={section.title}
                note={section.note?.note}
                updatedAt={section.note?.updatedAt}
              />
            );
          })}
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
