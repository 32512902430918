import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { type TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase } from "@mui/material";
import { getDate } from "date-fns";
import { type ForwardedRef, forwardRef } from "react";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { formatDayName } from "../utils/formatDayName";

export interface DateCellProps {
  date: Date;
  size?: "small" | "medium";
  isSelected?: boolean;
  isLoading?: boolean;
  onToggleSelect?: (isSelected: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SIZE_CONFIGS = {
  small: {
    paddingTop: 5,
    paddingBottom: 3,
    dayOfMonthVariant: "body1" as TextVariant,
    showDayName: false,
    loadingSpinnerMarginTop: 1,
  },
  medium: {
    paddingTop: 4,
    paddingBottom: 3,
    dayOfMonthVariant: "h5" as TextVariant,
    showDayName: true,
    loadingSpinnerMarginTop: 5,
  },
} as const;

function BaseDateCell(props: DateCellProps, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    date,
    size = "medium",
    isSelected,
    onToggleSelect,
    disabled = false,
    children,
    isLoading = false,
  } = props;

  const shortDate = formatShortDateWithWeekday(date);
  const dayName = formatDayName(date);
  const dayOfMonth = getDate(date);

  const { dayOfMonthVariant, showDayName, paddingTop, paddingBottom, loadingSpinnerMarginTop } =
    SIZE_CONFIGS[size];

  return (
    <ButtonBase
      ref={ref}
      aria-pressed={isSelected}
      aria-label={shortDate}
      disabled={disabled || isLoading}
      sx={(theme) => ({
        alignItems: "center",
        border:
          size === "medium"
            ? `1px solid ${String(theme.palette.border?.hidden)}`
            : `1px solid ${String(theme.palette.border?.subtle)}`,
        borderRadius: theme.borderRadius?.small,
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        backgroundColor: theme.palette.background.tertiary,
        gap: 1,
        width: theme.size?.dateCell[size].width,
        height: theme.size?.dateCell[size].height,
        justifyContent: "flex-start",
        paddingBottom,
        paddingTop,
        position: "relative",
        px: 2,
        transition: `${theme.transitions.create(["border-color", "background-color"], {
          duration: theme.transitions.duration.standard,
        })}`,
        userSelect: "none",

        // only implement hover on supported devices
        "@media (hover: hover)": {
          "&:not(:disabled):hover": {
            border: `1px solid ${String(theme.palette.primary.main)}`,
          },
        },

        "&:not(:disabled)[aria-pressed=true]": {
          border: `1px solid ${String(theme.palette.primary.main)}`,
        },

        "&:disabled": {
          color: String(theme.palette.disabled?.primary),
        },

        "& .MuiTypography-root": {
          color: disabled ? theme.palette.disabled?.primary : undefined,
          lineHeight: "1rem",
        },
      })}
      onClick={(event) => {
        if (!disabled && isDefined(onToggleSelect)) {
          onToggleSelect(!isSelected, event);
        }
      }}
    >
      {isLoading ? (
        <Box mt={loadingSpinnerMarginTop}>
          <LoadingSpinner size="small" />
        </Box>
      ) : (
        <>
          {showDayName && <Text variant="body2">{dayName}</Text>}

          <Text variant={dayOfMonthVariant}>{dayOfMonth}</Text>

          {children}
        </>
      )}
    </ButtonBase>
  );
}

export const DateCell = forwardRef(BaseDateCell);
