import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiRatingOverrides: Components<Theme> = {
  MuiRating: {
    styleOverrides: {
      root: {
        gap: 4,
      },
      readOnly: ({ theme }) => ({
        color: theme.palette.primary.main,

        "& .MuiRating-iconEmpty": {
          opacity: 1,
        },
      }),
      label: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      iconEmpty: {
        opacity: 0.2,
      },
      icon: ({ theme }) => ({
        color: theme.palette.primary.main,

        "& svg": {
          flexShrink: 0,
        },
      }),
    },
  },
};
