import { Text } from "@clipboard-health/ui-react";
import { Button, CardContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { useHistory } from "react-router-dom";

import { RatingsBreakdown } from "../../Review/RatingsBreakdown";
import { ReviewsSummary } from "../../Review/ReviewsSummary";
import { SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetWorkplaceReviewsCardProps {
  workplace: Facility;
}

export function ShiftBottomSheetWorkplaceReviewsCard(
  props: ShiftBottomSheetWorkplaceReviewsCardProps
) {
  const { workplace } = props;
  const { userId: workplaceId } = workplace;

  const { data: ratingBreakdowns } = useGetRatingAggregates(String(workplaceId));
  const { generateModalPath, setWorkplace } = useWorkplaceModalsDataContext();

  const history = useHistory();

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          padding: 6,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Text semibold variant="body1">
            Reviews
          </Text>

          <ReviewsSummary rating={ratingBreakdowns?.avgRating ?? 0} alignItems="flex-end" />
        </Stack>

        <Stack spacing={6} sx={{ marginTop: "auto" }}>
          <RatingsBreakdown ratingBreakdowns={ratingBreakdowns?.ratingsBreakdown ?? []} />

          <Button
            fullWidth
            variant="outlined"
            size="medium"
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_SCREEN_OPENED, {
                workplaceId,
                trigger: "Shift Bottom Sheet Reviews Card",
              });
              setWorkplace(workplace);

              history.push(
                generateModalPath(SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH, {
                  workplaceId: String(workplaceId),
                })
              );
            }}
          >
            View all reviews
          </Button>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
