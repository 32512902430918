import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";

import { type ShiftRateNegotiationConditions } from "./types";

/**
 * Retrieves the conditions for rate negotiation from respective feature flags.
 */
export function useShiftRateNegotiationConditions(): ShiftRateNegotiationConditions {
  const activationConditions = useCbhFlag(CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION, {
    defaultValue: {
      id: [],
      state: [],
      msa: [],
      global: false,
    },
  });

  const leadTime = useCbhFlag(CbhFeatureFlag.RATE_NEGOTIATION_LEAD_TIME, {
    defaultValue: 0,
  });

  return {
    enabledWorkplaceIds: activationConditions.id,
    enabledStates: activationConditions.state,
    enabledMsaIds: activationConditions.msa,
    enabledGlobally: activationConditions.global,
    leadTime,
  };
}
