import { Text } from "@clipboard-health/ui-react";
import { CardActionArea, CardContent, Stack } from "@mui/material";
import { Card } from "@src/appV2/redesign/components/Card";
import {
  type WorkplaceCommentAttributesType,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { parseISO } from "date-fns";

import { WorkplaceReviewCommentAuthorInfo } from "./CommentAuthorInfo";
import { WorkplaceReviewCommentLikesButton } from "./LikesButton";
import { WorkplaceReviewCommentRepliesAndLikesWrapper } from "./RepliesAndLikesWrapper";
import { WorkplaceReviewCommentRepliesCountIcon } from "./RepliesCountIcon";

interface WorkplaceReviewCommentCardProps {
  comment: WorkplaceCommentType;
  workplaceId: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onLikeChanged: (reaction: WorkplaceCommentAttributesType["workerReaction"]) => void;
}

export function WorkplaceReviewCommentCard(props: WorkplaceReviewCommentCardProps) {
  const { comment, workplaceId, onClick, onLikeChanged } = props;
  const { createdAt, workerInfo, text, aggregates, workerReaction } = comment.attributes;
  const { id: workerId, name: workerName } = workerInfo;
  const { totalLikes, totalReplies } = aggregates;

  return (
    <Card outlined variant="tertiary">
      <CardActionArea onClick={onClick}>
        <CardContent
          sx={{
            padding: 7,
            height: "100%",
            minHeight: "11rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Text variant="body2" sx={{ paddingBottom: 6 }}>
            {text}
          </Text>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <WorkplaceReviewCommentAuthorInfo
              workerName={workerName}
              workerId={workerId}
              createdAt={parseISO(createdAt)}
            />

            <WorkplaceReviewCommentRepliesAndLikesWrapper>
              <WorkplaceReviewCommentRepliesCountIcon count={totalReplies} />

              <WorkplaceReviewCommentLikesButton
                workplaceId={workplaceId}
                commentId={comment.id}
                isLiked={workerReaction === Reaction.LIKE}
                count={totalLikes}
                onLikeChanged={(reaction) => {
                  onLikeChanged(reaction);
                }}
              />
            </WorkplaceReviewCommentRepliesAndLikesWrapper>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
