import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiTabsOverrides: Components<Theme> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: "fit-content",
        borderRadius: theme.borderRadius?.large,
        border: `${theme.borderWidth?.regular} solid ${String(theme.palette.border?.primary)}`,
        padding: theme.spacing(1),
        height: theme.size?.tabs?.height,
      }),
      indicator: ({ theme }) => ({
        height: "100%",
        borderRadius: theme.borderRadius?.xLarge,
        // to show it below button content
        zIndex: 1,
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        // to show it above the tab indicator
        zIndex: 2,
        textTransform: "none",
        padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
        flexDirection: "row",
        lineHeight: theme.size?.tabs.lineHeight,
        minHeight: theme.size?.tabs.height,
        whiteSpace: "nowrap",

        "&.Mui-selected": {
          color: theme.palette.text?.invertPrimary,
        },

        "&.MuiButtonBase-root": {
          transition: "color 0.3s ease-in-out",
        },
      }),
    },
  },
};
