import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Dialog, type DialogProps } from "@mui/material";
import { type ReactNode } from "react";

import { type PaperVariant } from "../../../redesign/theming";

interface FullScreenDialogProps
  extends Omit<DialogProps, "open" | "onClose" | "fullScreen" | "PaperProps"> {
  modalState: UseModalState;
  children: ReactNode;
  variant?: PaperVariant;
  /**
   * If true, the top safe area padding will be skipped.
   * This might be useful for dialogs that have a custom header.
   */
  skipTopSafeAreaPadding?: boolean;
}

export function FullScreenDialog(props: FullScreenDialogProps) {
  const { modalState, children, variant, skipTopSafeAreaPadding, ...dialogProps } = props;

  return (
    <Dialog
      fullScreen
      open={modalState.modalIsOpen}
      PaperProps={{
        variant,
        sx: (theme) => ({
          backgroundColor:
            // TODO: Remove this once we migrate to new shift discovery theme
            isDefined(variant) ? undefined : theme.palette.legacyFullScreenDialogBackground,
          paddingX: 0,
          // we don't account for safe area inset bottom as this component should use DialogFooter
          paddingBottom: 0,
          paddingTop: skipTopSafeAreaPadding ? 0 : undefined,
        }),
      }}
      {...dialogProps}
      onClose={() => {
        modalState.closeModal();
      }}
    >
      {children}
    </Dialog>
  );
}
