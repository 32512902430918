import { Title } from "@clipboard-health/ui-react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { useFieldArray, type UseFormReturn } from "react-hook-form";

import { type CreateCandidateResumeSchema } from "../types";
import { CandidateResumeFormField } from "./CandidateResumeFormField";

interface CreateCandidateResumeExperienceFormProps {
  form: UseFormReturn<CreateCandidateResumeSchema>;
}

export function CreateCandidateResumeExperienceForm({
  form,
}: CreateCandidateResumeExperienceFormProps) {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "experience",
  });
  const {
    formState: {
      errors: { experience: experienceErrors },
    },
  } = form;

  return (
    <Stack spacing={2}>
      <Title component="h4" variant="h4">
        Experience
      </Title>
      {experienceErrors && <FormHelperText error>{experienceErrors.message}</FormHelperText>}
      {fields.map(({ id }, index) => (
        <Box
          key={id}
          sx={{
            padding: 2,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: 2,
            borderColor: "lightgray",
          }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: "start", justifyItems: "start" }}>
            <Stack spacing={2} sx={{ flex: 1 }}>
              <CandidateResumeFormField
                name={`experience.${index}.roleName`}
                control={control}
                render={(field) => <TextField label="Job Title" {...field} />}
              />
              <CandidateResumeFormField
                name={`experience.${index}.workplaceName`}
                control={control}
                render={(field) => <TextField label="Company Name" {...field} />}
              />
              <Stack direction="row" spacing={2}>
                <CandidateResumeFormField
                  name={`experience.${index}.timespan.startDate`}
                  control={control}
                  render={(field) => (
                    <DatePicker
                      label="Start Date"
                      views={["month", "year"]}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <CandidateResumeFormField
                  name={`experience.${index}.timespan.endDate`}
                  control={control}
                  render={(field) => (
                    <DatePicker
                      label="End Date"
                      views={["month", "year"]}
                      value={field.value}
                      disabled={form.watch(`experience.${index}.timespan.isCurrent`)}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Stack>
              <CandidateResumeFormField
                name={`experience.${index}.timespan.isCurrent`}
                control={control}
                render={(field) => (
                  <FormControlLabel label="I still work here" control={<Switch {...field} />} />
                )}
              />
            </Stack>
            <Box>
              <IconButton
                iconType="remove"
                variant="outlined"
                onClick={() => {
                  remove(index);
                }}
              />
            </Box>
          </Stack>
        </Box>
      ))}
      <Button
        variant="text"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => {
          append({
            // @ts-expect-error - append() expects a fully type-correct value, but we want to add an empty object so the user can input the details
            timespan: {},
          });
        }}
      >
        Add Experience
      </Button>
    </Stack>
  );
}
