import { Skeleton } from "@mui/material";

import { type AvatarSize } from "../theming";

interface AvatarSkeletonProps {
  size?: AvatarSize;
}

export function AvatarSkeleton(props: AvatarSkeletonProps) {
  const { size: avatarSize = "medium" } = props;

  return (
    <Skeleton
      variant="circular"
      sx={(theme) => ({
        width: theme.size?.avatar[avatarSize],
        height: theme.size?.avatar[avatarSize],
      })}
    />
  );
}
