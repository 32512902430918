import { Stack } from "@mui/material";
import { FormProvider, type UseFormReturn } from "react-hook-form";

import { type CreateCandidateResumeSchema } from "../types";
import { CreateCandidateResumeEducationForm } from "./CreateCandidateResumeEducationForm";
import { CreateCandidateResumeExperienceForm } from "./CreateCandidateResumeExperienceForm";

interface CreateCandidateResumeFormProps {
  form: UseFormReturn<CreateCandidateResumeSchema>;
}

export function CreateCandidateResumeForm({ form }: CreateCandidateResumeFormProps) {
  return (
    <FormProvider {...form}>
      <Stack spacing={4}>
        <CreateCandidateResumeExperienceForm form={form} />
        <CreateCandidateResumeEducationForm form={form} />
      </Stack>
    </FormProvider>
  );
}
