import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { IonLoading, isPlatform } from "@ionic/react";
import { useAppStateListener } from "@src/app/appInit/useAppStateListener";
import { useFetchAndInitializeWorker } from "@src/app/appInit/useFetchAndInitializeWorker";
import { useFirebaseListener } from "@src/app/appInit/useFirebaseListener";
import { GlobalBanners } from "@src/app/routing/home/globalBanners";
import { useNotifyDeletionRequestCancelled } from "@src/appV2/Accounts/DeleteAccount/useNotifyDeletionRequestCancelled";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { getAnonymousLdUser, getSignedInLdUser } from "@src/appV2/FeatureFlags/cbhUserContext";
import { getAppInfo } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { UXCamSdk } from "@src/appV2/lib/UXCamSdk/UXCamSdk";
import { useHyperTrack } from "@src/appV2/Tracking/HyperTrack";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { USER_EVENTS } from "@src/constants";
import { useGetReferenceStatus } from "@src/hooks/useGetReferenceStatus";
import { logReferenceStatus } from "@src/utils/logReferenceStatus";
// Rule disabled since this is where we identify the current user to LaunchDarkly
// eslint-disable-next-line no-restricted-imports
import { loadCustomFlags } from "@store/session";
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from "launchdarkly-react-client-sdk";
import qs from "query-string";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";
import { HomeRoutes } from "./home";
import { AppRouterProps } from "./model";
import { PrivateRoute } from "./privateRoute";
import { PublicPage } from "./PublicPage";
import { NoUserPage } from "../404Pages";
import { useAnalyticsRouterTagging } from "../analyticsRouterTagging";
import { useAppUrlListener } from "../deepLink";
import { useDynamicLinkHandler } from "../dynamicLink";
import { initDatadogRum } from "../utils/datadog";
import { WelcomeRoute } from "../welcome/route";

export const AppRouter: FC<AppRouterProps> = ({
  isVerifying,
  isLoggingOut,
  authStateHasLoaded,
  userId,
  ...props
}) => {
  const { isAuthorized } = props;
  const location = useLocation();
  const queryString = qs.parse(location.search);
  const dispatch = useDispatch();
  const ldFlags = useCbhFlags();
  const disableUxCamRecording = ldFlags?.[CbhFeatureFlag.DISABLE_UX_CAM];

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    StatusBar.setStyle({
      style: StatusBarStyle.Dark,
    });
  }, []);

  useEffect(() => {
    if (!authStateHasLoaded) {
      logEvent(APP_V2_APP_EVENTS.SPLASH_SCREEN_HIDING_SKIPPED_DUE_TO_AUTH_STATE, {
        authStateHasLoaded,
      });
      return;
    }

    logEvent(APP_V2_APP_EVENTS.SPLASH_SCREEN_HIDE_TIMEOUT_SET);
    setTimeout(() => {
      logEvent(APP_V2_APP_EVENTS.SPLASH_SCREEN_HIDDEN_DUE_TO_AUTH_STATE);
      SplashScreen.hide();
    }, 200);
  }, [authStateHasLoaded]);

  const { status, isLoading: referenceLoading, variant } = useGetReferenceStatus();

  useEffect(() => {
    if (referenceLoading || !userId) {
      return;
    }
    logReferenceStatus(userId, status, variant);
  }, [referenceLoading, status, userId, variant]);

  useEffect(() => {
    initDatadogRum();
    loadCustomFlags(dispatch);
    logEvent(USER_EVENTS.APP_LAUNCHED);
  }, [dispatch]);

  useEffect(() => {
    if (disableUxCamRecording) {
      UXCamSdk.stopSessionAndUploadData();
    }
    /**
     * FIXME - We don't have LD details when we initialize UXCam
     * so stopping it here after its initialization
     * Refactor this to stop UXCam initialization based on FF value
     */
  }, [disableUxCamRecording]);

  useAppStateListener();
  const isAuthenticatedInFirebase = useFirebaseListener(
    location.pathname,
    queryString.email as string
  );
  useFetchAndInitializeWorker(isAuthenticatedInFirebase, isAuthorized);

  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    (async () => {
      const cbhAppInfo = await getAppInfo();

      const ldUser = !worker?.userId
        ? getAnonymousLdUser(cbhAppInfo)
        : getSignedInLdUser(cbhAppInfo, {
            userId: worker?.userId ?? "",
            name: worker?.name ?? "",
            email: worker?.email ?? "",
            stage: worker?.stage ?? "",
            msa: worker?.address?.metropolitanStatisticalArea ?? "",
            city: worker?.address?.city ?? "",
            state: worker?.address?.state ?? "",
            stateCode: worker?.address?.stateCode ?? "",
            countryCode: worker?.address?.countryCode ?? "",
            qualification: worker?.preference?.qualification ?? "",
            createdAt: worker?.createdAt ?? "",
          });

      ldClient.identify(ldUser);
    })();
    // The worker object gets updated without changing its reference, so we have to memo each field separately.
  }, [
    ldClient,
    worker?.userId,
    worker?.name,
    worker?.email,
    worker?.stage,
    worker?.createdAt,
    worker?.address?.metropolitanStatisticalArea,
    worker?.address?.city,
    worker?.address?.state,
    worker?.address?.stateCode,
    worker?.address?.countryCode,
    worker?.preference?.qualification,
    dispatch,
  ]);

  useHyperTrack(userId);
  useDynamicLinkHandler();
  useAppUrlListener();
  useAnalyticsRouterTagging();
  useNotifyDeletionRequestCancelled();

  return (
    <>
      <div className="cbhAppV1">
        <GlobalBanners />
        <Switch>
          <Route exact path={GlobalRouterPath.NO_USER_PAGE} component={NoUserPage} />
          <Route exact path="/" render={() => <Redirect to={GlobalRouterPath.HOME} />} />
          <Route
            exact
            path={GlobalRouterPath.LINK}
            render={() => <Redirect to={GlobalRouterPath.HOME} />}
          />
        </Switch>
      </div>
      <PrivateRoute
        path={[GlobalRouterPath.HOME, GlobalRouterPath.APP_V2_HOME]}
        component={HomeRoutes}
        authStateHasLoaded={authStateHasLoaded}
        {...props}
      />
      <div className="cbhAppV1">
        <Route path={GlobalRouterPath.WELCOME}>
          <PublicPage authStateHasLoaded={authStateHasLoaded} isAuthorized={isAuthorized}>
            <WelcomeRoute />
          </PublicPage>
        </Route>

        <IonLoading isOpen={isVerifying} message="Logging in..." />
        <IonLoading isOpen={isLoggingOut} message="Logging out..." />
      </div>
    </>
  );
};
