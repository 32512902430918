import { Text, UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { REMOVE_LICENSE_MODAL, getRemoveLicenseModalSubtitle } from "@src/constants/modalAlerts";

export interface RemoveLicenseDialogProps {
  modalState: UseModalState;
  onSubmit: () => void;
  isClipboardScoreEnabled: boolean;
}

export function RemoveLicenseDialog(props: RemoveLicenseDialogProps) {
  const { modalState, onSubmit, isClipboardScoreEnabled } = props;
  return (
    <CommonDialog
      modalState={modalState}
      title={REMOVE_LICENSE_MODAL.TITLE}
      actions={
        <>
          <Button
            variant="contained"
            color="error"
            fullWidth
            aria-label="Confirm Remove License"
            onClick={() => onSubmit()}
          >
            Remove
          </Button>
          <Button variant="outlined" fullWidth onClick={() => modalState.closeModal()}>
            Cancel
          </Button>
        </>
      }
    >
      <Text textAlign="center" paragraph>
        {REMOVE_LICENSE_MODAL.CONTENT}
      </Text>
      <Text textAlign="center" bold>
        {getRemoveLicenseModalSubtitle(isClipboardScoreEnabled)}
      </Text>
    </CommonDialog>
  );
}
