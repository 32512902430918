import { get, post } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

import {
  type PlacementCandidate,
  placementCandidateSchema,
  placementCandidateWorkerAttributeIncludedSchema,
} from "../types";
import {
  getPlacementCandidateQueryKey,
  placementCandidateApiUrl,
  transformResponseIntoPlacementCandidate,
} from "./placementCandidate.helpers";
import { placementCandidateResponseSchema } from "./useGetPlacementCandidate";

interface UsePlacementCandidateParams {
  workerId: string;
}

const createPlacementCandidateRequestSchema = z.object({
  data: z.object({
    type: z.literal("placement-candidates"),
    attributes: z.object({ workerId: z.string() }),
  }),
});

export const createPlacementCandidateResponseSchema = z.object({
  data: placementCandidateSchema,
  included: z.array(placementCandidateWorkerAttributeIncludedSchema),
});

export function useCreateOrGetPlacementCandidate(
  params: UsePlacementCandidateParams,
  options?: UseQueryOptions<PlacementCandidate>
) {
  const { workerId } = params;
  return useQuery({
    queryKey: getPlacementCandidateQueryKey(workerId),
    queryFn: async () => {
      const response = await get({
        url: placementCandidateApiUrl,
        queryParams: {
          page: {
            size: 1,
          },
          filter: {
            "worker.id": workerId,
          },
        },
        responseSchema: placementCandidateResponseSchema,
      });
      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        return transformResponseIntoPlacementCandidate(response.data);
      }

      const createCandidateResponse = await post({
        url: placementCandidateApiUrl,
        data: {
          data: {
            type: "placement-candidates",
            attributes: {
              workerId,
            },
          },
        },
        requestSchema: createPlacementCandidateRequestSchema,
        responseSchema: createPlacementCandidateResponseSchema,
      });

      return transformResponseIntoPlacementCandidate(createCandidateResponse.data);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_OR_GET_PLACEMENT_CANDIDATE_FAILURE,
    },
    ...options,
  });
}
