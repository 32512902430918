import { isDefined } from "@clipboard-health/util-ts";

export const FILTER_ALL_VALUE = "filter_all";

type FilterAllValue = typeof FILTER_ALL_VALUE;
type SelectableOptions<Option> = Array<Option | FilterAllValue>;

/**
 * A hook that manages the selected options for a filter,
 * allowing for the selection of all options or a subset of options.
 * If the user selects all options, the selected options will be reset to an empty array.
 */
export function useFilterSelectedOptions<Option>(
  selected: Option[],
  onChange: (selected: Option[]) => void
) {
  const selectedOptions: SelectableOptions<Option> =
    selected.length > 0 ? selected : [FILTER_ALL_VALUE];

  const onChangeSelection = (newSelection?: SelectableOptions<Option>) => {
    const selectedAll =
      newSelection?.includes(FILTER_ALL_VALUE) && !selectedOptions.includes(FILTER_ALL_VALUE);

    if (!isDefined(newSelection) || selectedAll) {
      onChange([]);
      return;
    }

    const filteredSelection = newSelection.filter((v) => v !== FILTER_ALL_VALUE);
    onChange(filteredSelection as Option[]);
  };

  return { selectedOptions, onChangeSelection };
}
