import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

export const muiPaperOverrides: Components<Theme> = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    variants: [
      {
        props: { variant: "primary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background?.primary,
        }),
      },
      {
        props: { variant: "secondary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background?.secondary,
        }),
      },
      {
        props: { variant: "tertiary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background?.tertiary,
        }),
      },
      {
        props: { elevation: 0 },
        style: {
          boxShadow: "none",
        },
      },
      {
        props: { elevation: 1 },
        style: ({ theme }) => ({
          boxShadow: theme.shadows[1],
        }),
      },
      {
        props: { elevation: 2 },
        style: ({ theme }) => ({
          boxShadow: theme.shadows[2],
        }),
      },
      {
        props: { elevation: 3 },
        style: ({ theme }) => ({
          boxShadow: theme.shadows[3],
        }),
      },
    ],
  },
};
