import { type Components, type Theme } from "@mui/material/styles";

import { type AvatarSize } from "../types";

declare module "@mui/material/Avatar" {
  interface AvatarOwnProps {
    size?: AvatarSize;
  }
}

export const muiAvatarOverrides: Components<Theme> = {
  MuiAvatar: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background?.primary,

        variants: [
          {
            props: { size: "small" },
            style: {
              width: theme.size?.avatar.small,
              height: theme.size?.avatar.small,
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: theme.size?.avatar.medium,
              height: theme.size?.avatar.medium,
            },
          },
          {
            props: { size: "large" },
            style: {
              width: theme.size?.avatar.large,
              height: theme.size?.avatar.large,
            },
          },
        ],
      }),

      // Displayed when there is no image
      colorDefault: ({ theme }) => ({
        backgroundColor: theme.palette.surface?.shadedDark,
        color: theme.palette.disabled?.primary,
      }),
    },
  },
};
