import { Text } from "@clipboard-health/ui-react";
import { Button, CardContent, type SxProps, type Theme } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { parseISO } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { Pill } from "../../components/Pill";
import { formatDayName } from "../../utils/formatDayName";
import { formatDayOfMonth } from "../../utils/formatDayOfMonth";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { type WorkerShiftWithType } from "../types";
import { WorkerShiftSummaryInfo } from "./SumaryInfo";

interface WorkerShiftCardProps {
  shift: WorkerShiftWithType;
  sx?: SxProps<Theme>;
}

export function WorkerShiftCard(props: WorkerShiftCardProps) {
  const { shift, sx } = props;
  const { _id, start, type } = shift;

  const startDate = parseISO(start);

  const history = useHistory();

  return (
    <ShiftCardWrapper shiftType={type} sx={sx}>
      <CardContent
        sx={{
          padding: 5,
          gap: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <Pill
          iconType="checkmark"
          label={
            <DotSeparatedList>
              <Text semibold variant="body2">
                Booked
              </Text>
              <Text semibold variant="body2">{`${formatDayName(startDate)}, ${formatDayOfMonth(
                startDate
              )}`}</Text>
            </DotSeparatedList>
          }
          color={(theme) => theme.palette.callout?.success.labelDark}
          labelColor={(theme) => theme.palette.callout?.success.text}
        />

        <WorkerShiftSummaryInfo shift={shift} />

        {/* TODO: Show clock in option if close to the shift start time? */}

        <Button
          fullWidth
          variant="outlined"
          size="medium"
          onClick={() => {
            history.push(
              generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, { shiftId: _id })
            );
          }}
        >
          Shift details
        </Button>
      </CardContent>
    </ShiftCardWrapper>
  );
}
