import { AgentStage } from "@src/appV2/Agents";
import { useAttendanceScorePolicyLinks } from "@src/appV2/AttendanceScore/hooks/featureFlags";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { Callout } from "../components/Callout";

export function AccountDeactivatedCallout() {
  const { stage } = useDefinedWorker();

  const { understandingAccountStatus } = useAttendanceScorePolicyLinks();

  if (stage !== AgentStage.DEACTIVATED) {
    return null;
  }

  return (
    <Callout
      title="Your account is deactivated"
      iconType="remove"
      sx={(theme) => ({
        backgroundColor: theme.palette.callout?.error.label,
        color: theme.palette.callout?.error.text,
      })}
      onClick={() => window.open(understandingAccountStatus?.url, "_blank")}
    />
  );
}
