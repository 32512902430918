import { ToggleButton } from "@mui/material";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";

import { FiltersToggleButtonGroup } from "./BottomSheet/ToggleButtonGroup";
import { type FiltersFormValues } from "./BottomSheet/types";
import { FILTER_ALL_VALUE, useFilterSelectedOptions } from "./BottomSheet/useFilterSelectedOptions";

interface ShiftBookingTypeButtonGroupProps {
  value?: FiltersFormValues["shiftBookingTypes"];
  onChange: (value: FiltersFormValues["shiftBookingTypes"]) => void;
}

export function ShiftBookingTypeButtonGroup(props: ShiftBookingTypeButtonGroupProps) {
  const { value, onChange } = props;

  const { selectedOptions, onChangeSelection } = useFilterSelectedOptions(value ?? [], onChange);

  return (
    <FiltersToggleButtonGroup value={selectedOptions} onChange={onChangeSelection}>
      <ToggleButton value={FILTER_ALL_VALUE}>All</ToggleButton>
      <ToggleButton value={ShiftBookingType.PER_DIEM}>Per Diem</ToggleButton>
      <ToggleButton value={ShiftBookingType.BLOCK_BOOKING}>Blocks</ToggleButton>
    </FiltersToggleButtonGroup>
  );
}
