import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

import { SelectableBox } from "../../components/SelectableBox";
import { TextButton } from "../../components/TextButton";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";

export function ListViewPageOpenShiftsEmptyState() {
  const { navigateToModal } = useShiftModalsDataContext();
  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title
        textAlign="center"
        variant="h4"
        component="h3"
        // TODO: Add semibold to Title
        sx={(theme) => ({
          fontWeight: theme.typography.h4.fontWeight,
          textWrap: "balance",
        })}
      >
        There are no open shifts in the next 14 days
      </Title>
      <TextButton
        sx={{ alignSelf: "center" }}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPENED);
          navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
        }}
      >
        Load more dates
      </TextButton>

      <Stack spacing={3}>
        <Text semibold>You can also:</Text>
        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.REFERRAL}
          startIconType="user-add"
        >
          Refer a professional
        </SelectableBox>

        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.WORKPLACE_REFERRALS}
          startIconType="building"
        >
          Refer a workplace
        </SelectableBox>
      </Stack>
    </Stack>
  );
}
