interface GetSafeAreaPaddingsProps {
  /**
   * Decides whether to apply safe area padding on the top side
   */
  top?: boolean;
  /**
   * Decides whether to apply safe area padding on the bottom side
   */
  bottom?: boolean;
  /**
   * Decides whether to apply safe area padding on the left side
   */
  left?: boolean;
  /**
   * Decides whether to apply safe area padding on the right side
   */
  right?: boolean;
  /**
   * Fallback value for top padding. Will be used if there is no device inset
   */
  fallbackTop?: string;
  /**
   * Fallback value for bottom padding. Will be used if there is no device inset
   */
  fallbackBottom?: string;
  /**
   * Fallback value for left padding. Will be used if there is no device inset
   */
  fallbackLeft?: string;
  /**
   * Fallback value for right padding. Will be used if there is no device inset
   */
  fallbackRight?: string;
}

/**
 * This complex type is used to ensure that the result of `getSafeAreaPaddings` is a record with only values defined by the props keys
 * If you call getSafeAreaPaddings with all false values, you will get an empty object and typescript will be aware of that
 * Same as if you call it with partial true values, you will get an object with only the defined keys
 */
type SafeAreaPaddingResult<T extends GetSafeAreaPaddingsProps> = {
  paddingTop: T["top"] extends true ? string : never;
  paddingBottom: T["bottom"] extends true ? string : never;
  paddingLeft: T["left"] extends true ? string : never;
  paddingRight: T["right"] extends true ? string : never;
} extends Record<string, never>
  ? Record<string, unknown>
  : Pick<
      {
        paddingTop: string;
        paddingBottom: string;
        paddingLeft: string;
        paddingRight: string;
      },
      {
        [K in keyof T]: T[K] extends true
          ? K extends "top"
            ? "paddingTop"
            : K extends "bottom"
            ? "paddingBottom"
            : K extends "left"
            ? "paddingLeft"
            : K extends "right"
            ? "paddingRight"
            : never
          : never;
      }[keyof T]
    >;

/**
 * This function is used to get safe area padding with a fallback to the provided value. If there is no fallback, it will return the env variable
 *
 * To handle fallback, it dynamically resolves the safe area padding value from env variables
 * and if the value is not set, it will return the fallback value.
 *
 * It replaced previous approach of using `max` function to calculate the padding value, like `max(env(safe-area-inset-top), ${fallbackTop})`
 * As it was causing troubles and was not working as expected. It's probably some kind of timing issue, where the env variables are not set in time for the component to be rendered.
 *
 * This probably has something to do with the mixup of ion specific components. For new views that don't use ionic at all, evaluating the padding using `max` worked fine
 */
const getPaddingValue = (anchor: "top" | "bottom" | "left" | "right", fallback?: string) => {
  const variableName = `--ion-safe-area-${anchor}`;

  // if there is no fallback, we can directly return the variable
  if (!fallback) {
    return `var(${variableName})`;
  }

  const safeAreaValue = getComputedStyle(document.documentElement).getPropertyValue(variableName);

  if (safeAreaValue && safeAreaValue !== "0px" && safeAreaValue !== "0") {
    return `var(${variableName})`;
  }

  return fallback;
};

/**
 * Returns object with padding values that apply safe area insets if needed
 * Use fallback values to specify default padding values that are used when there are no safe area insets
 * See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
 */
export function getSafeAreaPaddings<T extends GetSafeAreaPaddingsProps>(
  props: T
): SafeAreaPaddingResult<T> {
  const { top, bottom, left, right, fallbackTop, fallbackBottom, fallbackLeft, fallbackRight } =
    props;

  const result: Record<string, string> = {
    ...(top && { paddingTop: getPaddingValue("top", fallbackTop) }),
    ...(bottom && { paddingBottom: getPaddingValue("bottom", fallbackBottom) }),
    ...(left && { paddingLeft: getPaddingValue("left", fallbackLeft) }),
    ...(right && { paddingRight: getPaddingValue("right", fallbackRight) }),
  };

  return result as SafeAreaPaddingResult<T>;
}
