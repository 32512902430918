import { mergeSxProps } from "@clipboard-health/ui-react";

import { Slider, type SliderProps } from "./Slider";

interface DistanceSliderProps
  extends Pick<
    SliderProps,
    | "defaultValue"
    | "sx"
    | "min"
    | "max"
    | "disabled"
    | "onChange"
    | "step"
    | "value"
    | "valueLabelDisplay"
  > {
  options: number[];
}

export function DistanceSlider(props: DistanceSliderProps) {
  const { options, sx, ...rest } = props;

  const marks = options.map((value) => ({
    value,
    label: `${value}mi`,
  }));

  const min = Math.min(...options);
  const max = Math.max(...options);

  return (
    <Slider
      thumb
      marks={marks}
      variant="rectangular"
      color="primary"
      min={min}
      max={max}
      valueLabelFormat={(value) => `${value}mi`}
      {...rest}
      sx={mergeSxProps(
        (theme) => ({
          width: "auto",
          height: "2rem",
          // compensate for mark labels which uses position absolute
          // and is below the slider
          marginBottom: "2rem",

          "& .MuiSlider-rail": {
            height: "2rem",
            color: theme.palette.surface?.shaded,
          },
        }),
        sx
      )}
    />
  );
}
