import { Button } from "@src/appV2/redesign/components/Button";
import pluralize from "pluralize";

import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface ShiftBottomSheetPendingDocumentsCardProps {
  pendingDocumentsCount: number;
  onClick: () => void;
}

export function ShiftBottomSheetPendingDocumentsCard(
  props: ShiftBottomSheetPendingDocumentsCardProps
) {
  const { pendingDocumentsCount, onClick } = props;
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title={`Your ${
        pendingDocumentsCount === 1 ? "document is" : `${pendingDocumentsCount} documents are`
      } in review`}
      description={`You uploaded ${pendingDocumentsCount} ${pluralize(
        "document",
        pendingDocumentsCount
      )} that ${pendingDocumentsCount === 1 ? "is" : "are"} under review.`}
      illustrationType="document-upload"
      footer={
        <Button fullWidth variant="contained" size="medium" onClick={onClick}>
          View documents
        </Button>
      }
    />
  );
}
