import { type UseModalState } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";
import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import { AssessmentErrorDialog } from "@src/appV2/SkillsAssessment/components/AssessmentErrorDialog";
import { AssessmentRequirementScheduledDialog } from "@src/appV2/SkillsAssessment/components/AssessmentRequirementScheduledDialog";
import { SKILLS_ASSESSMENT_BASE_PATH } from "@src/appV2/SkillsAssessment/paths";
import { SkillsAssessmentWorkerStatus } from "@src/appV2/SkillsAssessment/types";
import { isUserAllowedToTakeAssessment } from "@src/appV2/SkillsAssessment/utils";
import { useHistory } from "react-router-dom";

import { AssessmentFailedScheduledDialog } from "./AssessmentFailedScheduledDialog";
import { AssessmentUnderReviewScheduledDialog } from "./AssessmentUnderReviewScheduledDialog";

interface Props {
  modalState: UseModalState;
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function AssessmentBeforeBookingDialog(props: Props) {
  const {
    modalState,
    metadata: { status, retakeAllowedAfter, qualificationName, qualificationId },
  } = props;

  const history = useHistory();
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { modalIsOpen } = modalState;

  function handleDetailsClick() {
    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_DIALOG_DETAILS_BUTTON_CLICKED, {
      qualificationName,
      qualificationId,
      supportArticleLink: skillsAssessmentConfig.supportArticleLink,
    });
    modalState.closeModal();
    void openBrowser(skillsAssessmentConfig.supportArticleLink);
  }

  if (
    isUserAllowedToTakeAssessment({
      status,
      retakeAllowedAfter,
    })
  ) {
    if (modalIsOpen) {
      logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_VIEWED, {
        qualificationName,
        qualificationId,
      });
    }

    return (
      <AssessmentRequirementScheduledDialog
        modalState={modalState}
        qualificationName={qualificationName}
        onDetailsClick={() => {
          handleDetailsClick();
        }}
        onContinue={() => {
          logEvent(
            APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_CONTINUE_BUTTON_CLICKED,
            {
              qualificationName,
              qualificationId,
            }
          );
          modalState.closeModal();
          history.push(SKILLS_ASSESSMENT_BASE_PATH);
        }}
      />
    );
  }

  if (status === SkillsAssessmentWorkerStatus.UNDER_REVIEW) {
    if (modalIsOpen) {
      logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_UNDER_REVIEW_DIALOG_VIEWED, {
        qualificationName,
        qualificationId,
      });
    }

    return (
      <AssessmentUnderReviewScheduledDialog
        modalState={modalState}
        onDetailsClick={() => {
          handleDetailsClick();
        }}
        onContinue={() => {
          modalState.closeModal();
          history.push(SKILLS_ASSESSMENT_BASE_PATH);
        }}
      />
    );
  }

  if (status === SkillsAssessmentWorkerStatus.FAILED) {
    if (modalIsOpen) {
      logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_FAILED_DIALOG_VIEWED, {
        qualificationName,
        qualificationId,
      });
    }

    return (
      <AssessmentFailedScheduledDialog
        modalState={modalState}
        retakeAllowedAfter={retakeAllowedAfter}
        onDetailsClick={() => {
          handleDetailsClick();
        }}
        onOk={() => {
          modalState.closeModal();
        }}
      />
    );
  }

  if (modalIsOpen) {
    logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_UNEXPECTED_ERROR_DIALOG_VIEWED, {
      qualificationName,
      qualificationId,
    });
  }

  return (
    <AssessmentErrorDialog
      modalState={modalState}
      onOk={() => {
        modalState.closeModal();
      }}
    />
  );
}
