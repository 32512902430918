import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Link, Stack } from "@mui/material";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";

interface Props {
  assessment: SkillsAssessment;
  qualification?: string;
}
export function SkillsAssessmentVideoStartFromMyAccountScheduledStep(props: Props) {
  const { assessment, qualification } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4}>
      <Title component="h1">Continue to take the required assessment.</Title>
      <Ul>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            You&apos;ll answer questions pertaining to your knowledge and experience as a{" "}
            {qualification} over video.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            The assessment takes about{" "}
            {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            Tap{" "}
            <Link
              onClick={() => {
                void openBrowser(skillsAssessmentConfig.supportArticleLink);
              }}
            >
              here
            </Link>{" "}
            for additional details.
          </Text>
        </Li>
      </Ul>
    </Stack>
  );
}
