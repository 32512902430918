import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs/FullScreen/Dialog";
import {
  eachMonthOfInterval,
  formatISO,
  isPast,
  isToday,
  max,
  parseISO,
  startOfMonth,
} from "date-fns";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";

import { DateCell } from "../../Calendar/DateCell";
import { CalendarGridMonth } from "../../Calendar/GridMonth";
import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { LoadMoreTrigger } from "../../components/LoadMoreTrigger";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { getBookedShiftTimeSlotsFromWorkerDayShifts } from "../../utils/getBookedShiftTimeSlotsFromWorkerDayShifts";
import { getCalendarPickerDateRange } from "./getCalendarPickerDateRange";
import { ShiftDateCell } from "./ShiftDateCell";
import { ShiftDatePickerHeader } from "./ShiftDatePickerHeader";
import { useShiftDiscoveryCalendarData } from "./useShiftDiscoveryCalendarData";

interface ShiftDatePickerProps {
  modalState: UseModalState;
}

export function ShiftDatePicker(props: ShiftDatePickerProps) {
  const { modalState } = props;
  const { setDates: setFiltersDates, dates } = useShiftDiscoveryUserFiltersContext();

  const {
    workerShiftsByDate,
    filteredOpenShiftsCountData,
    dateRange,
    openShiftsCountIsLoading,
    workerShiftsIsLoading,
    loadMore,
    canLoadMore,
    workerShiftsIsFetchingNextPage,
    openShiftsCountIsFetchingNextPage,
  } = useShiftDiscoveryCalendarData({
    initialDateRange: getCalendarPickerDateRange(new Date()),
  });

  const isLoading = openShiftsCountIsLoading || workerShiftsIsLoading;

  const [selectedDates, setSelectedDates] = useState<string[]>(dates);

  const { startDate, endDate } = dateRange;

  const monthDates = eachMonthOfInterval({ start: startDate, end: endDate }).map((monthDate) =>
    max([startDate, startOfMonth(monthDate)])
  );

  const totalOpenShiftsCount =
    selectedDates.length > 0
      ? selectedDates.reduce(
          (total, date) => total + (filteredOpenShiftsCountData?.[date]?.total ?? 0),
          0
        )
      : 0;

  useEffect(() => {
    if (modalState.modalIsClosed) {
      setSelectedDates([]);
    }
  }, [modalState.modalIsClosed]);

  return (
    <FullScreenDialog
      hideBackdrop
      skipTopSafeAreaPadding
      modalState={modalState}
      variant="tertiary"
    >
      <ShiftDatePickerHeader />

      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Stack sx={{ px: 5, py: 7, overflow: "auto" }} gap={7}>
          {monthDates.map((monthDate) => (
            <CalendarGridMonth
              key={monthDate.toISOString()}
              startDate={monthDate}
              renderDateCell={(date) => {
                const isInPast = isPast(date) && !isToday(date);
                const formattedDate = formatISO(date, { representation: "date" });
                const isDateAvailable = isDefined(filteredOpenShiftsCountData?.[formattedDate]);
                const workerDayShifts = workerShiftsByDate?.[formattedDate];
                const bookedShiftTimeSlots =
                  getBookedShiftTimeSlotsFromWorkerDayShifts(workerDayShifts);

                return (
                  <ShiftDateCell
                    date={date}
                    size="small"
                    isLoading={isLoading}
                    disabled={isInPast}
                    isDateAvailable={isDateAvailable}
                    isSelected={selectedDates.includes(formattedDate)}
                    bookedShiftTimeSlots={bookedShiftTimeSlots}
                    onToggleSelect={(isSelected) => {
                      setSelectedDates((previousSelectedDates) => {
                        if (isSelected) {
                          return [...previousSelectedDates, formattedDate];
                        }

                        return previousSelectedDates.filter((date) => date !== formattedDate);
                      });
                    }}
                  />
                );
              }}
              renderPastCell={(date) => <DateCell disabled date={date} size="small" />}
            />
          ))}

          <LoadMoreTrigger
            canLoadMore={canLoadMore}
            isLoading={openShiftsCountIsFetchingNextPage || workerShiftsIsFetchingNextPage}
            renderEmptyState={() => (
              <Text color={(theme) => theme.palette.text.secondary} sx={{ textAlign: "center" }}>
                We admire your enthusiasm for planning ahead, but shifts beyond 1 year from now are
                out of reach. Please check back closer to the date!
              </Text>
            )}
            onLoadMore={loadMore}
          />
        </Stack>

        <DialogFooter
          withShadow
          orientation="horizontal"
          closeButtonAriaLabel="Close Shift Date Picker"
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_CLOSED);
            modalState.closeModal();
          }}
        >
          {selectedDates.length > 0 && (
            <Button
              variant="contained"
              size="large"
              disabled={totalOpenShiftsCount === 0}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_VIEWED_SELECTED, {
                  selectedDates,
                });
                // use sorted array so the calendar header is in order
                setFiltersDates(sortBy(selectedDates, (dateString) => parseISO(dateString)));
                modalState.closeModal();
              }}
            >
              {totalOpenShiftsCount > 0
                ? `View ${totalOpenShiftsCount} open shifts`
                : "No shifts available"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </FullScreenDialog>
  );
}
