import { isDefined } from "@clipboard-health/util-ts";
import { Fragment, useEffect, useMemo, useReducer, useState } from "react";

import { GoogleMapCustomClusterWrapper } from "../../../GeoLocation/GoogleMapCustomClusterWrapper";
import { MapMarkerCluster } from "../../../GeoLocation/MapMarkerCluster";
import { MAX_MAP_ZOOM } from "../constants";
import { useDebugMapViewWorkplaceClusterer } from "../mapViewDebuggingUtils/useDebugMapViewWorkplaceClusterer";
import type { MapViewWorkplace } from "../types";
import { buildWorkplaceClusters } from "./buildWorkplaceClusters";
import type { WorkplaceCluster } from "./types";

interface WorkplaceClustererProps {
  workplaces: MapViewWorkplace[];
  map?: google.maps.Map;
  enabled?: boolean;
  children: (workplace: MapViewWorkplace, position: google.maps.LatLng) => React.ReactNode;
  /**
   * Enable debugging for drawing rectangles around workplaces.
   * Useful for visualizing where markers would be if they were not clustered,
   * and check if they are being clustered correctly.
   */
  debug?: boolean;
}

export function MapViewWorkplaceClusterer(props: WorkplaceClustererProps) {
  const { workplaces, map, debug, enabled, children } = props;

  // recalculate is needed to trigger re-render of the
  // clusters when the map zoom or projection changes
  const [recalculate, setRecalculate] = useReducer((value: number) => value + 1, 0);

  // maxZoomByCluster is used to keep track of the maximum zoom level at
  // which a cluster should be displayed so that we can avoid displaying
  // the same cluster after it has been expanded
  const [maxZoomByCluster, setMaxZoomByCluster] = useState<Record<string, number>>({});

  const workplaceClusters = useMemo(() => {
    if (!map) {
      return [];
    }

    return buildWorkplaceClusters({ map, workplaces, enabled });
    // recalculate is needed to trigger re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, workplaces, enabled, recalculate]);

  useEffect(() => {
    if (!map) {
      return undefined;
    }

    // Keep track of zoom level and projection to re-calculate clusters when they change
    const zoomListener = map.addListener("zoom_changed", () => {
      setRecalculate();
    });

    // Keep track of projection to re-calculate clusters when it changes
    const projectionListener = map.addListener("projection_changed", () => {
      setRecalculate();
    });

    return () => {
      zoomListener?.remove();
      projectionListener?.remove();
    };
  }, [map]);

  const onClusterMarkerClick = (cluster: WorkplaceCluster) => {
    if (cluster.bounds && map) {
      // after the map has been zoomed to the cluster, set the maxZoomByCluster
      // to the current zoom level so that the cluster is not displayed again
      // at a lower zoom level
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        setMaxZoomByCluster((state) => ({
          ...state,
          [cluster.id]: map.getZoom() ?? MAX_MAP_ZOOM,
        }));
      });

      map.setCenter(cluster.position);
      map.fitBounds(cluster.bounds);
    }
  };

  useDebugMapViewWorkplaceClusterer({
    map,
    workplaceClusters,
    enabled: debug ?? false,
  });

  return (
    <>
      {workplaceClusters.flatMap((cluster) => {
        const maxZoom = maxZoomByCluster[cluster.id];
        const currentZoom = map?.getZoom();

        if (isDefined(maxZoom) && isDefined(currentZoom) && currentZoom >= maxZoom) {
          return cluster.workplaceMarkers.map(({ workplace, position }) => (
            <Fragment key={workplace.id}>{children(workplace, position)}</Fragment>
          ));
        }

        if (cluster.workplaceMarkers.length === 1) {
          const { workplace, position } = cluster.workplaceMarkers[0];
          return children(workplace, position);
        }

        return (
          <GoogleMapCustomClusterWrapper key={cluster.id} position={cluster.position}>
            <MapMarkerCluster
              primaryCount={cluster.workplaceMarkers.length}
              secondaryCount={cluster.totalShifts}
              iconType="building"
              onClick={() => {
                onClusterMarkerClick(cluster);
              }}
            />
          </GoogleMapCustomClusterWrapper>
        );
      })}
    </>
  );
}
