import { CbhIcon, type CbhIconProps, type IconType } from "../components/CbhIcon";
import { TimeSlot } from "./Open/types";

interface TimeSlotIconProps extends Pick<CbhIconProps, "size"> {
  timeSlot: TimeSlot;
}

export const iconTypeByTimeSlot: Record<TimeSlot, IconType> = {
  [TimeSlot.AM]: "sun-filled",
  [TimeSlot.PM]: "sunset-filled",
  [TimeSlot.NOC]: "moon-filled",
};

export function TimeSlotIcon(props: TimeSlotIconProps) {
  const { timeSlot, ...restProps } = props;

  return <CbhIcon type={iconTypeByTimeSlot[timeSlot]} {...restProps} />;
}
