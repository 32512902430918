import { mergeSxProps, Title } from "@clipboard-health/ui-react";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import pluralize from "pluralize";
import { type ComponentProps } from "react";

import { isDocumentInstantReview } from "../Bookability/Documents/isDocumentInstantReview";
import { type ShiftType } from "../constants";
import { type ShiftBlockingDocuments } from "./BlockingDocumentsCards/types";
import { ShiftBottomSheetTitleGenericContent } from "./TitleGenericContent";

type TitleProps = ComponentProps<typeof Title>;
type TitleVariant = TitleProps["variant"];
type TitleComponent = TitleProps["component"];

interface ShiftBottomSheetTitleProps extends Omit<TitleProps, "component" | "variant"> {
  shiftType: ShiftType;
  blockingDocuments: ShiftBlockingDocuments;
  hasQuizToPass?: boolean;
  hasAgreementToSign?: boolean;
  hasPaymentsDisabled?: boolean;
  priorityTillDatetimeString?: Shift["priorityTill"];
  endDatetimeString?: Shift["end"];
  startDatetimeString?: Shift["start"];
  component?: TitleComponent;
  variant?: TitleVariant;
}

export function ShiftBottomSheetTitle(props: ShiftBottomSheetTitleProps) {
  const {
    shiftType,
    blockingDocuments,
    hasQuizToPass,
    hasAgreementToSign,
    hasPaymentsDisabled,
    priorityTillDatetimeString,
    endDatetimeString,
    startDatetimeString,
    component = "h3",
    variant = "h3",
    sx,
    ...restProps
  } = props;

  const titleProps = { component, variant, ...restProps };
  const { missingDocumentsSet, pendingDocumentsSet, expiringDocumentsSet } = blockingDocuments;

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  const hasBlockingDocuments =
    missingDocumentsSet.size + expiringDocumentsSet.size + pendingDocumentsSet.size > 0;
  const hasMissingDocuments = missingDocumentsSet.size > 0;
  const hasExpiringDocuments = expiringDocumentsSet.size > 0;
  const missingOrExpiringDocuments = [...missingDocumentsSet, ...expiringDocumentsSet];
  const missingOrExpiringDocumentsCount = missingOrExpiringDocuments.length;
  const hasMissingOrExpiringDocuments = missingOrExpiringDocuments.length > 0;
  const areMissingOrExpiringDocumentsInvisibleRequirements = missingOrExpiringDocuments.every(
    (document) => !document.visibleToHCP
  );
  const areMissingOrExpiringDocumentsInstantReview = missingOrExpiringDocuments.every((document) =>
    isDocumentInstantReview({ document, isHelloSignEmbeddedFlowEnabled })
  );

  const hasPendingDocuments = pendingDocumentsSet.size > 0;
  const blockingShiftRequirements = [
    hasBlockingDocuments,
    hasQuizToPass,
    hasAgreementToSign,
    hasPaymentsDisabled,
  ];
  const hasMultipleBlockingRequirements = blockingShiftRequirements.filter(Boolean).length > 1;

  function handleMultipleBlockingRequirements() {
    // Special variation for only quiz and missing/expiring documents
    if (
      hasQuizToPass &&
      (hasMissingDocuments || hasExpiringDocuments) &&
      // Only show this title if these are the only 2 blocking requirements
      blockingShiftRequirements.filter(Boolean).length < 3
    ) {
      return `Complete ${
        missingOrExpiringDocumentsCount < 3 ? missingOrExpiringDocumentsCount : "3+"
      } ${pluralize(
        "document",
        missingOrExpiringDocumentsCount
      )} and a short quiz to book this shift`;
    }

    return "Complete 2+ requirements to book this shift";
  }

  function handleInstantReviewDocuments() {
    return `Complete ${
      missingOrExpiringDocumentsCount < 3 ? missingOrExpiringDocumentsCount : "3+"
    } instant review ${pluralize("document", missingOrExpiringDocumentsCount)} to book this shift`;
  }

  function handleMissingOrExpiringDocuments() {
    if (areMissingOrExpiringDocumentsInvisibleRequirements) {
      return "You'll be able to book this shift shortly";
    }

    if (hasExpiringDocuments && !hasMissingDocuments) {
      return `Complete ${
        missingOrExpiringDocumentsCount < 3 ? missingOrExpiringDocumentsCount : "3+"
      } expiring ${pluralize("document", missingOrExpiringDocumentsCount)} to book this shift`;
    }

    return `Complete ${
      missingOrExpiringDocumentsCount < 3 ? missingOrExpiringDocumentsCount : "3+"
    } ${pluralize("document", missingOrExpiringDocumentsCount)} to book this shift`;
  }

  function handlePendingDocuments() {
    return `${pendingDocumentsSet.size < 3 ? pendingDocumentsSet.size : "3+"} ${pluralize(
      "document",
      pendingDocumentsSet.size
    )} under review`;
  }

  function getContent() {
    if (hasMultipleBlockingRequirements) {
      return handleMultipleBlockingRequirements();
    }

    if (
      hasMissingOrExpiringDocuments &&
      !hasPendingDocuments &&
      areMissingOrExpiringDocumentsInstantReview
    ) {
      return handleInstantReviewDocuments();
    }

    if (hasMissingOrExpiringDocuments) {
      return handleMissingOrExpiringDocuments();
    }

    if (hasPendingDocuments && !hasMissingOrExpiringDocuments) {
      return handlePendingDocuments();
    }

    if (hasQuizToPass) {
      return "Please take a short quiz to book this shift";
    }

    if (hasAgreementToSign) {
      return "Please sign the agreement to book this shift";
    }

    if (hasPaymentsDisabled) {
      return "Set up your payment information to book this shift";
    }

    return (
      <ShiftBottomSheetTitleGenericContent
        shiftType={shiftType}
        priorityTillDatetimeString={priorityTillDatetimeString}
        endDatetimeString={endDatetimeString}
        startDatetimeString={startDatetimeString}
      />
    );
  }

  return (
    <Title {...titleProps} sx={mergeSxProps({ padding: 7, textWrap: "balance" }, sx)}>
      {getContent()}
    </Title>
  );
}
