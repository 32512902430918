import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Box, Stack } from "@mui/material";
import { getScoreColor } from "@src/appV2/AttendanceScore/utils";
import { getOrdinalPostfix } from "@src/appV2/lib/utils/formatNumbers";
import { ceil } from "lodash";

export interface ScorePageHeaderProps {
  score: number;
  rank?: number;
  totalWorkers?: number;
  showPercentile: boolean;
  workerType: string;
}

export function ScorePageHeader(props: ScorePageHeaderProps) {
  const { score, rank, totalWorkers, showPercentile, workerType } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
      }}
    >
      <Text bold variant="h1" sx={{ color: getScoreColor(score) }}>
        {score} pts
      </Text>

      {isDefined(rank) && isDefined(totalWorkers) && (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
          <EmojiEventsOutlinedIcon />
          <Stack direction="column">
            <Text bold>Local Rank</Text>
            <Text variant="subtitle2">
              {showPercentile ? (
                <>
                  Top {ceil(rank / totalWorkers, rank / totalWorkers < 0.1 ? 2 : 1) * 100}% of{" "}
                  {workerType}s
                </>
              ) : (
                <>
                  {rank}
                  {getOrdinalPostfix(rank)} of {totalWorkers} {workerType}s
                </>
              )}
            </Text>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
