import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import QRCode, { type QRCodeToDataURLOptions } from "qrcode";

/**
 * Fetches a QR code data URL for the given data, this
 * can be used as src for an image tag.
 *
 * @param params.data The data to encode in the QR code,
 * this can be a link or text.
 * Example: "https://example.com" or "Hello World"
 * @param params.qrOptions Options to pass to the QR code
 * generator library.
 *
 */
export function useQrCodeDataUrl(
  params: { data: string; qrOptions?: QRCodeToDataURLOptions },
  options: UseQueryOptions<string> = {}
): UseQueryResult<string> {
  const { data, qrOptions } = params;
  return useQuery({
    queryKey: [data],
    queryFn: async () => {
      return await QRCode.toDataURL(data, qrOptions);
    },
    ...options,
  });
}
