import { Text, Title } from "@clipboard-health/ui-react";
import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { LoadingSpinner } from "@src/appV2/redesign/components/LoadingSpinner";

interface SubmittingDocumentDialogProps {
  modalState: UseModalState;
}

export function SubmittingDocumentDialog(props: SubmittingDocumentDialogProps) {
  const { modalState } = props;

  return (
    <BottomSheet
      modalState={modalState}
      paperSx={{
        minHeight: (theme) => theme.size!.bottomSheet.loading.minHeight,
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingX: 4,
        }}
      >
        <Stack direction="column" alignItems="center" spacing={6}>
          <LoadingSpinner size="xlarge" variant="secondary" />
          {/* 
              Todo: this should be an h2, however there is an inconsistency in the typography, and we will fix this later 
              For now we will hack the font size to match with the designs 
              https://linear.app/clipboardhealth/issue/ALCH-3158/fix-hack-of-manual-font-size-in-shift-submissionsubmitted-modals
            */}
          <Title
            variant="h3"
            component="h3"
            sx={{ textWrap: "balance", textAlign: "center", fontSize: "2.5rem" }}
          >
            Submitting Document
          </Title>
          <Text variant="body1" align="center">
            Should only take a few seconds
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
