import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { parseISO } from "date-fns";

import { Button } from "../../components/Button";
import { SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceNoteIdentifier } from "../../Workplace/constants";
import { CancellationPoliciesSection } from "../../Workplace/Policy/CancellationPoliciesSection";
import { useWorkplaceNotesParsed } from "../../Workplace/useWorkplaceNotesParsed";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetCancellationPoliciesCardProps {
  lateCancellationPeriod: number;
  lateCancellationFeeHours: number;
  sentHomeChargeHours: number;
  workplaceId: string;
  // TODO: This field is temporary until we switch to use workplaceId in the modal context
  // This is just to set the workplace in the context when navigation to the modal
  workplace: Facility;
}

export function ShiftBottomSheetCancellationPoliciesCard(
  props: ShiftBottomSheetCancellationPoliciesCardProps
) {
  const {
    lateCancellationPeriod,
    lateCancellationFeeHours,
    sentHomeChargeHours,
    workplaceId,
    workplace,
  } = props;

  const { data: notes = {} } = useWorkplaceNotesParsed(workplaceId);
  const { generateModalPath, setWorkplace } = useWorkplaceModalsDataContext();

  const cancellationInstructionsNote = notes[WorkplaceNoteIdentifier.CANCEL_INSTRUCTIONS];

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent sx={{ padding: 6, height: "100%" }}>
        <Stack sx={{ height: "100%", gap: 7 }}>
          <Text semibold variant="body1">
            Cancellation Policies
          </Text>

          <CancellationPoliciesSection
            validAsOfDate={
              cancellationInstructionsNote?.updatedAt
                ? parseISO(cancellationInstructionsNote.updatedAt)
                : new Date()
            }
            cancellationWorkplaceNote={cancellationInstructionsNote?.note}
            lateCancellationPeriod={lateCancellationPeriod}
            lateCancellationFeeHours={lateCancellationFeeHours}
            sentHomeChargeHours={sentHomeChargeHours}
          />

          <Button
            fullWidth
            variant="outlined"
            size="medium"
            sx={{ marginTop: "auto" }}
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH, {
              workplaceId,
            })}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.VIEWED_WORKPLACE_RULES_PAGE, {
                workplaceId,
                trigger: "Shift Bottom Sheet Cancellation Policies Card",
              });
              setWorkplace(workplace);
            }}
          >
            Read Policies
          </Button>
          {/* TODO: This will be reused in my shifts and here will be a button to cancel the shift */}
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
