import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { assessmentEventSchema } from "../types";

export const createAssessmentEventRequestSchema = z.object({
  data: assessmentEventSchema.omit({ id: true }),
});
export type CreateAssessmentEventRequest = z.infer<typeof createAssessmentEventRequestSchema>;

export const createAssessmentEventParamsSchema =
  createAssessmentEventRequestSchema.shape.data.shape.attributes;
export type CreateAssessmentEventParams = z.infer<typeof createAssessmentEventParamsSchema>;

export const createAssessmentEventResponseSchema = z.object({
  data: assessmentEventSchema.omit({ attributes: true }),
});
export type CreateAssessmentEventResponse = z.infer<typeof createAssessmentEventResponseSchema>;

export const CREATE_ASSESSMENT_EVENT_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/assessment-events`;

export function useCreateAssessmentEvent(
  options: UseMutationOptions<
    CreateAssessmentEventResponse,
    AxiosError,
    CreateAssessmentEventParams
  > = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const response = await post({
        url: CREATE_ASSESSMENT_EVENT_URL,
        data: {
          data: {
            type: "assessment-event",
            attributes: params,
          },
        },
        requestSchema: createAssessmentEventRequestSchema,
        responseSchema: createAssessmentEventResponseSchema,
      });
      return response.data;
    },
    onError(error, variables) {
      logEvent(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_EVENT_FAILURE, { error, variables });
    },
    ...options,
  });
}
