import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  type CreateAssessmentEventParams,
  type CreateAssessmentEventResponse,
  useCreateAssessmentEvent,
} from "./useCreateAssessmentEvent";

export const scheduleAssessmentParamsSchema = z.object({
  workerId: z.string(),
  assessmentId: z.string(),
  scheduledFor: z.string().datetime(),
});
export type ScheduleAssessmentParams = z.infer<typeof scheduleAssessmentParamsSchema>;

export function useScheduleAssessment(
  options: UseMutationOptions<
    CreateAssessmentEventResponse,
    AxiosError,
    CreateAssessmentEventParams
  > = {}
) {
  const mutation = useCreateAssessmentEvent({
    onError: (error, _) => {
      logError(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_SCHEDULED_EVENT_FAILURE, { error });
    },
    ...options,
  });

  return {
    ...mutation,
    mutateAsync: async (params: ScheduleAssessmentParams) => {
      const scheduledParams = { ...params, status: "SCHEDULED" };
      return await mutation.mutateAsync(scheduledParams);
    },
  };
}
