import { Button as MuiButton, type ButtonProps as MuiButtonProps } from "@mui/material";

import type { ButtonSize, ButtonVariant } from "../theming";
import { ButtonInternalLink } from "./ButtonInternalLink";
import { CbhIcon, type IconType } from "./CbhIcon";

export interface ButtonProps
  extends Pick<
    MuiButtonProps,
    "children" | "disabled" | "fullWidth" | "href" | "onClick" | "sx" | "type"
  > {
  endIconType?: IconType;
  invert?: boolean;
  size?: ButtonSize;
  startIconType?: IconType;
  variant?: ButtonVariant;
}

const buttonVariantOverrides: Record<ButtonVariant, MuiButtonProps["variant"]> = {
  contained: "contained",
  outlined: "outlined",
  destructive: "outlined",
};

/**
 * A wrapper around MuiButton that limits variants to our custom ones and adds our custom icon handling
 * NOTE: Provide `href` to make the button render as an anchor tag. It will support react-router redirecs
 */
export function Button(props: ButtonProps) {
  const { variant = "contained", startIconType, endIconType, invert, ...restProps } = props;

  return (
    <MuiButton
      {...restProps}
      invert={invert ? "true" : undefined}
      variant={buttonVariantOverrides[variant]}
      color={variant === "destructive" ? "error" : "primary"}
      startIcon={startIconType ? <CbhIcon type={startIconType} /> : undefined}
      endIcon={endIconType ? <CbhIcon type={endIconType} /> : undefined}
      LinkComponent={ButtonInternalLink}
    />
  );
}
