import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";

import { Button, type ButtonProps } from "./Button";
import { LoadingSpinner } from "./LoadingSpinner";

export interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

function getLoadingSpinnerVariant(variant: ButtonProps["variant"], invert: boolean) {
  if (variant === "contained") {
    return invert ? "primary" : "invert";
  }

  if (variant === "destructive") {
    return "destructive";
  }

  return invert ? "invert" : "primary";
}

/**
 * A button that renders a loading spinner in the button while the button is loading
 * Uses `Button` internally, so it supports all the same props
 * TODO: This will ultimately go to cbh-core and replace `LoadingButton` from there
 */
export function LoadingButton(props: LoadingButtonProps) {
  const { isLoading, sx, children, variant, invert, disabled, ...restProps } = props;

  return (
    <Button
      {...restProps}
      variant={variant}
      disabled={isLoading || disabled}
      invert={invert}
      sx={mergeSxProps(sx, {
        ...(isLoading && { color: "transparent !important" }),
      })}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            visibility: "visible",
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          <LoadingSpinner
            size="small"
            variant={getLoadingSpinnerVariant(variant, invert ?? false)}
          />
        </Box>
      )}
      {children}
    </Button>
  );
}
