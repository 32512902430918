import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { isDefined } from "@clipboard-health/util-ts";
import type React from "react";
import { useEffect, useState } from "react";

import { isCapacitorPlatform } from "../utils";

export const PULL_TO_REFRESH_TRIGGER_THRESHOLD = 160;

export function usePullToRefresh(
  onRefresh: () => Promise<void>,
  ref?: React.RefObject<HTMLDivElement>
): {
  dragDistance: number;
  isRefreshing: boolean;
  percentage: number;
  isPulling: boolean;
} {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dragDistance, setDragDistance] = useState(0);
  const [isPulling, setIsPulling] = useState(false);

  const percentage = Math.min(1, dragDistance / PULL_TO_REFRESH_TRIGGER_THRESHOLD);

  useEffect(() => {
    const onTouchStart = (event: TouchEvent): void => {
      /**
       * FIXME -Using this to protect from pulling behind an open MuiBackdrop
       * https://linear.app/clipboardhealth/issue/FEF-728/add-hack-to-protect-from-pull-to-refresh-when-blocking-dialogs
       */
      if (isDefined(document.querySelector(".MuiBackdrop-root"))) {
        return;
      }

      // Don't apply pull-to-refresh unless the touch event is applied to a descendant of the element being scrolled
      if (
        ref?.current &&
        event.target &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        return;
      }

      // Don't apply pull-to-refresh unless the element being scrolled is at its topmost position
      if (ref?.current && ref.current.scrollTop !== 0) {
        return;
      }

      const initialY = event.touches[0].clientY;
      setIsPulling(true);

      const onTouchMove = (event: TouchEvent) => {
        const currentY = event.touches[0].clientY;
        const deltaY = Math.max(0, currentY - initialY);
        setDragDistance(deltaY);
      };

      const onTouchEnd = async (event: TouchEvent) => {
        const currentY = event.changedTouches[0].clientY;
        const deltaY = currentY - initialY;
        setIsPulling(false);

        if (deltaY >= PULL_TO_REFRESH_TRIGGER_THRESHOLD) {
          if (isCapacitorPlatform()) {
            await Haptics.impact({ style: ImpactStyle.Medium });
          }

          setIsRefreshing(true);
          await onRefresh?.();
          setIsRefreshing(false);
        }

        setDragDistance(0);

        document.removeEventListener("touchmove", onTouchMove);
        document.removeEventListener("touchend", onTouchEnd);
      };

      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
    };

    document.addEventListener("touchstart", onTouchStart);

    return () => {
      document.removeEventListener("touchstart", onTouchStart);
    };
  }, [onRefresh, ref]);

  return { dragDistance, isRefreshing, percentage, isPulling };
}
