import { Paper } from "@mui/material";
import { type ReactNode } from "react";

import { type PaperVariant } from "../theming";

interface Props {
  children: ReactNode;
  variant?: PaperVariant;
}

/**
 * This is minimalistic wrapper for a page. It has simple column flex layout as this is what we usually need
 * It's preferred to use components composition, so this component does not enforce any particular header, footer or margins
 */
export function PageWrapper(props: Props) {
  const { children, variant = "primary" } = props;

  return (
    <Paper
      variant={variant}
      sx={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Paper>
  );
}
