import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { type OpenShift } from "../../Shift/Open/types";
import { DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { ClearAllFiltersButton } from "../Filters/ClearAllFiltersButton";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

interface MapViewPageHeaderProps {
  unfilteredOpenShifts: OpenShift[];
}

export function MapViewPageHeader(props: MapViewPageHeaderProps) {
  const { unfilteredOpenShifts } = props;
  const { dates, appliedFiltersCount, clearFilters } = useShiftDiscoveryUserFiltersContext();

  return (
    <PageHeaderWrapper variant="tertiary">
      <Stack sx={{ paddingBottom: 7 }} spacing={3} alignItems="center">
        <Text semibold variant="body1" sx={{ paddingTop: 3 }}>
          Shifts for the next {DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS} days
        </Text>

        <ClearAllFiltersButton
          allShiftsCount={unfilteredOpenShifts.length}
          appliedFiltersCount={appliedFiltersCount}
          dates={dates}
          clearFilters={clearFilters}
          sx={{ margin: "auto", mb: 4 }}
        />
      </Stack>
    </PageHeaderWrapper>
  );
}
