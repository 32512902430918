import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { getFirebaseSingleton } from "@src/lib/firebase/src/lib/firebaseSingleton";
import { ActionType, getUserByEmail } from "@store/session";
import moment from "moment-timezone";
import { Dispatch } from "redux";

export async function populateSessionAfterLogin(dispatch: Dispatch) {
  const userAgent = await getUserByEmail();
  const user = getFirebaseSingleton().currentUser;

  const { agent } = userAgent || {};

  if (userAgent?.tmz) {
    moment.tz.setDefault(userAgent.tmz);
  }

  dispatch({
    type: ActionType.LOGGED_IN,
    data: {
      userId: userAgent?._id,
      sendBirdAccessToken: userAgent?.sendBirdAccessToken,
      profile: {
        name: agent?.name,
        email: agent?.email,
        phone: agent?.phone ?? user?.phoneNumber,
      },
    },
  });

  logEvent(APP_V2_APP_EVENTS.AUTHORIZED_WORKER, {
    userId: userAgent?._id,
    email: agent?.email,
    phone: agent?.phone ?? user?.phoneNumber,
    name: agent?.name,
  });
}
