import { Worker } from "@src/appV2/Worker/api/types";

import { OnboardingRouterPath } from "../routing/constant/onboardingRoute";

/* To enable more flexibility in the onboarding flow, we've added the following attributes to each stage:
 * Terms:
 * Onboarding screen - Specific screens within a section of onbording.
 *                     I.E. The first screen in the assessment scheduling flow.
 * Onboarding section - A group of screens that make up a specific feature.
 *                     I.E. The assessment scheduling flow.
 *
 * New Attributes:
 * - nextScreen: The next screen to navigate to if the current onboarding screen is successfully completed.
 *               This can be the next screen in a section or the first screen in the next section.
 * - nextSection: This is the first page of the next onboarding section.  If you want to allow a section
 *                to be skippable in case of failure, then you would use this attribute in order to redirect
 *                the user to the first screen of the next section.
 *                I.E. If we encountered three errors when scheduling an assessment we would redirect the user
 *                not to the next assessment screen, but instead the first screen in the Checkr invitation flow.
 * - previousScreen: The screen to navigate to if the user wants to go back to the previous screen regardless
 *                   of the section.
 *
 * We will continue to support nextStagePath temporarily but should update all screens to support
 * the new attributes and remove this later.
 */
export const STAGES = {
  agentSignUpInfo1: {
    path: OnboardingRouterPath.ONBOARDING_INFO1,
    nextStagePath: OnboardingRouterPath.ONBOARDING_EMAIL,
  },
  agentEmail: {
    path: OnboardingRouterPath.ONBOARDING_EMAIL,
    nextStagePath: OnboardingRouterPath.ONBOARDING_FIRST_NAME,
  },
  agentFirstName: {
    path: OnboardingRouterPath.ONBOARDING_FIRST_NAME,
    nextStagePath: OnboardingRouterPath.ONBOARDING_LAST_NAME,
  },
  agentLastName: {
    path: OnboardingRouterPath.ONBOARDING_LAST_NAME,
    nextStagePath: OnboardingRouterPath.ONBOARDING_ADDRESS,
  },
  agentAddress: {
    path: OnboardingRouterPath.ONBOARDING_ADDRESS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentDOB: {
    path: OnboardingRouterPath.ONBOARDING_DOB,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentSSN: {
    path: OnboardingRouterPath.ONBOARDING_SSN,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO2,
  },
  agentSignUpInfo2: {
    path: OnboardingRouterPath.ONBOARDING_INFO2,
    nextStagePath: OnboardingRouterPath.ONBOARDING_LICENSE,
  },
  agentQualification: {
    path: OnboardingRouterPath.ONBOARDING_QUALIFICATION,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO3,
  },
  agentLicense: {
    path: OnboardingRouterPath.ONBOARDING_QUALIFICATION,
    nextStagePath: OnboardingRouterPath.ONBOARDING_INFO3,
  },
  agentSignUpInfo3: {
    path: OnboardingRouterPath.ONBOARDING_INFO3,
    nextStagePath: OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS,
  },
  agentPotentialShifts: {
    path: OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW,
  },
  contractorAgreementReview: {
    path: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW,
    nextStagePath: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT,
  },
  asyncAssessment: {
    path: OnboardingRouterPath.ONBOARDING_SKILL_ASSESSMENT,
    nextStagePath: OnboardingRouterPath.ONBOARDING_STRIPE,
  },
  contractorAgreement: {
    path: OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT,
    nextStagePath: OnboardingRouterPath.ONBOARDING_STRIPE,
  },
  stripeOnboarding: {
    path: OnboardingRouterPath.ONBOARDING_STRIPE,
    nextStagePath: OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE,
  },
  agentReferralSubmission: {
    path: OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE,
    nextStagePath: OnboardingRouterPath.READY_TO_EARN,
  },
  agentReadyToEarn: {
    path: OnboardingRouterPath.READY_TO_EARN,
    nextStagePath: OnboardingRouterPath.SHOW_FRIENDS_SHIFTS,
  },
  agentShowFriendsShifts: {
    path: OnboardingRouterPath.SHOW_FRIENDS_SHIFTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO,
  },
  proceedToRequirementsInfo: {
    path: OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO,
    nextStagePath: OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS,
  },
  uploadDocuments: {
    path: OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS,
    nextStagePath: OnboardingRouterPath.ONBOARDING_WELCOME, // TODO: Ticket: https://linear.app/clipboardhealth/issue/ACT-1146/update-uploaddocuments-nextstagepath-once-assessments-goes-100percent
  },

  scheduledAssessmentInstructions: {
    path: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS,
    nextScreen: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT,
    nextSection: OnboardingRouterPath.ONBOARDING_WELCOME,
    previousScreen: OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS,
  },
  scheduleAssessment: {
    path: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT,
    nextScreen: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_CONFIRMATION,
    nextSection: OnboardingRouterPath.ONBOARDING_WELCOME,
    previousScreen: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS,
  },
  scheduleAssessmentConfirmation: {
    path: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_CONFIRMATION,
    nextScreen: OnboardingRouterPath.ONBOARDING_WELCOME,
    nextSection: OnboardingRouterPath.ONBOARDING_WELCOME,
    previousScreen: OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS, // TODO: Since they already scheduled the assessment, send them back to instructions
  },
};

export interface OnBoardingRouteProps {
  agent?: Worker;
}
