import { Clipboard } from "@capacitor/clipboard";
import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { logEvent } from "@src/appV2/lib/analytics";

import { APP_V2_APP_EVENTS, useToast } from "../../../lib";
import { IconButton } from "../../components/IconButton";

interface PlacementLocationMapProps {
  coordinates: number[];
  formattedAddress: string;
  placementId: string;
  placementCandidateId: string;
}

export function PlacementLocationMap({
  coordinates,
  formattedAddress,
  placementId,
  placementCandidateId,
}: PlacementLocationMapProps) {
  const { showSuccessToast } = useToast();

  return (
    <Box sx={{ position: "relative" }}>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "250px",
          borderRadius: "16",
        }}
        center={{
          lat: coordinates[1],
          lng: coordinates[0],
        }}
        zoom={15}
        options={{
          disableDefaultUI: true,
          gestureHandling: "cooperative",
        }}
      >
        <Marker
          position={{
            lat: coordinates[1],
            lng: coordinates[0],
          }}
        />
      </GoogleMap>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
          right: "16px",
          padding: "16px",
          backgroundColor: (theme) => theme.palette.background.tertiary,
          borderRadius: 4,
          border: "0.5px solid",
          borderColor: (theme) => theme.palette.border?.primary,
        }}
      >
        <Text variant="body2" color="text.secondary">
          {formattedAddress}
        </Text>
        <IconButton
          iconType="document"
          variant="outlined"
          size="small"
          onClick={async () => {
            await Clipboard.write({
              string: formattedAddress,
            });
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_ACTIONS, {
              action: "address_copied",
              placementId,
              placementCandidateId,
            });
            showSuccessToast("Address copied successfully");
          }}
        />
      </Stack>
    </Box>
  );
}
