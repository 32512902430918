import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

import { isDocumentInstantReview } from "../isDocumentInstantReview";

interface IsShiftInstantReviewProps {
  isHelloSignEmbeddedFlowEnabled: boolean;
  missingDocuments: HcpRequirement[];
}

export function isShiftInstantReview(props: IsShiftInstantReviewProps) {
  const { isHelloSignEmbeddedFlowEnabled, missingDocuments } = props;
  return (
    isHelloSignEmbeddedFlowEnabled &&
    missingDocuments.every((document) =>
      isDocumentInstantReview({
        document,
        isHelloSignEmbeddedFlowEnabled,
      })
    )
  );
}
