import constate from "constate";
import { parseISO } from "date-fns";
import { groupBy } from "lodash";
import { useRef, useState } from "react";
import { type GroupedVirtuosoHandle } from "react-virtuoso";

import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { type OpenShiftListItem } from "./types";

interface UseVirtualShiftListProps {
  shifts: OpenShiftListItem[];
}

export function useVirtualShiftList(props: UseVirtualShiftListProps) {
  const { shifts } = props;

  const listRef = useRef<GroupedVirtuosoHandle>(null);

  const [scrolledDate, setScrolledDate] = useState<string | undefined>(undefined);

  const getItemDate = (item: OpenShiftListItem) => {
    const dateString = "start" in item ? item.start : item.attributes.start;

    return parseISO(dateString);
  };

  const sortedShifts = shifts.sort((a, b) => {
    const dateA = getItemDate(a);
    const dateB = getItemDate(b);

    return dateA.getTime() - dateB.getTime();
  });

  const shiftsByDate = groupBy(sortedShifts, (shift) => {
    const date = getItemDate(shift);

    return formatDayOfMonthWithDayName(date);
  });

  const dateGroups = Object.keys(shiftsByDate);
  const shiftsByDateCount = Object.values(shiftsByDate).map((shifts) => shifts.length);
  const groupSortedShifts = Object.values(shiftsByDate).flat();

  const scrollToDate = (dateIso: string) => {
    const formattedDate = formatDayOfMonthWithDayName(parseISO(dateIso));
    const dateGroupIndex = dateGroups.indexOf(formattedDate);

    if (dateGroupIndex >= 0) {
      listRef.current?.scrollToIndex({
        groupIndex: dateGroupIndex,
        // offset is a way to scroll before the date label in the list.
        // Without it it scrolls in the way that first item is visible, but not a label
        offset: -1,
      });
    }
  };

  return {
    scrollToDate,
    listRef,
    getItemDate,
    shiftsByDate,
    dateGroups,
    shiftsByDateCount,
    groupSortedShifts,
    setScrolledDate,
    scrolledDate,
  };
}

export const [VirtualShiftListContextProvider, useVirtualShiftListContext] =
  constate(useVirtualShiftList);
