import WarningOutlinedIcon from "@mui/icons-material/WarningTwoTone";
import { Alert, AlertTitle } from "@mui/material";

export interface LatenessDeductionWarningAlertProps {
  latenessPointsDeductedInLast30Days?: number;
}

export function LatenessDeductionWarningAlert(props: LatenessDeductionWarningAlertProps) {
  const { latenessPointsDeductedInLast30Days = 0 } = props;

  return (
    <Alert
      severity="warning"
      icon={<WarningOutlinedIcon sx={{ color: (theme) => theme.palette.error.main }} />}
    >
      <AlertTitle sx={{ fontWeight: "bold", color: (theme) => theme.palette.error.main }}>
        Warning!
      </AlertTitle>
      {`You lost ${latenessPointsDeductedInLast30Days} points due to timeliness deductions in the last month.`}
    </Alert>
  );
}
