import { convertToGoogleMapsLocation } from "@src/appV2/Location/utils";
import { useEffect, useRef } from "react";

import type { MapViewWorkplace } from "./types";

/**
 * After workplaces and open shifts are loaded, make sure
 * the map is zoomed out enough to show any workplaces with open shifts.
 *
 * If a workplace with open shifts is already in the viewport,
 * the map zoom will not be changed.
 */
export function useMapFitOpenShiftsBounds(workplaces: MapViewWorkplace[], map?: google.maps.Map) {
  const initializedFitOpenShifts = useRef(false);

  useEffect(() => {
    const workplacesWithShifts = workplaces?.filter((workplace) => workplace.shiftsCount > 0);

    if (map && !initializedFitOpenShifts.current && workplacesWithShifts.length > 0) {
      initializedFitOpenShifts.current = true;

      const hasWorkplacesWithShiftsInViewport = workplacesWithShifts.some((workplace) =>
        map.getBounds()?.contains(convertToGoogleMapsLocation(workplace.attributes.location))
      );

      if (!hasWorkplacesWithShiftsInViewport) {
        const workplacesWithShiftsBounds = new google.maps.LatLngBounds();
        workplacesWithShifts.forEach((workplace) => {
          workplacesWithShiftsBounds.extend(
            convertToGoogleMapsLocation(workplace.attributes.location)
          );
        });

        map.setCenter(workplacesWithShiftsBounds.getCenter());
        map.fitBounds(workplacesWithShiftsBounds);
      }
    }
  }, [map, workplaces]);
}
