import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { parseISO } from "date-fns";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { Pill } from "../../components/Pill";
import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";

interface ATeamShiftPillProps {
  priorityTillDatetimeString?: string;
  compact?: boolean;
}

export function ATeamShiftPill(props: ATeamShiftPillProps) {
  const { compact = false, priorityTillDatetimeString } = props;

  const timeLeft = isDefined(priorityTillDatetimeString)
    ? formatDateRangeDurationCompact({
        startDate: new Date(),
        endDate: parseISO(priorityTillDatetimeString),
      })
    : undefined;

  return (
    <Pill
      label={
        compact ? (
          timeLeft ?? "A Team"
        ) : (
          <Text semibold variant="body2">
            <DotSeparatedList>
              A Team
              {timeLeft}
            </DotSeparatedList>
          </Text>
        )
      }
      iconType="priority"
      color={(theme) => theme.palette.shifts?.priority.labelLight}
      labelColor={(theme) => theme.palette.shifts?.priority.labelDark}
    />
  );
}
