import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useEffect } from "react";

import {
  LockScreenOrientationVariant,
  useDeprecatedScreenOrientation,
} from "./useScreenOrientation";

/**
 * Locks the screen orientation to portrait primary on mount.
 */
export function LockScreenOrientation() {
  const { lockOrientation } = useDeprecatedScreenOrientation();
  const lockOrientationEnabled = useCbhFlag(CbhFeatureFlag.LOCK_SCREEN_ORIENTATION, {
    defaultValue: false,
  });

  // Lock the screen orientation on mount
  useEffect(() => {
    if (lockOrientationEnabled) {
      void lockOrientation(LockScreenOrientationVariant.PORTRAIT_PRIMARY);
    }
  }, [lockOrientation, lockOrientationEnabled]);

  return null;
}
