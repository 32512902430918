import { Text } from "@clipboard-health/ui-react";
import { Stack, type SxProps, type Theme } from "@mui/material";

import { LoadingSpinner } from "../../components/LoadingSpinner";

interface LoadingMoreIndicatorProps {
  sx?: SxProps<Theme>;
}

export function LoadingMoreIndicator(props: LoadingMoreIndicatorProps) {
  const { sx } = props;

  return (
    <Stack spacing={5} alignItems="center" sx={sx}>
      <LoadingSpinner size="large" variant="primary" />

      <Text color={(theme) => theme.palette.text.secondary}>Loading more shifts...</Text>
    </Stack>
  );
}
