// TODO: handle block booking filtering after it is implemented

import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

// https://linear.app/clipboardhealth/issue/SPRTA-813/display-blocks-together-with-other-shifts
const BLOCK_BOOKING_ENABLED = false;

export function useIsShiftBookingTypeFilterEnabled() {
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });

  return shiftBlockBookingFlag.isBlockBookingEnabled && BLOCK_BOOKING_ENABLED;
}
