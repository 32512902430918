import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { LoadingButton } from "../../components/LoadingButton";
import { invalidateShifts } from "../../invalidateShifts";
import { type ShiftWithType } from "../types";

interface ShiftNotBookableButtonProps {
  bookabilityStatus: BookabilityStatusItem;
  shift?: ShiftWithType;
  isLoading: boolean;
  hasBlockingDocuments: boolean;
  onClose: () => void;
}

export function ShiftNotBookableButton(props: ShiftNotBookableButtonProps) {
  const { bookabilityStatus, shift, isLoading, hasBlockingDocuments, onClose } = props;

  const { _id: shiftId = "" } = shift ?? {};

  const isTestAccount = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT,
  ]);

  const isWorkplaceUnavailable = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.FACILITY_PENDING,
    BookabilityUnmetCriteria.FACILITY_SUSPENDED,
  ]);

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const hasAgreementToSign = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT,
  ]);

  const hasPaymentsDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
  ]);

  const isShiftUnavailable = (!isDefined(shift) && !isLoading) || isWorkplaceUnavailable;

  const getLabel = () => {
    if (isTestAccount) {
      return "Disabled for test accounts";
    }

    if (isShiftUnavailable) {
      logEvent(APP_V2_USER_EVENTS.VIEWED_CANT_BOOK_SHIFT_CTA, { shiftId });
      return "Sorry, this shift is not available anymore";
    }

    return "Can't book this shift";
  };

  const onClick = () => {
    onClose();
  };

  const queryClient = useQueryClient();
  useEffect(() => {
    // If the workplace is suspended or the shift is not defined, it means we're
    // displaying an outdated list with unbookable shifts. We need to
    // invalidate the data asap, so that the list of shifts is updated.
    if (isShiftUnavailable) {
      void invalidateShifts(queryClient);
    }
  }, [isShiftUnavailable, queryClient]);

  // In these cases, we will show the CTA within the card itself
  if (hasBlockingDocuments || hasQuizToPass || hasAgreementToSign || hasPaymentsDisabled) {
    return null;
  }

  return (
    <LoadingButton
      fullWidth
      variant="contained"
      size="large"
      isLoading={isLoading}
      onClick={onClick}
    >
      {getLabel()}
    </LoadingButton>
  );
}
