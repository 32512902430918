import { get } from "@src/appV2/api";
import { type DateRange } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { format } from "date-fns";

import {
  GET_OPEN_SHIFT_COUNT_URL,
  type OpenShiftCountParams,
  openShiftCountRequestSchema,
  type OpenShiftCountResponse,
  openShiftCountResponseSchema,
  type OpenShiftRequest,
} from "./useOpenShiftCount";

interface GetPaginatedOpenShiftCountParams
  extends Omit<OpenShiftCountParams, "startDate" | "endDate"> {
  initialDateRange: DateRange;
}

interface WrappedPaginatedOpenShiftCountResponse {
  dateRange: DateRange;
  data: OpenShiftCountResponse;
}

export function usePaginatedOpenShiftCount(
  params: GetPaginatedOpenShiftCountParams,
  options: UseInfiniteQueryOptions<WrappedPaginatedOpenShiftCountResponse> = {}
) {
  const { initialDateRange, qualification, coordinates, specialities, distance, tmz, isAgent } =
    params;

  return useInfiniteQuery<WrappedPaginatedOpenShiftCountResponse>({
    queryKey: [
      GET_OPEN_SHIFT_COUNT_URL,
      { qualification, coordinates, specialities, distance, tmz, isAgent },
    ],
    queryFn: async ({ pageParam: nextDateRange }: QueryFunctionContext<QueryKey, DateRange>) => {
      const dateRange = nextDateRange ?? initialDateRange;

      const queryParams: OpenShiftRequest = {
        dateFilter: {
          start: format(dateRange.startDate, "yyyy-MM-dd"),
          end: format(dateRange.endDate, "yyyy-MM-dd"),
        },
        qualification: qualification ?? "",
        coordinates: coordinates ?? [],
        specialities,
        distance,
        tmz,
        isAgent,
      };

      const response = await get({
        url: GET_OPEN_SHIFT_COUNT_URL,
        queryParams,
        requestSchema: openShiftCountRequestSchema,
        responseSchema: openShiftCountResponseSchema,
      });

      return { data: response.data, dateRange };
    },
    meta: {
      userErrorMessage: "Something went wrong while loading open shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_COUNT_FAILURE,
    },
    ...options,
  });
}
