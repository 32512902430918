import { isDefined } from "@clipboard-health/util-ts";
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "@src/appV2/SkillsAssessment/api/useSkillsAssessments";
import { OnboardingScheduleAssessment } from "@src/appV2/SkillsAssessment/components/Status/OnboardingScheduleAssessment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";

export function ScheduledAssessmentOnboarding(props: OnBoardingComponentProps) {
  const { nextScreen, previousScreen, nextSection } = props; // TODO: Ticket: https://linear.app/clipboardhealth/issue/ACT-1147/add-a-retry-backoff-and-on-failure-redirect-to-sync-assessment

  const worker = useDefinedWorker();
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_PAGE_VIEWED);

  const queryFailureHandler = () => {
    if (isDefined(nextSection)) {
      history.push(nextSection);
    }
  };

  const { showSuccessToast } = useToast();

  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    data: assessments,
    isLoading,
    isSuccess,
    refetch,
  } = useSkillsAssessments(
    {
      workerId: worker.userId,
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
      onFailure: queryFailureHandler,
    },
    {
      enabled: isDefined(worker.preference.qualification),
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={previousScreen} mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <Stack
        alignItems="center"
        width="100%"
        height="100%"
        paddingY={5}
        paddingX={5}
        overflow="auto"
      >
        {isLoading && <CircularProgress />}
        {isSuccess && (
          <OnboardingScheduleAssessment
            assessment={assessments?.data[0]}
            nextSection={nextSection}
            onSuccess={() => {
              showSuccessToast("Assessment successfully scheduled!");
              logEvent(
                APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_SCHEDULE_BUTTON_CLICKED
              );
              if (isDefined(nextScreen)) {
                history.push(nextScreen);
              }
              void refetch();
            }}
          />
        )}
      </Stack>
    </StyledIonPage>
  );
}
