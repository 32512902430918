import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";

interface AssessmentRequirementDialogProps {
  onDetailsClick: () => void;
  onContinue: () => void;
  modalState: UseModalState;
  qualificationName: string;
}

export function AssessmentRequirementScheduledDialog(props: AssessmentRequirementDialogProps) {
  const { onDetailsClick, onContinue, modalState, qualificationName } = props;

  return (
    <CommonDialog
      modalState={modalState}
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onContinue();
            }}
          >
            Continue
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              onDetailsClick();
            }}
          >
            Learn More
          </Button>
        </>
      }
      title={
        <Title component="h3" sx={{ fontWeight: 600 }}>
          Schedule your assessment
        </Title>
      }
    >
      <Text sx={{ lineHeight: "24px", textAlign: "center" }}>
        To book your next shift, you&apos;ll need to complete a quick video assessment about your
        experience as a {qualificationName}. Schedule a time to complete it now.
      </Text>
    </CommonDialog>
  );
}
