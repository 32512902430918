import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

declare module "@mui/material/Slider" {
  interface SliderOwnProps {
    variant?: "circular" | "rectangular";
    /**
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    thumb?: "true" | "false";
  }
}

export const muiSliderOverrides: Components<Theme> = {
  MuiSlider: {
    defaultProps: {
      variant: "circular",
    },
    styleOverrides: {
      root: {
        // TBD, but we don't have guidelines on larger slider in design spec
        height: "0.5rem",
        padding: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.5rem",
        },

        // MUI has a media query for touch devices, but it breaks the layout on mobile
        "@media (pointer: coarse)": {
          padding: "0.25rem",
        },

        variants: [
          {
            props: { variant: "rectangular" },
            style: {
              borderRadius: 0,
            },
          },
        ],
      },
      rail: ({ theme }) => ({
        color: theme.palette.border?.primary,
      }),
      track: {
        border: "none",
      },

      mark: {
        display: "none",
      },

      markLabel: ({ theme }) => ({
        marginTop: "1rem",
        color: theme.palette.text?.tertiary,
        fontWeight: 500,

        "&.MuiSlider-markLabelActive": {
          color: theme.palette.text?.primary,
        },
        // Align first mark label with the slider.
        // By default, transform is translateX(-50%).
        "&:nth-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(0)",
        },
        // Align last mark label with the slider.
        "&:nth-last-child(1 of .MuiSlider-markLabel)": {
          transform: "translateX(-100%)",
        },
      }),

      thumb: {
        display: "none",

        variants: [
          {
            props: { thumb: "true" },
            style: {
              display: "flex",
              "&:hover, &.Mui-focusVisible": {
                boxShadow: "0px 0px 0px 1px",
              },
              "&.Mui-active": {
                boxShadow: "0px 0px 0px 2px",
              },
            },
          },
          {
            props: { variant: "rectangular", thumb: "true" },
            style: {
              width: "2px",
              height: "calc(100% + 4px)",
              borderRadius: 0,
            },
          },
        ],
      },
      sizeSmall: {
        height: "0.25rem",

        "& .MuiSlider-rail": {
          height: "0.25rem",
        },
      },
    },
  },
};
