import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";

import { DialogHeader } from "../../components/DialogHeader";
import { List, ListItemButton, ListItemIcon, ListItemText } from "../../components/List";
import { useAnchorNavigation } from "../../useAnchorNavigation";
import { WorkplaceNoteIdentifier } from "../constants";
import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { PolicyInformationSection } from "./PolicyInformationSection";
import { type PolicyInformationSectionData } from "./types";

interface WorkplaceCheckInInstructionsModalProps {
  modalState: UseModalState;
  workplace: Facility;
}

export function WorkplaceCheckInInstructionsModal(props: WorkplaceCheckInInstructionsModalProps) {
  const { modalState, workplace } = props;
  const { userId: workplaceId } = workplace;

  const navigateToAnchor = useAnchorNavigation();

  const { data: notes = {} } = useWorkplaceNotesParsed(String(workplaceId));

  const parkingNote = notes[WorkplaceNoteIdentifier.PARKING_INSTRUCTIONS];
  const entranceNote = notes[WorkplaceNoteIdentifier.ENTRANCE_INSTRUCTIONS];
  const earlyCheckInNote = notes[WorkplaceNoteIdentifier.EARLY_CHECK_IN_INSTRUCTIONS];
  const timecardNote = notes[WorkplaceNoteIdentifier.TIMECARD_INSTRUCTIONS];
  const firstShiftNote = notes[WorkplaceNoteIdentifier.FIRST_SHIFT_INSTRUCTIONS];
  const orientationNote = notes[WorkplaceNoteIdentifier.ORIENTATION_INSTRUCTIONS];
  const otherNote = notes[WorkplaceNoteIdentifier.OTHER_INSTRUCTIONS];

  const sections: PolicyInformationSectionData[] = [
    {
      id: "parking",
      title: "Parking",
      iconType: "car",
      note: parkingNote,
      isAvailable: isDefined(parkingNote),
    },
    {
      id: "entrance",
      title: "Entrance",
      iconType: "exit",
      note: entranceNote,
      isAvailable: isDefined(entranceNote),
    },
    {
      id: "early-check-in",
      title: "Early Check-In",
      iconType: "passkeys",
      note: earlyCheckInNote,
      isAvailable: isDefined(earlyCheckInNote),
    },
    {
      id: "timecard",
      title: "Time Card",
      iconType: "date-clock",
      note: timecardNote,
      isAvailable: isDefined(timecardNote),
    },
    {
      id: "first-shift",
      title: "First Shift",
      iconType: "priority",
      note: firstShiftNote,
      isAvailable: isDefined(firstShiftNote),
    },
    {
      id: "orientation",
      title: "Orientation",
      iconType: "compass",
      note: orientationNote,
      isAvailable: isDefined(orientationNote),
    },
    {
      id: "other",
      title: "Other Instructions",
      iconType: "info",
      note: otherNote,
      isAvailable: isDefined(otherNote),
    },
  ];

  const availableSections = sections.filter((section) => section.isAvailable);

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />

        <Title component="h3" variant="h3" sx={{ marginBottom: 9 }}>
          Check-In Instructions
        </Title>

        <List sx={{ marginBottom: 10 }}>
          {availableSections.map((section, index) => (
            <ListItemButton
              key={section.id}
              divider={index < availableSections.length - 1}
              onClick={() => {
                navigateToAnchor(section.id);
              }}
            >
              <ListItemIcon iconType={section.iconType} />
              <ListItemText primary={section.title} />
            </ListItemButton>
          ))}
        </List>

        <Stack spacing={10} sx={{ py: 10 }}>
          {availableSections.map((section) => (
            <PolicyInformationSection
              key={section.id}
              id={section.id}
              iconType={section.iconType}
              title={section.title}
              note={section.note?.note}
              updatedAt={section.note?.updatedAt}
            />
          ))}
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
