import { isDefined } from "@clipboard-health/util-ts";
import { Box, Chip, type ChipProps, Stack, type Theme, useTheme } from "@mui/material";
import { formatDistance, parseISO } from "date-fns";

import { type PlacementDetail } from "../api/useGetPlacement";
import { PlacementApplicationStatus } from "../types/fetchPlacements.schema";
import { PlacementApplicationStatusChip } from "./PlacementApplicationStatusChip";

interface PlacementCardItemChipProps {
  placement: PlacementDetail;
  hasApplied: boolean;
  appliedDate: string | undefined;
}

function getChipProps(
  placement: PlacementDetail,
  hasApplied: boolean,
  appliedDate: string | undefined,
  theme: Theme
): (Omit<ChipProps, "children"> & { children?: JSX.Element | undefined }) | undefined {
  if (hasApplied && isDefined(appliedDate)) {
    return {
      label: `Applied ${formatDistance(new Date(), parseISO(appliedDate))} ago`,
      children:
        isDefined(placement.applications) &&
        placement.applications[0]?.attributes.status !== PlacementApplicationStatus.APPLIED ? (
          <PlacementApplicationStatusChip status={placement.applications[0].attributes.status} />
        ) : undefined,
    };
  }

  if (!placement.isLicenseEligible) {
    return {
      label: "License needed",
      sx: { backgroundColor: theme.palette.info.light },
    };
  }

  return undefined;
}

export function PlacementCardItemChip({
  placement,
  hasApplied,
  appliedDate,
}: PlacementCardItemChipProps) {
  const theme = useTheme();

  const chipProps = getChipProps(placement, hasApplied, appliedDate, theme);

  if (!chipProps) {
    return null;
  }

  const { children, ...rest } = chipProps;

  return (
    <Box sx={{ paddingBottom: 4 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip variant="filled" size="small" {...rest} />
        {children}
      </Stack>
    </Box>
  );
}
