import { z } from "zod";

export const TriggerStrategyNames = {
  on_route_open: "on_route_open",
  on_component_render: "on_component_render",
} as const;

export const inAppRatingConfigurationSchema = z.object({
  isEnabled: z.boolean(),
  promptCooldownPeriodInDays: z.number(),
  minimumEligibleShiftDurationInHours: z.number(),
  paidShiftCutoffDate: z.string(),
  triggerStrategies: z.object({
    [TriggerStrategyNames.on_route_open]: z
      .object({
        routes: z.array(z.string()),
      })
      .optional(),
    [TriggerStrategyNames.on_component_render]: z
      .object({
        components: z.array(z.string()),
        triggerDelayInMs: z.number().optional(),
      })
      .optional(),
  }),
});
