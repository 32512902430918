import { Camera, CameraResultType, type ImageOptions } from "@capacitor/camera";
import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import {
  type AcceptableUploadImageContentTypes,
  base64ToBlob,
  getFileTypeFromBlob,
  isAcceptableImageUploadContentType,
} from "@src/appV2/lib/files";

import { CameraPermissionDeniedError } from "../errors/CameraPermissionDeniedError";
import { type AllowedCameraSourceType, type UploadFileData } from "../types";
import { checkAndRequestPhotoPermissions } from "../utils/checkAndRequestPhotoPermissions";

interface TakePhotoProps {
  source: AllowedCameraSourceType;
  onSuccess: (photo: UploadFileData) => void;
  onError: (error: Error | CameraPermissionDeniedError) => void;
  photoOptions?: Pick<ImageOptions, "height" | "width" | "quality">;
}

export function useTakePhoto(props: TakePhotoProps): () => void {
  const { source, onSuccess, onError, photoOptions } = props;

  const cameraImageQuality = useCbhFlag(CbhFeatureFlag.CAMERA_IMAGE_QUALITY, {
    defaultValue: 85,
  });

  const cameraImageSize = useCbhFlag(CbhFeatureFlag.CAMERA_IMAGE_SIZE, {
    defaultValue: { height: 200 },
  });

  return async () => {
    try {
      await checkAndRequestPhotoPermissions(source);

      const { dataUrl } = await Camera.getPhoto({
        source,
        resultType: CameraResultType.DataUrl,
        quality: cameraImageQuality,
        ...cameraImageSize,
        ...photoOptions,
      });

      if (!isDefined(dataUrl)) {
        throw new Error("Invalid Camera Result");
      }

      const dataUrlBase64 = dataUrl;
      const blob = await base64ToBlob(dataUrlBase64);
      const { extension, contentType } = await getFileTypeFromBlob(blob);

      if (!isAcceptableImageUploadContentType(contentType)) {
        throw new Error(`Image with unsupported content type picked: ${contentType}`);
      }

      onSuccess({
        dataUrlBase64,
        blob,
        extension,
        contentType: contentType as AcceptableUploadImageContentTypes,
      });
    } catch (error) {
      const errorLogEvent =
        error instanceof CameraPermissionDeniedError
          ? APP_V2_APP_EVENTS.DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_PERMISSION_DENIED
          : APP_V2_APP_EVENTS.DOCUMENT_UPLOAD_CAMERA_TAKE_PHOTO_FAILURE;
      logError(errorLogEvent, { error });
      onError(error as Error | CameraPermissionDeniedError);
    }
  };
}
