import { Button } from "@src/appV2/redesign/components/Button";
import pluralize from "pluralize";

import { InstantReviewPill } from "../../Bookability/Documents/InstantReview/InstantReviewPill";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface ShiftBottomSheetMissingDocumentsCardProps {
  missingDocumentsCount: number;
  isInstantReview: boolean;
  onClick: () => void;
}

export function ShiftBottomSheetMissingDocumentsCard(
  props: ShiftBottomSheetMissingDocumentsCardProps
) {
  const { missingDocumentsCount, isInstantReview, onClick } = props;
  const documentText = pluralize("document", missingDocumentsCount);

  function getDescription() {
    if (isInstantReview) {
      return `Complete ${missingDocumentsCount} instant review ${documentText} before confirming your shift.`;
    }

    return `Please upload ${
      missingDocumentsCount === 1 ? "this requirement" : "these requirements"
    } before booking this shift.`;
  }

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title={`This facility requires ${missingDocumentsCount} ${documentText}`}
      description={getDescription()}
      illustrationType="document-upload"
      pill={isInstantReview ? <InstantReviewPill /> : undefined}
      footer={
        <Button fullWidth variant="contained" size="medium" onClick={onClick}>
          {isInstantReview ? "Complete" : "Upload documents"}
        </Button>
      }
    />
  );
}
