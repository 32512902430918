import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";

import { Illustration } from "../../components/Illustration";
import { formatFacilityHourRestrictionText } from "../../utils/formatFacilityHourRestrictionText";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetHourRestrictionsCardProps {
  maxAllowedWorkConsecutiveHours?: Facility["maxAllowedWorkConsecutiveHours"];
  maxAllowedWorkHoursPerWeek?: Facility["maxAllowedWorkHoursPerWeek"];
  preventDoubleShifts?: Facility["preventDoubleShifts"];
}

export function ShiftBottomSheetHourRestrictionsCard(
  props: ShiftBottomSheetHourRestrictionsCardProps
) {
  const { maxAllowedWorkConsecutiveHours, maxAllowedWorkHoursPerWeek, preventDoubleShifts } = props;

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 10,
        }}
      >
        <Stack alignItems="center" spacing={5} sx={{ textAlign: "center", textWrap: "balance" }}>
          <Illustration type="hours-limit" />
          <Text semibold variant="body1">
            This workplace has a booking limit
          </Text>
          <Text color={(theme) => theme.palette.text.secondary} variant="body2">
            {formatFacilityHourRestrictionText({
              maxAllowedWorkConsecutiveHours: maxAllowedWorkConsecutiveHours ?? undefined,
              maxAllowedWorkHoursPerWeek: maxAllowedWorkHoursPerWeek ?? undefined,
              preventDoubleShifts,
            })}
          </Text>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
