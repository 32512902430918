import { Span, Text } from "@clipboard-health/ui-react";
import { CardContent, Link } from "@mui/material";
import { openBrowserDeprecated } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { REFERRAL_POLICY_LINK } from "../constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../types";
import { getWorkerToWorkplaceVariantName } from "../utils/getWorkerToWorkplaceVariantName";
import { WorkplaceReferralCard } from "./WorkplaceReferralCard/WorkplaceReferralCard";

interface ReferralPolicyProps {
  referralCode: string;
}

export function ReferralPolicy(props: ReferralPolicyProps) {
  const { referralCode } = props;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  return (
    <WorkplaceReferralCard>
      <CardContent>
        <Text>
          <Span sx={{ verticalAlign: "middle" }}>
            {isWorkerToWorkplaceAffiliateProgramEnabled
              ? "Read our Affiliate Program Policy"
              : "Read our Workplace Referrals Policy"}
          </Span>{" "}
          <Link
            component="button"
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                action: WorkplaceReferralAction.REFERRAL_POLICY_CLICKED,
                component: WorkplaceReferralComponent.REFERRAL_POLICY_SECTION,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
                referralCode,
              });
              openBrowserDeprecated(REFERRAL_POLICY_LINK);
            }}
          >
            here.
          </Link>
        </Text>
      </CardContent>
    </WorkplaceReferralCard>
  );
}
