import { Title } from "@clipboard-health/ui-react";
import { Divider, Stack } from "@mui/material";
import { Controller } from "react-hook-form";

import { CalendarHeaderRow } from "../../Calendar/HeaderRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { useFiltersForm } from "../../ShiftDiscovery/Filters/BottomSheet/useFiltersForm";
import { ShiftTimeSlotButtonGroup } from "../../ShiftDiscovery/Filters/ShiftTimeSlotButtonGroup";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";

export function ShiftDatePickerHeader() {
  const { form } = useFiltersForm();
  const { setFilters } = useShiftDiscoveryUserFiltersContext();

  const values = form.watch();

  return (
    <PageHeaderWrapper
      component="div"
      variant="tertiary"
      sx={(theme) => ({
        boxShadow: theme.shadows[3],
      })}
    >
      <Stack spacing={6} sx={{ padding: 5 }}>
        <Title variant="h4" component="h2">
          Pick Dates
        </Title>

        <Controller
          control={form.control}
          name="shiftTimeSlots"
          render={({ field }) => (
            <ShiftTimeSlotButtonGroup
              value={field.value}
              onChange={(shiftTimeSlots) => {
                field.onChange(shiftTimeSlots);

                // intentionally not relying on `submitFilters` as it's needs additional render
                // to update values being submitted and we want immediate submission
                setFilters({
                  ...values,
                  shiftTimeSlots,
                });
              }}
            />
          )}
        />
      </Stack>

      <Divider />

      <CalendarHeaderRow sx={{ px: 5 }} />
    </PageHeaderWrapper>
  );
}
