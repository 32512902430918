import { Browser } from "@capacitor/browser";
import { InAppBrowser } from "@ionic-native/in-app-browser";

type BrowserTarget = "_system" | "_blank" | "_self";

/**
 * @deprecated Using capacitor's native browser provides a better user experience. This version has proven hard to close.
 */
export function openBrowserDeprecated(url: string, target: BrowserTarget = "_system") {
  InAppBrowser.create(url, target, {
    location: "no",
    hidenavigationbuttons: "yes",
    toolbarposition: "bottom",
    closebuttoncaption: "Done",
    closebuttoncolor: "#ffffff",
    toolbarcolor: "#001529",
    toolbartranslucent: "no",
    footer: "yes",
    footercolor: "#001529",
    zoom: "no",
    useWideViewPort: "no",
  });
}

export async function openBrowser(url: string) {
  await Browser.open({
    url,
    toolbarColor: "#001529",
    presentationStyle: "fullscreen",
  });
}
