export enum HelloSignWorkflowSessionStatus {
  CREATED = "CREATED",
  SIGNED = "SIGNED",
  ERRORED = "ERRORED",
  COMPLETED = "COMPLETED",
}

export enum HelloSignErrorCodes {
  NOTHING_TO_SIGN = "nothing_to_sign",
  NETWORK_ERROR = "network_error",
}
