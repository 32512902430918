import { Text, Title, useModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { useDocumentDistributionUrl } from "@src/appV2/Accounts/DocumentDetails/api/useDocumentDistributionUrl";
import { useGetFilteredDocuments } from "@src/appV2/Accounts/Documents/api";
import { RESUME_REQUIREMENT_ID } from "@src/appV2/Accounts/Documents/constants";
import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { FullScreenDialog, useToast } from "@src/appV2/lib";
import { useLocalStorage } from "@src/appV2/lib/utils";
import { type Worker } from "@src/appV2/Worker/api/types";
import { useEffect } from "react";

import { LoadingButton } from "../../components/LoadingButton";
import { useUploadResume } from "../api/useUploadResume";
import { type PlacementCandidate } from "../types";
import { PortResumeDialogPreview } from "./PortResumeDialogPreview";

export function PortResumeDialog({
  worker,
  onResumeUploaded,
  placementCandidate,
}: {
  worker: Worker;
  placementCandidate: PlacementCandidate;
  onResumeUploaded: () => Promise<void>;
}) {
  const { data: resumeDocuments, isLoading: isLoadingResumeDocuments } = useGetFilteredDocuments(
    {
      hcpId: worker.userId,
      filter: {
        fulfilledRequirementId: RESUME_REQUIREMENT_ID,
        status: DocumentStatus.APPROVED,
        workerUploaded: true,
      },
      limit: 1,
      sort: {
        createdAt: -1,
      },
    },
    {
      enabled: !!placementCandidate.resumeUrl, // do not query if resumeUrl is already set
    }
  );
  const modalState = useModalState();
  const [hasClosedDialog, setHasClosedDialog] = useLocalStorage("hasClosedPortResumeDialog", false);

  function closeDialog() {
    setHasClosedDialog(true);
    modalState.closeModal();
  }

  const { mutateAsync: uploadResume, isLoading: isUploadingResume } = useUploadResume({
    onSuccess: async () => {
      closeDialog();
      await onResumeUploaded();
    },
  });
  const { mutateAsync: createDistributionUrl, isLoading: isLoadingDistributionUrl } =
    useDocumentDistributionUrl();
  const { showErrorToast } = useToast();

  useEffect(() => {
    if (resumeDocuments?.data.documentList.length === 1 && !hasClosedDialog) {
      modalState.openModal();
    }
  }, [resumeDocuments, modalState, hasClosedDialog]);

  if (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    placementCandidate.resumeUrl ||
    isLoadingResumeDocuments ||
    !resumeDocuments?.data.documentList.length
  ) {
    return null;
  }

  async function onImportResume({ resumeDocumentId }: { resumeDocumentId: string }) {
    try {
      // create the distribution url
      const { distributionUrl } = await createDistributionUrl(resumeDocumentId);

      // download the resume
      const getResumeResponse = await fetch(distributionUrl);
      const resumeBlob = await getResumeResponse.blob();
      const resumeFile = new File([resumeBlob], "resume.pdf", { type: "application/pdf" });

      // upload the resume
      await uploadResume({
        file: resumeFile,
        placementCandidateId: placementCandidate.id,
      });
    } catch {
      showErrorToast("Failed to import resume. Try again later.");
    }
  }

  return (
    <FullScreenDialog modalState={modalState}>
      <DialogContent>
        <Stack spacing={3}>
          <Title variant="h3" component="h3">
            Import resume from profile
          </Title>
          <Text variant="body2">
            We noticed you already had a resume on file. Would you like to use this resume or upload
            a new one?
          </Text>
          <PortResumeDialogPreview resumeDocumentId={resumeDocuments.data.documentList[0]._id} />
          <Stack direction="row" spacing={2}>
            <LoadingButton
              isLoading={isLoadingDistributionUrl || isUploadingResume}
              onClick={async () => {
                await onImportResume({
                  resumeDocumentId: resumeDocuments.data.documentList[0]._id,
                });
              }}
            >
              Use this resume
            </LoadingButton>
            <Button
              disabled={isLoadingDistributionUrl || isUploadingResume}
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Skip
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
