import { LoadingButton, ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

interface PolicyDialogProps {
  isLoading: boolean;
  onContinue: () => void;
  onCancel: () => void;
  facilityName: string;
  timeclockLocation: string;
}

export function PolicyDialog(props: PolicyDialogProps) {
  const { isLoading, onContinue, onCancel, facilityName, timeclockLocation } = props;

  const modalState = useModalState(ModalStatus.OPEN);

  return (
    <CommonDialog
      modalState={modalState}
      title="Use the facility time clock"
      actions={
        <>
          <LoadingButton fullWidth variant="contained" isLoading={isLoading} onClick={onContinue}>
            Continue
          </LoadingButton>
          <Button fullWidth onClick={onCancel}>
            Go Back
          </Button>
        </>
      }
      onClose={onCancel}
    >
      <Text mb={2}>
        {facilityName} requires you to clock in/out using their biometric time clock. You must take
        a photo of yourself scanning your finger print on the time clock in order to continue with
        in-app clock in/out.
      </Text>
      <Text>
        The facility time clock is located <b>{timeclockLocation}</b>. If you need help using the
        time clock, please ask a facility admin.
      </Text>
    </CommonDialog>
  );
}
