import { isDefined } from "@clipboard-health/util-ts";
import constate from "constate";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { type ShiftDiscoverySearchMode } from "../../OpenShifts/ShiftAction/api/useClaimShift";

interface UseShiftModalsDataProps {
  /**
   * Base path used for navigation when opening/closing facility modals.
   * This path is used to:
   * 1. Determine where to navigate when closing modals
   * 2. Support rendering the same modals from different root views (map/list)
   */
  baseNavigationPath: string;

  /**
   * Search mode used by underlying components to identify which mode was used to open shift modals.
   */
  searchMode: ShiftDiscoverySearchMode;
}

/**
 * This context manages the state for shift modals with these key features:
 * 1. Path generation - Handles both direct and nested modal paths
 * 2. Base path override - Supports rendering on top of workplace modals
 *
 * Modal Hierarchy:
 * - Shift modals can appear independently or on top of workplace modals
 * - When appearing on top of workplace modals, baseNavigationPathOverride is used
 * - This allows the workplace modal to stay mounted while shift modals animate in/out
 *
 * Usage:
 * - Direct modal: uses baseNavigationPath
 * - Nested modal: uses baseNavigationPath + baseNavigationPathOverride
 */
function useShiftModalsData(props: UseShiftModalsDataProps) {
  const { baseNavigationPath, searchMode } = props;

  /**
   * When shift modals need to open on top of an existing modal (e.g. workplace open shifts modal),
   * we override the base navigation path. This ensures the new modal is rendered in addition to
   * the currently visible base modal, rather than replacing it.
   */
  const [baseNavigationPathOverride, setBaseNavigationPathOverride] = useState<string>();

  const history = useHistory();

  const navigateToModal = <S extends string>(
    path: S,
    params?: Parameters<typeof generatePath<S>>[1],
    forceReplacePath?: boolean
  ) => {
    const modalPath = generatePath(
      `${baseNavigationPath}${
        baseNavigationPathOverride ? `/${baseNavigationPathOverride}` : ""
      }/${path}`,
      params as unknown as Parameters<typeof generatePath<`${string}/${S}`>>[1]
    );

    if (isDefined(baseNavigationPathOverride) || forceReplacePath) {
      history.replace(modalPath);
    } else {
      history.push(modalPath);
    }
  };

  return {
    baseNavigationPath,
    searchMode,
    navigateToModal,
    baseNavigationPathOverride,
    setBaseNavigationPathOverride,
  };
}

export const [ShiftModalsDataProvider, useShiftModalsDataContext] = constate(useShiftModalsData);
