import { ToggleButton } from "@mui/material";

import { FiltersToggleButtonGroup } from "./BottomSheet/ToggleButtonGroup";
import { type FiltersFormValues } from "./BottomSheet/types";
import { FILTER_ALL_VALUE, useFilterSelectedOptions } from "./BottomSheet/useFilterSelectedOptions";

interface LicensesFilterButtonGroupProps {
  value?: FiltersFormValues["licenses"];
  onChange: (value: FiltersFormValues["licenses"]) => void;
  availableLicenses: string[];
}

export function LicensesFilterButtonGroup(props: LicensesFilterButtonGroupProps) {
  const { value, onChange, availableLicenses } = props;

  const { selectedOptions, onChangeSelection } = useFilterSelectedOptions(value ?? [], onChange);

  // Safety check to avoid showing an empty filter, likely never to happen.
  if (availableLicenses.length === 0) {
    return null;
  }

  return (
    <FiltersToggleButtonGroup
      value={selectedOptions}
      aria-label="Shift Licenses Filter Toggle Buttons"
      onChange={onChangeSelection}
    >
      <ToggleButton value={FILTER_ALL_VALUE}>All</ToggleButton>
      {availableLicenses.map((license) => (
        <ToggleButton key={license} value={license}>
          {license}
        </ToggleButton>
      ))}
    </FiltersToggleButtonGroup>
  );
}
