import { Box } from "@mui/material";
import { OverlayView, type OverlayViewProps } from "@react-google-maps/api";

interface GoogleMapCustomClusterWrapperProps extends Pick<OverlayViewProps, "position"> {
  children: React.ReactNode;
}

export function GoogleMapCustomClusterWrapper(props: GoogleMapCustomClusterWrapperProps) {
  const { children, ...restProps } = props;

  return (
    <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} {...restProps}>
      <Box
        // this transform is needed to properly position the cluster on the map
        sx={{ transform: "translate(-50%, -50%)", width: "fit-content", height: "fit-content" }}
      >
        {children}
      </Box>
    </OverlayView>
  );
}
