import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

interface UseScrollRestorationOptions {
  /**
   * Optional key to uniquely identify the scroll position in sessionStorage.
   * If not provided, location.pathname will be used.
   */
  key?: string;
  containerId: string;
  /**
   * Whether to save the scroll position when the component unmounts.
   * Default is true.
   */
  saveOnUnmount?: boolean;
}

/**
 * Saves the scroll position of the specified container.
 */
export function saveScrollPosition(key: string, containerId: string): void {
  const scrollPosition = document.querySelector(`#${containerId}`)?.scrollTop ?? 0;
  sessionStorage.setItem(key, scrollPosition.toString());
}

/**
 * Restores the scroll position of the specified container.
 */
export function restoreScrollPosition(key: string, containerId: string): void {
  const savedPosition = sessionStorage.getItem(key);
  if (savedPosition) {
    const scrollToContainer = document.querySelector(`#${containerId}`);
    if (scrollToContainer && typeof scrollToContainer.scrollTo === "function") {
      scrollToContainer.scrollTo(0, Number.parseInt(savedPosition, 10));
    }

    sessionStorage.removeItem(key);
  }
}

/**
 * A hook to preserve and restore the scroll position of a page.
 */
export function useScrollRestoration(options: UseScrollRestorationOptions): void {
  const { key, containerId, saveOnUnmount = true } = options;
  const location = useLocation();
  const storageKey = key ?? location.pathname;

  useLayoutEffect(() => {
    restoreScrollPosition(storageKey, containerId);

    return () => {
      if (saveOnUnmount) {
        saveScrollPosition(storageKey, containerId);
      }
    };
  }, [storageKey, containerId, saveOnUnmount]);
}
