import { IconWithCount } from "@src/appV2/redesign/components/IconWithCount";

interface WorkplaceReviewCommentRepliesCountIconProps {
  count: number;
}

export function WorkplaceReviewCommentRepliesCountIcon(
  props: WorkplaceReviewCommentRepliesCountIconProps
) {
  const { count } = props;

  return (
    <IconWithCount
      iconType="text-bubble"
      count={count}
      color={(theme) => theme.palette.text.tertiary}
    />
  );
}
