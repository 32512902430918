import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { isPlatform } from "@ionic/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from "react";

import { PageWrapper } from "../components/PageWrapper";
import { Navbar } from "../Navbar";
import { getRedesignTheme } from "../theming";
import { ShiftDiscoveryUserFiltersProvider } from "./Filters/useUserFiltersContext";
import { ShiftDiscoveryRouter } from "./Router";

export function ShiftDiscoveryContainer() {
  // TODO: Remove this once we migrate to new shift discovery theme
  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return undefined;
    }

    void StatusBar.setStyle({
      style: StatusBarStyle.Light,
    });

    return () => {
      void StatusBar.setStyle({
        style: StatusBarStyle.Dark,
      });
    };
  }, []);

  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <CssBaseline />
      <ShiftDiscoveryUserFiltersProvider>
        <PageWrapper>
          <ShiftDiscoveryRouter />
          <Navbar />
        </PageWrapper>
      </ShiftDiscoveryUserFiltersProvider>
    </ThemeProvider>
  );
}
