import { IonPage } from "@ionic/react";
import { StepUpAuthProvider } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/context";
import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NotFoundPage } from "../lib";
import { SkillsAssessmentMyAccountPage } from "../SkillsAssessment/MyAccountPage";
import { SKILLS_ASSESSMENT_PATH } from "../SkillsAssessment/paths";
import { ClipboardResumePage } from "./ClipboardResume/Page";
import { CLIPBOARD_RESUME_PATH } from "./ClipboardResume/paths";
import { DeleteAccountPage } from "./DeleteAccount";
import { DELETE_ACCOUNT_PATH } from "./DeleteAccount/paths";
import { DocumentDetailsPageContainer } from "./DocumentDetails/PageContainer";
import { DocumentsPage } from "./Documents/Page";
import {
  ACCOUNTS_DOCUMENT_DETAILS_PATH,
  ACCOUNTS_DOCUMENTS_PATH,
  ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH,
} from "./Documents/paths";
import { PAYROLL_PATH } from "./Payroll/paths";
import { PayrollRoutes } from "./Payroll/Routes";

export function AccountRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/${ACCOUNTS_DOCUMENTS_PATH}`}>
        <IonPage>
          <DocumentsPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH}`}>
        <IonPage>
          <DocumentsPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${ACCOUNTS_DOCUMENT_DETAILS_PATH}`}>
        <IonPage>
          <DocumentDetailsPageContainer />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${SKILLS_ASSESSMENT_PATH}`}>
        <SkillsAssessmentMyAccountPage />
      </Route>
      <Route exact path={`${match.url}/${DELETE_ACCOUNT_PATH}`}>
        <IonPage>
          <StepUpAuthProvider>
            <DeleteAccountPage />
          </StepUpAuthProvider>
        </IonPage>
      </Route>
      <Route path={`${match.url}/${PAYROLL_PATH}`}>
        <PayrollRoutes />
      </Route>
      <Route exact path={`${match.url}/${CLIPBOARD_RESUME_PATH}`}>
        <IonPage>
          <ClipboardResumePage />
        </IonPage>
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
