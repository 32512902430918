import { Box, ImageList, ImageListItem } from "@mui/material";

export interface MosaicGalleryItem {
  src: string;
  alt: string;
}

interface MosaicGalleryProps {
  images: MosaicGalleryItem[];
}

export function MosaicGallery(props: MosaicGalleryProps) {
  const { images } = props;

  if (images.length === 0) {
    return null;
  }

  const getImageRows = () => {
    if (images.length === 1) {
      return [{ cols: 2, rows: 2 }];
    }

    if (images.length === 2) {
      return [
        { cols: 2, rows: 1 },
        { cols: 2, rows: 1 },
      ];
    }

    return [
      { cols: 2, rows: 1 },
      { cols: 1, rows: 1 },
      { cols: 1, rows: 1 },
    ];
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.tertiary,
        overflow: "hidden",
        display: "flex",
      })}
    >
      <ImageList
        sx={{
          width: "100%",
          height: "100%",
          margin: 0,
          display: "grid",
          gridTemplateRows: "1fr 1fr",

          "& .MuiImageListItem-root": {
            position: "relative",
            overflow: "hidden",
          },
        }}
        variant="quilted"
        cols={2}
        gap={4}
      >
        {images.slice(0, 3).map((image, index) => (
          <ImageListItem
            key={image.src}
            cols={getImageRows()[index].cols}
            rows={getImageRows()[index].rows}
          >
            <img src={image.src} alt={image.alt} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
