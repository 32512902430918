import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";

export function useClipboardScoreStatus() {
  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  return {
    isWorkerClipboardScoreTargeted: isClipboardScoreEnabled,
  };
}
