import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

export const muiChipOverrides: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
          color: theme.palette.text?.primary,
          margin: 0,
          padding: 0,
        },

        "& > *:last-child:not(:only-child):not(:empty)": {
          marginLeft: theme.spacing(2),
        },

        "&.Mui-disabled": {
          opacity: 1,

          "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
            color: theme.palette.disabled?.primary,
          },
        },
      }),
      sizeSmall: ({ theme }: { theme: Theme }) => ({
        height: theme.size?.pill?.small,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }),

      sizeMedium: ({ theme }: { theme: Theme }) => ({
        height: theme.size?.pill?.medium,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      }),
    },

    variants: [
      {
        props: { variant: "filled" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.background?.primary,

          "&.Mui-disabled": {
            backgroundColor: theme.palette.disabled?.secondary,
          },
        }),
      },
      {
        props: { variant: "outlined" },
        style: ({ theme }) => ({
          border: `${theme.borderWidth?.thin} solid ${String(theme.palette.border?.primary)}`,

          "&.Mui-disabled": {
            borderColor: theme.palette.disabled?.secondary,
          },
        }),
      },
    ],
  },
};
