import { API_TYPES } from "@src/appV2/api";
import { z } from "zod";

import { placementsFilterSchema } from "./fetchPlacements.schema";

export const getPlacementWorkplacesForWorkerQuerySchema = z.object({
  filter: placementsFilterSchema
    .extend({
      coordinates: z
        .object({
          longitude: z.number(),
          latitude: z.number(),
        })
        .optional(),
    })
    .optional(),
});
export type GetPlacementWorkplacesForWorkerQuery = z.infer<
  typeof getPlacementWorkplacesForWorkerQuerySchema
>;

export const getWorkplacePlacementsResponseSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      type: z.literal(API_TYPES.workplace),
      attributes: z.object({
        location: z.object({
          longitude: z.number(),
          latitude: z.number(),
        }),
        distance: z.number(),
        rating: z.object({ value: z.number(), count: z.number() }),
        placementsCount: z.number(),
      }),
    })
  ),
});
export type GetPlacementWorkplacesResponse = z.infer<typeof getWorkplacePlacementsResponseSchema>;
