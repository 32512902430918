import { Text } from "@clipboard-health/ui-react";
import { Alert } from "@mui/material";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import pluralize from "pluralize";

export interface RestrictionWarningProps {
  restrictionCount: number;
  restrictionDuration: number;
  restrictionWindow: number;
  finalRestrictionDuration: number;
  restrictionLimit: number;
}
export function RestrictionDurationWarning(props: RestrictionWarningProps) {
  const {
    restrictionCount,
    restrictionDuration,
    restrictionWindow,
    finalRestrictionDuration,
    restrictionLimit,
  } = props;
  const { isWorkerAteamEnabled } = useATeamStatus();
  return (
    <Alert severity="warning">
      <Text bold variant="subtitle2" color={(theme) => theme.palette.text.secondary}>
        Your account has {restrictionCount} {pluralize("restriction", restrictionCount)} in the last{" "}
        {restrictionWindow} {pluralize("month", restrictionWindow)}.
      </Text>
      <Text variant="subtitle2" color={(theme) => theme.palette.text.secondary}>
        The first {restrictionLimit - 1} {pluralize("restriction", restrictionLimit - 1)} will last{" "}
        {restrictionDuration} {pluralize("day", restrictionDuration)} each, and the final
        restriction will last {finalRestrictionDuration}{" "}
        {pluralize("day", finalRestrictionDuration)}.{" "}
        {isWorkerAteamEnabled
          ? "The final restriction will also remove your account from Priority Plus lists."
          : ""}
      </Text>
    </Alert>
  );
}
