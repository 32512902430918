import { isDefined } from "@clipboard-health/util-ts";
import { logEvent } from "@src/appV2/lib/analytics";
import { type CbhAppInfo, isCapacitorPlatform } from "@src/appV2/lib/index";
import { SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS } from "@src/appV2/Shifts/Shift/constants";
import {
  SolveUnpaidBreaksRolloutStage,
  type SolveUnpaidBreaksRolloutStageWithTargeting,
} from "@src/appV2/Shifts/Shift/ShiftState/types";
import { USER_EVENTS } from "@src/constants";
import semver from "semver";

interface IsEnabledForMobileVersionAndOta {
  minimalStoreVersion: string;
  minimalOtaVersion: string;
  appInfo: CbhAppInfo;
}

export function isEnabledForAppVersionAndOta(parameters: IsEnabledForMobileVersionAndOta): boolean {
  const { minimalStoreVersion, minimalOtaVersion, appInfo } = parameters;

  if (
    minimalStoreVersion !== SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS &&
    semver.gt(minimalStoreVersion, appInfo.version)
  ) {
    return false;
  }

  if (
    minimalOtaVersion !== SOLVE_UNPAID_BREAKS_FF_MATCH_ALL_VERSIONS &&
    semver.eq(minimalStoreVersion, appInfo.version)
  ) {
    const minimalOtaVersionAsNumber = Number(minimalOtaVersion);
    const appOtaAsNumber = Number(appInfo.otaBuildId);

    if (!Number.isFinite(minimalOtaVersionAsNumber) || !Number.isFinite(appOtaAsNumber)) {
      return false;
    }

    if (minimalOtaVersionAsNumber > appOtaAsNumber) {
      return false;
    }
  }

  return true;
}

interface IsSolveUnpaidBreaksFeatureEnabled {
  solveUnpaidBreaksRolloutStageWithTargetingConfig?: SolveUnpaidBreaksRolloutStageWithTargeting;
  appInfo?: CbhAppInfo;
}

export function isSolveUnpaidBreaksFeatureEnabled(
  parameters: IsSolveUnpaidBreaksFeatureEnabled
): boolean {
  const { solveUnpaidBreaksRolloutStageWithTargetingConfig, appInfo } = parameters;

  try {
    if (!isDefined(appInfo) || !isDefined(solveUnpaidBreaksRolloutStageWithTargetingConfig)) {
      return false;
    }

    const { stage, minimalStoreVersion, minimalOtaVersion } =
      solveUnpaidBreaksRolloutStageWithTargetingConfig;

    if (
      isCapacitorPlatform() &&
      !isEnabledForAppVersionAndOta({ minimalOtaVersion, minimalStoreVersion, appInfo })
    ) {
      return false;
    }

    return (
      stage === SolveUnpaidBreaksRolloutStage.CBH_PAYS_FOR_WORKED_BREAKS ||
      stage === SolveUnpaidBreaksRolloutStage.FACILITIES_PAY_FOR_WORKED_BREAKS
    );
  } catch {
    logEvent(USER_EVENTS.SOLVE_UNPAID_BREAKS_VERSION_ERROR, {
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
    });
    return false;
  }
}
