export const DEFAULT_MAP_ZOOM = 10;
export const MAX_MAP_ZOOM = 16;

export const googleMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  zoom: DEFAULT_MAP_ZOOM,
  minZoom: 4,
  maxZoom: MAX_MAP_ZOOM,
  styles: [
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};
