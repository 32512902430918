import { Stack, type StackProps } from "@mui/material";

/**
 * A wrapper for the `TopItemList` component from react-virtuoso grouped list.
 * This prevents header from being rendered as sticky header.
 */
export function ShiftListTopItemList(props: StackProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { style, ...restProps } = props;

  return <Stack {...restProps} />;
}
