import { Text } from "@clipboard-health/ui-react";

import { DotSeparatedList } from "../../components/DotSeparatedList";
import { ListItem, ListItemIcon, ListItemText } from "../../components/List";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";
import { InstantPayPill } from "../InstantPayPill";
import { type ShiftWithType } from "../types";
import { useIsShiftInstantPay } from "../useIsShiftInstantPay";

interface ShiftBottomSheetPayInfoListItemProps {
  offer: ShiftWithType["offer"];
  finalPay: ShiftWithType["finalPay"];
  originalAmount: ShiftWithType["originalAmount"];
  isShiftInstantPayEligible: ShiftWithType["isInstantPay"];
  isHCFInstantPayProhibited: ShiftWithType["isHCFInstantPayProhibited"];
}

export function ShiftBottomSheetPayInfoListItem(props: ShiftBottomSheetPayInfoListItemProps) {
  const { offer, finalPay, originalAmount, isShiftInstantPayEligible, isHCFInstantPayProhibited } =
    props;
  const payBreakdown = getFormattedShiftPayBreakdown(offer?.pay.value, finalPay, originalAmount);

  const isInstantPay = useIsShiftInstantPay({
    isInstantPay: isShiftInstantPayEligible,
    isHCFInstantPayProhibited,
  });

  return (
    <ListItem>
      <ListItemIcon iconType="bolt-filled" />

      <ListItemText
        disableTypography
        primary={
          <Text semibold variant="h5">
            {payBreakdown?.hourlyPay} /hr
          </Text>
        }
        secondary={
          <DotSeparatedList inline={false}>
            <Text variant="body2">{payBreakdown?.totalPay}</Text>
            {isInstantPay && <InstantPayPill />}
          </DotSeparatedList>
        }
      />
    </ListItem>
  );
}
