import { ShiftDiscoverySearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { matchPath, useLocation } from "react-router-dom";

import { RootPaths } from "../../App/paths";
import { SHIFT_DISCOVERY_LIST_PATH } from "./paths";

export function useShiftDiscoverySearchMode() {
  const location = useLocation();

  const isListMode = matchPath(
    location.pathname,
    `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`
  );

  return isListMode ? ShiftDiscoverySearchMode.LIST : ShiftDiscoverySearchMode.MAP;
}

export function useIsShiftDiscoverySearchMode(searchMode: ShiftDiscoverySearchMode) {
  const currentSearchMode = useShiftDiscoverySearchMode();
  return searchMode === currentSearchMode;
}
