import { LoadingButton, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { getConflictingNegotiatingShifts } from "@src/app/rateNegotiation/api";
import { isValidUUID } from "@src/app/utils/uuid";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";
import { logApiFailureEvent } from "@src/lib/analytics";
import { Facility, Shift } from "@src/lib/interface";
import { getHumanReadableTime, timeRangeFormatter } from "@src/lib/utils";
import moment from "moment-timezone";
import pluralize from "pluralize";
import { useEffect, useState } from "react";

import lightningImage from "./lightning-blue.svg";
import { USER_EVENTS } from "../../../constants";
import { TIME_FORMAT } from "../constants";

interface ShiftBookingWarningDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  shift: Shift;
  isInstantBookingShift: boolean | undefined;
  isUrgentShift: boolean | undefined;
  isLoading: boolean;
}

export function ShiftBookingWarningDialog(props: ShiftBookingWarningDialogProps) {
  const { isOpen, onClose, onConfirm, shift, isInstantBookingShift, isUrgentShift, isLoading } =
    props;
  const [conflictingNegotiatingShifts, setConflictingNegotiatingShifts] = useState(0);

  const { showErrorToast } = useToast();

  const maxHoursRestrictionsWarningLabel = useMaxHoursRestrictionsWarningLabel(shift.facility);

  useEffect(() => {
    if (isOpen) {
      if (isInstantBookingShift) {
        logEvent(USER_EVENTS.VIEWED_CONFIRM_INSTANTBOOK_POPUP, {
          instant_book: true,
        });
      } else {
        logEvent(USER_EVENTS.VIEWED_CONFIRM_BOOK_POPUP);
      }
    }
  }, [isOpen, isInstantBookingShift]);

  useEffect(() => {
    (async () => {
      const isSyntheticShift = isValidUUID(shift?.shiftId ?? "");
      if (isSyntheticShift) {
        return;
        // we don't support rate negotiations for synthetic shifts
      }

      if (shift?.shiftId && !shift?.agentId) {
        try {
          const count = await getConflictingNegotiatingShifts(shift.shiftId);
          setConflictingNegotiatingShifts(count);
        } catch (error) {
          logApiFailureEvent(error);
          showErrorToast("Error while getting negotiating shifts.");
        }
      }
    })();
  }, [shift?.agentId, shift.shiftId, showErrorToast]);

  const ldFlags = useCbhFlags();

  /**
   * @deprecated
   * These values don't appear to be used in LD.
   */
  const { commuteUrgentShiftBooking, lateUrgentShiftBooking } =
    ldFlags[CbhFeatureFlag.URGENT_SHIFTS_CONFIG] ?? {};

  const { tmz: facilityTimeZone } = shift.facility as Facility;

  const date = moment(shift.start).tz(String(facilityTimeZone)).format("dddd, MMMM DD");
  let timeText = `${timeRangeFormatter(
    TIME_FORMAT,
    {
      start: shift.start ?? "",
      end: shift.end ?? "",
    },
    facilityTimeZone
  )} (${getHumanReadableTime(shift.time ?? 0)})`;

  let subTitle = isInstantBookingShift
    ? `Once you book this shift, it's all yours!`
    : `After you request this shift, a Clipboard Health team member will notify the workplace to confirm this shift.`;

  let bodyText = isInstantBookingShift
    ? "By booking this shift, you're committing to working this shift."
    : "Once a shift is officially booked, you are committing to working that shift and any other pending requests will be cancelled.";

  let facilityInformation: string = shift.facility?.name ?? "";
  const startsIn15Mins = moment().isSameOrAfter(moment(shift.start).subtract(15, "minute"));

  if (isDefined(shift.urgency)) {
    if (startsIn15Mins) {
      subTitle =
        commuteUrgentShiftBooking?.subTitle ??
        "After you book this Urgent Shift, please leave immediately.";
      bodyText =
        commuteUrgentShiftBooking?.bodyText ??
        "Clipboard Health will inform the workplace about your estimated time of arrival. They understand you will arrive past the original start time of the shift.";
      timeText = `Now to ${moment(shift.end).tz(String(facilityTimeZone)).format("hh:mm a")}`;
    } else {
      subTitle = lateUrgentShiftBooking?.subTitle ?? subTitle;
      bodyText = lateUrgentShiftBooking?.bodyText ?? bodyText;
    }

    if (typeof shift.distance === "number") {
      facilityInformation = `${facilityInformation} (${shift.distance.toFixed(1)} mi.)`;
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {isInstantBookingShift ? (
          <>
            <img alt="lightning" src={lightningImage} /> Book This Shift
          </>
        ) : (
          "Request This Shift"
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
        }}
      >
        {subTitle ? <Text paragraph>{subTitle}</Text> : null}
        {bodyText ? <Text paragraph>{bodyText}</Text> : null}
        <Text bold variant="body2">
          {date}
        </Text>
        <Text bold variant="body2">
          {timeText}
        </Text>
        <Text bold variant="body2">
          {facilityInformation}
        </Text>
        {maxHoursRestrictionsWarningLabel ? (
          <>
            <br />
            <Alert severity="warning" icon={false} aria-label="Max worked hours warning">
              {maxHoursRestrictionsWarningLabel}
            </Alert>
          </>
        ) : undefined}
        {conflictingNegotiatingShifts ? (
          <>
            <br />
            <Text color="error" bold>
              This will end {conflictingNegotiatingShifts}{" "}
              {pluralize("negotiation", conflictingNegotiatingShifts)} for conflicting shifts.
            </Text>
          </>
        ) : null}
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={<Button onClick={() => onClose()}>Cancel</Button>}
        rightAction={
          <LoadingButton
            isLoading={isLoading}
            onClick={() => {
              onConfirm();
            }}
          >
            {isInstantBookingShift || isUrgentShift ? "Book" : "Request"}
          </LoadingButton>
        }
      ></LegacyTwoButtonDialogActions>
    </Dialog>
  );
}

const useMaxHoursRestrictionsWarningLabel = (facility?: Facility): string | undefined => {
  const allowFacilitiesToSetMaxConsecutiveHoursFlag = useCbhFlag(
    CbhFeatureFlag.ALLOW_FACILITIES_TO_SET_MAX_CONSECUTIVE_HOURS,
    { defaultValue: false }
  );

  if (!allowFacilitiesToSetMaxConsecutiveHoursFlag || !facility) {
    return undefined;
  }

  const { maxAllowedWorkConsecutiveHours, preventDoubleShifts, maxAllowedWorkHoursPerWeek } =
    facility;

  if (preventDoubleShifts && isDefined(maxAllowedWorkHoursPerWeek)) {
    return `This workplace does not allow professionals to book double shifts or over ${maxAllowedWorkHoursPerWeek} hours of work per week. Please note that some shifts at this workplace may no longer be available after booking this shift.`;
  }

  if (preventDoubleShifts) {
    return `This workplace does not allow professionals to book double shifts. Please note that some shifts at this workplace may no longer be available after booking this shift.`;
  }

  if (isDefined(maxAllowedWorkConsecutiveHours) && isDefined(maxAllowedWorkHoursPerWeek)) {
    return `This workplace does not allow professionals to book more than ${maxAllowedWorkConsecutiveHours} hours of consecutive work or ${maxAllowedWorkHoursPerWeek} hours of work per week. Please note that some shifts at this workplace may no longer be available after booking this shift.`;
  }
  if (isDefined(maxAllowedWorkConsecutiveHours)) {
    return `This workplace does not allow professionals to book more than ${maxAllowedWorkConsecutiveHours} hours of consecutive work. Please note that some shifts at this workplace may no longer be available after booking this shift.`;
  }
  if (isDefined(maxAllowedWorkHoursPerWeek)) {
    return `This workplace does not allow professionals to book more than ${maxAllowedWorkHoursPerWeek} hours of work per week. Please note that some shifts at this workplace may no longer be available after booking this shift.`;
  }

  return undefined;
};
