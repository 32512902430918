import { isDefined } from "@clipboard-health/util-ts";

import { PayInfoCard } from "../../components/PayInfoCard";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";

interface ShiftCardPayInfoCardProps {
  offerPay?: number;
  finalPay?: number;
  originalAmount?: number;
  isLoading?: boolean;
}

export function ShiftCardPayInfoCard(props: ShiftCardPayInfoCardProps) {
  const { offerPay, finalPay, originalAmount, isLoading } = props;

  if (isLoading) {
    return <PayInfoCard loading={isLoading} />;
  }

  const payBreakdown = getFormattedShiftPayBreakdown(offerPay, finalPay, originalAmount);

  if (!isDefined(payBreakdown)) {
    return null;
  }

  return <PayInfoCard totalPay={payBreakdown.totalPay} hourlyPay={payBreakdown.hourlyPay} />;
}
