import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type SxProps, type Theme, useTheme } from "@mui/material";
import { ShiftPeriod, ShiftPeriodContent } from "@src/appV2/Shifts/Shift/types";
import pluralize from "pluralize";

const ShiftPeriodColor: Record<
  ShiftPeriod,
  {
    color: string;
  }
> = {
  [ShiftPeriod.AM]: { color: "#57afef" },
  [ShiftPeriod.PM]: { color: "#0c71ba" },
  [ShiftPeriod.NOC]: { color: "#375f7d" },
};

function useChipSx(props: {
  openShiftsCount: number;
  agentShiftsCount: number;
  shiftPeriod: ShiftPeriod;
  hasPriorityAccess: boolean;
}): SxProps {
  const theme = useTheme();
  const { openShiftsCount, agentShiftsCount, shiftPeriod, hasPriorityAccess } = props;

  const hasOpenShiftsCount = openShiftsCount > 0;
  const hasAgentShiftsCount = agentShiftsCount > 0;

  if (hasAgentShiftsCount) {
    return {
      color: "#2ecc71",
    };
  }

  if (hasPriorityAccess) {
    return {
      color: "#6a67ce",
    };
  }

  if (hasOpenShiftsCount) {
    return {
      color: ShiftPeriodColor[shiftPeriod],
    };
  }

  return {
    color: theme.palette.grey[100],
  };
}

function getFontSize(theme: Theme) {
  return `calc(${String(theme.typography.body1.fontSize)} * ${0.625})`;
}

interface ShiftNameChipProps {
  openShiftsCount: number;
  agentShiftsCount: number;
  hasPriorityAccess: boolean;
  shiftPeriod: ShiftPeriod;
  isDisabled: boolean;
}

export function ShiftNameChip(props: ShiftNameChipProps) {
  const { openShiftsCount, agentShiftsCount, hasPriorityAccess, shiftPeriod, isDisabled } = props;

  const chipSx = useChipSx({
    openShiftsCount,
    agentShiftsCount,
    shiftPeriod,
    hasPriorityAccess,
  });

  return (
    <Box
      sx={mergeSxProps(chipSx, {
        paddingX: 0.5,
        paddingY: 0.25,
        fontWeight: "bold",
        /**
         * fontSize is unreadable here, however, we are emulating the old font.
         * TODO: Refactor to be readable - ideally, use standard theme based variant.
         */
        fontSize: getFontSize,
        /**
         * `antd` global css sets `line-height` on `html` and `body`.
         * We need this override to ensure the chip is our desired height.
         */
        lineHeight: getFontSize,
      })}
      aria-hidden={isDisabled}
      aria-label={
        openShiftsCount === 0 && agentShiftsCount === 0
          ? `No ${ShiftPeriodContent[shiftPeriod]} shifts available to book`
          : `${openShiftsCount} ${ShiftPeriodContent[shiftPeriod]} ${pluralize(
              "shift",
              openShiftsCount
            )} available to book${
              agentShiftsCount > 0
                ? ` and ${agentShiftsCount} ${ShiftPeriodContent[shiftPeriod]} ${pluralize(
                    "shift",
                    agentShiftsCount
                  )} booked`
                : ""
            }`
      }
    >
      {ShiftPeriodContent[shiftPeriod]}
    </Box>
  );
}
