import { Pill } from "@src/appV2/redesign/components/Pill";
import pluralize from "pluralize";

interface ShiftDocumentsPillProps {
  missingDocumentsCount: number;
  /**
   * If true, the pill will be compact and show a shorter label
   */
  compact?: boolean;
}

export function ShiftDocumentsPill(props: ShiftDocumentsPillProps) {
  const { missingDocumentsCount, compact = false } = props;

  return (
    <Pill
      label={
        compact
          ? missingDocumentsCount
          : `${missingDocumentsCount} ${pluralize("document", missingDocumentsCount)} needed`
      }
      color={(theme) => theme.palette.callout?.neutral.labelLight}
      labelColor={(theme) => theme.palette.callout?.neutral.text}
      iconType={compact ? "document" : undefined}
    />
  );
}
