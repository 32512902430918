import { remove } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

interface UseDeleteResumeParams {
  placementCandidateId: string;
}

const deleteResumeResponseSchema = z.unknown();

export function useDeleteResume(
  options?: UseMutationOptions<unknown, unknown, UseDeleteResumeParams>
) {
  return useMutation({
    mutationFn: async (params: UseDeleteResumeParams) => {
      const { placementCandidateId } = params;
      await remove({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates/${placementCandidateId}/resume`,
        responseSchema: deleteResumeResponseSchema,
      });
    },
    onSuccess: (_, params) => {
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_DELETED, {
        placementCandidateId: params.placementCandidateId,
      });
    },
    meta: {
      logErrorMessage: "HCP Mobile App (V2): Failed to delete placement candidate resume",
    },
    ...options,
  });
}
