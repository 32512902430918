import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { type Control, Controller, type FieldPath, type FieldValues } from "react-hook-form";

import { PreferenceToggleButton } from "./PreferenceToggleButton";

interface Option {
  label: string;
  value: string;
}

interface PreferenceFormFieldProps<TFormValues extends FieldValues> {
  control: Control<TFormValues>;
  name: FieldPath<TFormValues>;
  label: string;
  options: Option[];
  error?: string;
  disabled?: boolean;
}

export function PreferenceFormField<TFormValues extends FieldValues>(
  props: PreferenceFormFieldProps<TFormValues>
) {
  const { control, name, label, options, error, disabled } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Text variant="body1" color={grey[900]}>
              {label}
            </Text>
            <Text variant="body2" color={grey[600]}>
              Select all that apply
            </Text>
          </Stack>
          <Stack spacing={1}>
            <PreferenceToggleButton
              options={options}
              value={field.value}
              disabled={disabled}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
            {error && (
              <Text variant="body2" color="error" sx={{ px: 1 }}>
                {error}
              </Text>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
}
