import { isDefined } from "@clipboard-health/util-ts";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";

import { ShiftBottomSheetBlockingDocumentsExpiringDocumentPill } from "../../Bookability/Documents/ExpiringDocumentPill";
import { InstantReviewPill } from "../../Bookability/Documents/InstantReview/InstantReviewPill";

interface ShiftBottomSheetMissingDocumentCardPillProps {
  isInstantReviewDocument: boolean;
  isExpiring: boolean;
  document: HcpRequirement;
}

export function ShiftBottomSheetMissingDocumentCardPill(
  props: ShiftBottomSheetMissingDocumentCardPillProps
) {
  const { isInstantReviewDocument, isExpiring, document } = props;
  if (isInstantReviewDocument) {
    return <InstantReviewPill />;
  }

  if (isExpiring && isDefined(document.expiry)) {
    return (
      <ShiftBottomSheetBlockingDocumentsExpiringDocumentPill documentExpiry={document.expiry} />
    );
  }

  return null;
}
