import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const ATTENDANCE_SCORE_HISTORY_REQUEST_SIZE = 20;

export const attendanceScoreActionLabelMap = new Map<string, string>([
  ["WORK_SHIFT", "Worked Shift"],
  ["CANCEL_SHIFT", "Cancellation"],
  ["NO_CALL_NO_SHOW", "No Show"],
  ["CORRECT_SCORE", "Score Correction"],
  ["ACCOUNT_RE_ENROLL", "Account Re-enrolled"],
  ["START_SHIFT_LATE", "Shift Started Late"],
  ["CLOCKIN_TIMELINESS_BONUS", "Clocked in On Time"],
]);

const attendanceScore = z.object({
  id: z.number().positive().int(),
  shiftId: z.string().nullish(),
  workplaceId: z.string().nullish(),
  score: z.number().int().max(100),
  points: z.number().int(),
  policyId: z.number().positive().int(),
  action: z.string(),
  shiftStart: z.string().nullish(),
  facilityName: z.string().nullish(),
  acceptedDispute: z.boolean().nullish(),
  scoreReversalReason: z.string().nullish(),
  createdAt: z.string(),
  clipboardScoreUpdatedScore: z.number().nullish(),
  clipboardScoreAdjustment: z.number().nullish(),
  attendanceScore: z.number(),
  attendanceScoreAdjustment: z.number(),
});

const attendanceScoreHistoryResponse = z.object({
  scoreLogs: z.array(attendanceScore),
});

export type AttendanceScoreHistoryResponse = z.infer<typeof attendanceScoreHistoryResponse>;
export type AttendaceScoreLog = z.infer<typeof attendanceScore>;

interface GetAttendanceScoreHistoryParams {
  userId: string;
  filter?: { shiftIds?: string[] };
}

export function useGetAttendanceScoreHistory(
  params: GetAttendanceScoreHistoryParams,
  options: UseQueryOptions<AttendanceScoreHistoryResponse> = {}
): UseQueryResult<AttendanceScoreHistoryResponse> {
  const { userId, filter } = params;
  const url = `${environmentConfig.REACT_APP_ATTENDANCE_SCORE_SERVICE_URL}/scores/${userId}/history`;
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await get({
        url,
        responseSchema: attendanceScoreHistoryResponse,
        queryParams: { pageSize: ATTENDANCE_SCORE_HISTORY_REQUEST_SIZE, filter },
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_ATTENDANCE_SCORE_HISTORY,
    },
    ...options,
  });
}
