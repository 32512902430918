/** eslint-disable import/max-dependencies */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Browser } from "@capacitor/browser";
import { Clipboard } from "@capacitor/clipboard";
import { Geolocation } from "@capacitor/geolocation";
import { Network } from "@capacitor/network";
import { Preferences } from "@capacitor/preferences";
import { PushNotifications } from "@capacitor/push-notifications";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { Share } from "@capacitor/share";
import { FCM } from "@capacitor-community/fcm";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Market } from "@ionic-native/market";
import { Box, Button, Table, TableBody, TableCell, TableRow, useTheme } from "@mui/material";
import { isAndroidPlatform, isIosPlatform } from "@src/appV2/lib";
import { startNFCScan } from "@src/appV2/Nfc/utils/nfcReader";
import { NfcReaderError } from "@src/appV2/Nfc/utils/nfcReaderError";
import { LockScreenOrientationVariant } from "@src/appV2/ScreenOrientation";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { changeUser } from "appboy-cordova-sdk/www/AppboyPlugin";
import { noop } from "lodash";
// eslint-disable-next-line import/max-dependencies
import { type ReactElement, useState } from "react";

enum TestResult {
  SUCCESS = "passed",
  FAILURE = "failed",
  PENDING = "pending",
}

interface Scenario {
  key: string;
  execute: () => Promise<TestResult>;
  displayName: string;
  status: TestResult;
}

async function rotateScreenToLandscape() {
  try {
    await ScreenOrientation.lock({
      orientation: LockScreenOrientationVariant.LANDSCAPE_PRIMARY,
    });
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function rotateScreenToPortrait() {
  try {
    await ScreenOrientation.lock({
      orientation: LockScreenOrientationVariant.PORTRAIT_PRIMARY,
    });
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function getDeviceLocation() {
  try {
    JSON.stringify(await Geolocation.getCurrentPosition());
    return TestResult.SUCCESS;
  } catch {
    return TestResult.FAILURE;
  }
}

async function testDeviceNetwork() {
  try {
    const networkHandler = await Network.addListener("networkStatusChange", noop);
    await networkHandler.remove();
    const status = await Network.getStatus();
    if (isDefined(status.connected) && isDefined(status.connectionType)) {
      return TestResult.SUCCESS;
    }

    return TestResult.FAILURE;
  } catch {
    return TestResult.FAILURE;
  }
}

async function testMarketPlugin() {
  try {
    if (isIosPlatform()) {
      await Market.open("id1472962791");
    }

    if (isAndroidPlatform()) {
      await Market.open("health.clipboard.worker");
    }

    return TestResult.SUCCESS;
  } catch {
    return TestResult.FAILURE;
  }
}

async function testFcm(userId: string) {
  try {
    await FCM.setAutoInit({ enabled: true });
    await PushNotifications.requestPermissions();
    await PushNotifications.register();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    changeUser(userId);
    return TestResult.SUCCESS;
  } catch {
    return TestResult.FAILURE;
  }
}

async function testCapacitorShare() {
  try {
    await Share.share({
      title: "Test title",
      text: "Test text",
      url: "https://test-share.com",
    });
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function testCapacitorClipboard() {
  try {
    await Clipboard.write({
      string: "Some text for clipboard",
    });
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function testCapacitorPreference() {
  try {
    const preferenceKey = "test-preference-key";
    const expectedValue = "test-preference-value";
    await Preferences.set({
      key: preferenceKey,
      value: expectedValue,
    });

    const { value: actualValue } = await Preferences.get({ key: preferenceKey });

    if (actualValue !== expectedValue) {
      throw new Error("Capacitor Preference set/get test has failed");
    }
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function testBrowserLink() {
  try {
    await Browser.open({ url: "https://clipboardhealth.com" });
  } catch {
    return TestResult.FAILURE;
  }

  return TestResult.SUCCESS;
}

async function testNfcPlugin() {
  let nfcReader;
  try {
    nfcReader = startNFCScan();
  } catch {
    return TestResult.FAILURE;
  }

  let nfcReadString: string;
  try {
    nfcReadString = nfcReader.readOne(500);
  } catch (error: unknown) {
    // Test passes if a timeout is reached (NFC scanner starts then is stopped)
    if (error instanceof NfcReaderError && error.type === NfcReaderErrorType.SCAN_TIMEOUT) {
      return TestResult.SUCCESS;
    }
  }

  // Test also passes if a tag is actually read in this time
  if (nfcReadString) {
    return TestResult.SUCCESS;
  }

  return TestResult.FAILURE;
}

export function NativePlugins(): ReactElement {
  const worker = useDefinedWorker();
  const initialScenarios: Scenario[] = [
    {
      key: "Rotate screen to landscape",
      execute: rotateScreenToLandscape,
      displayName: "Rotate screen to landscape",
      status: TestResult.PENDING,
    },
    {
      key: "Rotate screen to portrait",
      execute: rotateScreenToPortrait,
      displayName: "Rotate screen to portrait",
      status: TestResult.PENDING,
    },
    {
      key: "Test FCM",
      execute: async () => await testFcm(worker.userId),
      displayName: "Test FCM",
      status: TestResult.PENDING,
    },
    {
      key: "Get device location",
      execute: getDeviceLocation,
      displayName: "Get device location",
      status: TestResult.PENDING,
    },
    {
      key: "Test Capacitor Share",
      execute: testCapacitorShare,
      displayName: "Test Capacitor Share",
      status: TestResult.PENDING,
    },
    {
      key: "Test Capacitor Clipboard",
      execute: testCapacitorClipboard,
      displayName: "Test Capacitor Clipboard",
      status: TestResult.PENDING,
    },
    {
      key: "Test Capacitor Preference",
      execute: testCapacitorPreference,
      displayName: "Test Capacitor Preference",
      status: TestResult.PENDING,
    },
    {
      key: "Test Browser Link",
      execute: testBrowserLink,
      displayName: "Test Browser Link",
      status: TestResult.PENDING,
    },
    {
      key: "Test Network plugin",
      execute: testDeviceNetwork,
      displayName: "Test Network plugin",
      status: TestResult.PENDING,
    },
    {
      key: "Test Market plugin",
      execute: testMarketPlugin,
      displayName: "Test Market plugin",
      status: TestResult.PENDING,
    },
    {
      key: "Test NFC plugin",
      execute: testNfcPlugin,
      displayName: "Test NFC plugin",
      status: TestResult.PENDING,
    },
  ];

  const [scenarios, setScenarios] = useState<Scenario[]>(initialScenarios);

  const theme = useTheme();

  function getStatusColor(status: TestResult): string {
    switch (status) {
      case TestResult.PENDING: {
        return theme.palette.text.primary;
      }

      case TestResult.SUCCESS: {
        return theme.palette.success.main;
      }

      case TestResult.FAILURE: {
        return theme.palette.error.main;
      }

      default: {
        return theme.palette.text.primary;
      }
    }
  }

  return (
    <Box>
      <Table>
        <TableBody>
          {scenarios.map((scenario) => (
            <TableRow key={`scenario-${scenario.key}`}>
              <TableCell>
                <Button
                  size="small"
                  sx={{ fontSize: "10px", width: "200px" }}
                  variant="contained"
                  onClick={async () => {
                    const result = await scenario.execute();
                    setScenarios((previousScenarios) => {
                      return previousScenarios.map((s) =>
                        s.key === scenario.key ? { ...s, status: result } : s
                      );
                    });
                  }}
                >
                  {scenario.displayName}
                </Button>
              </TableCell>
              <TableCell>
                <Text color={getStatusColor(scenario.status)}>
                  {scenario.displayName}:{scenario.status}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        fullWidth
        sx={{ fontSize: "10px" }}
        variant="outlined"
        onClick={() => {
          setScenarios(initialScenarios);
        }}
      >
        Reset Scenarios
      </Button>
    </Box>
  );
}
