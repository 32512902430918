import { isDefined } from "@clipboard-health/util-ts";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import {
  type ShiftRateNegotiation,
  ShiftRateNegotiationRole,
  ShiftRateNegotiationStatus,
} from "@src/appV2/OpenShifts/RateNegotiation/types";
import { generatePath, useHistory } from "react-router-dom";

import { TextButton } from "../../components/TextButton";

interface RateNegotiationActionProps {
  shiftId: string;
  rateNegotiation?: ShiftRateNegotiation;
}

export function RateNegotiationAction(props: RateNegotiationActionProps) {
  const { rateNegotiation, shiftId } = props;

  const history = useHistory();

  function getNegotiationButtonText() {
    if (!isDefined(rateNegotiation)) {
      return "Negotiate pay rate";
    }

    switch (rateNegotiation.status) {
      case ShiftRateNegotiationStatus.OPEN: {
        if (
          rateNegotiation.metadata.lastOffer.createdByUserRole ===
            ShiftRateNegotiationRole.WORKPLACE &&
          isDefined(rateNegotiation.metadata.lastOffer.payRate)
        ) {
          return `Reply to proposal: ${rateNegotiation.metadata.lastOffer.payRate}/hr`;
        }

        return "You proposed rate is being reviewed";
      }

      case ShiftRateNegotiationStatus.EXPIRED: {
        return "Your proposal has expired";
      }

      case ShiftRateNegotiationStatus.REJECTED: {
        if (
          rateNegotiation.metadata.lastOffer.createdByUserRole ===
          ShiftRateNegotiationRole.WORKPLACE
        ) {
          return `Workplace rejected your proposed rate`;
        }

        return undefined;
      }

      default: {
        return undefined;
      }
    }
  }

  const label = getNegotiationButtonText();

  if (!isDefined(label)) {
    return null;
  }

  return (
    <TextButton
      sx={{ marginBottom: 3 }}
      onClick={() => {
        history.push(
          generatePath(DeprecatedGlobalAppV1Paths.RATE_NEGOTIATION_TOOL, {
            shiftId,
          })
        );
      }}
    >
      {label}
    </TextButton>
  );
}
