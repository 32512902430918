import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  type UseMutationOptions,
  type UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

export const shiftFeedbackType = z.enum(["POSITIVE", "NEGATIVE"]);
export type ShiftFeedbackType = z.infer<typeof shiftFeedbackType>;

export const createShiftFeedback = z.object({
  workerId: z.string(),
  shiftId: z.string(),
  question: z.string(),
  response: z.string(),
  signedBy: z.string(),
  type: shiftFeedbackType.optional(),
});

const shiftFeedbackSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("shiftFeedback"),
    attributes: z.object({
      workerId: z.string(),
      shiftId: z.string(),
      question: z.string(),
      response: z.string(),
      signedBy: z.string(),
    }),
  }),
});

// Type inference
export type ShiftFeedbackResponse = z.infer<typeof shiftFeedbackSchema>;

export type CreateShiftFeedbackRequest = z.infer<typeof createShiftFeedback>;

export function useCreateShiftSigningOutFeedback(
  options: UseMutationOptions<ShiftFeedbackResponse, AxiosError, CreateShiftFeedbackRequest> = {}
): UseMutationResult<ShiftFeedbackResponse, AxiosError, CreateShiftFeedbackRequest> {
  return useMutation({
    mutationFn: async (data) => {
      const { workerId, shiftId, question, response, signedBy, type } = data;
      const apiResponse = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/shift-feedbacks`,
        data: {
          data: {
            attributes: {
              shiftId,
              question,
              response,
              signedBy,
              type,
            },
          },
        },
        responseSchema: shiftFeedbackSchema,
      });

      return apiResponse.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_SHIFT_SIGNING_OUT_FEEDBACK_FAILURE,
    },
    ...options,
  });
}
