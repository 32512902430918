import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";

import {
  type GetPlacementWorkplacesForWorkerQuery,
  getPlacementWorkplacesForWorkerQuerySchema,
  type GetPlacementWorkplacesResponse,
  getWorkplacePlacementsResponseSchema,
} from "../types/placementWorkplaces.schema";

interface GetPlacementWorkplacesParams {
  workerId: string;
  queryParams: GetPlacementWorkplacesForWorkerQuery;
}

export function useGetPlacementWorkplaces(
  params: GetPlacementWorkplacesParams,
  options?: UseGetQueryOptions<GetPlacementWorkplacesResponse>
) {
  const { workerId, queryParams } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/placement-workplaces`,
    queryParams,
    requestSchema: getPlacementWorkplacesForWorkerQuerySchema,
    responseSchema: getWorkplacePlacementsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PLACEMENT_WORKPLACES_FAILURE,
      userErrorMessage: "Something went wrong while loading the placement workplaces.",
    },
    ...options,
  });
}
