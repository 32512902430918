import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useToast } from "@src/appV2/lib";
import { useOpenShifts } from "@src/appV2/OpenShifts/api/useOpenShifts";
import { useGetBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { LegacyShift } from "@src/lib/interface/src";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { NegotiationCommitType } from "./interfaces";
import { INegotiationCommitModalProps } from "./interfaces";
import { NegotiationCommitModal } from "./NegotiationCommitModal";
import { NegotiationTool } from "./negotiationTool";

interface RateNegotiationToolContainerPathParams {
  shiftId: string;
}
/**
 * This function servers as a bridge between appV2 and legacy rate negotiation flow.
 * As we have whole flow implemented as modals, we cannot use it in appV2 as we cannot import legacy code in appV2.
 * This component is accesible via route and will render legacy rate negotiation flow.
 */
export function RateNegotiationToolContainerPage() {
  const history = useHistory();

  const modalState = useModalState(ModalStatus.OPEN);
  const negotiationCommitModalState = useModalState();

  const { userId: workerId } = useDefinedWorker();
  const { shiftId } = useParams<RateNegotiationToolContainerPathParams>();

  const { showErrorToast } = useToast();

  const [requestLoading, setRequestLoading] = useState(false);

  const [negotiationCommitModalProps, setNegotiationCommitModalProps] =
    useState<INegotiationCommitModalProps>({
      modalType: NegotiationCommitType.PROPOSE_NEW_RATE,
      closeModal: () => {},
      onCommit: () => {},
      requestLoading: false,
    });

  const {
    data: shiftData,
    isSuccess: isShiftSuccess,
    isError: isShiftError,
  } = useOpenShifts({ id: [shiftId] });

  const { shiftBookabilityById, queryResult: bookabilityStatusQueryResult } =
    useGetBookabilityStatusForShifts({ shiftIds: [shiftId], agentId: workerId });

  const shift = shiftData?.openAgentShifts.find((s) => s._id === shiftId);

  const { isLoading: isShiftBookabilityLoading } = bookabilityStatusQueryResult;

  useEffect(() => {
    if (isShiftError || (isShiftSuccess && !isDefined(shift))) {
      showErrorToast("Error fetching shift data");
      history.goBack();
    }
  }, [isShiftSuccess, showErrorToast, history, isShiftError, shift]);

  if (!isDefined(shift)) {
    return null;
  }

  const isWorkerBookedForShift = shift.agentId === workerId;

  return (
    <>
      <NegotiationTool
        isOpen={modalState.modalIsOpen}
        closeModal={() => history.goBack()}
        shift={shift as unknown as LegacyShift}
        shiftBookability={shiftBookabilityById[shiftId]?.attributes.bookability}
        isLoadingShiftBook={isShiftBookabilityLoading}
        workApproved={isWorkerBookedForShift}
        updateShiftRateNegotiation={(updatedShift) => {
          history.goBack();
        }}
        onBookShift={(bookedShift) => {
          history.goBack();
        }}
        showCommitModal={(props) => {
          setNegotiationCommitModalProps(props);

          negotiationCommitModalState.setModalStatus(
            props.isOpen ? ModalStatus.OPEN : ModalStatus.CLOSED
          );
        }}
        closeCommitModal={() => {
          negotiationCommitModalState.closeModal();
        }}
        setRequestLoading={setRequestLoading}
      />

      <NegotiationCommitModal
        {...negotiationCommitModalProps}
        modalState={negotiationCommitModalState}
        closeModal={() => history.goBack()}
        requestLoading={requestLoading}
      />
    </>
  );
}
