import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { useLogWorkerCalendarStatsEffect } from "@src/appV2/Agents/useLogWorkerCalendarStatsEffect/useLogWorkerCalendarStatsEffect";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, Calendar, type DateRange } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { type Worker } from "@src/appV2/Worker/api/types";
import {
  addMonths,
  addWeeks,
  endOfMonth,
  format,
  isBefore,
  isPast,
  isToday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subDays,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { generatePath, useHistory } from "react-router-dom";

import { useAgentShifts } from "../../api/useAgentShifts";
import { type OpenShiftDay, useOpenShiftCount } from "../../api/useOpenShiftCount";
import { useOpenShiftContext } from "../../context";
import { groupShiftsByDateAndPeriod } from "../../utils";
import { OpenShiftCell } from "./Cells";
import { OpenShiftFilters } from "./OpenShiftFilters";

const DEFAULT_DISTANCE_IN_MILES = 150;

interface OpenShiftCalendarProps {
  agent: Worker;
  refetchUrgentShifts: () => Promise<any>;
}

export function OpenShiftCalendar(props: OpenShiftCalendarProps) {
  const { agent, refetchUrgentShifts } = props;

  const history = useHistory();

  const { currentDate, dateRange, onPrevious, onNext } = useOpenShiftContext();

  const calendarDateRange: DateRange = {
    startDate: startOfWeek(dateRange.startDate),
    endDate: subDays(addWeeks(startOfWeek(dateRange.startDate), 6), 1),
  };

  const { data: agentShiftsData, isSuccess: agentShiftsIsSuccess } = useAgentShifts(
    {
      startDate: calendarDateRange.startDate,
      endDate: calendarDateRange.endDate,
      groupByDate: false,
      tmz: agent?.tmz ?? "",
    },
    {
      enabled: isDefined(agent.userId),
    }
  );

  const { filters, setFilters } = useAgentPreferences();

  const agentShiftsByDate = agentShiftsIsSuccess
    ? groupShiftsByDateAndPeriod(agentShiftsData.agentShifts ?? [], agent.tmz)
    : {};

  const {
    data: openShiftsCountData,
    isLoading: openShiftsCountIsLoading,
    isSuccess: openShiftsCountIsSuccess,
    refetch: refetchOpenShiftsCount,
  } = useOpenShiftCount(
    {
      startDate: calendarDateRange.startDate,
      endDate: calendarDateRange.endDate,
      qualification: filters?.license,
      coordinates: agent.geoLocation?.coordinates,
      specialities: {
        hasSedationExperience: agent.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: agent.specialities?.hasTrayAssemblyExperience ?? false,
      },
      distance: filters?.distance ?? DEFAULT_DISTANCE_IN_MILES,
      tmz: agent.tmz ?? "",
      isAgent: true,
    },
    {
      enabled: isDefined(agent.tmz) && isDefined(filters),
    }
  );
  useLogWorkerCalendarStatsEffect({
    dataSuccessfullyLoaded: openShiftsCountIsSuccess,
    agentId: agent.userId,
    calendarDateRange,
    numberOfOpenShifts: Object.values(openShiftsCountData ?? {}).reduce(
      (accumulator, openShiftsCount) => accumulator + openShiftsCount.total,
      0
    ),
  });

  useLogEffect(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_VIEWED, {
    agent: agent.userId,
  });

  return (
    <>
      <PullToRefresh
        onRefresh={async () => {
          await Promise.all([refetchOpenShiftsCount(), refetchUrgentShifts()]);
        }}
      />
      <Box sx={{ paddingTop: 2 }}>
        <OpenShiftFilters
          agent={agent}
          disabled={openShiftsCountIsLoading || !isDefined(filters)}
          filters={filters ?? { distance: 150, license: "" }}
          setFilters={setFilters}
        />
      </Box>
      <Calendar
        isLoading={openShiftsCountIsLoading}
        dateRange={calendarDateRange}
        options={{
          headerTitle: format(dateRange.startDate, "MMMM yyyy"),
          minDate: startOfMonth(currentDate),
          maxDate: addMonths(endOfMonth(currentDate), 5),
        }}
        DateCell={({ value }) => {
          const formattedDate = format(value, "yyyy-MM-dd");
          const agentShiftsForDay = agentShiftsByDate[formattedDate];

          const dateInWorkerTmz = isDefined(agent.tmz) ? utcToZonedTime(value, agent.tmz) : value;

          let openShiftsForDay: OpenShiftDay | undefined;

          if (openShiftsCountIsSuccess) {
            openShiftsForDay = openShiftsCountData[formattedDate] ?? {
              [ShiftPeriod.AM]: 0,
              [ShiftPeriod.PM]: 0,
              [ShiftPeriod.NOC]: 0,
              total: 0,
            };

            // Still bookable shifts that started yesterday are counted as AM shifts today,
            // so we add them to AM and total for today and remove them from yesterday
            if (isToday(dateInWorkerTmz)) {
              const formattedYesterdayDate = format(subDays(value, 1), "yyyy-MM-dd");
              openShiftsForDay.am =
                (openShiftsForDay.am ?? 0) +
                (openShiftsCountData[formattedYesterdayDate]?.total ?? 0);
              openShiftsForDay.total += openShiftsCountData[formattedYesterdayDate]?.total ?? 0;
            } else if (isPast(dateInWorkerTmz)) {
              openShiftsForDay = undefined;
            }
          }

          return (
            <OpenShiftCell
              isDisabled={isPast(dateInWorkerTmz) && !isToday(dateInWorkerTmz)}
              date={value}
              openShiftsForDay={openShiftsForDay}
              agentShiftsForDay={agentShiftsForDay}
              isLoading={openShiftsCountIsLoading}
            />
          );
        }}
        onPrevious={() => {
          onPrevious();

          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_SELECT_PREVIOUS, {
            agentId: agent.userId,
          });
        }}
        onNext={() => {
          onNext();

          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_SELECT_NEXT, {
            agentId: agent.userId,
          });
        }}
        onDateSelect={({ value }) => {
          if (isBefore(value, startOfToday())) {
            return;
          }

          const formattedDate = format(value, "yyyy-MM-dd");
          const agentShiftsForDay = agentShiftsByDate[formattedDate];
          const openShiftsForDay: OpenShiftDay | undefined = openShiftsCountIsSuccess
            ? openShiftsCountData[formattedDate]
            : undefined;

          const agentShiftCount =
            (agentShiftsForDay?.am ?? 0) +
            (agentShiftsForDay?.pm ?? 0) +
            (agentShiftsForDay?.noc ?? 0);

          logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_DATE_SELECTED, {
            agentId: agent.userId,
            date: value,
            totalShiftCount: openShiftsForDay?.total,
            amShiftCount: openShiftsForDay?.[ShiftPeriod.AM],
            pmShiftCount: openShiftsForDay?.[ShiftPeriod.PM],
            nocShiftCount: openShiftsForDay?.[ShiftPeriod.NOC],
            agentShiftCount,
            hasPriorityAccessShift:
              openShiftsForDay?.priorityAccess?.includes(ShiftPeriod.AM) ??
              openShiftsForDay?.priorityAccess?.includes(ShiftPeriod.PM) ??
              openShiftsForDay?.priorityAccess?.includes(ShiftPeriod.NOC),
          });

          history.push(
            generatePath(DeprecatedGlobalAppV1Paths.OPEN_SHIFT_DAY_VIEW, {
              date: formattedDate,
            })
          );
        }}
      />
    </>
  );
}
