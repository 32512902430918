import { Clipboard } from "@capacitor/clipboard";
import { Image, type UseModalState } from "@clipboard-health/ui-react";
import { Text, Title } from "@clipboard-health/ui-react";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { environmentConfig } from "@src/appV2/environment";
import { useQrCodeDataUrl, useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { getWorkplaceSignUpWithReferralCodePath } from "../../api/utils";
import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import {
  WorkplaceReferralAction,
  WorkplaceReferralActionSource,
  WorkplaceReferralComponent,
} from "../../types";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";
import { ReferralCardSkeleton } from "../ReferralCardSkeleton";
import { shareReferralLink } from "./shareLink";

interface ShareReferralLinkDialogProps {
  modalState: UseModalState;
  referralCode: string;
}

export function ShareReferralLinkDialog(props: ShareReferralLinkDialogProps) {
  const { modalState, referralCode } = props;
  const { modalIsOpen, closeModal } = modalState;
  const { showSuccessToast } = useToast();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const signUpReferralCodeLink = `${
    environmentConfig.REACT_APP_ADMIN_WEB_APP_URL
  }${getWorkplaceSignUpWithReferralCodePath(referralCode)}`;
  const {
    data: referralCodeDataImageLink,
    isLoading: isLoadingReferralCodeDataImage,
    isSuccess: isReferralCodeDataImageSuccess,
  } = useQrCodeDataUrl(
    {
      data: signUpReferralCodeLink,
    },
    {
      enabled: modalIsOpen,
      meta: {
        userErrorMessage: "Failed to generate Referral QR Code",
      },
    }
  );

  return (
    <Dialog
      fullWidth
      open={modalIsOpen}
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
          action: WorkplaceReferralAction.SHARE_REFERRAL_LINK_DIALOG_CLOSED,
          component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_DIALOG,
          variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
          referralCode,
          source: WorkplaceReferralActionSource.OVERLAY_CLICKED,
        });
        closeModal();
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Title component="h3">Share Your Referral Link</Title>
          <IconButton
            aria-label="Close dialog"
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                action: WorkplaceReferralAction.SHARE_REFERRAL_LINK_DIALOG_CLOSED,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
                referralCode,
                source: WorkplaceReferralActionSource.BUTTON_CLICKED,
              });
              closeModal();
            }}
          >
            <CloseIcon color="disabled" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText paragraph>
          Have facilities scan this QR code to access the referral signup page.
        </DialogContentText>
        {isLoadingReferralCodeDataImage && <ReferralCardSkeleton count={1} height={{ body: 40 }} />}
        {isReferralCodeDataImageSuccess && (
          <Image src={referralCodeDataImageLink} alt="Referral QR Code" width="100%" />
        )}
        <DialogContentText paragraph>
          Or use the options below to share your referral link with facilities.
        </DialogContentText>
        <Box
          sx={{
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Stack direction="row" spacing={2} justifyContent="end">
            <IconButton
              color="primary"
              onClick={async () => {
                await shareReferralLink({
                  signUpReferralCodeLink,
                  referralCode,
                  variant: getWorkerToWorkplaceVariantName(
                    isWorkerToWorkplaceAffiliateProgramEnabled
                  ),
                });
              }}
            >
              <Stack spacing={1}>
                <ShareIcon />
                <Text variant="caption">Share link</Text>
              </Stack>
            </IconButton>
            <IconButton
              color="primary"
              onClick={async () => {
                await Clipboard.write({
                  string: signUpReferralCodeLink,
                });
                logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                  action: WorkplaceReferralAction.REFERRAL_LINK_COPIED,
                  component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_DIALOG,
                  variant: getWorkerToWorkplaceVariantName(
                    isWorkerToWorkplaceAffiliateProgramEnabled
                  ),
                  referralCode,
                  link: signUpReferralCodeLink,
                });
                showSuccessToast("Referral link copied successfully");
              }}
            >
              <Stack spacing={1}>
                <ContentCopyIcon />
                <Text variant="caption">Copy link</Text>
              </Stack>
            </IconButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
