import { isDefined } from "@clipboard-health/util-ts";
import {
  defaultHcpRequirementStatusApiResponse,
  deprecatedGetRequirementStatusDoNotUseGraphQlCH23034,
  GET_REQUIREMENTS_STATUS_PATH,
} from "@src/appV2/Accounts/Documents/api/deprecatedDoNotUseGraphQlCH23034";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { difference, groupBy, uniq } from "lodash";

import { filterVisibleRequirements } from "../../utils";
import { getPriorityStatusRequirementsById } from "../../utils/getPriorityStatusRequirementsById";
import { RequirementWorkflowType } from "../resources/requirements/constants";
import {
  type HcpRequirement,
  type HcpRequirementStatus,
  type HcpRequirementStatusResponse,
  MappingLevel,
  type UseGetRequirementStatus,
} from "../types";

export function useGetRequirementStatus(
  params: { workerId?: string; facilitiesId?: string[]; qualifications?: string[] },
  options: UseQueryOptions<HcpRequirementStatusResponse> = {}
): UseQueryResult<HcpRequirementStatusResponse> & UseGetRequirementStatus {
  const { workerId, facilitiesId, qualifications } = params;
  const response = useQuery({
    queryKey: [GET_REQUIREMENTS_STATUS_PATH, workerId, facilitiesId, qualifications],
    queryFn: deprecatedGetRequirementStatusDoNotUseGraphQlCH23034(
      workerId ?? "",
      facilitiesId,
      qualifications
    ),
    meta: { logErrorMessage: APP_V2_APP_EVENTS.GET_REQUIREMENT_STATUS_FAILURE },
    enabled: isDefined(workerId),
    ...options,
  });

  const hcpRequirementStatus =
    response.data?.data.hcpRequirementStatus ?? defaultHcpRequirementStatusApiResponse;

  const missingRequirements = difference(hcpRequirementStatus.missing, [
    ...hcpRequirementStatus.pending,
    ...hcpRequirementStatus.rejected,
    ...hcpRequirementStatus.completed,
  ]);

  const completedRequirements = [
    ...hcpRequirementStatus.completed,
    ...hcpRequirementStatus.validOptionalRequirements,
  ];

  const optionalRequirements = difference(hcpRequirementStatus.missingOptionalRequirements, [
    ...hcpRequirementStatus.pending,
    ...hcpRequirementStatus.rejected,
    ...hcpRequirementStatus.completed,
  ]);

  const expiredRequirements = difference(
    [...hcpRequirementStatus.expired, ...hcpRequirementStatus.expiredOptionalRequirements],
    hcpRequirementStatus.pending
  );

  const rejectedRequirements = difference(hcpRequirementStatus.rejected, [
    ...hcpRequirementStatus.pending,
    ...hcpRequirementStatus.expired,
    ...hcpRequirementStatus.expiredOptionalRequirements,
    ...hcpRequirementStatus.completed,
    ...hcpRequirementStatus.validOptionalRequirements,
  ]);

  const miscellaneousRequirements: HcpRequirement = {
    ...hcpRequirementStatus.miscellaneous,
    reqId: hcpRequirementStatus.miscellaneous._id,
    level: MappingLevel.GLOBAL,
    requiredBy: [],
    hcfType: [],
    visibleToHCP: true,
    requirementWorkflowType: RequirementWorkflowType.DOCUMENT_UPLOAD,
  };

  const statusRequirements = getPriorityStatusRequirementsById([
    miscellaneousRequirements,
    ...hcpRequirementStatus.requirements,
    ...hcpRequirementStatus.optionalRequirements,
  ]);

  const groupedRequirementMapping = groupBy(hcpRequirementStatus.requirements, "reqId");

  const groupedMandatoryRequirementIds = Object.keys(groupedRequirementMapping);

  const groupedMandatoryRequirementVisibleToWorker = filterVisibleRequirements(
    groupedMandatoryRequirementIds,
    statusRequirements
  );

  const totalMandatoryRequirementsNotAtHCFLevelVisibleToWorker =
    groupedMandatoryRequirementVisibleToWorker.filter((requestId) => {
      const requirement = statusRequirements[requestId];
      return requirement.level !== MappingLevel.HCF;
    });

  const missingRequirementIdsAtHCFLevel: string[] = [];
  const facilityIdsMissingRequirements: string[] = [];
  missingRequirements.forEach((missingRequirementId) => {
    const mappingsForRequirement = groupedRequirementMapping[missingRequirementId];
    const facilityIdsOfRequiringMissingRequirement: string[] = [];
    for (const mapping of mappingsForRequirement) {
      if (mapping.level !== MappingLevel.HCF) {
        return;
      }

      facilityIdsOfRequiringMissingRequirement.push(...(mapping?.requiredBy ?? []));
    }

    facilityIdsMissingRequirements.push(...facilityIdsOfRequiringMissingRequirement);
    missingRequirementIdsAtHCFLevel.push(missingRequirementId);
  });

  const missingRequirementsNotAtHCFLevel = difference(
    missingRequirements,
    missingRequirementIdsAtHCFLevel
  );

  const missingMandatoryRequirements = filterVisibleRequirements(
    difference(groupedMandatoryRequirementIds, [
      ...hcpRequirementStatus.pending,
      ...hcpRequirementStatus.completed,
    ]),
    statusRequirements
  );

  const countOfPendingOrCompletedRequiredNotAtHCFRequirements = difference(
    totalMandatoryRequirementsNotAtHCFLevelVisibleToWorker,
    [...missingMandatoryRequirements, ...expiredRequirements, ...rejectedRequirements]
  ).length;

  const status: HcpRequirementStatus = {
    ...hcpRequirementStatus,
    requirements: statusRequirements,
    pending: uniq(hcpRequirementStatus.pending),
    missing: uniq(filterVisibleRequirements(missingRequirementsNotAtHCFLevel, statusRequirements)),
    optional: uniq(filterVisibleRequirements(optionalRequirements, statusRequirements)),
    expired: uniq(filterVisibleRequirements(expiredRequirements, statusRequirements)),
    completed: uniq(filterVisibleRequirements(completedRequirements, statusRequirements)),
    rejected: uniq(filterVisibleRequirements(rejectedRequirements, statusRequirements)),
    miscellaneous: [hcpRequirementStatus.miscellaneous._id],
    missingRequirementAtHCFLevel: uniq(
      filterVisibleRequirements(missingRequirementIdsAtHCFLevel, statusRequirements)
    ),
  };

  return {
    ...response,
    hcpRequirementStatus: status,
    facilityIdsMissingRequirements,
    countOfPendingOrCompletedMandatoryRequirements:
      countOfPendingOrCompletedRequiredNotAtHCFRequirements,
    totalCountOfMandatoryRequirements:
      totalMandatoryRequirementsNotAtHCFLevelVisibleToWorker.length,
  };
}
