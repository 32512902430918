import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Chip, Stack } from "@mui/material";
import { isNumber } from "lodash";

export interface ScorePolicyEntryProps {
  title: string;
  consequence?: number | string;
}

export function ScorePolicyEntry(policy: ScorePolicyEntryProps) {
  const { title, consequence } = policy;
  return (
    <Stack direction="row" justifyContent="space-between">
      <Text>{title}</Text>
      {isDefined(consequence) && (
        <Chip
          label={
            isNumber(consequence) ? (
              <Text
                bold
                sx={{
                  color: consequence > 0 ? "#388E3C" : "#D32F2F",
                }}
              >
                {consequence > 0 ? "+" : ""}
                {consequence} pts
              </Text>
            ) : (
              <Text bold sx={{ color: "#D32F2F" }}>
                {consequence}
              </Text>
            )
          }
          variant="filled"
          sx={{
            borderRadius: "5px",
            backgroundColor: consequence > 0 ? "#81C7841A" : "#F443361A",
          }}
        />
      )}
    </Stack>
  );
}
