import { isDefined } from "@clipboard-health/util-ts";
import {
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";

import { HorizontalScrollView } from "../../components/HorizontalScrollView";
import { type ShiftWithType } from "../types";
import { ShiftBottomSheetBlockingDocumentsCardsContainer } from "./BlockingDocumentsCards/Container";
import { type ShiftBlockingDocuments } from "./BlockingDocumentsCards/types";
import { ShiftBottomSheetCancellationPoliciesCard } from "./CancellationPoliciesCard";
import { ShiftBottomSheetHourRestrictionsCard } from "./FacilityHourRestrictionsCard";
import { ShiftBottomSheetFacilityMapLocationCard } from "./FacilityMapLocationCard";
import { ShiftBottomSheetFacilityPhotosCard } from "./FacilityPhotosCard";
import { ShiftBottomSheetQuizCard } from "./QuizCard";
import { ShiftBottomSetupPaymentsCard } from "./SetupPaymentsCard";
import { ShiftBottomSignAgreementCard } from "./SignAgreementCard";
import { ShiftBottomSheetSummaryCard } from "./SummaryCard";
import { ShiftBottomSheetTitle } from "./Title";
import { ShiftBottomSheetWorkplaceHighlightsCard } from "./WorkplaceHighlightsCard";
import { ShiftBottomSheetWorkplaceReviewsCard } from "./WorkplaceReviewsCard";

interface ShiftBottomSheetSheetContentProps {
  shift: ShiftWithType;
  bookabilityStatus: BookabilityStatusItem;
  blockingDocuments: ShiftBlockingDocuments;
}

export function ShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const { shift, bookabilityStatus, blockingDocuments } = props;
  const { facility: workplace, type, priorityTill, start, end } = shift;
  const { missingDocumentsSet, expiringDocumentsSet, pendingDocumentsSet } = blockingDocuments;
  const { _id: shiftId } = shift;
  const {
    userId: workplaceId,
    lateCancellation,
    sentHomeChargeHours,
    geoLocation,
    maxAllowedWorkConsecutiveHours,
    maxAllowedWorkHoursPerWeek,
    preventDoubleShifts,
  } = workplace;

  const hasHourRestrictions =
    isDefined(maxAllowedWorkConsecutiveHours) || isDefined(maxAllowedWorkHoursPerWeek);

  const { period: lateCancellationPeriod, feeHours: lateCancellationFeeHours } =
    lateCancellation ?? {};

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const hasAgreementToSign = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT,
  ]);

  const hasPaymentsDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
  ]);

  const hasBlockingDocuments =
    missingDocumentsSet.size + expiringDocumentsSet.size + pendingDocumentsSet.size > 0;

  return (
    <>
      <ShiftBottomSheetTitle
        shiftType={type}
        blockingDocuments={blockingDocuments}
        hasQuizToPass={hasQuizToPass}
        hasAgreementToSign={hasAgreementToSign}
        hasPaymentsDisabled={hasPaymentsDisabled}
        priorityTillDatetimeString={priorityTill}
        endDatetimeString={end}
        startDatetimeString={start}
        sx={{ padding: 7, textWrap: "balance" }}
      />

      <HorizontalScrollView stretch sx={{ px: 5 }}>
        {hasPaymentsDisabled && <ShiftBottomSetupPaymentsCard shiftId={shiftId} />}
        {hasAgreementToSign && <ShiftBottomSignAgreementCard shiftId={shiftId} />}
        {hasBlockingDocuments && (
          <ShiftBottomSheetBlockingDocumentsCardsContainer
            shift={shift}
            blockingDocuments={blockingDocuments}
            bookabilityStatus={bookabilityStatus}
          />
        )}
        {hasQuizToPass && <ShiftBottomSheetQuizCard shiftId={shiftId} />}
        {hasHourRestrictions && (
          <ShiftBottomSheetHourRestrictionsCard
            maxAllowedWorkConsecutiveHours={maxAllowedWorkConsecutiveHours}
            maxAllowedWorkHoursPerWeek={maxAllowedWorkHoursPerWeek}
            preventDoubleShifts={preventDoubleShifts}
          />
        )}
        <ShiftBottomSheetSummaryCard shift={shift} />
        <ShiftBottomSheetCancellationPoliciesCard
          lateCancellationPeriod={lateCancellationPeriod ?? 0}
          lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
          sentHomeChargeHours={sentHomeChargeHours ?? 0}
          workplaceId={String(workplaceId)}
          workplace={workplace}
        />
        <ShiftBottomSheetFacilityPhotosCard workplaceId={workplaceId} />
        <ShiftBottomSheetWorkplaceHighlightsCard workplace={workplace} />
        <ShiftBottomSheetWorkplaceReviewsCard workplace={workplace} />
        <ShiftBottomSheetFacilityMapLocationCard
          workplaceId={workplaceId ?? ""}
          facilityLocation={geoLocation}
        />
      </HorizontalScrollView>
    </>
  );
}
