import { type UseModalState } from "@clipboard-health/ui-react";
import { useModalState } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { FullScreenDialog } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { type HelloSignModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import { SubmittingDocumentDialog } from "../SubmittingDocumentDialog";
import { InstantReviewShiftDocumentSubmissionErrorBottomSheet } from "./DocumentSubmissionErrorBottomSheet";
import { useHelloSignEmbeddedFlow } from "./useHelloSignEmbeddedFlow";

interface InstantReviewHelloSignFormBottomSheetProps {
  modalState: UseModalState;
}

export function InstantReviewHelloSignFormBottomSheet(
  props: InstantReviewHelloSignFormBottomSheetProps
) {
  const { modalState } = props;
  const helloSignIframeContainerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const submittingDocumentModalState = useModalState();
  const { openModal: openSubmittingDocumentModal } = submittingDocumentModalState;
  const errorModalState = useModalState();
  const { openModal: openErrorModal } = errorModalState;
  const { navigateToModal } = useShiftModalsDataContext();
  const { shiftId, requirementId } = useParams<HelloSignModalRoutePathParams>();

  const handleHelloSignError = useCallback(() => {
    logEvent(APP_V2_USER_EVENTS.SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_FAILED, {
      shiftId: shiftId ?? "",
      requirementId,
      requirementWorkflowType: RequirementWorkflowType.SIGN_WITH_HELLOSIGN,
    });
    openErrorModal();
  }, [openErrorModal, shiftId, requirementId]);

  const handleHelloSignSuccess = useCallback(
    (isDocumentApproved: boolean) => {
      const documentStatus = isDocumentApproved ? DocumentStatus.APPROVED : DocumentStatus.PENDING;
      logEvent(APP_V2_USER_EVENTS.SHIFT_BOOKING_FLOW_DOCUMENT_SUBMISSION_SUCCESSFUL, {
        shiftId: shiftId ?? "",
        requirementId,
        documentStatus,
        requirementWorkflowType: RequirementWorkflowType.SIGN_WITH_HELLOSIGN,
      });
      navigateToModal(
        SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH,
        {
          shiftId,
          requirementId,
          documentStatus,
        },
        true
      );
    },
    [navigateToModal, shiftId, requirementId]
  );

  const { isLoadingHelloSignDocument, startHelloSignEmbeddedFlow } = useHelloSignEmbeddedFlow({
    helloSignIframeContainerRef,
    requirementId,
    onHelloSignClientCancel: () => {
      history.goBack();
    },
    onSuccess: handleHelloSignSuccess,
    onError: handleHelloSignError,
  });

  /*
   * Our openErrorModal method is not stable/memoized, and would cause infinite rerenders if we call the
   * startHelloSignEmbeddedFlow method in a useEffect as normal.
   *
   * We use a mutation to call the startHelloSignEmbeddedFlow method in a stable way.
   */
  const { mutate: startHelloSignFlow } = useMutation({
    mutationFn: async () => {
      await startHelloSignEmbeddedFlow();
    },
  });

  useEffect(() => {
    if (requirementId) {
      startHelloSignFlow();
    }
  }, [requirementId, startHelloSignFlow]);

  useEffect(() => {
    if (isLoadingHelloSignDocument) {
      openSubmittingDocumentModal();
    }
  }, [isLoadingHelloSignDocument, openSubmittingDocumentModal]);

  return (
    <>
      <FullScreenDialog modalState={modalState}>
        <Box ref={helloSignIframeContainerRef} sx={{ height: "100%" }} />
      </FullScreenDialog>
      <SubmittingDocumentDialog modalState={submittingDocumentModalState} />
      <InstantReviewShiftDocumentSubmissionErrorBottomSheet
        modalState={errorModalState}
        onClick={async () => {
          errorModalState.closeModal();
          await startHelloSignEmbeddedFlow();
        }}
      />
    </>
  );
}
