/**
 * Creates a box around a latlng for the given bounds size in pixels.
 * Used to check if a workplace intersects with a cluster.
 *
 * Adapted from https://stackoverflow.com/a/11882282
 */
export function getBoundsAroundLatLng(
  map: google.maps.Map,
  center: google.maps.LatLng,
  boundsWidthInPixels: number,
  boundsHeightInPixels: number
): google.maps.LatLngBounds | undefined {
  const projection = map.getProjection();
  if (!projection) {
    return undefined;
  }

  const centerPoint = projection.fromLatLngToPoint(center);
  if (!centerPoint) {
    return undefined;
  }

  const scale = 2 ** (map.getZoom() ?? 0);

  const bottomLeft = new google.maps.Point(
    (centerPoint.x * scale - boundsWidthInPixels / 2) / scale,
    (centerPoint.y * scale - boundsHeightInPixels) / scale
  );
  const topRight = new google.maps.Point(
    (centerPoint.x * scale + boundsWidthInPixels / 2) / scale,
    (centerPoint.y * scale) / scale
  );

  const bottomLeftLatLng = projection.fromPointToLatLng(bottomLeft);
  const topRightLatLng = projection.fromPointToLatLng(topRight);
  if (!bottomLeftLatLng || !topRightLatLng) {
    return undefined;
  }

  const bounds = new google.maps.LatLngBounds();
  bounds.extend(bottomLeftLatLng);
  bounds.extend(topRightLatLng);

  return bounds;
}
