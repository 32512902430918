import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { DistanceText } from "../../components/DistanceText";
import { FacilityAvatar } from "../../components/FacilityAvatar";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";
import type { OpenShiftWorkplace } from "../Open/types";

interface ShiftCardFacilityInfoProps {
  workplace: OpenShiftWorkplace;
}

export function ShiftCardFacilityInfo(props: ShiftCardFacilityInfoProps) {
  const { workplace } = props;
  const { id: facilityId, attributes } = workplace;
  const { name: facilityName, location: geoLocation } = attributes;

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation: geoLocation,
  });

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <FacilityAvatar facilityId={String(facilityId)} size="small" />

      <Stack spacing={1} sx={{ overflow: "hidden" }}>
        <Text
          semibold
          variant="body2"
          sx={{
            // clamp text to max 2 lines
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {facilityName}
        </Text>

        <DistanceText
          isError={distanceIsError}
          isLoading={distanceIsLoading}
          formattedDistance={formattedDistance}
          variant="caption"
        />
      </Stack>
    </Stack>
  );
}
