import { type UseGetMissingRequirements } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";

export function getMissingAndPendingDocumentsForQualification(
  workplaceId: string,
  qualification: string,
  documentsResults: Map<string, UseGetMissingRequirements>
) {
  const documentsResult = documentsResults.get(workplaceId);

  return {
    missingDocuments: (documentsResult
      ? [...documentsResult.missingDocuments.values()]
      : []
    ).filter((r) => r.hcpType?.includes(qualification)),
    pendingDocuments: (documentsResult
      ? [...documentsResult.pendingDocuments.values()]
      : []
    ).filter((r) => r.hcpType?.includes(qualification)),
  };
}
