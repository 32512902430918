import { InternalLink, LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { isCbhApiAxiosError } from "@src/appV2/Errors/errorUtilts";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import { PlacementDetailCreateCandidateProfileDialog } from "@src/appV2/redesign/PlacementCandidate/components/PlacementDetailCreateCandidateProfileDialog";
import {
  type PlacementCandidate,
  PlacementCandidateStatus,
} from "@src/appV2/redesign/PlacementCandidate/types";
import { useIsPlacementChatsEnabled } from "@src/appV2/redesign/PlacementChats/useIsPlacementChatsEnabled";
import {
  type CreatePlacementChatChannelRequest,
  useCreateChatChannel,
} from "@src/appV2/ShiftBlocks/api/useCreateChatChannel";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type QueryObserverResult } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import { useCreatePlacementApplication } from "../api/useCreatePlacementApplication";
import { type PlacementDetail } from "../api/useGetPlacement";
import { getPlacementChatPath } from "../paths";
import { type PlacementResponse } from "../types/fetchPlacements.schema";
import { PlacementApplicationSuccessBottomSheet } from "./PlacementApplicationSuccessBottomSheet";

interface PlacementApplyButtonContainerProps {
  placementDetails: PlacementDetail;
  placementCandidate: PlacementCandidate;
  refetchPlacement: () => Promise<QueryObserverResult<PlacementResponse>>;
}

export function PlacementApplyButtonContainer(props: PlacementApplyButtonContainerProps) {
  const { placementDetails, placementCandidate, refetchPlacement } = props;
  const { enableHcpChatWithoutApplication } = useIsPlacementChatsEnabled();
  const { showErrorToast } = useToast();
  const history = useHistory();
  const worker = useDefinedWorker();
  const { mutateAsync: createPlacementApplication, isLoading: isCreatingPlacementApplication } =
    useCreatePlacementApplication();
  const placementApplicationSuccessModalState = useModalState();
  const createCandidateProfileModalState = useModalState();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel<CreatePlacementChatChannelRequest>();

  const hasApplied =
    isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0;

  const applyButtonProps =
    hasApplied || placementDetails.isLicenseEligible
      ? {
          label: hasApplied ? "Applied" : "Apply to this Job",
          onClick: async () => {
            try {
              await createPlacementApplication({
                placementCandidateId: placementCandidate.id,
                placementId: placementDetails.id,
              });
              await refetchPlacement();
              placementApplicationSuccessModalState.openModal();
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATED, {
                placementCandidateId: placementCandidate.id,
                placementDetails,
              });
            } catch (error) {
              if (
                isCbhApiAxiosError(error) &&
                error.response.data.errors.some(
                  (error) =>
                    error.detail ===
                    "The candidate is not licensed for any of this placement's worker types in the facility's state"
                )
              ) {
                showErrorToast(
                  <Text variant="body2" color="inherit">
                    Application failed since you aren&apos;t licensed to work in the facility&apos;s
                    state.{" "}
                    <InternalLink
                      color="inherit"
                      sx={{ textDecorationColor: "inherit" }}
                      to={DeprecatedGlobalAppV1Paths.LICENSE_MANAGER}
                    >
                      Tap here
                    </InternalLink>{" "}
                    to update your licenses.
                  </Text>
                );
                return;
              }

              showErrorToast("Failed to apply for job.");
            }
          },
        }
      : {
          label: "Upload valid license",
          warning: `Please make sure your licenses are up to date and valid in ${placementDetails.workplace.state}`,
          onClick: () => {
            history.push(DeprecatedGlobalAppV1Paths.LICENSE_MANAGER);
          },
        };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: 4,
          paddingY: 6,
          backgroundColor: (theme) => theme.palette.background.tertiary,
          borderTop: 1,
          borderColor: "divider",
          zIndex: 1200,
        }}
      >
        {isDefined(placementDetails) && isDefined(placementCandidate) && (
          <Stack spacing={5}>
            {placementCandidate.status === PlacementCandidateStatus.ACTIVATED && (
              <>
                {applyButtonProps.warning && (
                  <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                    {applyButtonProps.warning}
                  </Text>
                )}
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  isLoading={isCreatingPlacementApplication}
                  disabled={hasApplied}
                  onClick={applyButtonProps.onClick}
                >
                  {applyButtonProps.label}
                </LoadingButton>
                {enableHcpChatWithoutApplication && (
                  <LoadingButton
                    fullWidth
                    isLoading={isCreatingChatChannel}
                    disabled={isCreatingChatChannel}
                    variant="outlined"
                    size="large"
                    color="primary"
                    startIcon={isCreatingChatChannel ? undefined : <CbhIcon type="text-bubble" />}
                    onClick={async () => {
                      await createChatChannel({
                        facilityId: placementDetails.workplace.id,
                        placementId: placementDetails.id,
                        agentId: worker.userId,
                      });

                      history.push(
                        getPlacementChatPath(
                          placementDetails.id,
                          placementDetails.workplace.id,
                          "chat-with-workplace"
                        )
                      );
                    }}
                  >
                    Chat with workplace
                  </LoadingButton>
                )}
              </>
            )}
            {placementCandidate.status === PlacementCandidateStatus.ONBOARDING && (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    createCandidateProfileModalState.openModal();
                  }}
                >
                  Create a Profile
                </Button>
                <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                  You must create a profile before you can apply for this job.
                </Text>
              </>
            )}
          </Stack>
        )}
      </Box>
      {placementApplicationSuccessModalState.modalIsOpen && (
        <PlacementApplicationSuccessBottomSheet
          modalState={placementApplicationSuccessModalState}
          placementDetails={placementDetails}
        />
      )}
      {createCandidateProfileModalState.modalIsOpen && (
        <PlacementDetailCreateCandidateProfileDialog
          modalState={createCandidateProfileModalState}
          placementCandidate={placementCandidate}
          worker={worker}
        />
      )}
    </>
  );
}
