import { isDevelopmentNodeEnvironment } from "@src/appV2/environment";
import { convertToGoogleMapsLocation, type GeoLocation } from "@src/appV2/Location";
import { useEffect, useRef } from "react";

import { getBoundsAroundLatLng } from "../WorkplaceClusterer/getBoundsAroundLatLng";

const MILES_TO_METERS_RATIO = 1609.34;
const CENTER_PIN_SIZE = 10;

/**
 * Enable debugging for drawing a circle around the location filter.
 * Useful for visualizing the radius used for fetching workplaces,
 * and checking if it's correctly triggering new fetches when the user moves the map.
 */
export function useDebugMapViewLocationFilter(props: {
  map: google.maps.Map | undefined;
  radiusInMiles: number;
  locationFilter?: GeoLocation;
  enabled: boolean;
}) {
  const { map, radiusInMiles, locationFilter, enabled } = props;

  const enableDebugging = isDevelopmentNodeEnvironment() && enabled;

  const radiusCircleRef = useRef<google.maps.Circle>(
    new google.maps.Circle({
      fillOpacity: 0.1,
      strokeColor: "#FF0000", // red
      strokeOpacity: 1,
      strokeWeight: 2,
      radius: radiusInMiles * MILES_TO_METERS_RATIO,
    })
  );

  const centerPinRef = useRef<google.maps.Rectangle>(
    new google.maps.Rectangle({
      fillColor: "#FF00FF", // pink
      fillOpacity: 0.7,
      strokeOpacity: 0,
    })
  );

  useEffect(() => {
    if (!enableDebugging) {
      return;
    }

    radiusCircleRef.current.setRadius(radiusInMiles * MILES_TO_METERS_RATIO);
  }, [radiusInMiles, enableDebugging]);

  useEffect(() => {
    if (!enableDebugging || !map || !locationFilter) {
      return undefined;
    }

    const radiusCircle = radiusCircleRef.current;
    const centerPin = centerPinRef.current;

    if (!radiusCircle.getMap()) {
      radiusCircle.setMap(map);
    }

    if (!centerPin.getMap()) {
      centerPin.setMap(map);
    }

    radiusCircle.setCenter(convertToGoogleMapsLocation(locationFilter));

    function updateCenterPin() {
      const newCenter = map?.getCenter();
      if (map && newCenter) {
        const bounds = getBoundsAroundLatLng(map, newCenter, CENTER_PIN_SIZE, CENTER_PIN_SIZE);
        if (bounds) {
          centerPin.setBounds(bounds);
        }
      }
    }

    const centerChangeListener = map.addListener("center_changed", () => {
      updateCenterPin();
    });

    const zoomChangeListener = map.addListener("zoom_changed", () => {
      updateCenterPin();
    });

    return () => {
      centerChangeListener?.remove();
      zoomChangeListener?.remove();
      radiusCircle.setMap(null);
      centerPin.setMap(null);
    };
  }, [locationFilter, map, radiusInMiles, enableDebugging]);
}
