// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { mergeSxProps } from "@clipboard-health/ui-react";
import { CardActionArea, CardContent, Stack, type SxProps, type Theme } from "@mui/material";
import { type HcpRequirement } from "@src/appV2/Accounts/Documents/types";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { isShiftPriorityValid } from "@src/appV2/lib/utils/isShiftPriorityValid";
import { USER_EVENTS } from "@src/constants";
import { differenceInMinutes, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { isShiftInstantReview } from "../Bookability/Documents/InstantReview/isShiftInstantReview";
import { ShiftCardDateTimeInfo } from "../Card/DateTimeInfo";
import { ShiftCardFacilityInfo } from "../Card/FacilityInfo";
import { ShiftCardPayInfoCard } from "../Card/PayInfoCard";
import { ShiftCardWrapper } from "../Card/Wrapper";
import { ShiftQualificationPill } from "../QualificationPill";
import { resolveShiftType } from "../resolveShiftType";
import { OpenShiftCardPills } from "./Pills";
import type { Offer, OpenShift, OpenShiftWorkplace } from "./types";
import { useCreateOffer } from "./useCreateOffer";

interface OpenShiftCardProps {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  missingDocuments: HcpRequirement[];
  pendingDocuments: HcpRequirement[];
  sx?: SxProps<Theme>;
}

export function OpenShiftCard(props: OpenShiftCardProps) {
  const { shift, workplace, onClick, missingDocuments, pendingDocuments, sx } = props;
  const { timeSlot, start, end, qualification, window, durationInHours, priorityTill, isUrgent } =
    shift.attributes;

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };
  const shiftType = resolveShiftType({ isUrgent, window, priorityTill });

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  const { mutate: createOffer, isLoading } = useCreateOffer();
  const [offer, setOffer] = useState<Offer | undefined>(undefined);

  useEffect(() => {
    if (!offer) {
      createOffer(
        { shiftId: shift.id },
        {
          onSuccess: (data) => {
            setOffer(data.data);
          },
        }
      );
    }
  }, [offer, createOffer, shift.id]);

  const { ref } = useInView({
    rootMargin: "0px",
    threshold: 1,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        logEvent(USER_EVENTS.OPEN_SHIFT_VIEWED, {
          start,
          end,
          facility_id: shift.relationships.workplace.data.id,
          shift_id: shift.id,
          hourly_pay: offer ? offer.attributes.pay.amountInMinorUnits / 100 : 0,
          agent_req: qualification,
          shift_type: shiftType,
          has_missing_documents: missingDocuments.length > 0,
          isPriorityAccessShift: priorityTill ? isShiftPriorityValid(priorityTill) : false,
          window,
          priorityAccessTimeRemaining: priorityTill
            ? differenceInMinutes(Date.now(), parseISO(priorityTill))
            : 0,
          shiftOfferId: offer ? offer.id : undefined,
          is_instant_review: isShiftInstantReview({
            isHelloSignEmbeddedFlowEnabled,
            missingDocuments,
          }),
          missing_documents: missingDocuments.map((document) => document.reqId),
        });
      }
    },
  });

  return (
    <ShiftCardWrapper
      shiftType={shiftType}
      sx={mergeSxProps({ display: "flex" }, sx)}
      data-testid={`shift-card-${shift.id}`}
    >
      <CardActionArea ref={ref} sx={{ flexGrow: 1 }} onClick={onClick}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 5,
            height: "100%",
          }}
        >
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={5}>
            <Stack spacing={5}>
              <OpenShiftCardPills
                shift={shift}
                shiftType={shiftType}
                missingDocuments={missingDocuments}
                pendingDocuments={pendingDocuments}
              />
              <ShiftCardDateTimeInfo
                dateRange={dateRange}
                timeSlot={timeSlot}
                durationInHours={durationInHours}
                timezone={workplace.attributes.location.timezone}
              />
            </Stack>

            <ShiftQualificationPill qualificationName={qualification} />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <ShiftCardFacilityInfo workplace={workplace} />

            {offer ? (
              <ShiftCardPayInfoCard
                offerPay={offer.attributes.pay.amountInMinorUnits / 100}
                finalPay={offer.attributes.pay.amountInMinorUnits / 100}
                originalAmount={durationInHours * (offer.attributes.pay.amountInMinorUnits / 100)}
              />
            ) : (
              <ShiftCardPayInfoCard isLoading={isLoading} />
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </ShiftCardWrapper>
  );
}
