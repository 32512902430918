import { isDefined } from "@clipboard-health/util-ts";
import { FormControl, FormHelperText } from "@mui/material";
import {
  type Control,
  type ControllerRenderProps,
  type FieldPath,
  useController,
} from "react-hook-form";

import { type CreateCandidateResumeSchema } from "../types";

interface CandidateResumeFormFieldProps {
  name: FieldPath<CreateCandidateResumeSchema>;
  control: Control<CreateCandidateResumeSchema>;
  render: (
    field: ControllerRenderProps<
      CreateCandidateResumeSchema,
      FieldPath<CreateCandidateResumeSchema>
    >
  ) => React.ReactNode;
}

export function CandidateResumeFormField(props: CandidateResumeFormFieldProps) {
  const { name, control, render } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl>
      {render(field)}
      <FormHelperText
        hidden={!error?.message}
        sx={{
          height: "1rem",
          marginLeft: 0,
        }}
        error={isDefined(error)}
      >
        {error?.message}
      </FormHelperText>
    </FormControl>
  );
}
