import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { WorkplaceShiftRateNegotiability } from "@src/appV2/OpenShifts/RateNegotiation/types";
import { BookabilityDecision, type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { DialogFooter } from "../../components/DialogFooter";
import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { isShiftRateNegotiationEnabled } from "../RateNegotiation/isShiftRateNegotiationEnabled";
import { useShiftRateNegotiationConditions } from "../RateNegotiation/useShiftRateNegotiationConditions";
import { type ShiftWithType } from "../types";
import { BookShiftButton } from "./BookShiftButton";
import { RateNegotiationAction } from "./RateNegotiationAction";
import { ShiftNotBookableButton } from "./ShiftNotBookableButton";

interface ShiftBottomSheetSheetFooterProps {
  isLoading: boolean;
  shift?: ShiftWithType;
  hasBlockingDocuments: boolean;
  modalState: UseModalState;
  bookabilityStatus: BookabilityStatusItem;
}

export function ShiftBottomSheetFooter(props: ShiftBottomSheetSheetFooterProps) {
  const { shift, modalState, bookabilityStatus, isLoading, hasBlockingDocuments } = props;
  const { _id: shiftId } = shift ?? {};

  const { navigateToModal } = useShiftModalsDataContext();

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;

  const rateNegotiationConditions = useShiftRateNegotiationConditions();
  const isRateNegotiationEnabled =
    isDefined(shift) &&
    isShiftRateNegotiationEnabled(
      {
        shiftStart: shift.start,
        workplaceShiftRateNegotiability:
          shift.facility.rateNegotiation ?? WorkplaceShiftRateNegotiability.OFF,
        workplaceId: shift.facility.userId,
        workplaceState: shift.facility.fullAddress?.state,
        workplaceMsa: shift.facility.fullAddress?.metropolitanStatisticalArea,
        isShiftChargeUpdatedManually: shift.isChargeUpdatedManually,
      },
      rateNegotiationConditions
    );

  return (
    <DialogFooter
      orientation="vertical"
      onClose={() => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shiftId ?? "" });
        modalState.closeModal();
      }}
    >
      {(!isBookable || !isDefined(shift)) && (
        <ShiftNotBookableButton
          isLoading={isLoading}
          bookabilityStatus={bookabilityStatus}
          hasBlockingDocuments={hasBlockingDocuments}
          shift={shift}
          onClose={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_UNAVAILABLE_CLOSED, {
              shiftId: shiftId ?? "",
            });
            modalState.closeModal();
          }}
        />
      )}

      {isDefined(shift) && isBookable && (
        <>
          <BookShiftButton
            shift={shift}
            onBook={() => {
              navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, {
                shiftId: String(shiftId),
              });
            }}
          />

          {isRateNegotiationEnabled && (
            <RateNegotiationAction
              rateNegotiation={shift.rateNegotiation}
              shiftId={String(shiftId)}
            />
          )}
        </>
      )}
    </DialogFooter>
  );
}
