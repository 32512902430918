import { isDefined } from "@clipboard-health/util-ts";
import { groupBy } from "lodash";

import { type OpenShift } from "../../Shift/Open/types";
import { type Workplace } from "../../Workplace/types";
import { type MapViewWorkplace } from "./types";

export function getMapViewWorkplaces(
  workplaces: Workplace[],
  openShifts: OpenShift[],
  openShiftWorkplacesMap = new Map<string, Workplace>()
) {
  const mapViewWorkplacesMap = new Map<string, MapViewWorkplace>();
  const openShiftsByWorkplaceId = groupBy(openShifts, "relationships.workplace.data.id");

  function setMapViewWorkplace(workplace: Workplace) {
    const { id: workplaceId, attributes } = workplace;
    const { location } = attributes;

    // Can't add workplaces that don't have a location to the map.
    if (!isDefined(location) || (location.latitude === 0 && location.longitude === 0)) {
      return;
    }

    const workplaceOpenShifts = openShiftsByWorkplaceId[workplaceId];

    mapViewWorkplacesMap.set(workplaceId, {
      ...workplace,
      shiftsCount: workplaceOpenShifts?.length ?? 0,
    });
  }

  workplaces.forEach((workplace) => {
    setMapViewWorkplace(workplace);
  });

  // There might be workplaces coming from open shifts that are not included in the workplaces list.
  // eslint-disable-next-line unicorn/prefer-spread
  const additionalOpenShiftWorkplaces = Array.from(openShiftWorkplacesMap.values()).filter(
    (workplace) => !mapViewWorkplacesMap.has(workplace.id)
  );

  additionalOpenShiftWorkplaces.forEach((workplace) => {
    setMapViewWorkplace(workplace);
  });

  // Can't use spread operator because our tsconfig targets ES5 and it doesn't support it.
  // TODO: Update tsconfig to target ES2015 or higher src/appV2/tsconfig.json
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(mapViewWorkplacesMap.values());
}
