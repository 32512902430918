import { Text } from "@clipboard-health/ui-react";
import { Link, Stack } from "@mui/material";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";
import { VideoRecordingIcon } from "../VideoRecordingIcon";

interface Props {
  assessment: SkillsAssessment;
  qualification?: string;
}
export function SkillsAssessmentVideoStartFromMyAccountAsyncStep(props: Props) {
  const { assessment, qualification } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4}>
      <Stack alignItems="center">
        <VideoRecordingIcon sx={{ fontSize: "64px" }} />
      </Stack>

      <Text sx={{ lineHeight: "24px" }}>
        To ensure that the best {qualification ?? "professional"}s have access to shifts,
        you&apos;ll need to complete the video assessment below, where you&apos;ll answer a few
        questions about your {qualification ?? "professional"} experience.
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        The assessment includes {assessment.attributes.videoAsk.numberOfQuestions} questions and
        should take about {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes
        to finish. Additional details can be found{" "}
        <Link
          onClick={() => {
            void openBrowser(skillsAssessmentConfig.supportArticleLink);
          }}
        >
          here
        </Link>
        .
      </Text>

      <Text sx={{ lineHeight: "24px" }}>
        Remember, the quality of your responses and your level of professionalism will impact your
        access to shifts—so treat this like an interview!
      </Text>
    </Stack>
  );
}
