import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import {
  HELLO_SIGN_SESSION_RESOURCE,
  useCreateHelloSignSession,
  useUpdateHelloSignSession,
} from "@src/appV2/Accounts/DocumentDetails/api/hellosign";
import { HelloSignErrorReason } from "@src/appV2/Accounts/DocumentDetails/hooks/hellosign/constants";
import { Resources } from "@src/appV2/Accounts/Documents/resources/constants";
import {
  HelloSignErrorCodes,
  HelloSignWorkflowSessionStatus,
} from "@src/appV2/Accounts/Documents/resources/hellosign/constants";
import { helloSignClient } from "@src/appV2/HelloSign/helloSignClient";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { isAxiosError } from "axios";
import { useCallback, useRef, useState } from "react";

import { useHelloSignClientEvents } from "./useHelloSignClientEvents";
import { useHelloSignHandleSuccessOrError } from "./useHelloSignSuccessOrError";

interface UseHelloSignEmbeddedFlowProps {
  requirementId: string;
  // We need a container as it doesn't automatically handle safe areas on phones
  helloSignIframeContainerRef: React.RefObject<HTMLDivElement>;
  onHelloSignClientCancel: () => void;
  onSuccess: (isDocumentApproved: boolean) => void;
  onError: () => void;
}

export function useHelloSignEmbeddedFlow(props: UseHelloSignEmbeddedFlowProps) {
  const {
    requirementId,
    helloSignIframeContainerRef,
    onHelloSignClientCancel,
    onSuccess,
    onError,
  } = props;

  const [helloSignSessionId, setHelloSignSessionId] = useState<string | undefined>(undefined);
  const helloSignOpenStartTime = useRef<number | undefined>(undefined);

  const worker = useDefinedWorker();
  const { mutateAsync: createHelloSignSession, isLoading: isCreatingHelloSignSession } =
    useCreateHelloSignSession({
      requirementId,
      workerId: worker.userId,
    });

  const {
    mutateAsync: updateHelloSignSessionToSigned,
    isLoading: isUpdatingHelloSignSessionToSigned,
  } = useUpdateHelloSignSession({
    helloSignSessionId,
    requirementId,
    workerId: worker.userId,
    include: Resources.HCP_DOCUMENTS,
  });

  const { mutateAsync: updateHelloSignSessionToErrored } = useUpdateHelloSignSession({
    helloSignSessionId,
    requirementId,
    workerId: worker.userId,
  });

  const { handleHelloSignSuccess, handleHelloSignError } = useHelloSignHandleSuccessOrError({
    requirementId,
    onSuccess,
    onError,
  });

  const startHelloSignEmbeddedFlow = useCallback(async () => {
    const logMetadata: { helloSignSessionId?: string } = {};

    try {
      if (isNullOrUndefined(requirementId)) {
        throw new Error("Missing requirementId");
      }

      const session = await createHelloSignSession({
        data: {
          type: HELLO_SIGN_SESSION_RESOURCE,
        },
      });

      logMetadata.helloSignSessionId = session.data.id;
      setHelloSignSessionId(session.data.id);

      if (isNullOrUndefined(session.data.attributes.signUrl)) {
        throw new Error("Missing signUrl");
      }

      const { signUrl } = session.data.attributes;
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_SESSION_STARTED, {
        requirementId,
        signUrl,
        helloSignSessionId: session.data.id,
      });
      helloSignOpenStartTime.current = Date.now();
      helloSignClient.open(signUrl, {
        skipDomainVerification: true,
        container: isDefined(helloSignIframeContainerRef.current)
          ? helloSignIframeContainerRef.current
          : undefined,
      });
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        handleHelloSignError(HelloSignErrorReason.ALREADY_SIGNED, logMetadata.helloSignSessionId);
      } else {
        handleHelloSignError(HelloSignErrorReason.BACKEND_ERROR, logMetadata.helloSignSessionId);
      }
    }
  }, [requirementId, createHelloSignSession, helloSignIframeContainerRef, handleHelloSignError]);

  useHelloSignClientEvents({
    onSign: async () => {
      let attempts = 0;
      const maxAttempts = 3;
      const delayTime = 1000;
      const delay = async (ms: number) =>
        await new Promise((resolve) => {
          setTimeout(resolve, ms);
        });

      /*
       * We want to retry this request a few times, as its possible the backend
       * will be having a write conflict with the running webhook which is currently
       * processing the resource
       */
      while (attempts < maxAttempts) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const helloSignSession = await updateHelloSignSessionToSigned({
            status: HelloSignWorkflowSessionStatus.SIGNED,
          });
          handleHelloSignSuccess(helloSignSession);
          return;
        } catch {
          attempts += 1;
          if (attempts < maxAttempts) {
            // eslint-disable-next-line no-await-in-loop
            await delay(delayTime);
          } else {
            handleHelloSignError(HelloSignErrorReason.BACKEND_ERROR);
          }
        }
      }
    },
    onError: async (errorCode) => {
      if (errorCode !== HelloSignErrorCodes.NOTHING_TO_SIGN) {
        await updateHelloSignSessionToErrored({
          status: HelloSignWorkflowSessionStatus.ERRORED,
          errorMessage: errorCode,
        });
        handleHelloSignError(HelloSignErrorReason.BACKEND_ERROR);
      }
    },
    onCancel: () => {
      onHelloSignClientCancel();
    },
    requirementId,
    helloSignSessionId,
    helloSignOpenStartTime: helloSignOpenStartTime.current,
  });

  return {
    isCreatingHelloSignSession,
    isLoadingHelloSignDocument: isUpdatingHelloSignSessionToSigned,
    startHelloSignEmbeddedFlow,
  };
}
