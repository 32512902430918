import { isCapacitorPlatform } from "@src/appV2/lib";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location/deviceLocation";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";
import { convertToGeoLocation, convertToGoogleMapsLocation } from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

export function useWorkerLocations() {
  const worker = useDefinedWorker();

  const { data: isDeviceLocationPermissionGranted } = useIsDeviceLocationPermissionGranted();
  const { data: deviceGeoLocation } = useDeviceGeoLocationIfAllowed({
    enabled: !!isDeviceLocationPermissionGranted,
  });

  const shouldRequestLocationPermission =
    !deviceGeoLocation && !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  const workerCoordinates = worker.geoLocation?.coordinates;
  const homeLocation = workerCoordinates
    ? convertToGoogleMapsLocation(convertToGeoLocation(workerCoordinates))
    : undefined;

  const deviceLocation = deviceGeoLocation?.geoLocation
    ? convertToGoogleMapsLocation(deviceGeoLocation.geoLocation)
    : undefined;

  const workerLocation = deviceLocation ?? homeLocation;

  return {
    workerLocation,
    deviceLocation,
    homeLocation,
    shouldRequestLocationPermission,
  };
}
