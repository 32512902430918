import type { UseGetQueryOptions } from "@src/appV2/api";
import type { DateRange } from "@src/appV2/lib/Calendar/types";
import { convertToGeoLocation, type GeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import type { QueryClient } from "@tanstack/react-query";
import { endOfDay, parseISO, startOfDay } from "date-fns";
import { useMemo, useState } from "react";

import { getIncludedOpenShiftsData } from "../../Shift/Open/getIncludedOpenShiftsData";
import {
  type GetOpenShiftsResponse,
  invalidateGetOpenShifts,
  useGetOpenShifts,
} from "../../Shift/Open/useGetOpenShifts";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { invalidateGetWorkplaces, useGetWorkplaces } from "../../Workplace/useGetWorkplaces";
import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { getMapViewWorkplaces } from "./getMapViewWorkplaces";
import { sortMapViewWorkplaces } from "./sortMapViewWorkplaces";

interface UseMapWorkplacesDataProps {
  geoLocation?: GeoLocation;
}

export async function invalidateMapViewWorkplacesData(queryClient: QueryClient): Promise<void> {
  await Promise.all([invalidateGetOpenShifts(queryClient), invalidateGetWorkplaces(queryClient)]);
}

export function useMapViewWorkplacesData(
  props: UseMapWorkplacesDataProps,
  options: UseGetQueryOptions<GetOpenShiftsResponse> = {}
) {
  const { geoLocation } = props;
  const {
    distance,
    licenses,
    dates: dateStrings,
    shiftBookingTypes,
    shiftTimeSlots,
  } = useShiftDiscoveryUserFiltersContext();

  const worker = useDefinedWorker();
  const coordinates =
    geoLocation ?? convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const [dateRange, setDateRange] = useState<DateRange>(getShiftDiscoveryDefaultDateRange());
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const dateFilters =
    dates.length > 0
      ? dates.map((date) => ({
          gte: startOfDay(date).toISOString(),
          lte: endOfDay(date).toISOString(),
        }))
      : [{ gte: dateRange.startDate.toISOString(), lte: dateRange.endDate.toISOString() }];

  const areQueriesEnabled =
    coordinates.latitude !== 0 && coordinates.longitude !== 0 && (options?.enabled ?? true);

  const {
    data: openShiftsData,
    isLoading: isLoadingOpenShiftsData,
    isError: isErrorOpenShiftsData,
    isSuccess: isSuccessOpenShiftsData,
  } = useGetOpenShifts(
    {
      filter: {
        start: dateFilters,
        area: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radiusInMiles: distance,
        },
      },
      include: ["workplace"],
    },
    {
      ...options,
      enabled: areQueriesEnabled,
      refetchOnMount: "always",
    }
  );

  const {
    data: workplacesData,
    isLoading: isLoadingWorkplaces,
    isError: isErrorWorkplaces,
    isSuccess: isSuccessWorkplaces,
  } = useGetWorkplaces(
    {
      filter: {
        area: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radiusInMiles: distance,
        },
      },
    },
    { enabled: areQueriesEnabled, refetchOnMount: "always" }
  );

  const sortedMapViewWorkplaces = useMemo(() => {
    const { workplacesMap: openShiftsWorkplacesMap } = getIncludedOpenShiftsData(openShiftsData);
    const filteredOpenShifts = filterOpenShifts(
      { shifts: openShiftsData?.data ?? [] },
      { dates: dateStrings, licenses, shiftBookingTypes, shiftTimeSlots }
    );

    const mapViewWorkplaces = getMapViewWorkplaces(
      workplacesData?.data ?? [],
      filteredOpenShifts,
      openShiftsWorkplacesMap
    );

    return sortMapViewWorkplaces(mapViewWorkplaces);
  }, [
    dateStrings,
    licenses,
    openShiftsData,
    shiftBookingTypes,
    shiftTimeSlots,
    workplacesData?.data,
  ]);

  return {
    data: { sortedMapViewWorkplaces, unfilteredOpenShifts: openShiftsData?.data ?? [] },
    isLoading: isLoadingOpenShiftsData || isLoadingWorkplaces,
    isError: isErrorOpenShiftsData || isErrorWorkplaces,
    isSuccess: isSuccessOpenShiftsData && isSuccessWorkplaces,
    dateRange,
    setDateRange,
    distanceFilter: distance,
  };
}
