import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { isMandatoryBreakPolicyNote } from "@src/appV2/Facilities/types";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  useDeviceGeoLocationIfAllowed,
  useIsDeviceLocationPermissionGranted,
} from "@src/appV2/Location/deviceLocation/geoLocation";
import { useGetPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useGetPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";

import { LoadingButton } from "../../components/LoadingButton";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
} from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useBookShift } from "../Booking/useBookShift";
import { type ShiftWithType } from "../types";

interface BookShiftButtonProps {
  shift: ShiftWithType;
  onBook: () => void;
}

// TODO: Following features need to be ported: Rate negotiation, request non-IP shift, conflicting shift invites
export function BookShiftButton({ shift, onBook }: BookShiftButtonProps) {
  const { attemptBookingShift, isBooking } = useBookShift({ shift, onBook });

  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  // if the query is disabled, 'isLoading' is true until the query runs and we have data
  // this can cause an infinite spinner if location permissions are not given, as
  // useDeviceGeoLocationIfAllowed will always be isLoading === true. Therefore,
  // we need to use isInitialLoading instead
  // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading

  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const { navigateToModal } = useShiftModalsDataContext();
  const { data: facilityNotesData, isInitialLoading: isFacilityNotesLoading } = useFacilityNotes(
    shift.facilityId ?? "",
    { enabled: isDefined(shift.facilityId) }
  );
  const { noteId: breakPolicyNoteId } =
    facilityNotesData?.find((note) => isMandatoryBreakPolicyNote(note)) ?? {};

  const { data: policyAcknowledgementData, isInitialLoading: isPolicyAcknowledgementLoading } =
    useGetPolicyAcknowledgement(
      {
        noteId: breakPolicyNoteId ?? "",
        policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
      },
      { enabled: isDefined(breakPolicyNoteId) }
    );

  const requiresBreakPolicyAcknowledgement =
    policyAcknowledgementData?.data.length === 0 && isDefined(breakPolicyNoteId);

  const isLoading =
    isFacilityNotesLoading ||
    isPolicyAcknowledgementLoading ||
    isDeviceLocationPermissionGrantedLoading ||
    isDeviceGeolocationLoading;

  return (
    <LoadingButton
      fullWidth
      size="large"
      variant="contained"
      isLoading={isLoading || isBooking}
      onClick={async () => {
        if (geolocationIsUnavailable) {
          navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
          return;
        }

        if (requiresBreakPolicyAcknowledgement) {
          navigateToModal(SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH, {
            shiftId: shift._id,
            noteId: breakPolicyNoteId,
          });
          return;
        }

        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_BOOK_SHIFT, {
          shiftId: shift.shiftId ?? shift._id,
        });

        await attemptBookingShift(deviceGeolocation?.geoLocation);
      }}
    >
      Book this shift
    </LoadingButton>
  );
}
