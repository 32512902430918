/* eslint-disable max-lines */
import { type PaletteOptions } from "@mui/material";
/**
 * TODO: This file needs to be reorganized.
 * Rather than having consts like TEXT_PRIMARY_COLOR, we should have a base type for color palette of shape:
 *
 * interface Palette {
 *   text: {
 *     primary: string;
 *   }
 * }
 *
 * And then we should have separated light/dark files where we will manually assign these.
 *
 * Then we could create the theme with component overrides like this:
 *
 * const darkTheme = createTheme({
 *   palette: {
 *     mode: 'dark',
 *     ...darkPalette,
 *   },
 *   components: {
 *     MuiButton: {
 *       styleOverrides: {
 *         root: ({ theme }) => ({
 *           color: theme.palette.custom.textPrimary,
 *           backgroundColor: theme.palette.primary.main,
 *           '&:hover': {
 *             backgroundColor: theme.palette.primary.dark,
 *           },
 *         }),
 *       },
 *     },
 *   },
 * });
 *
 * !! We should start using functions when we need to access the theme in the styleOverrides !!
 */

/**
 * !! DO NOT IMPORT THESE COLORS DIRECTLY !!
 * Always use MUI Theme for colors and if any of these is not in the palette, add it
 */
export const WARM_GREY_00_COLOR = "#FFFFFF";
export const WARM_GREY_01_COLOR = "#F6F3F1";
export const WARM_GREY_02_COLOR = "#F1EDEA";
export const WARM_GREY_03_COLOR = "#E6E2DD";
export const WARM_GREY_04_COLOR = "#D5D1CE";
export const WARM_GREY_05_COLOR = "#B0AEAC";
export const WARM_GREY_06_COLOR = "#8B8987";
export const WARM_GREY_07_COLOR = "#3D414B";

export const COOL_GREY_01_COLOR = "#E9ECF2";
export const COOL_GREY_02_COLOR = "#D7DEE8";
export const COOL_GREY_03_COLOR = "#B4BECD";
export const COOL_GREY_04_COLOR = "#9CA9BD";
export const COOL_GREY_05_COLOR = "#7689A6";
export const COOL_GREY_06_COLOR = "#3B4C65";
export const COOL_GREY_07_COLOR = "#2F3C50";

export const BLUE_01_COLOR = "#E8F4FF";
export const BLUE_02_COLOR = "#CCE7FF";
export const BLUE_03_COLOR = "#B7DDFF";
export const BLUE_04_COLOR = "#98CCFB";
export const BLUE_05_COLOR = "#83AFEC";
export const BLUE_06_COLOR = "#0D2B57";
export const BLUE_07_COLOR = "#0C1C43";

export const PURPLE_01_COLOR = "#ECEBFF";
export const PURPLE_02_COLOR = "#D7D4FF";
export const PURPLE_03_COLOR = "#BDB8FE";
export const PURPLE_04_COLOR = "#9890FF";
export const PURPLE_05_COLOR = "#7269F0";
export const PURPLE_06_COLOR = "#5851C6";
export const PURPLE_07_COLOR = "#372FAA";

export const TEAL_01_COLOR = "#E1F5F6";
export const TEAL_02_COLOR = "#C9F2F4";
export const TEAL_03_COLOR = "#AEEDF1";
export const TEAL_04_COLOR = "#75D1D6";
export const TEAL_05_COLOR = "#45C1C8";
export const TEAL_06_COLOR = "#03929A";
export const TEAL_07_COLOR = "#004345";

export const GREEN_01_COLOR = "#E0FBEF";
export const GREEN_02_COLOR = "#C0F6D4";
export const GREEN_03_COLOR = "#ADF3C7";
export const GREEN_04_COLOR = "#75DEAF";
export const GREEN_05_COLOR = "#43C390";
export const GREEN_06_COLOR = "#169563";
export const GREEN_07_COLOR = "#094534";

export const YELLOW_01_COLOR = "#FFFBF6";
export const YELLOW_02_COLOR = "#FFF3E6";
export const YELLOW_03_COLOR = "#FFE7BC";
export const YELLOW_04_COLOR = "#FACE97";
export const YELLOW_05_COLOR = "#FFC884";
export const YELLOW_06_COLOR = "#BD6801";
export const YELLOW_07_COLOR = "#764100";

export const RED_01_COLOR = "#FCF2F0";
export const RED_02_COLOR = "#F9E1E1";
export const RED_03_COLOR = "#F7D0D0";
export const RED_04_COLOR = "#FCBDC1";
export const RED_05_COLOR = "#DB646A";
export const RED_06_COLOR = "#B01921";
export const RED_07_COLOR = "#82090F";

export const PRIMARY_COLOR = BLUE_07_COLOR;
export const PRIMARY_DARK_COLOR = BLUE_06_COLOR;
export const SECONDARY_COLOR = WARM_GREY_01_COLOR;

export const DISABLED_PRIMARY_COLOR = WARM_GREY_06_COLOR;
export const DISABLED_SECONDARY_COLOR = WARM_GREY_02_COLOR;
export const DISABLED_TERTIARY_COLOR = WARM_GREY_01_COLOR;

export const BACKGROUND_PRIMARY_COLOR = WARM_GREY_01_COLOR;
export const BACKGROUND_SECONDARY_COLOR = WARM_GREY_02_COLOR;
export const BACKGROUND_TERTIARY_COLOR = WARM_GREY_00_COLOR;
export const BACKGROUND_INVERT_COLOR = PRIMARY_COLOR;

export const TEXT_PRIMARY_COLOR = PRIMARY_COLOR;
export const TEXT_SECONDARY_COLOR = WARM_GREY_07_COLOR;
export const TEXT_TERTIARY_COLOR = WARM_GREY_06_COLOR;
export const TEXT_DISABLED_COLOR = WARM_GREY_05_COLOR;
export const TEXT_INVERT_PRIMARY_COLOR = WARM_GREY_00_COLOR;
export const TEXT_INVERT_SECONDARY_COLOR = COOL_GREY_03_COLOR;
export const TEXT_INVERT_TERTIARY_COLOR = COOL_GREY_04_COLOR;
export const TEXT_DESTRUCTIVE_COLOR = RED_07_COLOR;
export const TEXT_INVERT_DESTRUCTIVE_COLOR = RED_05_COLOR;

export const ICON_PRIMARY_COLOR = PRIMARY_COLOR;
export const ICON_DISABLED_COLOR = WARM_GREY_06_COLOR;
export const ICON_DESTRUCTIVE_COLOR = RED_07_COLOR;
export const ICON_INVERT_LIGHT_COLOR = WARM_GREY_00_COLOR;
export const ICON_INVERT_DARK_COLOR = COOL_GREY_03_COLOR;

export const SURFACE_PRIMARY_COLOR = PRIMARY_COLOR;
export const SURFACE_SECONDARY_COLOR = WARM_GREY_00_COLOR;
export const SURFACE_SHADED_COLOR = WARM_GREY_01_COLOR;
export const SURFACE_SHADED_DARK_COLOR = WARM_GREY_03_COLOR;
export const SURFACE_INVERT_COLOR = WARM_GREY_00_COLOR;
export const SURFACE_NEUTRAL_LIGHT_COLOR = BLUE_02_COLOR;
export const SURFACE_NEUTRAL_DARK_COLOR = BLUE_03_COLOR;

export const BORDER_PRIMARY_COLOR = WARM_GREY_04_COLOR;
export const BORDER_SUBTLE_COLOR = WARM_GREY_01_COLOR;
export const BORDER_DARK_COLOR = WARM_GREY_05_COLOR;
export const BORDER_PRIMARY_INVERT_COLOR = COOL_GREY_06_COLOR;
export const BORDER_SUBTLE_INVERT_COLOR = COOL_GREY_07_COLOR;
export const BORDER_DARK_INVERT_COLOR = COOL_GREY_03_COLOR;
export const BORDER_DESTRUCTIVE_COLOR = RED_05_COLOR;

export const NEUTRAL_CALLOUT_LABEL_LIGHT_COLOR = SURFACE_NEUTRAL_LIGHT_COLOR;
export const NEUTRAL_CALLOUT_LABEL_DARK_COLOR = SURFACE_NEUTRAL_DARK_COLOR;
export const NEUTRAL_CALLOUT_BORDER_COLOR = BLUE_04_COLOR;
export const NEUTRAL_CALLOUT_TEXT_COLOR = BLUE_06_COLOR;
export const NEUTRAL_CALLOUT_ICON_COLOR = BLUE_06_COLOR;

export const SUCCESS_CALLOUT_LABEL_LIGHT_COLOR = GREEN_01_COLOR;
export const SUCCESS_CALLOUT_LABEL_DARK_COLOR = GREEN_03_COLOR;
export const SUCCESS_CALLOUT_BORDER_COLOR = GREEN_04_COLOR;
export const SUCCESS_CALLOUT_TEXT_COLOR = GREEN_07_COLOR;
export const SUCCESS_CALLOUT_ICON_LIGHT_COLOR = GREEN_06_COLOR;
export const SUCCESS_CALLOUT_ICON_DARK_COLOR = GREEN_07_COLOR;

export const WARNING_CALLOUT_LABEL_LIGHT_COLOR = YELLOW_04_COLOR;
export const WARNING_CALLOUT_LABEL_DARK_COLOR = YELLOW_06_COLOR;
export const WARNING_CALLOUT_BORDER_COLOR = YELLOW_06_COLOR;
export const WARNING_CALLOUT_TEXT_COLOR = YELLOW_07_COLOR;
export const WARNING_CALLOUT_ICON_COLOR = YELLOW_07_COLOR;

export const ERROR_CALLOUT_LABEL_COLOR = RED_03_COLOR;
export const ERROR_CALLOUT_BORDER_COLOR = RED_05_COLOR;
export const ERROR_CALLOUT_TEXT_COLOR = RED_07_COLOR;
export const ERROR_CALLOUT_ICON_COLOR = RED_06_COLOR;

export const SHIFT_GENERAL_COLOR = BLUE_03_COLOR;
export const SHIFT_DAY_COLOR = BLUE_02_COLOR;
export const SHIFT_EVENING_COLOR = BLUE_04_COLOR;
export const SHIFT_OVERNIGHT_COLOR = BLUE_05_COLOR;

export const SHIFT_PRIORITY_LABEL_LIGHT_COLOR = PURPLE_02_COLOR;
export const SHIFT_PRIORITY_LABEL_DARK_COLOR = PURPLE_07_COLOR;
export const SHIFT_PRIORITY_BORDER_COLOR = PURPLE_02_COLOR;
export const SHIFT_PRIORITY_TEXT_COLOR = PURPLE_07_COLOR;
export const SHIFT_PRIORITY_ICON_COLOR = PURPLE_07_COLOR;

export const SHIFT_URGENT_LABEL_COLOR = YELLOW_04_COLOR;
export const SHIFT_URGENT_BORDER_COLOR = YELLOW_06_COLOR;
export const SHIFT_URGENT_ICON_COLOR = YELLOW_07_COLOR;
export const SHIFT_URGENT_TEXT_COLOR = YELLOW_07_COLOR;

export const SHIFT_BLOCK_LABEL_COLOR = TEAL_03_COLOR;
export const SHIFT_BLOCK_BORDER_COLOR = TEAL_06_COLOR;
export const SHIFT_BLOCK_ICON_COLOR = TEAL_07_COLOR;
export const SHIFT_BLOCK_TEXT_COLOR = TEAL_07_COLOR;

export const SHIFT_GENERAL_GRADIENT = `linear-gradient(180deg, ${BLUE_03_COLOR} 19.5%, ${WARM_GREY_01_COLOR} 100%)`;
export const SHIFT_DAY_GRADIENT = `linear-gradient(174.67deg, #D3EAFF 5.15%, ${WARM_GREY_01_COLOR} 95.73%)`;
export const SHIFT_EVENING_GRADIENT = `linear-gradient(180deg, ${BLUE_04_COLOR} 19.5%, #F7F0EA 100%)`;
export const SHIFT_OVERNIGHT_GRADIENT = `linear-gradient(171.85deg, ${BLUE_05_COLOR} 20.55%, ${WARM_GREY_01_COLOR} 89.4%)`;
export const SHIFT_BLOCK_GRADIENT = `linear-gradient(180deg, ${TEAL_03_COLOR} -5.14%, ${WARM_GREY_01_COLOR} 41.79%)`;
export const SHIFT_PRIORITY_GRADIENT = `linear-gradient(349.66deg, ${WARM_GREY_01_COLOR} 53.34%, ${PURPLE_03_COLOR} 93.29%)`;
export const SHIFT_URGENT_GRADIENT = "linear-gradient(166.67deg, #FFE3C3 5.67%, #F9F5F2 49.8%)";

export const UNREAD_CHAT_NOTIFICATION_BADGE_COLOR = BLUE_05_COLOR;

export const SPINNER_PRIMARY_COLOR = BLUE_06_COLOR;
export const SPINNER_SECONDARY_COLOR = BLUE_03_COLOR;
export const SPINNER_INVERT_COLOR = COOL_GREY_03_COLOR;
export const SPINNER_DESTRUCTIVE_COLOR = RED_05_COLOR;

export const generateSpinnerGradient = (hexColor: string) =>
  `conic-gradient(from 90deg at 50% 50%, ${hexColor}00 0deg, ${hexColor}0A 0.04deg, ${hexColor}FF 360deg)`;

declare module "@mui/material/styles" {
  // So far these are optional as module augmentation will affect the old theme and I want to avoid confusions
  interface CustomPalette {
    surface?: {
      primary: string;
      secondary: string;
      shaded: string;
      shadedDark: string;
      invert: string;
      neutralLight: string;
      neutralDark: string;
    };
    disabled?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
    };
    border?: {
      primary?: string;
      dark?: string;
      subtle?: string;
      primaryInvert?: string;
      subtleInvert?: string;
      darkInvert?: string;
      destructive?: string;
      hidden?: string;
    };
    icon?: {
      primary?: string;
      disabled?: string;
      destructive?: string;
      lightInvert?: string;
      darkInvert?: string;
    };
    callout?: {
      neutral: {
        labelLight: string;
        labelDark: string;
        border: string;
        text: string;
        icon: string;
      };
      success: {
        labelLight: string;
        labelDark: string;
        border: string;
        text: string;
        iconLight: string;
        iconDark: string;
      };
      warning: {
        labelLight: string;
        labelDark: string;
        border: string;
        text: string;
        icon: string;
      };
      error: {
        label: string;
        border: string;
        text: string;
        icon: string;
      };
    };
    instantPay?: {
      surface: string;
      text: string;
    };
    // This is intentionally exported separately so in the future we have the possibility to split base design system from hcp app.
    // If we were to continue using this design system for other apps, we could easily maintain a global one with generic colors
    // and only export HCP and shifts specific stuff separately.
    shifts?: {
      urgent: {
        label: string;
        text: string;
        gradient: string;
        icon: string;
        border: string;
      };
      priority: {
        labelLight: string;
        labelDark: string;
        border: string;
        text: string;
        icon: string;
        gradient: string;
      };
      block: {
        label: string;
        border: string;
        text: string;
        icon: string;
        gradient: string;
      };
      general: {
        background: string;
        gradient: string;
      };
      day: {
        background: string;
        gradient: string;
      };
      evening: {
        background: string;
        gradient: string;
      };
      overnight: {
        background: string;
        gradient: string;
      };
    };
    components?: {
      locationMarker: {
        background: string;
        foreground: string;
        border: string;
      };
      clusterMarker: {
        background: string;
        foreground: string;
      };
      spinner: {
        primary: string;
        secondary: string;
        destructive: string;
        invert: string;
        generateGradient: (hexColor: string) => string;
      };
    };
    legacyFullScreenDialogBackground: string;
  }

  // Actual MUI types
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary?: string;
    neutral?: string;
    invertPrimary?: string;
    invertSecondary?: string;
    invertTertiary?: string;
    destructive?: string;
    invertDestructive?: string;
    disabled: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    primary: string;
    secondary: string;
    tertiary?: string;
    invert?: string;
  }
}

export const muiThemePalette: PaletteOptions = {
  // MUI by default handles e.g pressed/hover states by applying a darker shade of the color/
  // In our designs we want to have a lighter shade for pressed/hover states, so we need to swap
  // dark and light
  primary: {
    main: PRIMARY_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
    dark: PRIMARY_DARK_COLOR,
    light: PRIMARY_COLOR,
  },
  // TODO: This needs some love but will be tackled here:
  // https://linear.app/clipboardhealth/issue/SPRTA-844/update-button-component
  secondary: {
    main: SECONDARY_COLOR,
    contrastText: PRIMARY_COLOR,
    // dark: GREY_02_COLOR,
    // light: GREY_01_COLOR,
  },
  error: {
    main: RED_06_COLOR,
    light: RED_07_COLOR,
    dark: RED_05_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  info: {
    main: BLUE_05_COLOR,
    light: BLUE_03_COLOR,
    dark: BLUE_04_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  warning: {
    main: YELLOW_04_COLOR,
    light: YELLOW_01_COLOR,
    dark: YELLOW_02_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  success: {
    main: GREEN_06_COLOR,
    light: GREEN_04_COLOR,
    dark: GREEN_05_COLOR,
    contrastText: TEXT_INVERT_PRIMARY_COLOR,
  },
  disabled: {
    primary: DISABLED_PRIMARY_COLOR,
    secondary: DISABLED_SECONDARY_COLOR,
    tertiary: DISABLED_TERTIARY_COLOR,
  },
  background: {
    default: BACKGROUND_PRIMARY_COLOR,
    paper: BACKGROUND_PRIMARY_COLOR,
    primary: BACKGROUND_PRIMARY_COLOR,
    secondary: BACKGROUND_SECONDARY_COLOR,
    tertiary: BACKGROUND_TERTIARY_COLOR,
    invert: BACKGROUND_INVERT_COLOR,
  },
  text: {
    primary: TEXT_PRIMARY_COLOR,
    secondary: TEXT_SECONDARY_COLOR,
    tertiary: TEXT_TERTIARY_COLOR,
    neutral: PRIMARY_DARK_COLOR,
    invertPrimary: TEXT_INVERT_PRIMARY_COLOR,
    invertSecondary: TEXT_INVERT_SECONDARY_COLOR,
    invertTertiary: TEXT_INVERT_TERTIARY_COLOR,
    destructive: TEXT_DESTRUCTIVE_COLOR,
    invertDestructive: TEXT_INVERT_DESTRUCTIVE_COLOR,
    disabled: TEXT_DISABLED_COLOR,
  },
  action: {
    active: PRIMARY_COLOR,
  },
  divider: BORDER_SUBTLE_COLOR,
  border: {
    primary: BORDER_PRIMARY_COLOR,
    dark: BORDER_DARK_COLOR,
    subtle: BORDER_SUBTLE_COLOR,
    primaryInvert: BORDER_PRIMARY_INVERT_COLOR,
    subtleInvert: BORDER_SUBTLE_INVERT_COLOR,
    darkInvert: BORDER_DARK_INVERT_COLOR,
    destructive: BORDER_DESTRUCTIVE_COLOR,
    hidden: WARM_GREY_00_COLOR,
  },
  icon: {
    primary: ICON_PRIMARY_COLOR,
    disabled: ICON_DISABLED_COLOR,
    destructive: ICON_DESTRUCTIVE_COLOR,
    lightInvert: ICON_INVERT_LIGHT_COLOR,
    darkInvert: ICON_INVERT_DARK_COLOR,
  },
  surface: {
    primary: SURFACE_PRIMARY_COLOR,
    secondary: SURFACE_SECONDARY_COLOR,
    shaded: SURFACE_SHADED_COLOR,
    shadedDark: SURFACE_SHADED_DARK_COLOR,
    invert: SURFACE_INVERT_COLOR,
    neutralLight: SURFACE_NEUTRAL_LIGHT_COLOR,
    neutralDark: SURFACE_NEUTRAL_DARK_COLOR,
  },
  callout: {
    neutral: {
      labelLight: NEUTRAL_CALLOUT_LABEL_LIGHT_COLOR,
      labelDark: NEUTRAL_CALLOUT_LABEL_DARK_COLOR,
      border: NEUTRAL_CALLOUT_BORDER_COLOR,
      text: NEUTRAL_CALLOUT_TEXT_COLOR,
      icon: NEUTRAL_CALLOUT_ICON_COLOR,
    },
    success: {
      labelLight: SUCCESS_CALLOUT_LABEL_LIGHT_COLOR,
      labelDark: SUCCESS_CALLOUT_LABEL_DARK_COLOR,
      border: SUCCESS_CALLOUT_BORDER_COLOR,
      text: SUCCESS_CALLOUT_TEXT_COLOR,
      iconLight: SUCCESS_CALLOUT_ICON_LIGHT_COLOR,
      iconDark: SUCCESS_CALLOUT_ICON_DARK_COLOR,
    },
    warning: {
      labelLight: WARNING_CALLOUT_LABEL_LIGHT_COLOR,
      labelDark: WARNING_CALLOUT_LABEL_DARK_COLOR,
      border: WARNING_CALLOUT_BORDER_COLOR,
      text: WARNING_CALLOUT_TEXT_COLOR,
      icon: WARNING_CALLOUT_ICON_COLOR,
    },
    error: {
      label: ERROR_CALLOUT_LABEL_COLOR,
      border: ERROR_CALLOUT_BORDER_COLOR,
      text: ERROR_CALLOUT_TEXT_COLOR,
      icon: ERROR_CALLOUT_ICON_COLOR,
    },
  },
  instantPay: {
    surface: SUCCESS_CALLOUT_LABEL_DARK_COLOR,
    text: SUCCESS_CALLOUT_TEXT_COLOR,
  },
  shifts: {
    urgent: {
      label: SHIFT_URGENT_LABEL_COLOR,
      text: SHIFT_URGENT_TEXT_COLOR,
      icon: SHIFT_URGENT_ICON_COLOR,
      border: SHIFT_URGENT_BORDER_COLOR,
      gradient: SHIFT_URGENT_GRADIENT,
    },
    priority: {
      labelLight: SHIFT_PRIORITY_LABEL_LIGHT_COLOR,
      labelDark: SHIFT_PRIORITY_LABEL_DARK_COLOR,
      border: SHIFT_PRIORITY_BORDER_COLOR,
      text: SHIFT_PRIORITY_TEXT_COLOR,
      icon: SHIFT_PRIORITY_ICON_COLOR,
      gradient: SHIFT_PRIORITY_GRADIENT,
    },
    block: {
      label: SHIFT_BLOCK_LABEL_COLOR,
      border: SHIFT_BLOCK_BORDER_COLOR,
      text: SHIFT_BLOCK_TEXT_COLOR,
      icon: SHIFT_BLOCK_ICON_COLOR,
      gradient: SHIFT_BLOCK_GRADIENT,
    },
    day: {
      background: SHIFT_DAY_COLOR,
      gradient: SHIFT_DAY_GRADIENT,
    },
    general: {
      background: SHIFT_GENERAL_COLOR,
      gradient: SHIFT_GENERAL_GRADIENT,
    },
    evening: {
      background: SHIFT_EVENING_COLOR,
      gradient: SHIFT_EVENING_GRADIENT,
    },
    overnight: {
      background: SHIFT_OVERNIGHT_COLOR,
      gradient: SHIFT_OVERNIGHT_GRADIENT,
    },
  },
  components: {
    locationMarker: {
      background: PURPLE_03_COLOR,
      foreground: PURPLE_07_COLOR,
      border: WARM_GREY_00_COLOR,
    },
    clusterMarker: {
      background: WARM_GREY_00_COLOR,
      foreground: BLUE_07_COLOR,
    },
    spinner: {
      primary: SPINNER_PRIMARY_COLOR,
      secondary: SPINNER_SECONDARY_COLOR,
      destructive: SPINNER_DESTRUCTIVE_COLOR,
      invert: SPINNER_INVERT_COLOR,
      generateGradient: generateSpinnerGradient,
    },
  },
  legacyFullScreenDialogBackground: BACKGROUND_PRIMARY_COLOR,
};
/* eslint-enable max-lines */
