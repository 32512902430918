import "./style.scss";
import { Text } from "@clipboard-health/ui-react";
import { Span } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { TextareaChangeEventDetail } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { Alert, Stack } from "@mui/material";
import { SentHomeRequestPayload, requestSentHomeConfirmation } from "@src/app/api/shift";
import { locationService } from "@src/app/openShifts/urgentShifts/locationService";
import { setShowToast } from "@src/app/store/shift";
import { useAgentSentHomeCancellationPayParams } from "@src/appV2/Agents/api/useAgentSentHomeCancellationPayParams";
import { ATTENDANCE_SCORE, CLIPBOARD_SCORE } from "@src/appV2/AttendanceScore/constants";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { Li, Ul } from "@src/appV2/lib/Lists";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { chevronBackOutline } from "ionicons/icons";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  DEFAULT_CONFIRMATION_ALERT_OPTION,
  SENT_HOME_REQUEST_FAILURE_MESSAGE_OPTION,
  SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION,
} from "../constants";

export const SentHomeRequestPage: React.VFC<{
  shift: Shift;
  onClose: () => void;
}> = ({ shift, onClose }) => {
  const facilityId = shift.facility?.userId;
  const facilityName = shift.facility?.name ?? "Workplace";
  const shiftId = shift._id;
  const [sentHomeDescription, setSentHomeDescription] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDescriptionTextareaActive, setIsDescriptionTextareaActive] = useState<boolean>(false);
  const [submitRequestInProgress, setSubmitRequestInProgress] = useState<boolean>(false);
  const [showSentHomeConfirmationAlert, setShowSentHomeConfirmationAlert] =
    useState<boolean>(false);
  const [atFacility, setAtFacility] = useState<boolean>();

  const worker = useDefinedWorker();

  const history = useHistory();
  const dispatch = useDispatch();

  const ldFlags = useCbhFlags();
  const { failureToast: failureToastOptions, confirmationAlert: confirmationAlertOption } =
    ldFlags[CbhFeatureFlag.SENT_HOME_REQUEST_CONFIG];

  const sentHomeReason = "SENT_HOME_BY_FACILITY";

  const {
    data: sentHomePayoutParamResponse,
    isLoading,
    isError,
  } = useAgentSentHomeCancellationPayParams(shiftId);

  useEffect(() => {
    if (facilityId) {
      locationService.isWithinFacilityGeofence(facilityId).then((atFacility) => {
        setAtFacility(atFacility);
      });
    }
  }, [facilityId]);
  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });
  const onRequestFacilityConfirmation = async () => {
    if (atFacility === false) {
      setShowSentHomeConfirmationAlert(true);
    } else {
      await submitFacilitySentMeHomeRequest();
    }
  };

  const submitFacilitySentMeHomeRequest = async () => {
    if (submitRequestInProgress) {
      return;
    }

    setSubmitRequestInProgress(true);
    const payload: SentHomeRequestPayload = {
      shiftId: shift._id as string,
      reason: sentHomeReason,
      description: sentHomeDescription,
      atFacility,
    };

    const eventPayload = {
      ...payload,
      workerId: worker.userId,
      workplaceId: shift.facility?.userId?.toString(),
      chatChannelUrl: `${
        environmentConfig.REACT_APP_WEB_APP_URL
      }/facility/chat/${shift.facility?.userId?.toString()}/${worker.userId}`,
    };

    try {
      await requestSentHomeConfirmation(payload);
      logEvent(USER_EVENTS.FACILITY_CANCELLED_ME_REQUEST_SUBMITTED, {
        ...eventPayload,
        success: true,
      });
      dispatch(setShowToast(SENT_HOME_REQUEST_SUCCESS_MESSAGE_OPTION.toastName));
      onClose();
      history.replace("/home/myShifts");
    } catch (error) {
      logEvent(USER_EVENTS.FACILITY_CANCELLED_ME_REQUEST_SUBMITTED, {
        ...eventPayload,
        success: false,
      });
      setErrorMessage(
        failureToastOptions?.message ?? SENT_HOME_REQUEST_FAILURE_MESSAGE_OPTION.message
      );
    } finally {
      setSubmitRequestInProgress(false);
    }
  };

  const shouldShowSentHomeRequestLineItems =
    !isLoading && !isError && isDefined(sentHomePayoutParamResponse);

  const sentHomeRequestIsPayable =
    sentHomePayoutParamResponse &&
    sentHomePayoutParamResponse.isPayable &&
    sentHomePayoutParamResponse.time > 0 &&
    sentHomePayoutParamResponse.payableAmount > 0;

  return (
    <Fragment>
      <IonHeader no-border>
        <IonToolbar className="sent-home-toolbar" mode="ios">
          <IonButtons align-center>
            <IonButton
              routerDirection="back"
              fill="clear"
              color="light"
              onClick={onClose}
              className="sent-home-request__back-btn"
            >
              <IonIcon icon={chevronBackOutline} mode="ios" size="large" />
            </IonButton>
          </IonButtons>
          <IonTitle className="sent-home-request__header-title">Request Sent Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Stack sx={{ marginX: 2, paddingTop: 2 }} spacing={2}>
          <Text variant="h3">Things you should know about your sent home request:</Text>
          <Ul>
            {shouldShowSentHomeRequestLineItems &&
              (sentHomeRequestIsPayable ? (
                <>
                  <Li>
                    <Text sx={{ display: "inline" }}>
                      {facilityName} offers <b>{sentHomePayoutParamResponse.time} hours</b> of sent
                      home pay
                    </Text>
                  </Li>
                  <Li>
                    <Text sx={{ display: "inline" }}>
                      You'll receive{" "}
                      <Span color={(theme) => theme.palette.success.main}>
                        {formatDollarsAsUsd(sentHomePayoutParamResponse.payableAmount)}
                      </Span>{" "}
                      if the workplace approves your request.
                    </Text>
                  </Li>
                </>
              ) : (
                <Li>
                  <Text sx={{ display: "inline" }}>{facilityName} doesn't offer sent home pay</Text>
                </Li>
              ))}
            <Li>
              <Text sx={{ display: "inline" }}>
                No timesheet is required for this sent home request
              </Text>
            </Li>
          </Ul>
          <Alert severity="warning">
            <Text variant="body2">
              If the request is rejected, your account's{" "}
              {isClipboardScoreEnabled ? CLIPBOARD_SCORE : ATTENDANCE_SCORE} may be affected. Please
              ask the workplace to approve your request before leaving.
            </Text>
            <Text sx={{ marginTop: 1 }} variant="body2">
              If either of these happened:
            </Text>
            <Ul>
              <Li>
                <Text variant="body2">You were sent home sick</Text>
              </Li>
              <Li>
                <Text variant="body2">There was a safety incident</Text>
              </Li>
            </Ul>
            <Text variant="body2">Do not use this form. Instead, contact Clipboard support.</Text>
          </Alert>
          <IonTextarea
            onIonFocus={() => setIsDescriptionTextareaActive(true)}
            onIonBlur={() => setIsDescriptionTextareaActive(false)}
            className={`reason-description ${isDescriptionTextareaActive ? "active" : ""}`}
            placeholder="Please describe in detail why you were sent home, including the name of the workplace representative (required)"
            data-testid="sent-home-reason-textarea"
            onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) =>
              setSentHomeDescription(e.detail.value ?? "")
            }
            rows={3}
            value={sentHomeDescription}
          />
          <Alert severity="info">This message will be shared with the workplace</Alert>
        </Stack>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton
          data-testid="sent-home-request-button"
          size="large"
          shape="round"
          expand="block"
          color="primary"
          disabled={!sentHomeDescription}
          onClick={onRequestFacilityConfirmation}
          className="shift-cancel-button margin-bottom-30"
        >
          {submitRequestInProgress ? (
            <IonSpinner name="lines" data-testid="cancel-shift-button-loading" />
          ) : (
            "Request Workplace Confirmation"
          )}
        </IonButton>
      </IonFooter>
      <IonToast
        isOpen={!!errorMessage}
        header={failureToastOptions?.header ?? SENT_HOME_REQUEST_FAILURE_MESSAGE_OPTION.header}
        onDidDismiss={() => setErrorMessage(undefined)}
        message={errorMessage ?? ""}
        duration={SENT_HOME_REQUEST_FAILURE_MESSAGE_OPTION.duration}
        color="danger"
        position="top"
        data-testid="fcm-error-message"
      />
      <IonAlert
        data-testid="sent-home-request-confirmation-alert"
        mode="ios"
        isOpen={showSentHomeConfirmationAlert}
        onDidDismiss={() => setShowSentHomeConfirmationAlert(false)}
        header={confirmationAlertOption?.header ?? DEFAULT_CONFIRMATION_ALERT_OPTION.header}
        message={confirmationAlertOption?.message ?? DEFAULT_CONFIRMATION_ALERT_OPTION.message}
        buttons={[
          {
            text: "Go back",
            role: "cancel",
          },
          {
            text: "Confirm",
            role: "confirm",
            handler: submitFacilitySentMeHomeRequest,
          },
        ]}
      />
    </Fragment>
  );
};
