import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib/Calendar/types";
import { type ComponentProps } from "react";

import { formatTimeRange } from "../utils/formatTimeRange";
import { getTimezoneAbbreviation } from "../utils/getTimezoneAbbreviation";

// TODO: Export TextProps from @clipboard-health/ui-react
export interface TimeRangeLabelProps extends ComponentProps<typeof Text> {
  dateRange: DateRange;
  timezone?: string;
  deviceTimezone?: string;
}

/**
 * Renders text with a time range in the format "9:30 am - 2:15 pm"
 * If `timezone` and `deviceTimezone` are passed and different, it will append the abbreviation of the reference timezone
 * Example:
 * - "9:30 am - 2:15 pm"
 * - "9:30 am - 2:15 pm (EST)"
 */
export function TimeRangeLabel(props: TimeRangeLabelProps) {
  const { dateRange, timezone, deviceTimezone, ...textProps } = props;

  return (
    <Text variant="body2" {...textProps}>
      {formatTimeRange(dateRange, timezone)}
      {isDefined(timezone) &&
        isDefined(deviceTimezone) &&
        deviceTimezone !== timezone &&
        ` (${getTimezoneAbbreviation(timezone)})`}
    </Text>
  );
}
