import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { CounterText } from "../../components/CounterText";
import { ClearAllFiltersButton } from "../Filters/ClearAllFiltersButton";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

interface ShiftDiscoveryListViewListTitleProps {
  count: number;
  daysCount: number;
  allShiftsCount: number;
}

function getLabel(dates: string[], daysCount: number) {
  if (dates.length > 0) {
    return "All selected dates";
  }

  return `All shifts in the next ${daysCount} days`;
}

export function ShiftDiscoveryListViewListTitle(props: ShiftDiscoveryListViewListTitleProps) {
  const { count, daysCount, allShiftsCount } = props;

  const { dates, appliedFiltersCount, clearFilters } = useShiftDiscoveryUserFiltersContext();

  return (
    <Stack spacing={5} sx={{ px: 3 }}>
      <CounterText count={count}>
        <Title
          bold
          variant="h5"
          component="h2"
          // TODO: add semibold to Title
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
        >
          {getLabel(dates, daysCount)}
        </Title>
      </CounterText>

      <ClearAllFiltersButton
        allShiftsCount={allShiftsCount}
        appliedFiltersCount={appliedFiltersCount}
        dates={dates}
        clearFilters={clearFilters}
      />
    </Stack>
  );
}
