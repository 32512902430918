import { getWorkplacesRequestQuery } from "@clipboard-health/contract-worker-app-bff";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type QueryClient, type UseQueryResult } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";
import { z } from "zod";

import { workplaceSchema } from "./types";

// Not using getWorkplacesResponse from the contract as it is currently inaccurate.
// In the contract, workplaces have name and requiresLunchBreak, but they are not
// included in the response of this endpoint. It also has the location and rating
// attributes as optional, but they should be required.
const getWorkplacesResponse = z.object({ data: z.array(workplaceSchema) });

export type GetWorkplacesResponse = z.infer<typeof getWorkplacesResponse>;
export type GetWorkplacesRequestQuery = Omit<z.input<typeof getWorkplacesRequestQuery>, "fields">;

export const GET_WORKPLACES_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/workplaces`;

export async function invalidateGetWorkplaces(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [GET_WORKPLACES_URL] });
}

export function useGetWorkplaces(
  query: GetWorkplacesRequestQuery,
  options: UseGetQueryOptions<GetWorkplacesResponse> = {}
): UseQueryResult<GetWorkplacesResponse> {
  return useGetQuery<GetWorkplacesRequestQuery, GetWorkplacesResponse>({
    url: GET_WORKPLACES_URL,
    queryParams: {
      ...query,
      // This is not currently used by the backend, but it's required by the query contract.
      fields: { workplace: "location,rating" },
    },
    requestSchema: getWorkplacesRequestQuery,
    responseSchema: getWorkplacesResponse,
    meta: {
      userErrorMessage: "Something went wrong while loading workplaces",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACES_FAILURE,
    },
    // We don't want to refetch workplaces frequently as it is unnecessary.
    staleTime: minutesToMilliseconds(5),
    ...options,
  });
}
