import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

import { LoadingSpinner } from "./LoadingSpinner";

interface LoadMoreTriggerProps {
  isLoading?: boolean;
  canLoadMore?: boolean;
  /**
   * The function to call when the user scrolls to the bottom of the list.
   * Should be stable and not change between renders.
   */
  onLoadMore: () => void;
  /**
   * A component to render when canLoadMore is false.
   */
  renderEmptyState?: () => React.ReactNode;
}

/**
 * This component is used to load more items when the user scrolls to the
 * bottom of the list. It uses an IntersectionObserver to detect when the
 * loader is in the viewport and then calls the onLoadMore function.
 */
export function LoadMoreTrigger(props: LoadMoreTriggerProps) {
  const { isLoading, canLoadMore, onLoadMore, renderEmptyState } = props;

  const loaderRef = useRef<HTMLDivElement>(null);

  const loadMore = useMemo(
    () =>
      debounce(() => {
        if (canLoadMore && !isLoading) {
          onLoadMore();
        }
      }, 250),
    [canLoadMore, isLoading, onLoadMore]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current && observer) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [loadMore]);

  if (!canLoadMore && isDefined(renderEmptyState)) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
        {renderEmptyState()}
      </Stack>
    );
  }

  return (
    <Stack
      ref={loaderRef}
      alignItems="center"
      justifyContent="center"
      sx={{ flex: 1, opacity: isLoading ? 1 : 0 }}
    >
      <LoadingSpinner size="small" variant="primary" />
    </Stack>
  );
}
