import { App } from "@capacitor/app";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useAppStateContext } from "../../App/useAppState";
import { APP_V2_USER_EVENTS } from "../../lib/analytics/events";
import { logEvent } from "../../lib/analytics/log";
import { invalidateShifts } from "../invalidateShifts";

export function useInvalidateShiftsOnDeeplink() {
  const queryClient = useQueryClient();

  const { state } = useAppStateContext();

  useEffect(() => {
    const result = App.addListener("appUrlOpen", () => {
      logEvent(APP_V2_USER_EVENTS.SHIFTS_INVALIDATED_ON_DEEPLINK);

      void invalidateShifts(queryClient);
    });

    return () => {
      void result?.then(async (listener) => {
        await listener?.remove();
      });
    };
  }, [queryClient, state.isAppActive]);
}
