import { isDefined } from "@clipboard-health/util-ts";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

/**
 * Formats a date into a string in the format "9:30 am"
 */
export function formatTime(date: Date, timezone?: string): string {
  if (isDefined(timezone)) {
    return formatInTimeZone(date, timezone, "h:mm a");
  }

  return format(date, "h:mm a", {}).toLowerCase();
}
