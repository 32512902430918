import { Stack, type StackProps } from "@mui/material";

/**
 * A wrapper for the `Group` component from react-virtuoso grouped list.
 * This allows us to render a date label at the top of the list without sticky styles
 */
export function ShiftListGroupWrapper(props: StackProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { style, ...restProps } = props;

  return <Stack {...restProps} pb={4} />;
}
