import { ButtonBase } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";

interface OpenShiftDatePickerButtonProps {
  onClick: () => void;
}

export function OpenShiftDatePickerButton(props: OpenShiftDatePickerButtonProps) {
  const { onClick } = props;
  return (
    <ButtonBase
      aria-label="Calendar View Navigation"
      sx={(theme) => ({
        width: theme.size?.dateCell.medium.width,
        height: theme.size?.dateCell.medium.height,
        backgroundColor: "transparent",
        flexShrink: 0,
        marginRight: 1,
      })}
      onClick={onClick}
    >
      <CbhIcon type="calendar-navigation" size="large" />
    </ButtonBase>
  );
}
