import { API_TYPES, useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";

import {
  type PlacementIncludedApplication,
  type PlacementIncludedWorkplace,
  type PlacementResponse,
  placementResponseSchema,
} from "../types/fetchPlacements.schema";

export function transformPlacementData(placement: PlacementResponse) {
  const workplace = placement.included.find(
    (included) =>
      included.type === API_TYPES.workplace &&
      included.id === placement.data.relationships.workplace.data.id
  ) as PlacementIncludedWorkplace | undefined;
  if (!workplace) {
    throw new Error("Workplace not found");
  }

  const applications = placement.data.relationships.applications?.data.map((application) => {
    const applicationData = placement.included.find(
      (included) =>
        included.type === API_TYPES.placementApplication && included.id === application.id
    ) as PlacementIncludedApplication | undefined;
    if (!applicationData) {
      throw new Error("Application details not found");
    }

    return applicationData;
  });

  return {
    ...placement.data.attributes,
    id: placement.data.id,
    isLicenseEligible: placement.data.meta.isLicenseEligible,
    workplace: { ...workplace.attributes, id: workplace.id },
    applications,
  };
}

export type PlacementDetail = ReturnType<typeof transformPlacementData>;

interface GetPlacementParams {
  placementId: string;
  workerId: string;
}

export function useGetPlacement(
  params: GetPlacementParams,
  options?: UseGetQueryOptions<PlacementResponse>
) {
  const { placementId, workerId } = params;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/placements/${placementId}`,
    responseSchema: placementResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PLACEMENT_FAILURE,
      userErrorMessage: "Something went wrong while loading the placement.",
    },
    ...options,
  });
}
