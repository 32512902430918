import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

import { type CreateCandidateResumeSchema } from "../types";

interface UseCreateResumeParams {
  placementCandidateId: string;
  profile: CreateCandidateResumeSchema;
}

const timespanSchema = z.object({
  isCurrent: z.boolean(),
  startDate: z.date(),
  endDate: z.date().optional(),
});

const createResumeRequestSchema = z.object({
  data: z.object({
    type: z.literal("placement-candidates"),
    id: z.string(),
    attributes: z.object({
      experience: z.array(
        z.object({
          roleName: z.string(),
          workplaceName: z.string(),
          timespan: timespanSchema,
        })
      ),
      education: z.array(
        z.object({
          institutionName: z.string(),
          educationType: z.string(),
          timespan: timespanSchema,
        })
      ),
    }),
  }),
});
const createResumeResponseSchema = z.unknown();

export function useCreateResume(
  options?: UseMutationOptions<unknown, unknown, UseCreateResumeParams>
) {
  return useMutation({
    mutationFn: async (params: UseCreateResumeParams) => {
      const { placementCandidateId, profile } = params;
      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates/${placementCandidateId}/resume`,
        requestSchema: createResumeRequestSchema,
        responseSchema: createResumeResponseSchema,
        data: {
          data: {
            type: "placement-candidates",
            id: placementCandidateId,
            attributes: profile,
          },
        },
      });
    },
    onSuccess: (_, params) => {
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_CREATED, {
        placementCandidateId: params.placementCandidateId,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_CREATE_FAILURE,
    },
    ...options,
  });
}
