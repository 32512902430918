import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { GET_BOOKABILITY_STATUS_URL } from "./useBookabilityStatus";

export function useInvalidateGetBookabilityStatusForShifts() {
  const queryClient = useQueryClient();

  useEffect(() => {
    void queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === GET_BOOKABILITY_STATUS_URL,
    });
  }, [queryClient]);
}
