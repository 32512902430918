import { useDocumentDistributionUrl } from "@src/appV2/Accounts/DocumentDetails/api/useDocumentDistributionUrl";
import { useEffect, useState } from "react";

export function PortResumeDialogPreview({ resumeDocumentId }: { resumeDocumentId: string }) {
  const { mutateAsync: createDistributionUrl, isLoading: isLoadingDistributionUrl } =
    useDocumentDistributionUrl();
  const [distributionUrl, setDistributionUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getDistributionUrl(resumeDocumentId: string) {
      const { distributionUrl } = await createDistributionUrl(resumeDocumentId);
      setDistributionUrl(distributionUrl);
    }

    void getDistributionUrl(resumeDocumentId);
  }, [resumeDocumentId, createDistributionUrl, setDistributionUrl]);

  if (!distributionUrl || isLoadingDistributionUrl) {
    return null;
  }

  return (
    // cant use sandbox because we need a cross origin iframe
    // eslint-disable-next-line react/iframe-missing-sandbox
    <iframe
      src={distributionUrl}
      style={{ width: "100%", height: "70vh", borderRadius: "16px", border: "1px solid lightgray" }}
      title="Resume Preview"
    />
  );
}
