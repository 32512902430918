import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { useGetMissingRequirementsForShift } from "../Booking/useGetMissingRequirementsForShift";
import { type ShiftWithType } from "../types";
import { ShiftBottomSheetFooter } from "./Footer";
import { ShiftBottomSheetContent } from "./SheetContent";
import { ShiftBottomSheetSkeleton } from "./SheetSkeleton";
import { ShiftBottomSheetWrapper, type ShiftBottomSheetWrapperProps } from "./Wrapper";

interface ShiftBottomSheetProps extends Pick<ShiftBottomSheetWrapperProps, "modalState"> {
  shift?: ShiftWithType;
  isShiftLoading: boolean;
  isError: boolean;
  bookabilityStatus: BookabilityStatusItem;
}

/**
 * A bottom sheet container for displaying shift details.
 *
 * The shift data can come from two sources:
 * 1. Directly via props when opened from a shift card
 * 2. Fetched from the API using the shift ID when:
 *    - Navigating between workplace views and browsing different shifts
 *    - Using back button in workplace modals going back to various shifts visited earlier
 *    - Opening a shift directly via URL
 */
export function ShiftBottomSheet(props: ShiftBottomSheetProps) {
  const { shift, modalState, isShiftLoading, isError, bookabilityStatus } = props;
  const { name: shiftTimeSlot, type: shiftType, facility: workplace } = shift ?? {};
  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const {
    missingDocumentsSet,
    pendingDocumentsSet,
    expiringDocumentsSet,
    isLoading: isMissingDocumentsLoading,
  } = useGetMissingRequirementsForShift({
    shift,
    workplace,
    bookabilityStatus,
    selectedWorkerQualifications:
      selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
  });
  const blockingDocuments = {
    missingDocumentsSet,
    expiringDocumentsSet,
    pendingDocumentsSet,
  };
  const hasBlockingDocuments =
    missingDocumentsSet.size + expiringDocumentsSet.size + pendingDocumentsSet.size > 0;

  const isLoading = isShiftLoading || isMissingDocumentsLoading;
  return (
    <ShiftBottomSheetWrapper
      shiftType={shiftType}
      shiftTimeSlot={shiftTimeSlot}
      modalState={modalState}
      footer={
        <ShiftBottomSheetFooter
          shift={shift}
          hasBlockingDocuments={hasBlockingDocuments}
          isLoading={isLoading}
          modalState={modalState}
          bookabilityStatus={bookabilityStatus}
        />
      }
    >
      {isDefined(shift) && isDefined(bookabilityStatus) && !isLoading && (
        <ShiftBottomSheetContent
          blockingDocuments={blockingDocuments}
          shift={shift}
          bookabilityStatus={bookabilityStatus}
        />
      )}

      {isLoading && <ShiftBottomSheetSkeleton />}

      {isError && (
        // TODO: Add proper error state
        <Text
          variant="h3"
          sx={(theme) => ({
            textAlign: "center",
            padding: 10,
            color: theme.palette.text.destructive,
          })}
        >
          Could not load shift details
        </Text>
      )}
    </ShiftBottomSheetWrapper>
  );
}
