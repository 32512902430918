import { App, AppState } from "@capacitor/app";
import { useModalState } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { IonAlert } from "@ionic/react";
import { Market } from "@ionic-native/market";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { FC, useEffect, useState } from "react";

export const APP_STORE_ID = "id1472962791";
export const GOOGLE_PLAY_STORE_ID = "health.clipboard.worker";

const AppVersion: FC<{ beforeLogin?: boolean }> = ({ beforeLogin }) => {
  const { modalIsOpen, openModal, closeModal } = useModalState();

  // this default state is to account for the fact that state is active on mount prior to attaching the event listener
  const [isAppStateActive, setIsAppStateActive] = useState(true);
  const isForceUpdateActivated = useCbhFlag(CbhFeatureFlag.FORCE_UPDATE, {
    defaultValue: false,
  });

  const isAppUpdateNudgeActivated = useCbhFlag(CbhFeatureFlag.APP_UPDATE_NUDGE, {
    defaultValue: false,
  });

  // we actually want this function to be recreated on each render to ensure the state from the
  // app state change is the latest.  If we inline this in the mount effect, the state will be stale
  // and we will not be able to show the dialog when the app becomes active
  const onAppStateChange = (state: AppState) => {
    setIsAppStateActive(state.isActive);
  };

  // This useEffect is used to determine whether or not the update dialog should be shown
  // regardless of if a update nudge or force update is activated
  useEffect(() => {
    if ((isForceUpdateActivated || isAppUpdateNudgeActivated) && isAppStateActive) {
      openModal();
      logEvent(APP_V2_APP_EVENTS.SHOWING_FORCE_UPDATE_OR_NUDGE_DIALOG);
    }
    // openModal will change on each render and it is also not possible to memoize it without
    // using it as a dependency in the memoization function which causes it to be memoized again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForceUpdateActivated, isAppUpdateNudgeActivated, isAppStateActive]);

  // A mount effect to trigger a component state change when the app state changes.
  useEffect(() => {
    const result = App.addListener("appStateChange", onAppStateChange);
    return () => {
      result?.then((listener) => listener?.remove());
    };
  }, []);

  return (
    <IonAlert
      isOpen={modalIsOpen}
      header="Update Available"
      backdropDismiss={!isForceUpdateActivated}
      message={
        "A new version of the Clipboard Health app is available. Click Update and enjoy our new features!"
      }
      buttons={[
        ...(isForceUpdateActivated
          ? []
          : [
              {
                text: "Later",
                role: "cancel",
                handler: () => closeModal(),
              },
            ]),
        {
          text: "Update",
          handler: async () => {
            try {
              if (isPlatform(window, "ios")) {
                await Market.open(APP_STORE_ID);
              }
              if (isPlatform(window, "android")) {
                await Market.open(GOOGLE_PLAY_STORE_ID);
              }
              closeModal();
            } catch (error) {
              logError(APP_V2_APP_EVENTS.FAILED_TO_OPEN_OS_STORE, error);
            }
          },
        },
      ]}
    />
  );
};

export { AppVersion };
