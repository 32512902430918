import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from "@mui/material";

interface ReviewPicturePageProps {
  imageUrl?: string;
  onContinue: () => void;
  onCancel: () => void;
}

export function ReviewPicturePage(props: ReviewPicturePageProps) {
  const { imageUrl, onContinue, onCancel } = props;
  const theme = useTheme();

  const modalState = useModalState(ModalStatus.OPEN);

  return (
    <Dialog fullScreen open={modalState.modalIsOpen} onClose={onCancel}>
      <DialogTitle sx={{ padding: 2 }}>Looks good?</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <Text textAlign="center" marginBottom={2}>
          The photo will be reviewed by Clipboard Health
        </Text>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
        >
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Clock Action"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: theme.shape.borderRadius,
                objectFit: "contain",
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        <Button fullWidth variant="contained" onClick={onContinue}>
          Looks great! Submit photo
        </Button>
        <Button fullWidth onClick={onCancel}>
          Try again - go back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
