import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

interface Props {
  onClose: () => void;
}

export function OnboardingScheduleAssessmentInstructions(props: Props) {
  const { onClose } = props;

  const worker = useDefinedWorker();

  return (
    <Stack justifyContent="space-between" width="100%" height="100%" spacing={5}>
      <Stack spacing={3}>
        <Title component="h1">Unlock shifts by completing your assessment</Title>
        <Text sx={{ lineHeight: "24px" }}>
          Before you can book shifts, we ask you to complete a quick video assessment. This helps
          us:
        </Text>
        <Ul style={{ marginTop: 10 }}>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>Verify your knowledge and experience.</Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Ensure that the best professionals have access to the shifts they want.
            </Text>
          </Li>
        </Ul>
        <Text>What to Expect:</Text>
        <Ul style={{ marginTop: 10 }}>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You&apos;ll answer questions about your past experience and skills on video.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Treat this like an interview! Respond to the best of your ability and in a
              professional manner.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You must pass the assessment to book shifts on Clipboard.
            </Text>
          </Li>
        </Ul>
      </Stack>
      <Button
        sx={{ fontSize: 16, borderRadius: 84, lineHeight: "24px", minHeight: "3rem" }} // Override font size to match design
        variant="contained"
        onClick={() => {
          onClose();

          logEvent(
            APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_ONBOARDING_INSTRUCTIONS_BUTTON_CLICKED,
            {
              workerId: worker.userId,
            }
          );
        }}
      >
        Continue to schedule assessment
      </Button>
    </Stack>
  );
}
