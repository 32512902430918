import { CameraSource } from "@capacitor/camera";
import { type UseModalState } from "@clipboard-health/ui-react";
import { type CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { useTakePhoto } from "@src/appV2/Accounts/DocumentDetails/hooks/useTakePhoto";
import { type UploadFileData } from "@src/appV2/Accounts/DocumentDetails/types";
import { useState } from "react";

import { ErrorDialog } from "./ErrorDialog";
import { PolicyDialog } from "./PolicyDialog";
import { ReviewPicturePage } from "./ReviewPicturePage";

export interface ClockActionPictureDialogProps {
  facilityName: string;
  timeclockLocation: string;
  modalState: UseModalState;
  onSuccess: () => void;
  onCancel: () => void;
}

export function ClockActionPictureDialog(props: ClockActionPictureDialogProps) {
  const {
    modalState,
    onSuccess,
    onCancel,
    facilityName = "The facility",
    timeclockLocation,
  } = props;
  const [currentPage, setCurrentPage] = useState<"policy" | "review-picture" | "error">("policy");
  const [photoData, setPhotoData] = useState<UploadFileData>();
  const [photoError, setPhotoError] = useState<Error | CameraPermissionDeniedError>();

  const [isLoading, setIsLoading] = useState(false);

  const takePicture = useTakePhoto({
    source: CameraSource.Camera,
    onSuccess: (result) => {
      setIsLoading(false);
      setPhotoError(undefined);
      setPhotoData(result);

      setCurrentPage("review-picture");
    },
    onError: (error) => {
      setIsLoading(false);
      setPhotoError(error);

      setCurrentPage("error");
    },
    photoOptions: {
      height: 1200,
    },
  });

  return modalState.modalIsOpen ? (
    <>
      {currentPage === "policy" && (
        <PolicyDialog
          isLoading={isLoading}
          facilityName={facilityName}
          timeclockLocation={timeclockLocation}
          onContinue={async () => {
            setIsLoading(true);
            takePicture();
          }}
          onCancel={() => {
            setIsLoading(false);

            setCurrentPage("policy");
            onCancel();
          }}
        />
      )}
      {currentPage === "review-picture" && (
        <ReviewPicturePage
          imageUrl={photoData?.dataUrlBase64}
          onContinue={() => {
            onSuccess();
          }}
          onCancel={() => {
            takePicture();
          }}
        />
      )}
      {currentPage === "error" && (
        <ErrorDialog
          isLoading={isLoading}
          error={photoError}
          onRetry={() => {
            setIsLoading(true);
            takePicture();
          }}
          onClose={() => {
            if (photoData) {
              setCurrentPage("review-picture");
            } else {
              setCurrentPage("policy");
            }

            onCancel();
          }}
        />
      )}
    </>
  ) : null;
}
