import { Diagnostic } from "@ionic-native/diagnostic";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { isCapacitorPlatform } from "@src/appV2/lib/utils/capacitor/platformUtils";
import { useEffect, useState } from "react";

/**
 * Although VideoAsk iFrame asks for camera and microphone permissions,
 * the user can deny the request. On web, the iFrame does not let the user proceed, but
 * in mobile devices, the iFrame allows the user to proceed without granting the permissions.
 * This poses a problem as we get corrupted recordings (for example, missing audio).
 * Example issue - https://linear.app/clipboardhealth/issue/ACT-1110
 */
export function useRecordPermissions(checkHardwarePermissionsBeforeRecordingAssessment: boolean): {
  isMicrophoneAuthorized: boolean | undefined;
  isCameraAuthorized: boolean | undefined;
} {
  const [isMicrophoneAuthorized, setIsMicrophoneAuthorized] = useState<boolean>();
  const [isCameraAuthorized, setIsCameraAuthorized] = useState<boolean>();

  useEffect(() => {
    async function checkPermissions() {
      if (!isCapacitorPlatform() || !checkHardwarePermissionsBeforeRecordingAssessment) {
        // On web, we will defer handling permission to the called (VideoAsk)
        // The iFrame will handle the permission request
        setIsMicrophoneAuthorized(true);
        setIsCameraAuthorized(true);
        return;
      }

      // Diagnostic.permissionStatus is not available on web
      const NOT_REQUESTED_PERMISSION_STATUSES = new Set([
        Diagnostic.permissionStatus.NOT_REQUESTED,
      ]);
      const ALLOWED_PERMISSION_STATUSES = new Set([
        Diagnostic.permissionStatus.GRANTED,
        Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
      ]);

      const microphoneStatus = (await Diagnostic.getMicrophoneAuthorizationStatus()) as string;
      // Passing `false` since we don't need to check for storage permissions
      // since only cordova needs both.
      const cameraStatus = (await Diagnostic.getCameraAuthorizationStatus(false)) as string;

      // Intentionally not using useLogEffect, as we want to log this event every time payload changes
      logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_RECORDING_PERMISSIONS_CHECKED, {
        microphoneStatus,
        cameraStatus,
      });

      if (NOT_REQUESTED_PERMISSION_STATUSES.has(microphoneStatus)) {
        // The promise is resolved when the native modal/dialog for the permission is closed
        await Diagnostic.requestMicrophoneAuthorization();
        // Check permissions again to see if the permission was granted
        await checkPermissions();
        return;
      }

      if (NOT_REQUESTED_PERMISSION_STATUSES.has(cameraStatus)) {
        // The promise is resolved when the native modal/dialog for the permission is closed
        await Diagnostic.requestCameraAuthorization(true);
        // Check permissions again to see if the permission was granted
        await checkPermissions();
        return;
      }

      setIsMicrophoneAuthorized(ALLOWED_PERMISSION_STATUSES.has(microphoneStatus));
      setIsCameraAuthorized(ALLOWED_PERMISSION_STATUSES.has(cameraStatus));
    }

    void checkPermissions();
  }, [checkHardwarePermissionsBeforeRecordingAssessment]);

  return { isMicrophoneAuthorized, isCameraAuthorized };
}
