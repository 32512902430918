import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const DEFAULT_DELAY_IN_MINUTES = 120;

export function useQualityBonusFlag() {
  const { enabled, delayInMinutes = DEFAULT_DELAY_IN_MINUTES } = useCbhFlag(
    CbhFeatureFlag.ROLLOUT_FEEDBACK_QUALITY_BONUS,
    {
      defaultValue: {
        enabled: false,
      },
    }
  );
  return { enabled, delayInMinutes };
}
