import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

import { TextButton, type TextButtonProps } from "../../components/TextButton";

export interface ClearAllFiltersButtonProps extends TextButtonProps {
  allShiftsCount: number;
  appliedFiltersCount: number;
  dates: string[];
  clearFilters: () => void;
}

export function ClearAllFiltersButton(props: ClearAllFiltersButtonProps) {
  const { allShiftsCount, appliedFiltersCount, dates, clearFilters, ...restProps } = props;

  if (appliedFiltersCount === 0 && dates.length === 0) {
    return null;
  }

  return (
    <TextButton
      size="medium"
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_DATES_CLEARED, { dates });
        clearFilters();
      }}
      {...restProps}
    >
      Clear all filters to view {allShiftsCount} shifts
    </TextButton>
  );
}
