import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type Location } from "@src/appV2/lib/GoogleMaps";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { OpenGeoLocationBottomSheet } from "@src/appV2/redesign/components/OpenGeoLocation/OpenGeoLocationBottomSheet";
import { useOpenGeoLocationContext } from "@src/appV2/redesign/components/OpenGeoLocation/useOpenGeoLocation";

interface WorkplaceProfileActionsProps {
  phoneNumber?: string;
  location?: Location;
}

export function WorkplaceProfileActions(props: WorkplaceProfileActionsProps) {
  const { phoneNumber, location } = props;

  const { href, onClick } = useOpenGeoLocationContext();

  return (
    <Stack direction="row" spacing={3}>
      {isDefined(phoneNumber) && (
        <IconButton
          invert
          iconType="phone"
          size="large"
          variant="contained"
          href={`tel:+1${phoneNumber}`}
        />
      )}
      {isDefined(location) && (
        <>
          <IconButton
            invert
            iconType="compass"
            size="large"
            variant="contained"
            href={href}
            onClick={onClick}
          />
          <OpenGeoLocationBottomSheet />
        </>
      )}
    </Stack>
  );
}
