import {
  type GetOpenShiftsResponse as GetOpenShiftsResponseBFF,
  type OpenShift as OpenShiftBFF,
} from "@clipboard-health/contract-worker-app-bff";

import { type WorkerShiftWithType } from "../types";

export {
  API_TYPES,
  type Offer,
  // Alias to avoid conflicts with other Workplace types
  type Workplace as OpenShiftWorkplace,
  Urgency,
  Window,
} from "@clipboard-health/contract-worker-app-bff";

// TODO: export from contract once SURG is removed from TimeSlot
export enum TimeSlot {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

type OpenShiftAttributes = Omit<OpenShiftBFF["attributes"], "timeSlot"> & {
  timeSlot: TimeSlot;
};

// TODO: export from contract once SURG is removed from TimeSlot
export type OpenShift = Omit<OpenShiftBFF, "attributes"> & {
  attributes: OpenShiftAttributes;
};

// TODO: export from contract once SURG is removed from TimeSlot
export type GetOpenShiftsResponse = Omit<GetOpenShiftsResponseBFF, "data"> & {
  data: OpenShift[];
};

export type OpenShiftListItem = OpenShift | WorkerShiftWithType;
