import { isDevelopmentNodeEnvironment } from "@src/appV2/environment";
import { useEffect } from "react";

import type { WorkplaceCluster } from "../WorkplaceClusterer/types";

/**
 * Generates a color based on the given latLng.
 * The hue is generated using a hash function, ensuring a consistent color for each location.
 * Used to debug and visualize the bounds of workplaces.
 * Shouldn't be used for anything else.
 */
function latLngToColor(latLng: google.maps.LatLng) {
  const coordinates = `${latLng.lat()},${latLng.lng()}`;

  let hash = 0;
  for (let index = 0; index < coordinates.length; index += 1) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + coordinates.codePointAt(index)!;
    hash = Math.trunc(hash);
  }

  // Map the hash to HSL values
  const hue = Math.abs(hash) % 360; // Ensure hue is in the range [0, 360]
  const saturation = 70;
  const lightness = 50;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

/**
 * Draws rectangles around workplaces' original locations.
 * Useful for visualizing where markers would be if they were not clustered,
 * and check if they are being clustered correctly.
 */
export function useDebugMapViewWorkplaceClusterer(props: {
  map: google.maps.Map | undefined;
  workplaceClusters: WorkplaceCluster[];
  enabled: boolean;
}) {
  const { map, workplaceClusters, enabled } = props;

  useEffect(() => {
    if (!isDevelopmentNodeEnvironment() || !enabled || !map || workplaceClusters.length === 0) {
      return undefined;
    }

    const rectangles: google.maps.Rectangle[] = [];

    workplaceClusters.forEach((cluster) => {
      // Use same color for cluster and its markers so it is clear they belong to the same cluster
      const clusterColor = latLngToColor(cluster.position);

      if (cluster.bounds) {
        const rectangle = new google.maps.Rectangle({
          map,
          bounds: cluster.bounds,
          strokeColor: clusterColor,
          strokeOpacity: 1,
          strokeWeight: 3,
          fillOpacity: 0.1,
          fillColor: clusterColor,
        });
        rectangle.setMap(map);
        rectangles.push(rectangle);
      }

      cluster.workplaceMarkers.forEach((marker) => {
        if (marker.bounds) {
          const rectangle = new google.maps.Rectangle({
            map,
            bounds: marker.bounds,
            strokeColor: clusterColor,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillOpacity: 0,
          });
          rectangle.setMap(map);
          rectangles.push(rectangle);
        }
      });
    });

    return () => {
      rectangles.forEach((rectangle) => {
        rectangle.setMap(null);
      });
    };
  }, [enabled, map, workplaceClusters]);
}
