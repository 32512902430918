import { Text } from "@clipboard-health/ui-react";
import { CardContent } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceHighlightPills } from "../Highlights/Pills";
import { useWorkplacePolicyHighlights } from "../Highlights/useWorkplacePolicyHighlights";
import { useWorkplaceReviewHighlights } from "../Highlights/useWorkplaceReviewHighlights";

export interface WorkplacePoliciesCardProps {
  workplaceId: string;
  lateCancellation: Facility["lateCancellation"];
  requiresLunchBreak: Facility["requiresLunchBreak"];
}

export function WorkplacePoliciesCard(props: WorkplacePoliciesCardProps) {
  const { workplaceId, lateCancellation, requiresLunchBreak } = props;

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  const { highlights: policyHighlights, isLoading: isPolicyHighlightsLoading } =
    useWorkplacePolicyHighlights({ workplaceId, lateCancellation, requiresLunchBreak });

  return (
    <Card>
      <CardContent sx={{ padding: 7 }}>
        <Text semibold variant="body1" sx={{ marginBottom: 7 }}>
          Policies
        </Text>

        <WorkplaceHighlightPills
          highlights={[...policyHighlights, ...reviewHighlights]}
          isLoading={isReviewHighlightsLoading || isPolicyHighlightsLoading}
        />

        <Button
          fullWidth
          variant="outlined"
          size="medium"
          sx={{ marginTop: 8 }}
          href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH, {
            workplaceId,
          })}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.VIEWED_WORKPLACE_RULES_PAGE, {
              workplaceId,
              trigger: "Workplace Profile Modal",
            });
          }}
        >
          View policies
        </Button>
      </CardContent>
    </Card>
  );
}
