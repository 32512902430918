import { ExternalLink, Text } from "@clipboard-health/ui-react";
import CalendarTodayIcon from "@mui/icons-material/CalendarMonth";
import { IconButton, Stack } from "@mui/material";
import { SHIFT_PRIORITY_COLOR } from "@src/appV2/lib/Theme/constants";
import { SupportArticleLinks } from "@src/appV2/support/constants";

import { logArticleClicked } from "./logs";

interface ClipboardScoreCopyProps {
  workerId: string;
  articleLink?: string;
}

export function ClipboardScoreCopy({ workerId, articleLink }: ClipboardScoreCopyProps) {
  return (
    <Stack justifyContent="center" marginY={1} gap={1}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton sx={{ color: SHIFT_PRIORITY_COLOR, padding: 0 }} size="small">
          <CalendarTodayIcon />
        </IconButton>
        <Text
          variant="h4"
          align="center"
          sx={{
            color: SHIFT_PRIORITY_COLOR,
            fontWeight: "600",
            marginTop: "4px",
            // FIXME: fontSize is not applied due to global.scss override for ion-item.
            // remove !important when porting to V2
            fontSize: "16px !important",
          }}
        >
          High Clipboard Score Priority Access
        </Text>
      </Stack>
      <Text
        variant="body2"
        align="center"
        sx={{
          color: SHIFT_PRIORITY_COLOR,
          fontWeight: "600",
          fontSize: "12px",
        }}
      >
        The higher you score, the more Priority Access you get.
        <ExternalLink
          to={articleLink ?? SupportArticleLinks.CLIPBOARD_SCORE}
          onClick={() => logArticleClicked(workerId)}
          data-testId="clipboard-score-article-link"
        >
          Learn more.
        </ExternalLink>
      </Text>
    </Stack>
  );
}
