import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import QuestionMark from "@mui/icons-material/QuestionMark";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import { Stack } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { type AttendanceScorePolicy } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { RestrictedAccountStatusWarning } from "@src/appV2/AttendanceScore/components/RestrictedAccountStatusWarning";
import { ScoreFrequentlyAskedQuestions } from "@src/appV2/AttendanceScore/components/ScoreFrequentlyAskedQuestions";
import { ScoreLearnMoreLinks } from "@src/appV2/AttendanceScore/components/ScoreLearnMoreLinks";
import type { ScorePolicyEntryProps } from "@src/appV2/AttendanceScore/components/ScorePolicyEntry";
import {
  ATTENDANCE_SCORE,
  CLIPBOARD_SCORE,
  DEFAULT_NO_CALL_NO_SHOW_POINTS,
} from "@src/appV2/AttendanceScore/constants";
import { SupportArticleLinks } from "@src/appV2/support/constants";

import { ListItemLink } from "./ListItemLink";

function ThemedRedeemedOutlinedIcon() {
  return <RedeemOutlinedIcon sx={{ color: "#4527A0" }} />;
}

export interface ScoreInformationProps {
  policy: AttendanceScorePolicy;
  accountReEnrollmentDate?: string;
  accountStatus?: AgentStage;
  clipboardScore: number;
  isClipboardScoreEnabled: boolean;
  isWorkerATeamEnabled: boolean;
  disableSecondShiftBonus: boolean;
  priorityAccessShiftsPerWeek: number;
  attendanceScorePolicyLinks: Record<string, { label: string; url: string }>;
  qualityBonusIsEnabled?: boolean;
}

function getRestrictionExtraInformation(isWorkerATeamEnabled: boolean) {
  return [
    "Your score is capped at 0.",
    isWorkerATeamEnabled
      ? "You are unable to book shifts in your region until the Restriction is lifted, though you may still receive and accept Shift Invites."
      : "You can only book shifts if you are a Favorite at the facility or if you received a Shift Invite.",
  ];
}

function getHowItWorksInformation(isClipboardScoreEnabled = false) {
  const instructions = [
    isClipboardScoreEnabled ? "Your score has no limit." : "The maximum score is 100 points.",
    isClipboardScoreEnabled ? "Higher scores mean more Priority Access." : undefined,
    "Earn points by working shifts and by being on time.",
    "Lose points by cancelling and being late.",
    <Text key="restriction">
      A score of 0 or a No-Show results in account <b>Restriction</b>.
    </Text>,
  ];

  return instructions.filter((element) => isDefined(element));
}

function constructNegativePolicies(props: {
  isClipboardScoreEnabled: boolean;
  noCallNoShow?: { points: number };
  // eslint-disable-next-line @typescript-eslint/ban-types
  cancelShift?: Array<{ points: number; leadTime: Array<number | null> }>;
  disableSecondShiftBonus: boolean;
  latenessConfig?: Array<{
    points: number;
    minutesLateRange: { startFrom: number; stopAt?: number };
  }>;
}) {
  const {
    isClipboardScoreEnabled,
    noCallNoShow,
    cancelShift,
    disableSecondShiftBonus,
    latenessConfig,
  } = props;

  const lateArrivalPolicies: ScorePolicyEntryProps[] = (latenessConfig ?? []).map(
    (latenessConfig) => {
      const { points: consequence = 0, minutesLateRange } = latenessConfig;
      const { startFrom: from, stopAt: to } = minutesLateRange;

      const range = isDefined(to) ? `${from}-${to}` : `${from}+`;
      const title = `${range} minutes late`;

      return {
        title,
        consequence,
      };
    }
  );

  if (disableSecondShiftBonus) {
    // the 4 hour threshold is currently hardcoded in the API as well
    // MX-3836 outlines the work to refactor this to be configurable and have a shared source of truth
    lateArrivalPolicies.push({
      title:
        "* Timeliness deductions do not apply if the shift is the second shift in double or was booked within 4 hours of the shifts start time.",
    });
  }

  return [
    {
      title: "Cancelling a shift",
      content: [
        {
          title: "No Show",
          consequence: isClipboardScoreEnabled
            ? "Instant Restriction"
            : noCallNoShow?.points ?? DEFAULT_NO_CALL_NO_SHOW_POINTS,
        },
        ...(cancelShift ?? []).map((cancellationPolicy) => {
          const { points: consequence = 0, leadTime } = cancellationPolicy;

          const [from, to] = leadTime;
          let title = `${from ?? "0"}`;
          title += isDefined(to) ? `-${to}` : "+";
          title += " hours notice";
          return {
            title,
            consequence,
          };
        }),
      ],
    },
    {
      title: `Arriving late for a shift${disableSecondShiftBonus ? "*" : ""}`,
      content: lateArrivalPolicies,
    },
  ];
}

export function ScoreInformationTab(props: ScoreInformationProps) {
  const {
    accountStatus,
    accountReEnrollmentDate,
    isWorkerATeamEnabled,
    isClipboardScoreEnabled,
    attendanceScorePolicyLinks,
    disableSecondShiftBonus,
    priorityAccessShiftsPerWeek,
    clipboardScore,
    policy: {
      workShift,
      shiftClockInConfig,
      noCallNoShow,
      latenessConfig,
      cancelShift,
      qualityBonusConfig,
    } = {},
    qualityBonusIsEnabled = false,
  } = props;

  const { howAttendanceWorks, understandingAccountStatus, doNotReturnPolicy } =
    attendanceScorePolicyLinks;

  const negativePolicies = constructNegativePolicies({
    isClipboardScoreEnabled,
    noCallNoShow,
    cancelShift,
    disableSecondShiftBonus,
    latenessConfig,
  });

  const faqContent = [
    {
      title: "How it Works",
      icon: <QuestionMark />,
      content: getHowItWorksInformation(isClipboardScoreEnabled),
    },
  ];

  const optionalPriorityAccessCountText = (
    <span>
      {" "}
      to over <b style={{ color: "#4527A0" }}>{priorityAccessShiftsPerWeek} shifts per week</b> in
      your area
    </span>
  );

  if (isClipboardScoreEnabled && clipboardScore >= 200) {
    faqContent.push({
      title: "Perks of a High Score",
      icon: <ThemedRedeemedOutlinedIcon />,
      content: [
        <Text key="reward">
          Because of your solid track record, you get{" "}
          <b style={{ color: "#4527A0" }}>Priority Access</b>
          {priorityAccessShiftsPerWeek > 0 && (
            <span> {optionalPriorityAccessCountText} on average</span>
          )}
          .
        </Text>,
      ],
    });
  } else if (isClipboardScoreEnabled) {
    faqContent.push({
      title: "Improve your score for perks",
      icon: <ThemedRedeemedOutlinedIcon />,
      content: [
        <Text key="reward">
          The higher your score, the earlier you get to book shifts.
          {priorityAccessShiftsPerWeek > 0 && (
            <span>
              {" "}
              On average, top scorers get <b style={{ color: "#4527A0" }}>Priority Access</b>
              {optionalPriorityAccessCountText}.
            </span>
          )}
        </Text>,
      ],
    });
  }

  const positiveReviewPolicies = {
    title: "Receiving a positive shift review",
    consequence: qualityBonusConfig?.points ?? 0,
  };

  const workShiftPoints = workShift?.points ?? 0;
  const workShiftPolicy = { title: "Working a shift", consequence: workShiftPoints };

  return (
    <Stack direction="column" spacing={10} paddingTop={2}>
      {accountStatus === AgentStage.RESTRICTED && (
        <RestrictedAccountStatusWarning
          reEnrollmentDate={accountReEnrollmentDate}
          extraInformation={getRestrictionExtraInformation(isWorkerATeamEnabled)}
        />
      )}
      <ScoreFrequentlyAskedQuestions
        content={faqContent}
        policies={{
          positive: [
            ...(workShiftPoints > 0 ? [workShiftPolicy] : []),
            {
              title: "Clocking in on time*",
              consequence: shiftClockInConfig?.points ?? 0,
            },
            ...(qualityBonusIsEnabled ? [positiveReviewPolicies] : []),
            {
              title: "* Timeliness bonuses do not apply to the second shift in a double.",
            },
          ],
          negative: negativePolicies,
        }}
      />

      <ScoreLearnMoreLinks>
        <ListItemLink
          label={
            howAttendanceWorks?.label ??
            `How the ${isClipboardScoreEnabled ? CLIPBOARD_SCORE : ATTENDANCE_SCORE} Works`
          }
          url={
            howAttendanceWorks?.url ??
            (isClipboardScoreEnabled
              ? SupportArticleLinks.CLIPBOARD_SCORE
              : SupportArticleLinks.ATTENDANCE_SCORE)
          }
        />
        <ListItemLink
          label={understandingAccountStatus?.label ?? "Understanding Account Status"}
          url={understandingAccountStatus?.url ?? SupportArticleLinks.ACCOUNT_STATUS}
        />
        <ListItemLink
          label={doNotReturnPolicy?.label ?? "Do Not Return (DNR) Policy"}
          url={doNotReturnPolicy?.url ?? SupportArticleLinks.DNR_REQUESTS_WORKER}
        />
      </ScoreLearnMoreLinks>
    </Stack>
  );
}
