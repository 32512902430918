import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import TrendingDown from "@mui/icons-material/TrendingDown";
import TrendingUp from "@mui/icons-material/TrendingUp";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { BulletedTextList } from "@src/appV2/AttendanceScore/components/BulletedTextList";
import {
  type PolicyCategory,
  ScorePolicyCategory,
} from "@src/appV2/AttendanceScore/components/ScorePolicyCategory";
import { type ScorePolicyEntryProps } from "@src/appV2/AttendanceScore/components/ScorePolicyEntry";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import { first, snakeCase, uniqueId } from "lodash";
import { isValidElement, type ReactNode, type SyntheticEvent, useState } from "react";

export interface FrequentlyAskedQuestionsSection<T> {
  title: string;
  content: T;
  icon?: ReactNode;
}

export interface ScoreFrequentlyAskedQuestionsProps {
  content: Array<FrequentlyAskedQuestionsSection<Array<ReactNode | string>>>;
  policies: {
    positive: Array<ScorePolicyEntryProps | PolicyCategory>;
    negative: Array<ScorePolicyEntryProps | PolicyCategory>;
  };
}

function normalizeToText(element: unknown): ReactNode {
  return isValidElement(element) ? element : <Text>{element}</Text>;
}

export function ScoreFrequentlyAskedQuestions(props: ScoreFrequentlyAskedQuestionsProps) {
  const [expanded, setExpanded] = useState<string | undefined>(undefined);

  const {
    content,
    policies: { positive: positivePolicies, negative: negativePolicies },
  } = props;

  const handleCollapse = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const sections: Array<FrequentlyAskedQuestionsSection<ReactNode>> = content.map((faq) => ({
    title: faq.title,
    icon: faq.icon,
    content:
      faq.content.length > 1 ? (
        <BulletedTextList items={faq.content} />
      ) : (
        normalizeToText(first(faq.content))
      ),
  }));

  if (positivePolicies.length > 0) {
    sections.push({
      title: "How you gain points",
      icon: <TrendingUp color="success" />,
      content: <ScorePolicyCategory policies={positivePolicies} />,
    });
  }

  if (negativePolicies.length > 0) {
    sections.push({
      title: "How you lose points",
      icon: <TrendingDown color="error" />,
      content: <ScorePolicyCategory policies={negativePolicies} />,
    });
  }

  return (
    <Box padding={0} gap={0}>
      {sections.map((faq) => {
        const { title, icon, content } = faq;
        const SECTION_ID = snakeCase(title);

        return (
          <Accordion
            key={uniqueId(`score-faq-section-${SECTION_ID}-`)}
            disableGutters
            elevation={0}
            expanded={expanded === SECTION_ID}
            onChange={handleCollapse(SECTION_ID)}
          >
            <AccordionSummary
              expandIcon={<CbhIcon type="chevron-down" />}
              aria-controls={`${SECTION_ID}-content`}
              id={`${SECTION_ID}-header`}
              sx={{
                borderBottom: `1px solid`,
                borderBottomColor: "#DEE2E6",
              }}
            >
              {isDefined(icon) && icon}
              <Text bold padding={1}>
                {title}
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <Box paddingTop={2}>{content}</Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
