import { formatDate } from "@clipboard-health/date-time";
import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Link, Stack } from "@mui/material";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";

interface Props {
  retakeAllowedAfter?: string;
}
export function SkillsAssessmentStatusFailedScheduled(props: Props) {
  const { retakeAllowedAfter } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack spacing={4}>
      <Title component="h1">
        Unfortunately your submission didn&apos;t meet the requirements to pass this assessment.
      </Title>
      <Ul>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            <b>
              You&apos;ll have the opportunity to schedule another assessment
              {isDefined(retakeAllowedAfter) ? ` on ${formatDate(retakeAllowedAfter)}` : ""}.
            </b>{" "}
            We&apos;ll send you a reminder to complete the assessment at that time.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            You&apos;ll still be able to work any shifts that you&apos;ve already booked.
          </Text>
        </Li>
        <Li>
          <Text sx={{ lineHeight: "24px" }}>
            Tap{" "}
            <Link
              onClick={() => {
                void openBrowser(skillsAssessmentConfig.supportArticleLink);
              }}
            >
              here
            </Link>{" "}
            to view additional details about the assessment.
          </Text>
        </Li>
      </Ul>
    </Stack>
  );
}
