import { Box, Button, Stack, ThemeProvider } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";

import { useLocalStorage } from "../../lib/utils";
import { useDebounce } from "../../lib/utils/useDebounce";
import { type PlacementCandidate } from "../PlacementCandidate/types";
import { getRedesignTheme } from "../theming";
import { FilterButton } from "./components/FilterButton";
import { PlacementListView } from "./components/PlacementListView";
import { PlacementMapView } from "./components/PlacementMapView";
import { type GetPlacementsForWorkplaceQuery } from "./types/fetchPlacements.schema";

interface PlacementsPageProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementsPage(props: PlacementsPageProps) {
  const { placementCandidate, worker } = props;
  const [viewMode, setViewMode] = useLocalStorage<"list" | "map">("jobs-view-mode", "list");
  const [filter, setFilter] = useLocalStorage<GetPlacementsForWorkplaceQuery["filter"]>(
    "jobs-filter",
    {}
  );
  const [searchByWorkplaceName, setSearchByWorkplaceName] = useLocalStorage<string>(
    "jobs-search-workplace-name",
    ""
  );
  const [showApplications, setShowApplications] = useLocalStorage<boolean>(
    "jobs-show-applications",
    false
  );

  useLogEffect(USER_EVENTS.VIEWED_JOBS, {
    viewMode,
    filter,
    searchByWorkplaceName,
    showApplications,
  });

  const debouncedSearchByWorkplaceName = useDebounce({
    value: searchByWorkplaceName,
    debounceTimeInMs: 1000,
    onUpdateValue: (value) => {
      setSearchByWorkplaceName(value);
    },
  });

  const isListViewMode = viewMode === "list";

  return (
    <ThemeProvider theme={getRedesignTheme()}>
      {isListViewMode ? (
        <PlacementListView
          placementCandidate={placementCandidate}
          worker={worker}
          filter={filter}
          setFilter={setFilter}
          searchByWorkplaceName={searchByWorkplaceName}
          setSearchByWorkplaceName={setSearchByWorkplaceName}
          showApplications={showApplications}
          setShowApplications={setShowApplications}
          debouncedSearchByWorkplaceName={debouncedSearchByWorkplaceName}
        />
      ) : (
        <PlacementMapView worker={worker} filter={filter} showApplications={showApplications} />
      )}
      <Box
        sx={(theme) => ({
          position: "fixed",
          bottom: 10,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: theme.zIndex.fab,
          width: "auto",
          maxWidth: "90%",
        })}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "max-content" }}
            onClick={() => {
              const newViewMode = isListViewMode ? "map" : "list";
              setViewMode(newViewMode);
              logEvent(USER_EVENTS.TAPPED_JOBS_VIEW_MODE, {
                viewMode: newViewMode,
                filter,
                searchByWorkplaceName,
                showApplications,
              });
            }}
          >
            {isListViewMode ? "View Map" : "View List"}
          </Button>
          <FilterButton
            filter={filter}
            setFilter={setFilter}
            worker={worker}
            placementCandidateId={placementCandidate.id}
            searchByWorkplaceName={debouncedSearchByWorkplaceName}
          />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
