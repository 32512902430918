import { Title } from "@clipboard-health/ui-react";
import { Box, type SxProps, type Theme } from "@mui/material";

import { CounterText } from "../../components/CounterText";

interface ShiftListDateLabelProps {
  label: string;
  shiftCount: number;
  sx?: SxProps<Theme>;
}

export function ShiftListDateLabel(props: ShiftListDateLabelProps) {
  const { label, shiftCount, sx } = props;

  return (
    <Box sx={sx} aria-label={label}>
      <CounterText count={shiftCount}>
        <Title
          bold
          variant="h5"
          component="h2"
          sx={(theme) => ({
            paddingLeft: 3,
            py: 4,
            // TODO: add semibold to Title
            fontWeight: theme.typography.fontWeightMedium,
            color: shiftCount === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
          })}
        >
          {label}
        </Title>
      </CounterText>
    </Box>
  );
}
