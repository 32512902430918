import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";

import { Button } from "../../components/Button";
import { SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSheetQuizCardProps {
  shiftId: string;
}

export function ShiftBottomSheetQuizCard(props: ShiftBottomSheetQuizCardProps) {
  const { shiftId } = props;
  const { navigateToModal } = useShiftModalsDataContext();

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="This workplace's rules have changed."
      description="Please take a quiz to demonstrate your understanding of these new rules before you can
            book this shift"
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_START_QUIZ, {
              shiftId,
            });
            navigateToModal(SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH, {
              shiftId,
            });
          }}
        >
          Start Quiz (3 min)
        </Button>
      }
    />
  );
}
