import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import {
  ScorePolicyEntry,
  type ScorePolicyEntryProps,
} from "@src/appV2/AttendanceScore/components/ScorePolicyEntry";
import { uniqueId } from "lodash";

export interface PolicyCategory {
  title: string;
  content: ScorePolicyEntryProps[];
}

export interface ScorePolicyCategoryProps {
  policies: Array<ScorePolicyEntryProps | PolicyCategory>;
}

function isPolicyCategory(
  policy: ScorePolicyEntryProps | PolicyCategory
): policy is PolicyCategory {
  return "content" in policy;
}

export function ScorePolicyCategory(props: ScorePolicyCategoryProps) {
  const { policies } = props;

  return (
    <Stack direction="column" spacing={1}>
      {policies.map((policy) => {
        if (isPolicyCategory(policy)) {
          const { title, content } = policy;
          return (
            <Stack key={uniqueId("score-policy-nested-section-")} direction="column" spacing={2}>
              <Text bold>{title}:</Text>
              <Stack
                key={uniqueId("score-policy-nested-section-policies-")}
                direction="column"
                spacing={1}
                padding={1}
              >
                {content.map((policy) => (
                  <ScorePolicyEntry key={uniqueId("nested-score-policy-entry-")} {...policy} />
                ))}
              </Stack>
            </Stack>
          );
        }

        return <ScorePolicyEntry key={uniqueId("score-policy-entry-")} {...policy} />;
      })}
    </Stack>
  );
}
