import { Pill } from "@src/appV2/redesign/components/Pill";
import { differenceInDays, parseISO } from "date-fns";

interface ShiftBottomSheetBlockingDocumentsExpiringDocumentPillProps {
  documentExpiry: string;
}

export function ShiftBottomSheetBlockingDocumentsExpiringDocumentPill(
  props: ShiftBottomSheetBlockingDocumentsExpiringDocumentPillProps
) {
  const { documentExpiry } = props;
  const expiryDate = parseISO(documentExpiry);
  const daysUntilExpiry = differenceInDays(expiryDate, new Date());

  if (daysUntilExpiry < 0) {
    return null;
  }

  const label = daysUntilExpiry === 0 ? "Expires today" : `Expires in ${daysUntilExpiry}d`;

  return (
    <Pill
      label={label}
      color={(theme) => theme.palette.callout?.warning.labelLight}
      labelColor={(theme) => theme.palette.callout?.warning.text}
    />
  );
}
