import { useListViewOpenShiftsData } from "../../ListView/useListViewOpenShiftsData";
import { filterOpenShifts } from "../filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../useUserFiltersContext";
import type { FiltersFormValues } from "./types";

export function useFilteredOpenShiftsCount(formValues: FiltersFormValues) {
  const { dates } = useShiftDiscoveryUserFiltersContext();

  const { distance, ...localUserFilters } = formValues;

  const { shifts = [], isLoading } = useListViewOpenShiftsData({ distance });

  const filteredOpenShiftsData = filterOpenShifts({ shifts }, { dates, ...localUserFilters });

  return {
    count: filteredOpenShiftsData.length,
    isLoading,
  };
}
