import { Browser } from "@capacitor/browser";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";

export interface ListItemLinkProps {
  url: string;
  label: string;
}

export function ListItemLink(props: ListItemLinkProps) {
  const { url, label } = props;

  const theme = useTheme();

  const openUrl = async (url: string) => {
    await Browser.open({
      url,
      toolbarColor: theme.palette.background.tertiary,
      presentationStyle: "popover", // iOS only: 'fullscreen' or 'popover'
    });
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={async () => {
          await openUrl(url);
        }}
      >
        <ListItemText primary={label} />
        <ChevronRight />
      </ListItemButton>
    </ListItem>
  );
}
