import { useGoogleMapsSdkContext } from "@src/appV2/lib/GoogleMaps/context";
import constate from "constate";
import { useState } from "react";

/**
 * @deprecated Use `useGoogleMapsSdkContext` to check whether sdk is available instead
 * Store your state (map center, zoom level, etc.) in the actual component (or lower level context, if needed) instead of using this global context
 */
function useGoogleMap() {
  const { isSdkLoaded, sdkLoadError } = useGoogleMapsSdkContext();

  const [googleMapCenter, setGoogleMapCenter] = useState<google.maps.LatLngLiteral>();
  const [googleMapZoomLevel, setGoogleMapZoomLevel] = useState(10);

  return {
    isMapLoaded: isSdkLoaded,
    mapLoadError: sdkLoadError,
    googleMapCenter,
    setGoogleMapCenter,
    googleMapZoomLevel,
    setGoogleMapZoomLevel,
  };
}

export const [GoogleMapProvider, useGoogleMapContext] = constate(useGoogleMap);
