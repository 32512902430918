import { Pill } from "@src/appV2/redesign/components/Pill";

export function InstantReviewPill() {
  return (
    <Pill
      label="Instant Review"
      color={(theme) => theme.palette.callout?.success.labelDark}
      labelColor={(theme) => theme.palette.callout?.success.text}
    />
  );
}
