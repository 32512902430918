import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import pluralize from "pluralize";

import { Rating } from "./Rating";

interface ReviewsSummaryProps {
  rating: number;
  reviewsCount?: number;
  alignItems?: "flex-start" | "flex-end";
}

export function ReviewsSummary(props: ReviewsSummaryProps) {
  const { rating, reviewsCount, alignItems = "flex-start" } = props;

  return (
    <Stack spacing={3} alignItems={alignItems}>
      <Text variant="h1">{rating.toFixed(1)}</Text>

      <Stack direction="row" alignItems="center" spacing={4}>
        <Rating readOnly precision={0.5} value={rating} size="medium" />

        {isDefined(reviewsCount) && (
          <Text bold variant="body2">
            {reviewsCount} {pluralize("review", reviewsCount)}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}
