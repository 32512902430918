import ProfessionalReferences from "@src/app/professionalReferences";
import { CbhFeatureFlag, EnableReferralSignupScreenVariants } from "@src/appV2/FeatureFlags";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import {
  AgentAddress,
  AgentDob,
  AgentEmail,
  AgentFirstName,
  AgentLastName,
  AgentOnboardingLicense,
  AgentPotentialShifts,
  AgentQualification,
  AgentSSN,
  AgentSignUpInfo1,
  AgentSignUpInfo2,
  AgentSignUpInfo3,
  ScheduledAssessmentOnboarding,
  ScheduledAssessmentOnboardingConfirmation,
  ScheduledAssessmentOnboardingInstructions,
  WelcomeAgent,
} from "./components";
import { AgentProfileErrorModal } from "./components/AgentProfileError/AgentProfileErrorModal";
import { AgentUploadDocuments } from "./components/AgentUploadDocuments";
import { ContractorAgreementReview } from "./components/ContractorAgreementReview";
import { ProceedToRequirementInfo } from "./components/ProceedToRequirementsInfo";
import ReferralSubmissionPage from "./components/ReferralSubmission/ReferralSubmissionPageWrapper";
import SignUpBasedReferrals from "./components/SignUpBasedReferrals/SignUpBasedReferrals";
import { SkillsAssessmentOnboarding } from "./components/SkillAssessmentOnboarding";
import { StripeOnboarding } from "./components/StripeOnboarding";
import { OnBoardingRouteProps, STAGES } from "./model";
import { OnboardToStripe } from "../onboardingStripe";
import { OnboardingRouterPath } from "../routing/constant/onboardingRoute";
import { DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard } from "../routing/LaunchDarklyFeatureFlagRouteGaurd/LaunchDarklyFeatureFlagRouteGaurd";
import { VerifyPage } from "../welcome";

const SIGNUP_BASED_REFERRALS_ENABLED_FLAG_VARIATIONS = [
  {
    key: CbhFeatureFlag.ENABLE_REFERRAL_SIGNUP_SCREEN,
    value: EnableReferralSignupScreenVariants.ON_WITHOUT_CONTACTS,
  },
  {
    key: CbhFeatureFlag.ENABLE_REFERRAL_SIGNUP_SCREEN,
    value: EnableReferralSignupScreenVariants.ON_WITH_CONTACTS,
  },
];

const FLAGS_FOR_REFERRAL_SUBMISSION_ONBOARDING_SCREEN = [
  {
    key: CbhFeatureFlag.ENABLE_REFERRAL_SUBMISSION_ONBOARDING_SCREEN,
    value: true,
  },
];

export function OnBoardingRoutes(props: OnBoardingRouteProps) {
  const match = useRouteMatch();
  return (
    <div className="cbhAppV1">
      <AgentProfileErrorModal />
      <Switch>
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_INFO1}
          render={() => <AgentSignUpInfo1 {...props} {...STAGES.agentSignUpInfo1} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_INFO2}
          render={() => <AgentSignUpInfo2 {...props} {...STAGES.agentSignUpInfo2} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_INFO3}
          render={() => <AgentSignUpInfo3 {...props} {...STAGES.agentSignUpInfo3} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_ADDRESS}
          render={() => <AgentAddress {...props} {...STAGES.agentAddress} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_QUALIFICATION}
          render={() => <AgentQualification {...props} {...STAGES.agentQualification} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_LICENSE}
          render={() => <AgentOnboardingLicense {...props} {...STAGES.agentQualification} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_FIRST_NAME}
          render={() => <AgentFirstName {...props} {...STAGES.agentFirstName} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_LAST_NAME}
          render={() => <AgentLastName {...props} {...STAGES.agentLastName} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_EMAIL}
          render={() => <AgentEmail {...props} {...STAGES.agentEmail} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS}
          render={() => <AgentPotentialShifts {...props} {...STAGES.agentPotentialShifts} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW}
          render={() => (
            <ContractorAgreementReview {...props} {...STAGES.contractorAgreementReview} />
          )}
        />

        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT}
          render={() => <OnboardToStripe {...props} {...STAGES.contractorAgreement} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS}
          render={() => (
            <ScheduledAssessmentOnboardingInstructions
              {...props}
              {...STAGES.scheduledAssessmentInstructions}
            />
          )}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_SKILL_ASSESSMENT}
          render={() => <SkillsAssessmentOnboarding {...props} {...STAGES.scheduleAssessment} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT_CONFIRMATION}
          render={() => (
            <ScheduledAssessmentOnboardingConfirmation
              {...props}
              {...STAGES.scheduleAssessmentConfirmation}
            />
          )}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_STRIPE}
          render={() => <StripeOnboarding {...props} {...STAGES.stripeOnboarding} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE}
          render={() => (
            <DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard
              conditionalFlagValues={FLAGS_FOR_REFERRAL_SUBMISSION_ONBOARDING_SCREEN}
              conditional="OR"
              redirectTo={OnboardingRouterPath.READY_TO_EARN}
              route={OnboardingRouterPath.ONBOARDING_APPLY_REFERRAL_CODE}
            >
              <ReferralSubmissionPage {...props} {...STAGES.agentReferralSubmission} />
            </DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard>
          )}
        />
        <Route
          exact
          path={OnboardingRouterPath.READY_TO_EARN}
          render={() => (
            <DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard
              conditionalFlagValues={SIGNUP_BASED_REFERRALS_ENABLED_FLAG_VARIATIONS}
              conditional="OR"
              redirectTo={OnboardingRouterPath.SHOW_FRIENDS_SHIFTS}
              route={OnboardingRouterPath.READY_TO_EARN}
            >
              <SignUpBasedReferrals {...props} {...STAGES.agentReadyToEarn} />
            </DeprecatedDoNotUseLaunchDarklyFeatureFlagRouteGuard>
          )}
        />
        <Redirect
          exact
          path={OnboardingRouterPath.SHOW_FRIENDS_SHIFTS}
          to={OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO}
          render={() => (
            <ProceedToRequirementInfo {...props} {...STAGES.proceedToRequirementsInfo} />
          )}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS}
          render={() => <AgentUploadDocuments {...props} {...STAGES.uploadDocuments} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_SCHEDULED_ASSESSMENT}
          render={() => <ScheduledAssessmentOnboarding {...props} {...STAGES.scheduleAssessment} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_WELCOME}
          render={() => <WelcomeAgent {...props} />}
        />
        <Route exact path={OnboardingRouterPath.ONBOARDING_REVERIFY} component={VerifyPage} />
        <Route
          exact
          path={match.path}
          render={() => {
            return <Redirect to={OnboardingRouterPath.ONBOARDING_INFO1} />;
          }}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_SSN}
          render={() => <AgentSSN {...props} {...STAGES.agentSSN} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_DOB}
          render={() => <AgentDob {...props} {...STAGES.agentDOB} />}
        />
        <Route
          exact
          path={OnboardingRouterPath.ONBOARDING_PROFESSIONAL_REFERENCES}
          render={() => <ProfessionalReferences {...props} />}
        />
      </Switch>
    </div>
  );
}
