import { ToggleButton } from "@mui/material";

import { TimeSlot } from "../../Shift/Open/types";
import { TimeSlotIcon } from "../../Shift/TimeSlotIcon";
import { FiltersToggleButtonGroup } from "./BottomSheet/ToggleButtonGroup";
import { type FiltersFormValues } from "./BottomSheet/types";
import { FILTER_ALL_VALUE, useFilterSelectedOptions } from "./BottomSheet/useFilterSelectedOptions";

interface ShiftTimeSlotButtonGroupProps {
  value?: FiltersFormValues["shiftTimeSlots"];
  onChange: (value: FiltersFormValues["shiftTimeSlots"]) => void;
}

export function ShiftTimeSlotButtonGroup(props: ShiftTimeSlotButtonGroupProps) {
  const { value, onChange } = props;

  const { selectedOptions, onChangeSelection } = useFilterSelectedOptions(value ?? [], onChange);

  return (
    <FiltersToggleButtonGroup
      aria-label="Shift Times Filter Toggle Buttons"
      value={selectedOptions}
      onChange={onChangeSelection}
    >
      <ToggleButton value={FILTER_ALL_VALUE}>All</ToggleButton>
      <ToggleButton value={TimeSlot.AM}>
        <TimeSlotIcon timeSlot={TimeSlot.AM} />
        Day
      </ToggleButton>
      <ToggleButton value={TimeSlot.PM}>
        <TimeSlotIcon timeSlot={TimeSlot.PM} />
        Evening
      </ToggleButton>
      <ToggleButton value={TimeSlot.NOC}>
        <TimeSlotIcon timeSlot={TimeSlot.NOC} />
        Overnight
      </ToggleButton>
    </FiltersToggleButtonGroup>
  );
}
