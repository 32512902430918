import { useGetQualifications } from "@src/appV2/Qualifications/api/useGetQualifications";

import { Pill } from "../components/Pill";
import { PillSkeleton } from "../components/PillSkeleton";

interface ShiftQualificationPillProps {
  qualificationName: string;
}

export function ShiftQualificationPill(props: ShiftQualificationPillProps) {
  const { qualificationName } = props;

  const {
    data: qualificationsData,
    isSuccess: qualificationsIsSuccess,
    isLoading: qualificationsIsLoading,
  } = useGetQualifications({ enabled: true });

  if (qualificationsIsLoading) {
    return <PillSkeleton width="2rem" />;
  }

  if (!qualificationsIsSuccess) {
    return null;
  }

  const qualification = qualificationsData?.data.find(
    (q) => q.attributes.name === qualificationName
  );

  return (
    <Pill
      label={qualification?.attributes.displayLabel ?? qualificationName}
      variant="outlined"
      sx={{ width: "fit-content" }}
    />
  );
}
