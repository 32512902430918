import { Text, Title } from "@clipboard-health/ui-react";
import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { Illustration } from "@src/appV2/redesign/components/Illustration";

interface InstantReviewShiftDocumentSubmissionErrorBottomSheetProps {
  modalState: UseModalState;
  onClick: () => void;
}

export function InstantReviewShiftDocumentSubmissionErrorBottomSheet(
  props: InstantReviewShiftDocumentSubmissionErrorBottomSheetProps
) {
  const { modalState, onClick } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button fullWidth size="large" onClick={onClick}>
            Try again
          </Button>
        </DialogFooter>
      }
      paperSx={{
        minHeight: (theme) => theme.size!.bottomSheet.review.minHeight,
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingX: 4,
        }}
      >
        <Stack alignItems="center" spacing={8}>
          <Illustration type="document-rejected" />
          {/* 
              Todo: this should be an h2, however there is an inconsistency in the typography, and we will fix this later 
              For now we will hack the font size to match with the designs 
              https://linear.app/clipboardhealth/issue/ALCH-3158/fix-hack-of-manual-font-size-in-shift-submissionsubmitted-modals
            */}
          <Title
            variant="h3"
            component="h3"
            sx={{ textWrap: "balance", textAlign: "center", fontSize: "2.5rem" }}
          >
            Something went wrong
          </Title>
          <Text align="center">
            An issue occurred while submitting your document. Please try again or wait a few minutes
            and try again later.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
