import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { qualificationSchema } from "@src/appV2/Qualifications/types";
import { useEffect } from "react";
import { z } from "zod";

import {
  assessmentEventSchema,
  skillsAssessmentSchema,
  skillsAssessmentStatusSchema,
} from "../types";

const RETRY_COUNT = 3;

export const skillsAssessmentsParamsSchema = z
  .object({
    filter: z.object({
      workerId: z.string(),
      qualificationNames: z.array(z.string()),
    }),
    include: z.string(),
  })
  .optional();
export type SkillsAssessmentsParams = z.infer<typeof skillsAssessmentsParamsSchema>;

export const assessmentQualificationSchema = qualificationSchema.extend({
  attributes: qualificationSchema.shape.attributes.pick({ name: true }),
});
export type AssessmentQualification = z.infer<typeof assessmentQualificationSchema>;

export const skillsAssessmentsResponseSchema = z.object({
  data: z.array(skillsAssessmentSchema),
  included: z.array(
    z.union([skillsAssessmentStatusSchema, assessmentQualificationSchema, assessmentEventSchema])
  ),
});
export type SkillsAssessmentsResponse = z.infer<typeof skillsAssessmentsResponseSchema>;

export const SKILLS_ASSESSMENTS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/assessments`;

export function getSkillsAssessmentsQueryKey(params?: SkillsAssessmentsParams) {
  return [SKILLS_ASSESSMENTS_URL, ...(isDefined(params) ? [params] : [])];
}

export function useSkillsAssessments(
  params: {
    workerId: string;
    qualificationNames: string[];
    onFailure?: () => void;
  },
  options: UseGetQueryOptions<SkillsAssessmentsResponse> = {}
) {
  const { workerId, qualificationNames, onFailure } = params;
  const queryParams: SkillsAssessmentsParams = {
    filter: {
      workerId,
      qualificationNames,
    },
    include: "assessment-status,qualification,latestScheduledEvent",
  };

  const query = useGetQuery({
    url: SKILLS_ASSESSMENTS_URL,
    queryParams,
    requestSchema: skillsAssessmentsParamsSchema,
    responseSchema: skillsAssessmentsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SKILLS_ASSESSMENTS_FAILURE,
    },
    staleTime: 1000 * 60 * 1,
    retry: RETRY_COUNT,
    retryDelay: (attempt) => 500 * attempt,
    ...options,
  });

  useEffect(() => {
    if ((query.isError || query.failureCount === RETRY_COUNT) && isDefined(onFailure)) {
      onFailure();
    }
  }, [query.isError, query.failureCount, onFailure]);

  return query;
}
