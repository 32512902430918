import { SkillsAssessmentWorkerStatus } from "@src/appV2/SkillsAssessment/types";
import { z } from "zod";

export enum BookabilityDecision {
  BLOCKED = "BLOCKED",
  ALLOWED = "ALLOWED",
  UNDETERMINED = "UNDETERMINED",
}

export enum BookabilityUnmetCriteria {
  FACILITY_CHARGE_RATE_MISSING = "FACILITY_CHARGE_RATE_MISSING",
  FACILITY_PENDING = "FACILITY_PENDING",
  FACILITY_SUSPENDED = "FACILITY_SUSPENDED",
  SHIFT_ALREADY_GRABBED = "SHIFT_ALREADY_GRABBED",
  SHIFT_DELETED = "SHIFT_DELETED",
  SHIFT_IN_PAST = "SHIFT_IN_PAST",
  SHIFT_INSTANT_BOOK_DISABLED = "SHIFT_INSTANT_BOOK_DISABLED",
  SHIFT_INSTANT_BOOK_LEAD_TIME_NOT_SET = "SHIFT_INSTANT_BOOK_LEAD_TIME_NOT_SET",
  SHIFT_INVALID = "SHIFT_INVALID",
  SHIFT_OVERLAPPING = "SHIFT_OVERLAPPING",
  SHIFT_OVERWORKING = "SHIFT_OVERWORKING",
  WORKER_ACCOUNT_IN_REVIEW = "WORKER_ACCOUNT_IN_REVIEW",
  WORKER_CANT_CLAIM_SHIFT = "WORKER_CANT_CLAIM_SHIFT",
  WORKER_DEACTIVATED = "WORKER_DEACTIVATED",
  WORKER_DISABLED_TEST_ACCOUNT = "WORKER_DISABLED_TEST_ACCOUNT",
  WORKER_MISSING_LICENSE = "WORKER_MISSING_LICENSE",
  WORKER_MISSING_REQUIREMENTS = "WORKER_MISSING_REQUIREMENTS",
  WORKER_PAYMENTS_DISABLED = "WORKER_PAYMENTS_DISABLED",
  WORKER_PENDING_DOCUMENTS = "WORKER_PENDING_DOCUMENTS",
  WORKER_RESTRICTED = "WORKER_RESTRICTED",
  WORKER_UNSIGNED_AGREEMENT = "WORKER_UNSIGNED_AGREEMENT",
  WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES = "WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES",
  WORKER_HAS_NOT_PASSED_ASSESSMENTS = "WORKER_HAS_NOT_PASSED_ASSESSMENTS",
  WORKER_HAS_REACHED_DNR_LIMIT = "WORKER_HAS_REACHED_DNR_LIMIT",
  WORKER_HAS_REACHED_MAX_HOURS_PER_WEEK = "WORKER_HAS_REACHED_MAX_HOURS_PER_WEEK",
  WORKER_HAS_REACHED_MAX_CONSECUTIVE_HOURS = "WORKER_HAS_REACHED_MAX_CONSECUTIVE_HOURS",
}

const workerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadataSchema = z.object({
  status: z.nativeEnum(SkillsAssessmentWorkerStatus),
  videoAsk: z.object({
    estimatedTimeToCompleteInMinutes: z.number(),
    numberOfQuestions: z.number(),
    url: z.string(),
  }),
  assessmentId: z.string(),
  qualificationId: z.string(),
  qualificationName: z.string(),
  retakeAllowedAfter: z.string().datetime().optional(),
});

const bookabilityUnmetCriteriaZodEnum = z.enum(
  Object.values(BookabilityUnmetCriteria) as [BookabilityUnmetCriteria]
);

export enum WorkerQuizResultStatus {
  NEVER_TOOK_A_QUIZ = "NEVER_TOOK_A_QUIZ",
  TOOK_A_QUIZ_IN_PAST = "TOOK_A_QUIZ_IN_PAST",
  TOOK_LATEST_QUIZ_A_MONTH_AGO = "TOOK_LATEST_QUIZ_A_MONTH_AGO",
}

const bookabilityUnmetCriteriaWithMetadataSchema = z.discriminatedUnion("unmetCriteria", [
  z.object({
    unmetCriteria: z.literal(
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES
    ),
    metadata: z.object({
      workerQuizResultStatus: z.nativeEnum(WorkerQuizResultStatus),
    }),
  }),
  z.object({
    unmetCriteria: z.literal(BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS),
    metadata: workerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadataSchema,
  }),
  z.object({
    unmetCriteria: bookabilityUnmetCriteriaZodEnum.exclude([
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
      BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS,
    ]),
  }),
]);

const bookabilityAttributeSchema = z.discriminatedUnion("decision", [
  z.object({
    decision: z.literal(BookabilityDecision.ALLOWED),
  }),
  z.object({
    decision: z.literal(BookabilityDecision.UNDETERMINED),
  }),
  z.object({
    decision: z.literal(BookabilityDecision.BLOCKED),
    unmetCriteria: z.array(z.nativeEnum(BookabilityUnmetCriteria)).min(1),
    unmetCriteriaWithMetadata: z.array(bookabilityUnmetCriteriaWithMetadataSchema).min(1),
  }),
]);

const bookabilityStatusItemSchema = z.object({
  id: z.string(),
  type: z.literal("shift"),
  attributes: z.object({
    bookability: bookabilityAttributeSchema,
  }),
});

export const bookabilityStatusRequestSchema = z.object({
  workerId: z.string(),
  shiftIds: z.string(),
  returnFirstUnmetCriteria: z.boolean().optional(),
});

export const bookabilityStatusResponseSchema = z.object({
  data: z.array(bookabilityStatusItemSchema),
});

export type BookabilityAttributes = z.infer<typeof bookabilityAttributeSchema>;
export type BookabilityStatusItem = z.infer<typeof bookabilityStatusItemSchema>;

export type BookabilityStatusResponse = z.infer<typeof bookabilityStatusResponseSchema>;
export type BookabilityStatusRequest = z.infer<typeof bookabilityStatusRequestSchema>;

export const claimShiftResponseSchema = z.object({
  _id: z.string(),
  agentId: z.string(),
  assignedBy: z.string(),
  pay: z.number(),
  charge: z.number(),
  agentReq: z.string(),
});

export type ClaimShiftResponse = z.infer<typeof claimShiftResponseSchema>;
export type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata = z.infer<
  typeof workerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadataSchema
>;

export interface BookabilityNegotiationMetadata {
  negotiationId: string;
  bookRate: number;
}
