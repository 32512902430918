import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";
import { WorkplaceShiftRateNegotiability } from "@src/appV2/OpenShifts/RateNegotiation/types";
import { differenceInHours, parseISO } from "date-fns";

import { type ShiftRateNegotiationConditions } from "./types";

export interface IsShiftRateNegotiationEnabledProps {
  shiftStart: string;
  workplaceShiftRateNegotiability?: WorkplaceShiftRateNegotiability;
  workplaceId?: string;
  workplaceState?: string;
  workplaceMsa?: string;
  isShiftChargeUpdatedManually?: boolean;
}

/**
 * Determines if rate negotiation is enabled for a shift.
 * This function accepts both shift/workplace related fields and conditions from the feature flag.
 * @param props - Shift/workplace related fields.
 * @param conditions - Conditions from the feature flag.
 * @returns Whether rate negotiation is enabled for the shift.
 */
export function isShiftRateNegotiationEnabled(
  props: IsShiftRateNegotiationEnabledProps,
  conditions: ShiftRateNegotiationConditions
) {
  const {
    shiftStart,
    workplaceShiftRateNegotiability,
    workplaceId,
    workplaceState,
    workplaceMsa,
    isShiftChargeUpdatedManually,
  } = props;

  const { enabledWorkplaceIds, enabledStates, enabledMsaIds, enabledGlobally, leadTime } =
    conditions;

  const isNotTooLate = differenceInHours(parseISO(shiftStart), new Date()) >= leadTime;
  const isShiftEligible = !isShiftChargeUpdatedManually;

  const isWorkplaceSettingsEnabled =
    isDefined(workplaceShiftRateNegotiability) &&
    workplaceShiftRateNegotiability === WorkplaceShiftRateNegotiability.ON;

  const isEnabledById = Boolean(workplaceId && enabledWorkplaceIds.includes(workplaceId));
  const isEnabledByMsa = Boolean(workplaceMsa && enabledMsaIds.includes(workplaceMsa));
  const isEnabledByState = Boolean(workplaceState && enabledStates.includes(workplaceState));

  const isWorkplaceEnabledFromFeatureFlag =
    enabledGlobally || isEnabledById || isEnabledByMsa || isEnabledByState;

  return (
    isNotTooLate &&
    isShiftEligible &&
    isWorkplaceSettingsEnabled &&
    isWorkplaceEnabledFromFeatureFlag
  );
}

export function useIsRateNegotiationEnabledForShift(props: IsShiftRateNegotiationEnabledProps) {
  const activationConditions = useCbhFlag(CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION, {
    defaultValue: {
      id: [],
      state: [],
      msa: [],
      global: false,
    },
  });

  const leadTime = useCbhFlag(CbhFeatureFlag.RATE_NEGOTIATION_LEAD_TIME, {
    defaultValue: 0,
  });

  const conditions: ShiftRateNegotiationConditions = {
    enabledWorkplaceIds: activationConditions.id,
    enabledStates: activationConditions.state,
    enabledMsaIds: activationConditions.msa,
    enabledGlobally: activationConditions.global,
    leadTime,
  };

  return isShiftRateNegotiationEnabled(props, conditions);
}
