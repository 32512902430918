import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";
import { SHIFT_DISCOVERY_PRIORITY_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";

interface PriorityShiftsCalloutProps {
  count: number;
}

export function PriorityShiftsCallout(props: PriorityShiftsCalloutProps) {
  const { count } = props;
  const history = useHistory();
  const { baseNavigationPath } = useShiftModalsDataContext();

  if (count <= 0) {
    return null;
  }

  return (
    <Callout
      title={
        <Stack direction="row" alignItems="center" gap={2} component="span">
          <span>Priority</span>
          <span>{count}</span>
        </Stack>
      }
      iconType="priority"
      sx={(theme) => ({
        backgroundColor: theme.palette.shifts?.priority.labelLight,
        color: theme.palette.shifts?.priority.labelDark,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALLOUT_VIEWED, { type: "priorityShifts", count });
        history.push(`${baseNavigationPath}/${SHIFT_DISCOVERY_PRIORITY_PATH}`);
      }}
    />
  );
}
