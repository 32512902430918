import { IonPage } from "@ionic/react";
import { STREAK_TRACKER_PATH, StreakTrackerPage } from "@src/appV2/Streaks";
import { type ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { AccountRoutes, ACCOUNTS_PATH } from "../Accounts";
import { DEBUG_PATH, DebugRoutes } from "../Debug";
import { NotFoundPage } from "../lib";
import { PLACEMENTS_BASE_PATH } from "../redesign/Placements/paths";
import { PlacementRoutes } from "../redesign/Placements/Routes";
import { ShiftDiscoveryContainer } from "../redesign/ShiftDiscovery/Container";
import { SHIFT_DISCOVERY_PATH } from "../redesign/ShiftDiscovery/paths";
import { SHIFT_BLOCKS_PATH } from "../ShiftBlocks/paths";
import { ShiftBlockRoutes } from "../ShiftBlocks/Routes";
import { MY_SHIFTS_PATH, MyShiftsPage } from "../Shifts/MyShifts";
import { SHIFT_INVITES_ROUTE_PATH } from "../Shifts/ShiftInvites/constant";
import { ShiftInvitesPage } from "../Shifts/ShiftInvites/Page";
import { UNVERIFIED_SHIFTS_PATH } from "../Shifts/UnverifiedShifts/constants";
import { UnverifiedShiftsPage } from "../Shifts/UnverifiedShifts/Page";
import { WORKER_AVAILABILITIES_PATH, WorkerAvailabilityPage } from "../WorkerAvailability";
import { WorkerAvailabilityProvider } from "../WorkerAvailability/context";

export function AppV2PrivateRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/${DEBUG_PATH}`}>
        <DebugRoutes />
      </Route>
      <Route path={`${match.url}/${ACCOUNTS_PATH}`}>
        <AccountRoutes />
      </Route>
      <Route exact path={`${match.url}/${MY_SHIFTS_PATH}`}>
        <IonPage>
          <MyShiftsPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${UNVERIFIED_SHIFTS_PATH}`}>
        <IonPage>
          <UnverifiedShiftsPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${SHIFT_INVITES_ROUTE_PATH}`}>
        <IonPage>
          <ShiftInvitesPage />
        </IonPage>
      </Route>
      <Route exact path={`${match.url}/${WORKER_AVAILABILITIES_PATH}`}>
        <IonPage>
          <WorkerAvailabilityProvider>
            <WorkerAvailabilityPage />
          </WorkerAvailabilityProvider>
        </IonPage>
      </Route>
      <Route path={`${match.url}/${SHIFT_BLOCKS_PATH}`}>
        <ShiftBlockRoutes />
      </Route>
      <Route exact path={STREAK_TRACKER_PATH}>
        <StreakTrackerPage />
      </Route>
      <Route exact path={match.url}>
        <Redirect to={`${match.url}/${DEBUG_PATH}`} />
      </Route>
      <Route path={`${match.url}/${SHIFT_DISCOVERY_PATH}`}>
        <ShiftDiscoveryContainer />
      </Route>
      <Route path={`${match.url}/${PLACEMENTS_BASE_PATH}`}>
        <PlacementRoutes />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
