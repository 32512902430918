import { Card as MuiCard, type CardProps as MuiCardProps } from "@mui/material";
import { type ForwardedRef, forwardRef } from "react";

import { type Elevation } from "../theming";

export interface CardProps extends Omit<MuiCardProps, "outlined"> {
  variant?: "primary" | "secondary" | "tertiary";
  outlined?: boolean;
  elevation?: Elevation;
}

function BaseCard(props: CardProps, ref: ForwardedRef<HTMLDivElement>) {
  const { variant = "tertiary", outlined = false, elevation = 0, ...restProps } = props;

  return (
    <MuiCard
      {...restProps}
      ref={ref}
      variant={variant}
      elevation={elevation}
      outlined={outlined ? "true" : undefined}
    />
  );
}

export const Card = forwardRef(BaseCard);
