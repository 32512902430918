import { Card, CardContent, CardHeader, List } from "@mui/material";
import { type ReactNode } from "react";

export interface ScoreLearnMoreLinksProps {
  children: ReactNode[];
}

export function ScoreLearnMoreLinks(props: ScoreLearnMoreLinksProps) {
  const { children } = props;

  return (
    <Card data-testid="ratings-learn-more-card" variant="outlined">
      {/*
      CardContent has a 24px padding on the :last-child element as part of MUI so to make that even we'll
      set the top padding of the header to 24px as well
      */}
      <CardHeader sx={{ paddingBottom: 0, paddingTop: "24px" }} title="Help Articles" />
      <CardContent sx={{ padding: 0 }}>
        <List sx={{ padding: 0 }}>{...children}</List>
      </CardContent>
    </Card>
  );
}
