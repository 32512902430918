import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";
import { type ReactNode } from "react";

import { APP_BAR_COLORS } from "../../Theme";

export enum CloseIconType {
  ARROW_BACK,
  CLOSE,
}

interface FullScreenDialogTitleProps {
  children: ReactNode;
  onClose: () => void;
  closeIconType?: CloseIconType;
}

export function FullScreenDialogTitle(props: FullScreenDialogTitleProps) {
  const { children, onClose, closeIconType } = props;
  const icon = closeIconType ?? CloseIconType.ARROW_BACK;

  return (
    <DialogTitle
      sx={{
        paddingX: 0,
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "normal",
        color: (theme) => theme.palette.common.white,
        fontSize: (theme) => theme.typography.h3.fontSize,
        backgroundColor: APP_BAR_COLORS.backgroundColors.light,
      }}
    >
      <IconButton size="large" color="inherit" aria-label="Close" title="close" onClick={onClose}>
        {icon === CloseIconType.ARROW_BACK ? <ArrowBackIcon /> : null}
        {icon === CloseIconType.CLOSE ? <CloseIcon /> : null}
      </IconButton>

      {children}

      {/* to align title in center */}
      <IconButton disabled size="large" color="inherit">
        <ArrowBackIcon sx={{ visibility: "hidden" }} />
      </IconButton>
    </DialogTitle>
  );
}
