import { type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import { type Worker } from "@src/appV2/Worker/api/types";
import { useHistory } from "react-router-dom";

import { type PlacementCandidate } from "../types";
import { PlacementCandidateProfileUpdateDialog } from "./PlacementCandidateProfileUpdateDialog";

interface PlacementDetailCreateCandidateProfileDialogProps {
  modalState: UseModalState;
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementDetailCreateCandidateProfileDialog({
  modalState,
  placementCandidate,
  worker,
}: PlacementDetailCreateCandidateProfileDialogProps) {
  const history = useHistory();
  return (
    <PlacementCandidateProfileUpdateDialog
      updateCandidateProfileModalState={modalState}
      placementCandidate={placementCandidate}
      worker={worker}
      footer={
        <DialogFooter
          withShadow
          orientation="horizontal"
          backgroundColor={(theme) => String(theme.palette.background.tertiary)}
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Stack direction="row" justifyContent="end" sx={{ width: "100%" }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ maxWidth: "60%" }}
              size="large"
              onClick={() => {
                modalState.closeModal();
                history.push(
                  `${DeprecatedGlobalAppV1Paths.OPEN_SHIFTS}?shiftBookingTab=${ShiftBookingType.JOBS}`
                );
              }}
            >
              View Jobs
            </Button>
          </Stack>
        </DialogFooter>
      }
    />
  );
}
