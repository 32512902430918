import { isDefined } from "@clipboard-health/util-ts";
import { useAgentProfilePicture } from "@src/appV2/Agents/api/useAgentProfilePicture";

import type { AvatarSize } from "../theming";
import { Avatar } from "./Avatar";
import { AvatarSkeleton } from "./AvatarSkeleton";

interface WorkerAvatarProps {
  workerId?: string;
  size?: AvatarSize;
}

export function WorkerAvatar(props: WorkerAvatarProps) {
  const { workerId, size } = props;
  const { data: profilePicture, isLoading: isProfilePictureLoading } = useAgentProfilePicture(
    workerId ?? "",
    {
      enabled: isDefined(workerId),
    }
  );

  if (isProfilePictureLoading && isDefined(workerId)) {
    return <AvatarSkeleton size={size} />;
  }

  return <Avatar src={profilePicture?.distribution_url} size={size} />;
}
