import { GlobalRouterPath } from "./globalRoute";
import { RoutePathInfo } from "./interface";

const OnboardingRouterPath = {
  ONBOARDING_INFO1: `${GlobalRouterPath.HOME}/agentSignupInfo1`,
  ONBOARDING_INFO2: `${GlobalRouterPath.HOME}/agentSignupInfo2`,
  ONBOARDING_INFO3: `${GlobalRouterPath.HOME}/agentSignupInfo3`,
  ONBOARDING_ADDRESS: `${GlobalRouterPath.HOME}/agentAddress`,
  ONBOARDING_LICENSE: `${GlobalRouterPath.HOME}/agentLicense`,
  ONBOARDING_SKILL_ASSESSMENT: `${GlobalRouterPath.HOME}/skillAssessment`,
  /**
   * @deprecated This route should not be used anymore, we've are redirecting to agentLicense
   */
  ONBOARDING_QUALIFICATION: `${GlobalRouterPath.HOME}/agentQualification`,
  ONBOARDING_FIRST_NAME: `${GlobalRouterPath.HOME}/agentFirstName`,
  ONBOARDING_LAST_NAME: `${GlobalRouterPath.HOME}/agentLastName`,
  ONBOARDING_APPLY_REFERRAL_CODE: `${GlobalRouterPath.HOME}/agentReferralSubmission`,
  ONBOARDING_EMAIL: `${GlobalRouterPath.HOME}/agentEmail`,
  ONBOARDING_WELCOME: `${GlobalRouterPath.HOME}/welcomeAgent`,
  ONBOARDING_REVERIFY: `${GlobalRouterPath.HOME}/signupReVerify`,
  ONBOARDING_POTENTIAL_SHIFTS: `${GlobalRouterPath.HOME}/agentPotentialShifts`,
  ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW: `${GlobalRouterPath.HOME}/contractAgreementReview`,
  ONBOARDING_CONTRACTOR_AGREEMENT: `${GlobalRouterPath.HOME}/contractAgreement`,
  ONBOARDING_STRIPE: `${GlobalRouterPath.HOME}/onboardToStripe`,
  ONBOARDING_PROFESSIONAL_REFERENCES: `${GlobalRouterPath.HOME}/onboardingProfessionalReferences`,
  READY_TO_EARN: `${GlobalRouterPath.HOME}/readyToEarn`,
  ONBOARDING_PROCEED_TO_REQUIREMENT_INFO: `${GlobalRouterPath.HOME}/requirementInfo`,
  ONBOARDING_UPLOAD_DOCUMENTS: `${GlobalRouterPath.HOME}/uploadDocuments`,
  ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS: `${GlobalRouterPath.HOME}/scheduledAssessmentInstructions`,
  ONBOARDING_SCHEDULED_ASSESSMENT: `${GlobalRouterPath.HOME}/scheduledAssessment`,
  ONBOARDING_SCHEDULED_ASSESSMENT_CONFIRMATION: `${GlobalRouterPath.HOME}/scheduledAssessmentConfirmation`,
  ONBOARDING_SSN: `${GlobalRouterPath.HOME}/agentSSN`,
  ONBOARDING_DOB: `${GlobalRouterPath.HOME}/agentDoB`,
  SHOW_FRIENDS_SHIFTS: `${GlobalRouterPath.HOME}/agentShowFriendsShifts`,
};

const OnboardingRouterPathInfo: Record<keyof typeof OnboardingRouterPath, RoutePathInfo> = {
  ONBOARDING_INFO1: {
    name: "Onboarding Info 1",
  },
  ONBOARDING_INFO2: {
    name: "Onboarding Info 2",
  },
  ONBOARDING_INFO3: {
    name: "Onboarding Info 3",
  },
  ONBOARDING_ADDRESS: {
    name: "Onboarding Address",
  },
  ONBOARDING_LICENSE: {
    name: "Onboarding License",
  },
  ONBOARDING_SCHEDULED_ASSESSMENT_INSTRUCTIONS: {
    name: "Onboarding Scheduled Assessment Instructions",
  },
  ONBOARDING_SKILL_ASSESSMENT: {
    name: "Onboarding Skill Assessment",
  },
  ONBOARDING_SCHEDULED_ASSESSMENT_CONFIRMATION: {
    name: "Onboarding Scheduled Assessment Confirmation",
  },
  ONBOARDING_QUALIFICATION: {
    name: "Onboarding Qualification",
  },
  ONBOARDING_FIRST_NAME: {
    name: "Onboarding First Name",
  },
  ONBOARDING_LAST_NAME: {
    name: "Onboarding Last Name",
  },
  ONBOARDING_APPLY_REFERRAL_CODE: {
    name: "Onboarding Referral Submission",
  },
  ONBOARDING_EMAIL: {
    name: "Onboarding Email",
  },
  ONBOARDING_WELCOME: {
    name: "Onboarding Welcome",
  },
  ONBOARDING_POTENTIAL_SHIFTS: {
    name: "Onboarding Potential Shifts",
  },
  ONBOARDING_REVERIFY: {
    name: "Onboarding Reverify",
  },
  ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW: {
    name: "Onboarding Contractor Agreement Review",
  },
  ONBOARDING_CONTRACTOR_AGREEMENT: {
    name: "Onboarding Contractor Agreement",
  },
  ONBOARDING_STRIPE: {
    name: "Onboarding to Stripe",
  },
  ONBOARDING_PROFESSIONAL_REFERENCES: {
    name: "Professional References",
  },
  READY_TO_EARN: {
    name: "Ready to Earn",
  },
  ONBOARDING_PROCEED_TO_REQUIREMENT_INFO: {
    name: "Onboarding Proceed to Requirement Info",
  },
  ONBOARDING_UPLOAD_DOCUMENTS: {
    name: "Onboarding Upload Documents",
  },
  ONBOARDING_SCHEDULED_ASSESSMENT: {
    name: "Onboarding Scheduled Assessment",
  },
  ONBOARDING_SSN: {
    name: "Onboarding SSN",
  },
  ONBOARDING_DOB: {
    name: "Onboarding DoB",
  },
  SHOW_FRIENDS_SHIFTS: {
    name: "Show Friends Shifts",
  },
};

export { OnboardingRouterPath, OnboardingRouterPathInfo };
