import { Avatar as MuiAvatar } from "@mui/material";

import { type AvatarSize } from "../theming";
import { CbhIcon, type IconSize } from "./CbhIcon";

interface AvatarProps {
  src?: string;
  alt?: string;
  size?: AvatarSize;
  children?: React.ReactNode;
}

export const iconSizeByAvatarSize: Record<AvatarSize, IconSize> = {
  small: "small",
  medium: "large",
  large: "xLarge",
};

// TODO: Add Avatar from MUI to eslint ignore pattern after we roll out the redesign
export function Avatar(props: AvatarProps) {
  const { src, alt, size = "medium", children } = props;

  // Fallback to first letter of alt if src and children are not provided.
  // Replicates MUI's default behavior for broken images.
  // MUI does not show the fallback alt text if src is not provided.
  // https://mui.com/material-ui/react-avatar/#fallbacks
  if (!src && !children && alt) {
    const firstLetter = alt[0];
    return <MuiAvatar size={size}>{firstLetter}</MuiAvatar>;
  }

  return (
    <MuiAvatar src={src} alt={alt} size={size}>
      {children ?? <CbhIcon type="user" size={iconSizeByAvatarSize[size]} />}
    </MuiAvatar>
  );
}
