import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

import { type BadgeSize } from "../types";

declare module "@mui/material/Badge" {
  interface BadgeOwnProps {
    size?: BadgeSize;
  }
}

export const muiBadgeOverrides: Components<Theme> = {
  MuiBadge: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      colorInfo: ({ theme }) => theme.palette.info?.main,

      badge: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
        variants: [
          {
            props: { size: "small", variant: "standard" },
            style: {
              minWidth: "16px",
              height: "16px",
              padding: "0 4px",
              fontSize: theme.typography.caption.fontSize,
            },
          },
        ],
      }),
    },
  },
};
