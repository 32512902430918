import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";

import { RoutePathInfo } from "./interface";

export const TabRouterPath = DeprecatedGlobalAppV1Paths;

/**
 * @deprecated
 * This is no longer being maintained.
 * This is only used for logging and for one deprecated hook.
 * We will no longer be maintaining names for routes
 * The routes are the definitive truth, and it is more useful to know the exact route in logs.
 */
export const TabRouterPathInfo: Record<keyof typeof TabRouterPath, RoutePathInfo> = {
  MY_SHIFTS: {
    name: "My Shifts",
  },
  OPEN_SHIFTS: {
    name: "Open Shift Calendar",
  },
  URGENT_SHIFTS: {
    name: "Urgent Shifts",
  },
  RATINGS: {
    name: "Attendance Rating",
  },
  RATINGS_ATTENDANCE_SCORE: {
    name: "Attendance Rating",
  },
  RATINGS_PRIORITY_ACCESS: {
    name: "Priority Access Rating",
  },
  OPEN_SHIFT_DAY_VIEW: {
    name: "Open Shift Day View V2",
  },
  ACCOUNT: {
    name: "Account Page",
  },
  CHAT: {
    name: "Chat Page",
  },
  CHAT_CHANNELS_LIST: {
    name: "Workplace Chats",
  },
  SUPPORT: {
    name: "Support Page",
  },
  ADDRESS: {
    name: "Address View",
  },
  ADDRESS_SEARCH: {
    name: "Address Search View",
  },
  ADDRESS_EDIT: {
    name: "Address Edit Page",
  },
  PAYROLL: {
    name: "Payroll View",
  },
  DEPRECATED_V1_DOCUMENTS: {
    name: "Document List View",
  },
  DEPRECATED_V1_DOCUMENTS_HCF: {
    name: "Document List View for HCF",
  },
  DOCUMENT_VIEW: {
    name: "Document Detail View",
  },
  PROFILE: {
    name: "Profile View",
  },
  PROFILE_CONTRACT: {
    name: "Contract View",
  },
  REFERRAL: {
    name: "Referral Page",
  },
  CO_WORKER_REFERRAL: {
    name: "Referral Co-Worker Page",
  },
  MY_SHIFT_DAY: {
    name: "My Shift List Day View",
  },
  MY_SHIFT_DETAIL: {
    name: "Shift Detail Page",
  },
  UNVERIFIED_SHIFTS: {
    name: "Unverified Shifts Page",
  },
  SUCCESS_ONBOARDING: {
    name: "Success Onboarded",
  },
  PAYMENT_SERVICE_ONBOARDING: {
    name: "Onboarding Payment Service",
  },
  UPDATED_CONTRACTOR_AGREEMENT: {
    name: "Updated Contractor Agreement",
  },
  FACILITY_DETAIL: {
    name: "Workplace Detail",
  },
  NEED_HELP: {
    name: "Need Help?",
  },
  HELP_DETAILS: {
    name: "Help Details",
  },
  UPDATE_PROFILE: {
    name: "Update Profile",
  },
  DELETE_DATA: {
    name: "Delete Data",
  },
  LICENSE_MANAGER: {
    name: "License Manager",
  },
  LICENSE_DETAILS: {
    name: "License Details Page",
  },
  ADD_LICENSE: {
    name: "Add License",
  },
  COLLEAGUE_LIST: {
    name: "Colleague List",
  },
  FRIENDS_LIST: {
    name: "Work with Friends",
  },
  SETUP_STRIPE: {
    name: "Setup Stripe",
  },
  PROFESSIONAL_REFERENCE: {
    name: "Professional References",
  },
  PENDING_SENT_HOME_REQUESTS: {
    name: "Pending Sent Home Requests",
  },
  OPEN_SHIFT_FACILITY: {
    name: "Workplace Open Shifts",
  },
  NOTIFICATION_CENTER: {
    name: "Notification Center",
  },
  REFERRAL_V2: {
    name: "Refer Professionals",
  },
  RATE_NEGOTIATIONS: {
    name: "Rate Negotiations",
  },
  RATINGS_A_TEAM: {
    name: "A-Team",
  },
  CAREER_AGENT: {
    name: "Career Agent",
  },
  WORKPLACE_REFERRALS: {
    name: "Workplace Referrals",
  },
  RATE_NEGOTIATION_TOOL: {
    name: "Rate Negotiation Tool",
  },
};

/**
 * Checks to see if the base path is a shift path.
 * @param pathname Current path
 * @returns true if the path is a shift path
 */
export function isAShiftPath(pathname: string) {
  return (
    pathname.startsWith(TabRouterPath.OPEN_SHIFTS) ||
    pathname.startsWith(TabRouterPath.URGENT_SHIFTS) ||
    pathname.startsWith(TabRouterPath.MY_SHIFTS)
  );
}
