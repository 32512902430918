import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { useGetMissingRequirementsForWorkplaces } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { GroupedVirtuoso } from "react-virtuoso";

import { getMissingAndPendingDocumentsForQualification } from "../../Shift/Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { ShiftListGroupWrapper } from "../../Shift/Open/GroupWrapper";
import { ShiftListDateLabel } from "../../Shift/Open/ListDateLabel";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { ShiftListTopItemList } from "../../Shift/Open/TopItemList";
import type { OpenShift, OpenShiftWorkplace } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../paths";
import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { UrgentShiftsEmptyState } from "./EmptyState";

interface UrgentShiftsListProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  workplacesMap: Map<string, OpenShiftWorkplace>;
  scrollRef: React.RefObject<HTMLDivElement>;
}

export function UrgentShiftsList(props: UrgentShiftsListProps) {
  const { isError, isLoading, isSuccess, workplacesMap, scrollRef } = props;

  const { groupSortedShifts, shiftsByDateCount, dateGroups, listRef } =
    useVirtualShiftListContext();

  const { navigateToModal } = useShiftModalsDataContext();
  const { userId: workerId, licensesData } = useDefinedWorker();

  const hasShifts = groupSortedShifts.length > 0;

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    Array.from(workplacesMap.values(), (workplace) => ({
      id: workplace.id,
      type: workplace.attributes.type,
      msa: workplace.attributes.location.msa,
      state: workplace.attributes.location.state,
      region: workplace.attributes.location.region,
    })),
    licensesData?.map((l) => l.qualification),
    {
      enabled: hasShifts,
    }
  );

  if (isError) {
    return <Text align="center">Error loading urgent shifts</Text>;
  }

  if (isLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying urgent shifts",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isSuccess && !hasShifts) {
    return <UrgentShiftsEmptyState />;
  }

  return (
    <GroupedVirtuoso
      ref={listRef}
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      groupCounts={shiftsByDateCount}
      components={{
        TopItemList: ShiftListTopItemList,
        Group: ShiftListGroupWrapper,
        Footer: () => (
          // always rendering footer to have consistent bottom padding
          <Box
            sx={(theme) => ({
              paddingTop: 4,
              paddingBottom: `calc(
              ${theme.spacing(3)} +
              ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
              ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
        )`,
            })}
          />
        ),
      }}
      topItemCount={0}
      groupContent={(index) => {
        const label = dateGroups[index];

        return <ShiftListDateLabel label={label} shiftCount={shiftsByDateCount[index]} />;
      }}
      itemContent={(index) => {
        const shift = groupSortedShifts[index] as OpenShift;

        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!workplace) {
          return null;
        }

        const { missingDocuments, pendingDocuments } =
          getMissingAndPendingDocumentsForQualification(
            workplace.id,
            shift.attributes.qualification,
            missingRequirements.results
          );

        return (
          <OpenShiftCard
            key={shift.id}
            shift={shift}
            sx={{
              marginBottom: index < groupSortedShifts.length - 1 ? 5 : 0,
            }}
            workplace={workplace}
            missingDocuments={missingDocuments}
            pendingDocuments={pendingDocuments}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, { shiftId: shift.id });
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: shift.id });
            }}
          />
        );
      }}
    />
  );
}
