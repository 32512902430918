import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, AlertTitle } from "@mui/material";
import { BulletedTextList } from "@src/appV2/AttendanceScore/components/BulletedTextList";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";

export interface RestrictedAccountStatusWarningProps {
  reEnrollmentDate?: string;
  extraInformation?: string[];
}

export function RestrictedAccountStatusWarning(props: RestrictedAccountStatusWarningProps) {
  const { reEnrollmentDate, extraInformation } = props;

  return (
    <Alert variant="outlined" severity="error" icon={false}>
      <AlertTitle>
        <Text bold color={(theme) => theme.palette.error.main}>
          Your account is Restricted{" "}
          {isDefined(reEnrollmentDate)
            ? `until ${format(parseISO(reEnrollmentDate), "MM/dd/yyyy")}`
            : ""}
        </Text>
      </AlertTitle>
      {isDefined(extraInformation) && extraInformation.length > 0 && (
        <BulletedTextList items={extraInformation} />
      )}
    </Alert>
  );
}
