import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";

import { isDocumentInstantReview } from "../../Bookability/Documents/isDocumentInstantReview";
import { type ShiftWithType } from "../../types";
import { ShiftBottomSheetMissingDocumentCard } from "./MissingDocumentCard";
import { ShiftBottomSheetMissingDocumentsCard } from "./MissingDocumentsCard";
import { ShiftBottomSheetPendingDocumentsCard } from "./PendingDocumentsCard";
import { type ShiftBlockingDocuments } from "./types";

interface ShiftBottomSheetBlockingDocumentsCardProps {
  blockingDocuments: ShiftBlockingDocuments;
  shift: ShiftWithType;
  onClick: () => void;
}

const MAX_DOCUMENTS_MISSING_TO_SHOW_INDIVIDUAL_CAROUSEL_CARDS = 3;

export function ShiftBottomSheetBlockingDocumentsCard(
  props: ShiftBottomSheetBlockingDocumentsCardProps
) {
  const { shift, blockingDocuments, onClick } = props;
  const { missingDocumentsSet, expiringDocumentsSet, pendingDocumentsSet } = blockingDocuments;

  const hasMissingDocuments = missingDocumentsSet.size > 0;
  const hasExpiringDocuments = expiringDocumentsSet.size > 0;
  const hasPendingDocuments = pendingDocumentsSet.size > 0;
  const { navigateToModal } = useShiftModalsDataContext();

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  // We intentionally don't show the pending documents card if there are missing or expiring documents,
  // as pending documents are only shown after the missing or expiring documents are completed
  if (hasMissingDocuments || hasExpiringDocuments) {
    const combinedDocuments = [...missingDocumentsSet, ...expiringDocumentsSet];
    const totalDocumentsCount = combinedDocuments.length;

    if (
      totalDocumentsCount <= MAX_DOCUMENTS_MISSING_TO_SHOW_INDIVIDUAL_CAROUSEL_CARDS &&
      combinedDocuments.every((document) => document.visibleToHCP)
    ) {
      return (
        <>
          {combinedDocuments.map((document) => {
            const isExpiring = expiringDocumentsSet.has(document);
            return (
              <ShiftBottomSheetMissingDocumentCard
                key={document._id}
                document={document}
                isExpiring={isExpiring}
                shift={shift}
              />
            );
          })}
        </>
      );
    }
  }

  if (hasPendingDocuments && !hasMissingDocuments && !hasExpiringDocuments) {
    return (
      <ShiftBottomSheetPendingDocumentsCard
        pendingDocumentsCount={pendingDocumentsSet.size}
        onClick={onClick}
      />
    );
  }

  const missingAndExpiringDocuments = [...missingDocumentsSet, ...expiringDocumentsSet];
  const areAllBlockingDocumentsInstantReview = missingAndExpiringDocuments.every((document) =>
    isDocumentInstantReview({
      document,
      isHelloSignEmbeddedFlowEnabled,
    })
  );

  return (
    <ShiftBottomSheetMissingDocumentsCard
      missingDocumentsCount={missingAndExpiringDocuments.length}
      isInstantReview={areAllBlockingDocumentsInstantReview}
      onClick={() => {
        if (areAllBlockingDocumentsInstantReview) {
          navigateToModal(
            SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
            {
              requirementId: missingAndExpiringDocuments[0].reqId,
              shiftId: shift?._id ?? "",
            },
            true
          );
        } else {
          onClick();
        }
      }}
    />
  );
}
