import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { eachDayOfInterval, formatISO, parseISO } from "date-fns";
import { useEffect, useRef } from "react";

import { APP_V2_USER_EVENTS, logEvent } from "../../../lib/analytics";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { OpenShiftDatePickerButton } from "../../Shift/Calendar/OpenShiftDatePickerButton";
import { ShiftDateCell } from "../../Shift/Calendar/ShiftDateCell";
import { ShiftDateListWrapper } from "../../Shift/Calendar/ShiftDateListWrapper";
import { useShiftDiscoveryCalendarData } from "../../Shift/Calendar/useShiftDiscoveryCalendarData";
import { type OpenShift } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext";
import { getBookedShiftTimeSlotsFromWorkerDayShifts } from "../../utils/getBookedShiftTimeSlotsFromWorkerDayShifts";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { ShiftDiscoveryCalloutsContainer } from "../CalloutsContainer";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";

interface ListViewPageHeaderProps {
  unfilteredOpenShifts: OpenShift[];
}

export function ListViewPageHeader(props: ListViewPageHeaderProps) {
  const { unfilteredOpenShifts } = props;
  const {
    workerShiftsByDate,
    filteredOpenShiftsCountData,
    dateRange,
    openShiftsCountIsLoading,
    workerShiftsIsLoading,
  } = useShiftDiscoveryCalendarData({
    initialDateRange: getShiftDiscoveryDefaultDateRange(),
  });

  const scrolledCell = useRef<HTMLButtonElement>(null);

  const { scrollToDate, scrolledDate } = useVirtualShiftListContext();

  const { navigateToModal } = useShiftModalsDataContext();

  const { dates } = useShiftDiscoveryUserFiltersContext();

  const daysToShow =
    dates.length > 0
      ? dates.map((date) => parseISO(date))
      : eachDayOfInterval({
          start: dateRange.startDate,
          end: dateRange.endDate,
        });

  useEffect(() => {
    if (isDefined(scrolledDate) && scrolledCell.current) {
      scrolledCell.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [scrolledDate]);

  return (
    <PageHeaderWrapper sx={{ paddingBottom: 4 }}>
      <Stack direction="row">
        <OpenShiftDatePickerButton
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPENED);
            navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
          }}
        />

        <ShiftDateListWrapper>
          {daysToShow.map((date) => {
            const formattedDate = formatISO(date, { representation: "date" });
            const isDateAvailableFromCalendarData = isDefined(
              filteredOpenShiftsCountData?.[formattedDate]
            );

            /* If we can't get open shifts data from the shifts count data, for
             * example because the list view date filter falls outside the initial
             * window of 2 weeks, we'll instead load shift availability from open
             * shifts data instead for now.
             */
            const isDateAvailableFromOpenShiftsData = unfilteredOpenShifts.some(
              (shift) =>
                formatISO(parseISO(shift.attributes.start), { representation: "date" }) ===
                formattedDate
            );

            const workerDayShifts = workerShiftsByDate?.[formattedDate];
            const bookedShiftTimeSlots =
              getBookedShiftTimeSlotsFromWorkerDayShifts(workerDayShifts);
            const isLoading = openShiftsCountIsLoading || workerShiftsIsLoading;

            return (
              <ShiftDateCell
                key={formattedDate}
                ref={scrolledDate === formattedDate ? scrolledCell : undefined}
                size="medium"
                date={date}
                isLoading={isLoading}
                isSelected={dates.includes(formattedDate)}
                isDateAvailable={
                  isDateAvailableFromCalendarData || isDateAvailableFromOpenShiftsData
                }
                bookedShiftTimeSlots={bookedShiftTimeSlots}
                onToggleSelect={(isSelected) => {
                  logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_DATE_TOGGLED, {
                    date: formattedDate,
                    isSelected,
                    previousSelection: dates,
                  });

                  scrollToDate?.(formattedDate);
                }}
              />
            );
          })}
        </ShiftDateListWrapper>
      </Stack>

      <ShiftDiscoveryCalloutsContainer
        unfilteredOpenShifts={unfilteredOpenShifts}
        sx={{ marginTop: 4, px: 5 }}
      />
    </PageHeaderWrapper>
  );
}
