import { z } from "zod";

export enum WorkplaceShiftRateNegotiability {
  ON = "ON",
  OFF = "OFF",
}

export enum ShiftRateNegotiationRole {
  WORKER = "WORKER",
  WORKPLACE = "WORKPLACE",
  ADMIN = "ADMIN",
  NEW_PROPOSAL = "NEW_PROPOSAL",
}

export enum ShiftRateNegotiationHistoryAction {
  INITIAL = "INITIAL",
  STARTED = "STARTED",
  PROPOSED = "PROPOSED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  BOOKED_BY_HCP = "BOOKED_BY_HCP",
  BOOKED_BY_ANOTHER_HCP = "BOOKED_BY_ANOTHER_HCP",
  BOOKED_CONFLICTING_SHIFT = "BOOKED_CONFLICTING_SHIFT",
  SHIFT_ASSIGNED_BY_ADMIN = "SHIFT_ASSIGNED_BY_ADMIN",
  HIDDEN = "HIDDEN",
  SHIFT_LEAD_TIME_EXPIRED = "SHIFT_LEAD_TIME_EXPIRED",
  WORKER_SUSPENDED = "WORKER_SUSPENDED",
  WORKPLACE_SUSPENDED = "WORKPLACE_SUSPENDED",
  WORKER_DEACTIVATED = "WORKER_DEACTIVATED",
  WORKPLACE_DEACTIVATED = "WORKPLACE_DEACTIVATED",
  WORKER_BLOCKED = "WORKER_BLOCKED",
  WORKPLACE_BLOCKED = "WORKPLACE_BLOCKED",
}

export enum ShiftRateNegotiationStatus {
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
}

export const shiftRateNegotiationHistoryResponseSchema = z.object({
  action: z.nativeEnum(ShiftRateNegotiationHistoryAction),
  createdByUserId: z.string(),
  createdByUserRole: z.nativeEnum(ShiftRateNegotiationRole),
  payRate: z.number().optional(),
  start: z.string().optional(),
  end: z.string().optional(),
  createdAt: z.string().optional(),
});

export const shiftRateNegotiationMetadataSchema = z.object({
  lastOffer: shiftRateNegotiationHistoryResponseSchema,
  shiftDetails: z.record(z.string(), z.any()).optional(),
});

export const shiftRateNegotiationSchema = z.object({
  _id: z.string(),
  shiftId: z.string(),
  workerId: z.string(),
  workplaceId: z.string(),
  status: z.nativeEnum(ShiftRateNegotiationStatus),
  multiplier: z.number(),
  initialChargeRate: z.number(),
  initialPayRate: z.number(),
  initialStart: z.string(),
  initialEnd: z.string(),
  history: z.array(shiftRateNegotiationHistoryResponseSchema),
  metadata: shiftRateNegotiationMetadataSchema,
});

export type ShiftRateNegotiationHistoryResponse = z.infer<
  typeof shiftRateNegotiationHistoryResponseSchema
>;
export type ShiftRateNegotiationMetadata = z.infer<typeof shiftRateNegotiationMetadataSchema>;
export type ShiftRateNegotiation = z.infer<typeof shiftRateNegotiationSchema>;
