import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { useListViewOpenShiftsData } from "./useListViewOpenShiftsData";

export function useFilteredListViewOpenShiftsData() {
  const { distance, ...localUserFilters } = useShiftDiscoveryUserFiltersContext();

  const {
    shifts = [],
    workplacesMap = new Map(),
    ...openShiftsDataResult
  } = useListViewOpenShiftsData({ distance });

  return {
    data: {
      filteredOpenShifts: filterOpenShifts({ shifts }, localUserFilters),
      unfilteredOpenShifts: shifts,
      workplacesMap,
    },
    ...openShiftsDataResult,
  };
}
