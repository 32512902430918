import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  type CreateAssessmentEventResponse,
  useCreateAssessmentEvent,
} from "./useCreateAssessmentEvent";

export const submitAssessmentParamsSchema = z.object({
  workerId: z.string(),
  assessmentId: z.string(),
});
export type SubmitAssessmentParams = z.infer<typeof submitAssessmentParamsSchema>;

export function useSubmitAssessment(
  options: UseMutationOptions<
    CreateAssessmentEventResponse,
    AxiosError,
    SubmitAssessmentParams
  > = {}
) {
  const mutation = useCreateAssessmentEvent({
    onSuccess: (_, params) => {
      logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SUBMITTED_EVENT, {
        ...params,
      });
    },
    onError: (error, variables) => {
      logEvent(APP_V2_APP_EVENTS.SKILLS_ASSESSMENTS_SUBMITTED_EVENT_FAILURE, { error, variables });
    },
    ...options,
  });

  return {
    ...mutation,
    mutateAsync: async (params: SubmitAssessmentParams) => {
      const submittedParams = { ...params, status: "SUBMITTED" };
      return await mutation.mutateAsync(submittedParams);
    },
  };
}
