import { isDefined } from "@clipboard-health/util-ts";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { type ShiftInvite } from "@src/appV2/Shifts/ShiftInvites/types";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { useShiftInviteActions } from "./api/useShiftInviteActions";
import { useShiftInviteItemContext } from "./context/shiftInviteItemContext";
import {
  ShiftInviteConflictDialog,
  ShiftInviteConflictingInviteDialog,
  ShiftInviteOverworkingDialog,
  ShiftInviteWeeklyHoursLimitExceededDialog,
} from "./Dialogs";
import { ShiftInviteMissingCoreRequiredDocumentsDialog } from "./Dialogs/MissingCoreRequiredDocumentsDialog";
import { ShiftInviteMissingFacilityRequiredDocumentsDialog } from "./Dialogs/MissingFacilityRequiredDocumentsDialog";

interface ShiftInviteItemProps {
  shiftInvite: ShiftInvite;
}
export function ShiftInviteItemDialog(props: ShiftInviteItemProps) {
  const { shiftInvite } = props;
  const history = useHistory();
  const { acceptShiftInvite } = useShiftInviteActions();
  const {
    conflictingShift,
    conflictingInvites,
    hasAgreedToDeclineConflictingInvites,
    setHasAgreedToDeclineConflictingInvites,
    conflictingShiftDialogState,
    conflictingInviteDialogState,
    overworkingShiftDialogState,
    missingFacilityRequiredDocumentsDialogState,
    missingCoreRequiredDocumentsDialogState,
    missingDocuments,
    weeklyHoursLimitExceededDialogState,
    weeklyHoursLimit,
  } = useShiftInviteItemContext();

  const [skipFacilityRequiredDocumentCheck, setSkipFacilityRequiredDocumentCheck] = useState(false);

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  return (
    <>
      {isDefined(conflictingShift) && (
        <ShiftInviteConflictDialog
          isClipboardScoreEnabled={isClipboardScoreEnabled}
          modalState={conflictingShiftDialogState}
          conflictingShift={conflictingShift}
          onConfirm={() => {
            conflictingShiftDialogState.closeModal();
            history.push(DeprecatedGlobalAppV1Paths.MY_SHIFTS);
          }}
        />
      )}
      {conflictingInvites?.length > 0 && (
        <ShiftInviteConflictingInviteDialog
          modalState={conflictingInviteDialogState}
          conflictingInvites={conflictingInvites}
          onCancel={conflictingInviteDialogState.closeModal}
          onConfirm={async () => {
            conflictingInviteDialogState.closeModal();
            setHasAgreedToDeclineConflictingInvites(true);
            await acceptShiftInvite({
              shiftInvite,
              shouldDeclineConflictingInvites: true,
            });
          }}
        />
      )}

      <ShiftInviteOverworkingDialog
        modalState={overworkingShiftDialogState}
        onCancel={() => {
          overworkingShiftDialogState.closeModal();
          // We need to reset this state because of the case where the user
          // agrees to decline conflicting invites, but then are prompted for
          // overworking and decide not to accept the shift invite.
          setHasAgreedToDeclineConflictingInvites(false);
        }}
        onConfirm={async () => {
          overworkingShiftDialogState.closeModal();
          await acceptShiftInvite({
            shiftInvite,
            isOverworkingShiftsAllowed: true,
            shouldDeclineConflictingInvites: hasAgreedToDeclineConflictingInvites,
            skipFacilityRequiredDocumentCheck,
          });
          logEvent(APP_V2_APP_EVENTS.ACCEPT_OVERWORKING_SHIFT_INVITE, {
            shiftInvite,
          });
        }}
      />
      <ShiftInviteMissingFacilityRequiredDocumentsDialog
        modalState={missingFacilityRequiredDocumentsDialogState}
        missingDocuments={missingDocuments}
        onConfirm={async () => {
          missingFacilityRequiredDocumentsDialogState.closeModal();
          setSkipFacilityRequiredDocumentCheck(true);
          await acceptShiftInvite({
            shiftInvite,
            skipFacilityRequiredDocumentCheck: true,
          });
        }}
      />
      <ShiftInviteMissingCoreRequiredDocumentsDialog
        modalState={missingCoreRequiredDocumentsDialogState}
        missingDocuments={missingDocuments}
      />
      {weeklyHoursLimit > 0 && (
        <ShiftInviteWeeklyHoursLimitExceededDialog
          isClipboardScoreEnabled={isClipboardScoreEnabled}
          modalState={weeklyHoursLimitExceededDialogState}
          weeklyHoursLimit={weeklyHoursLimit}
          onConfirm={async () => {
            weeklyHoursLimitExceededDialogState.closeModal();
            history.push(DeprecatedGlobalAppV1Paths.MY_SHIFTS);
          }}
        />
      )}
    </>
  );
}
