import { isDefined } from "@clipboard-health/util-ts";

import { useOpenShifts } from "../../OpenShifts/api/useOpenShifts";
import { useGetBookabilityStatusForShifts } from "../../OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import type { TimeSlot } from "../Shift/Open/types";
import { resolveShiftType } from "../Shift/resolveShiftType";
import { type ShiftWithType } from "../Shift/types";

interface UseGetShiftModalShiftDataProps {
  shiftId: string;
  workerId: string;
}

interface UseGetShiftModalShiftDataOptions {
  enabled?: boolean;
}

/**
 * Fetches shift details and bookability status for a shift, needed to open a shift modal
 */
export function useGetShiftModalShiftData(
  props: UseGetShiftModalShiftDataProps,
  options: UseGetShiftModalShiftDataOptions = {}
) {
  const { shiftId, workerId } = props;
  const { enabled } = options;

  const {
    data: shiftResponse,
    isError: shiftIsError,
    isLoading: shiftIsLoading,
  } = useOpenShifts(
    {
      id: [shiftId],
      includeMHRRestrictedShifts: true,
    },
    { enabled }
  );

  const { shiftBookabilityById, queryResult: bookabilityStatusQueryResult } =
    useGetBookabilityStatusForShifts(
      {
        shiftIds: [shiftId],
        agentId: workerId,
      },
      {
        enabled,
      }
    );

  const { isLoading: bookabilityIsLoading, isError: bookabilityIsError } =
    bookabilityStatusQueryResult;

  const bookabilityStatus = shiftBookabilityById[shiftId];

  let shift: ShiftWithType | undefined;

  const fetchedShift = shiftResponse?.openAgentShifts.find((s) => s.shiftId === shiftId);
  if (isDefined(fetchedShift)) {
    shift = {
      ...fetchedShift,
      name: fetchedShift.name as unknown as TimeSlot,
      type: resolveShiftType({
        isUrgent: isDefined(fetchedShift.urgency),
        window: fetchedShift.window,
        priorityTill: fetchedShift.priorityTill,
      }),
    };
  }

  return {
    data: {
      shift,
      bookabilityStatus,
    },
    isLoading: shiftIsLoading || bookabilityIsLoading,
    isError: shiftIsError || bookabilityIsError,
  };
}
