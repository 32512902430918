import { LoadingButton, Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, DialogContent, Skeleton, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { CounterText } from "@src/appV2/redesign/components/CounterText";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { DialogHeader } from "@src/appV2/redesign/components/DialogHeader";
import { FacilityAvatar } from "@src/appV2/redesign/components/FacilityAvatar";
import { type PlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/types";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import { type WorkplaceProfile } from "@src/appV2/redesign/Workplace/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { filter } from "lodash";
import { useHistory } from "react-router-dom";

import {
  transformResponseIntoPlacement,
  useFetchPaginatedPlacements,
} from "../api/useFetchPaginatedPlacements";
import { PlacementCardItem } from "../components/PlacementCardItem";
import { PlacementsLoader } from "../components/PlacementsLoader";
import { PLACEMENT_WORKPLACE_PROFILE_PATH } from "../paths";
import { buildPlacementsFilter } from "../utils";

interface WorkplacePlacementsProps {
  workplaceId: string;
  workplace?: WorkplaceProfile;
  modalState: UseModalState;
  placementCandidate: PlacementCandidate;
}

export function WorkplacePlacements(props: WorkplacePlacementsProps) {
  const { workplaceId, modalState, workplace, placementCandidate } = props;

  const history = useHistory();
  const { generateModalPath } = useWorkplaceModalsDataContext();
  const worker = useDefinedWorker();

  const {
    data: placementsData,
    isLoading: isPlacementsLoading,
    isSuccess: isPlacementsSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: placementCandidate.id ?? "",
      filter: buildPlacementsFilter({
        filter: { workplaceId },
        worker,
      }),
    },
    {
      enabled: isDefined(worker.userId),
    }
  );

  const placementsCount = placementsData?.pages.reduce(
    (count, page) => count + page.data.length,
    0
  );

  return (
    <>
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />
        <Stack spacing={8}>
          <Stack direction="row" spacing={7} alignItems="center">
            <FacilityAvatar facilityId={workplaceId} size="medium" />

            {isDefined(workplace?.name) && (
              <Title component="h3" variant="h5">
                {workplace?.name}
              </Title>
            )}

            {!isDefined(workplace?.name) && (
              <Stack flex={1}>
                <Skeleton variant="text" />
              </Stack>
            )}
          </Stack>
          {placementsCount ? (
            <CounterText count={placementsCount}>
              <Title
                bold
                variant="h5"
                component="h4"
                sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
              >
                Jobs
              </Title>
            </CounterText>
          ) : undefined}

          <Stack spacing={6}>
            {isPlacementsLoading ? <PlacementsLoader /> : undefined}

            {isPlacementsSuccess && (placementsData.pages[0]?.data.length ?? 0) === 0 ? (
              <Stack justifyContent="center" flex={1} alignItems="center">
                <Text variant="body1">No relevant jobs listed yet!</Text>
              </Stack>
            ) : undefined}

            {isDefined(placementsData) && placementsData.pages.length > 0 ? (
              <Stack direction="column" spacing={6}>
                {placementsData?.pages.flatMap((placementPage, pageIndex) => {
                  return placementPage.data.map((placement, placementIndex) => {
                    const placementWithDetails = transformResponseIntoPlacement(
                      placement,
                      placementPage.included
                    );
                    return (
                      <PlacementCardItem
                        key={placementWithDetails.id}
                        placement={placementWithDetails}
                        placementCandidateId={placementCandidate.id}
                        pageIndex={pageIndex}
                        placementIndex={placementIndex}
                      />
                    );
                  });
                })}
              </Stack>
            ) : undefined}

            {hasNextPage ? (
              <Stack direction="row" justifyContent="center">
                <LoadingButton
                  sx={{ mb: 10 }}
                  isLoading={isFetchingNextPage}
                  onClick={async () => {
                    await fetchNextPage();
                    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                      placementCandidateId: placementCandidate.id,
                      filters: { ...filter },
                      action: "load_more_clicked",
                    });
                  }}
                >
                  Load more
                </LoadingButton>
              </Stack>
            ) : undefined}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => String(theme.palette.background.tertiary)}
        onClose={() => {
          modalState.closeModal();
        }}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ maxWidth: "60%" }}
          onClick={() => {
            history.push(
              generateModalPath(PLACEMENT_WORKPLACE_PROFILE_PATH, {
                workplaceId,
              })
            );
          }}
        >
          View workplace profile
        </Button>
      </DialogFooter>
    </>
  );
}
