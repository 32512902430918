import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSetupPaymentsCardProps {
  shiftId: string;
}

export function ShiftBottomSetupPaymentsCard(props: ShiftBottomSetupPaymentsCardProps) {
  const { shiftId } = props;
  const history = useHistory();
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Set up your Stripe account"
      description="Our payments partner still needs some information in order to pay you. All debit cards and most bank account types are eligible for instant payouts!"
      illustrationType="instant-pay"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_SETUP_PAYMENTS, {
              shiftId,
            });

            history.push(`${DeprecatedGlobalAppV1Paths.PAYMENT_SERVICE_ONBOARDING}`);
          }}
        >
          Setup payments
        </Button>
      }
    />
  );
}
