import { Text, Title } from "@clipboard-health/ui-react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGetAttendanceScoreHistory } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreHistory";
import { type FinalRestriction } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import {
  DEFAULT_FINAL_RESTRICTION,
  DEFAULT_RESTRICTION_COUNT,
  DEFAULT_RESTRICTION_DURATION_IN_DAYS,
} from "@src/appV2/AttendanceScore/constants";
import pluralize from "pluralize";

import { AttendanceScoreHistoryRowItem } from "./attendanceScoreHistoryRow";

export function AttendanceScoreRecentActivity(props: {
  workerUserId: string;
  restrictionCount: number | undefined;
  restrictionDuration: number | undefined;
  finalRestriction: FinalRestriction;
}) {
  // Defaults are needed for restriction fields since the APS has defined all fields as optional
  const {
    workerUserId,
    restrictionCount = DEFAULT_RESTRICTION_COUNT,
    restrictionDuration = DEFAULT_RESTRICTION_DURATION_IN_DAYS,
    finalRestriction = DEFAULT_FINAL_RESTRICTION,
  } = props;
  const { data: attendanceScoreHistory, isSuccess: attendanceScoreHistoryIsSuccess } =
    useGetAttendanceScoreHistory({ userId: workerUserId });
  const restrictionsAllowedBeforeFinalRestriction = finalRestriction.restriction - 1;
  return (
    <Stack spacing={1}>
      <Title bold component="h3">
        Activity History
      </Title>
      <Text gutterBottom variant="subtitle2" color="text.secondary">
        <Text bold variant="subtitle2" color="text.secondary">
          Your account has {restrictionCount} {pluralize("restriction", restrictionCount)} in the
          last {finalRestriction.window} {pluralize("month", finalRestriction.window)}.
        </Text>
        The first {restrictionsAllowedBeforeFinalRestriction}{" "}
        {pluralize("restriction", restrictionsAllowedBeforeFinalRestriction)} will last{" "}
        {restrictionDuration} {pluralize("day", restrictionDuration)} each, and the final
        restriction will last {finalRestriction.duration}{" "}
        {pluralize("day", finalRestriction.duration)}.
      </Text>
      <Text gutterBottom bold variant="subtitle2" color="text.secondary">
        {!attendanceScoreHistoryIsSuccess && "Loading..."}
        {attendanceScoreHistoryIsSuccess &&
          (attendanceScoreHistory.scoreLogs.length > 0
            ? "The latest changes to your account's attendance score:"
            : "No activities yet")}
      </Text>
      {attendanceScoreHistoryIsSuccess && attendanceScoreHistory.scoreLogs.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "8px" }}>Date</TableCell>
                <TableCell sx={{ padding: "8px" }}>Event</TableCell>
                <TableCell sx={{ padding: "0px" }}>Change</TableCell>
                <TableCell sx={{ padding: "0px" }} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceScoreHistory.scoreLogs.map((scoreLog) => (
                <AttendanceScoreHistoryRowItem key={scoreLog.id} scoreLog={scoreLog} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
}
