export function getOrdinalPostfix(n: number): string {
  if (n === 0) {
    return "";
  }

  const ordinalRules = new Intl.PluralRules("en", { type: "ordinal" });
  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);
  const rule = ordinalRules.select(n);
  return suffixes.get(rule) ?? "";
}
