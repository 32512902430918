import { Li, Text, Title, Ul } from "@clipboard-health/ui-react";
import { Button, Link, Stack } from "@mui/material";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";

import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";

interface Props {
  scheduledFor: string;
  onContinue: () => void;
}
export function OnboardingStatusScheduled(props: Props) {
  const { scheduledFor, onContinue } = props;
  const { skillsAssessmentConfig } = useSkillsAssessmentFeatureFlags();

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          You&apos;ve booked an assessment for{" "}
          <b>
            {format(parseISO(scheduledFor), "MMMM do")} at{" "}
            {format(parseISO(scheduledFor), "h:mmaaa z")}
          </b>
          .
        </Title>
        <Stack paddingX={1}>
          <Ul>
            <Li>
              <Text bold sx={{ lineHeight: "24px" }}>
                We&apos;ve just sent a text to you with a link to the assessment, save this link!
              </Text>
            </Li>
            <Li>
              <Text sx={{ lineHeight: "24px" }}>
                At your scheduled time, use the link you saved to navigate to the assessment and
                complete it.
              </Text>
            </Li>
            <Li>
              <Text sx={{ lineHeight: "24px" }}>
                If you lose your link, login to your Clipboard account and go to &quot;My
                Account&quot; &#8594; &quot;Skills Assessment&quot; to find it.
              </Text>
            </Li>
            <Li>
              <Text sx={{ lineHeight: "24px" }}>
                Tap{" "}
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    void openBrowser(skillsAssessmentConfig.supportArticleLink);
                  }}
                >
                  here
                </Link>{" "}
                for additional details.
              </Text>
            </Li>
          </Ul>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{ fontSize: 16, borderRadius: 84 }} // Override font size to match design
        onClick={onContinue}
      >
        Continue
      </Button>
    </Stack>
  );
}
