import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Skeleton, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { useEffect, useRef } from "react";
import { generatePath } from "react-router-dom";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { DialogHeader } from "../../components/DialogHeader";
import { FacilityAvatar } from "../../components/FacilityAvatar";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext";
import { ShiftDiscoveryListViewListTitle } from "../../ShiftDiscovery/ListView/ListTitle";
import {
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH,
} from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import type { WorkplaceProfile } from "../types";
import { WorkplaceOpenShiftsList } from "./List";
import { useWorkplaceOpenShiftsData } from "./useWorkplaceOpenShiftsData";

interface WorkplaceOpenShiftsModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading: boolean;
}

export function WorkplaceOpenShiftsModal(props: WorkplaceOpenShiftsModalProps) {
  const { modalState, workplaceId, workplace, isLoading } = props;
  const { name } = workplace ?? {};

  const {
    data: { workplaceShifts, workplacesMap },
    isError: openShiftsIsError,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    daysCount,
    pagination,
  } = useWorkplaceOpenShiftsData(workplace);

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  /**
   * When this modal is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this workplace modal
   */
  useEffect(() => {
    const path = generatePath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
      workplaceId,
    });

    setBaseNavigationPathOverride(path);

    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride, workplaceId]);

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <VirtualShiftListContextProvider shifts={workplaceShifts}>
        <DialogContent
          ref={scrollRef}
          sx={{ px: 7, pt: 0, display: "flex", flexDirection: "column" }}
        >
          <DialogHeader />

          <Stack spacing={8} sx={{ flex: 1 }}>
            <Stack direction="row" spacing={7} alignItems="center">
              <FacilityAvatar facilityId={workplaceId} size="medium" />

              {isDefined(name) && (
                <Title component="h3" variant="h5">
                  {name}
                </Title>
              )}

              {isLoading && !isDefined(name) && (
                <Stack flex={1}>
                  <Skeleton variant="text" />
                </Stack>
              )}
            </Stack>

            {openShiftsIsSuccess && workplaceShifts.length > 0 && (
              <ShiftDiscoveryListViewListTitle
                count={workplaceShifts.length}
                daysCount={daysCount}
                allShiftsCount={workplaceShifts.length}
              />
            )}

            <WorkplaceOpenShiftsList
              scrollRef={scrollRef}
              isError={openShiftsIsError}
              isLoading={openShiftsIsLoading}
              isSuccess={openShiftsIsSuccess}
              workplace={workplacesMap.get(workplaceId)!}
              loadMore={() => {
                void pagination?.fetchNextPage();
              }}
              canLoadMore={Boolean(pagination?.hasNextPage)}
              isLoadingMore={pagination?.isFetchingNextPage ?? false}
            />
          </Stack>
        </DialogContent>

        <DialogFooter
          withShadow
          orientation="horizontal"
          backgroundColor={(theme) => theme.palette.background.tertiary}
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ maxWidth: "60%" }}
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
              workplaceId,
            })}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                workplaceId,
                trigger: "Workplace Open Shifts Modal",
              });
            }}
          >
            View workplace profile
          </Button>
        </DialogFooter>
      </VirtualShiftListContextProvider>
    </FullScreenDialog>
  );
}
