import "./style.scss";

import { formatDateTime } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { Link } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { ATTENDANCE_SCORE } from "@src/appV2/AttendanceScore/constants";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { openInAppBrowser } from "@src/lib/deprecatedCode";

import { DEFAULT_TIMEZONE } from "./constants";
import { getAccountRestrictedMessage, getFavoritePolicyEnrolledMessage } from "./messageString";

interface MyAttendanceScoreProps {
  score: number;
  accountStatus: {
    status?: AgentStage | null;
    reEnrollmentDate?: string | undefined;
  };
  policyDetailUrl: string;
  tmz?: string;
}

export function MyAttendanceScoreContainer(props: MyAttendanceScoreProps) {
  const {
    score,
    accountStatus: { status, reEnrollmentDate },
    policyDetailUrl,
    tmz,
  } = props;
  const getScoreStyle = () => {
    if (score >= 70) {
      return "ratings-green";
    }
    if (score >= 30) {
      return "ratings-yellow";
    }
    if (score >= 1) {
      return "ratings-red";
    }
    return "ratings-black";
  };
  const { aTeamStatus, aTeamWorkplacesCount } = useGetATeamWorkplaces();
  const formattedDate = isDefined(reEnrollmentDate)
    ? formatDateTime(reEnrollmentDate, { timeZone: tmz ?? DEFAULT_TIMEZONE })
    : undefined;

  return (
    <div
      className="ratings-my-attendance-score-container"
      data-testid="my-attendance-score-container"
    >
      <div className={`ratings-score ${getScoreStyle()}`}>{score}</div>
      <div className="ratings-my-attendance-score-title">{ATTENDANCE_SCORE}</div>
      <div className="ratings-my-attendance-score-description">
        <>
          {status === AgentStage.RESTRICTED
            ? getAccountRestrictedMessage({
                formattedDate,
                aTeamStatus,
                aTeamWorkplacesCount,
              })
            : getFavoritePolicyEnrolledMessage()}{" "}
          <Link
            onClick={() => {
              openInAppBrowser(policyDetailUrl);
            }}
          >
            Learn more
          </Link>
          .
        </>
      </div>
    </div>
  );
}
