import { get } from "@src/appV2/api";
import { type DateRange } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";

import {
  type AgentShiftRequest,
  agentShiftRequestSchema,
  type AgentShiftResponse,
  agentShiftResponseSchema,
  GET_AGENT_SHIFTS_URL,
} from "./useAgentShifts";

export interface GetPaginatedWorkerShiftsParams {
  initialDateRange: DateRange;
  tmz: string;
  groupByDate: boolean;
}

interface GetPaginatedWorkerShiftsResponse extends AgentShiftResponse {
  dateRange: DateRange;
}

export function usePaginatedWorkerShifts(
  params: GetPaginatedWorkerShiftsParams,
  options: UseInfiniteQueryOptions<GetPaginatedWorkerShiftsResponse> = {}
) {
  const { initialDateRange, groupByDate, tmz } = params;

  return useInfiniteQuery({
    queryKey: [GET_AGENT_SHIFTS_URL, { tmz, groupByDate }],
    queryFn: async ({ pageParam: nextDateRange }: QueryFunctionContext<QueryKey, DateRange>) => {
      const dateRange = nextDateRange ?? initialDateRange;

      const queryParams: AgentShiftRequest = {
        dateFilter: {
          start: dateRange.startDate.toISOString(),
          end: dateRange.endDate.toISOString(),
        },
        tmz,
        groupByDate,
      };

      const response = await get({
        url: GET_AGENT_SHIFTS_URL,
        queryParams,
        requestSchema: agentShiftRequestSchema,
        responseSchema: agentShiftResponseSchema,
      });

      return { ...response.data, dateRange };
    },
    meta: {
      userErrorMessage: "Something went wrong while loading worker shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_SHIFTS_FAILURE,
    },
    ...options,
  });
}
