export function getOtaBuildIdAsNumber(otaBuildId: string): number {
  if (!/^\d+$/.test(otaBuildId)) {
    return 0;
  }

  const buildNumber = Number.parseInt(otaBuildId, 10);
  if (Number.isNaN(buildNumber)) {
    return 0;
  }

  return buildNumber;
}
