import { isDefined } from "@clipboard-health/util-ts";
import { isFuture, parseISO } from "date-fns";

import { BookabilityUnmetCriteria } from "../OpenShifts/ShiftAction/types";
import {
  type FacilityTypeMetadata,
  type ShiftBlock,
  type ShiftBlocksResponse,
  type ShiftBlockWithMappedDetails,
  type ShiftTypeMetadata,
} from "./api/useFetchPaginatedShiftBlocks";

// Keep in sync with MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS at backend-main/src/modules/shifts/constants.ts
export const MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS = 6;

export function convertToMetadataObject<T extends { type: string; id: string }>(
  metadataArray: T[]
): Record<string, Record<string, T>> {
  const metadata: Record<string, Record<string, T>> = {};

  for (const item of metadataArray) {
    const { type, id } = item;

    if (!isDefined(metadata[type])) {
      metadata[type] = {};
    }

    metadata[type][id] = item;
  }

  return metadata;
}

export enum IncludedEntityType {
  FACILITY = "facility",
  SHIFT = "shift",
}

export function isFacilityTypeMetadata(typeMetadata: {
  type: string;
}): typeMetadata is FacilityTypeMetadata {
  return typeMetadata.type === IncludedEntityType.FACILITY;
}

export function isShiftTypeMetadata(typeMetadata: {
  type: string;
}): typeMetadata is ShiftTypeMetadata {
  return typeMetadata.type === IncludedEntityType.SHIFT;
}

export enum ShiftBookingType {
  PER_DIEM = "per-diem",
  BLOCK_BOOKING = "block-booking",
  HOME_HEALTH = "home-health",
  JOBS = "jobs",
}

export function isShiftBlockAssigned(shiftBlockShifts: ShiftBlockWithMappedDetails["shifts"]) {
  return shiftBlockShifts
    .filter((shift) => isFuture(parseISO(shift.end)))
    .every((shift) => isDefined(shift.agentId));
}

export function convertToShiftBookingType(shiftBookingType: string): ShiftBookingType {
  if (shiftBookingType === ShiftBookingType.BLOCK_BOOKING) {
    return ShiftBookingType.BLOCK_BOOKING;
  }

  return ShiftBookingType.PER_DIEM;
}

export function transformIntoShiftBlockWithDetails(
  shiftBlock: ShiftBlock,
  shiftBlockIncludedMetaData: ShiftBlocksResponse["included"]
): ShiftBlockWithMappedDetails {
  const shiftBlockMetadata = convertToMetadataObject(shiftBlockIncludedMetaData);
  const shifts = shiftBlock.relationships.shifts.data.map((shift) => {
    const shiftMetadata = shiftBlockMetadata[IncludedEntityType.SHIFT][
      shift.id
    ] as ShiftTypeMetadata;
    return {
      ...shiftMetadata.attributes,
      id: shift.id,
    };
  });
  return {
    ...shiftBlock.attributes,
    id: shiftBlock.id,
    isShiftBlockAssigned: isShiftBlockAssigned(shifts),
    shifts,
    facility: {
      ...(
        shiftBlockMetadata[IncludedEntityType.FACILITY][
          shiftBlock.relationships.facility.data.id
        ] as FacilityTypeMetadata
      ).attributes,
      id: shiftBlock.relationships.facility.data.id,
    },
  };
}

/**
 * Returns shift duration in hours and minutes e.g. "6 hours 30 minutes"
 */
interface ShiftDurationParams {
  shiftDuration: number;
  withLunchBreak?: boolean;
}

export function getHumanReadableShiftDuration({
  shiftDuration,
  withLunchBreak = false,
}: ShiftDurationParams): string {
  // Lunch break is hard coded to 30 mins.
  const totalHours = withLunchBreak ? shiftDuration + 0.5 : shiftDuration;
  if (totalHours === 0) {
    return "0 hrs";
  }

  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  const formattedHours = hours === 1 ? `${hours} hr` : `${hours} hrs`;
  const formattedMinutes = minutes === 1 ? `${minutes} min` : `${minutes} mins`;

  return minutes === 0 ? `${formattedHours}` : `${formattedHours} ${formattedMinutes}`;
}

const ShiftBlockErrorPrefix = "Shift block cannot be booked because";

export const BookShiftBlockErrors: Record<BookabilityUnmetCriteria, string> = {
  [BookabilityUnmetCriteria.FACILITY_CHARGE_RATE_MISSING]: `${ShiftBlockErrorPrefix} facility charge rate is missing.`,
  [BookabilityUnmetCriteria.FACILITY_PENDING]: `${ShiftBlockErrorPrefix} facility is in pending state.`,
  [BookabilityUnmetCriteria.FACILITY_SUSPENDED]: `${ShiftBlockErrorPrefix} facility is suspended.`,
  [BookabilityUnmetCriteria.SHIFT_ALREADY_GRABBED]: `${ShiftBlockErrorPrefix} is it not available.`,
  [BookabilityUnmetCriteria.SHIFT_DELETED]: `${ShiftBlockErrorPrefix} it is not available.`,
  [BookabilityUnmetCriteria.SHIFT_IN_PAST]: `${ShiftBlockErrorPrefix} it is in the past and cannot be booked.`,
  [BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED]: `${ShiftBlockErrorPrefix} it is not available for instant book.`,
  [BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_LEAD_TIME_NOT_SET]: `${ShiftBlockErrorPrefix} it is not available for instant book.`,
  [BookabilityUnmetCriteria.SHIFT_INVALID]: `${ShiftBlockErrorPrefix} it is invalid.`,
  [BookabilityUnmetCriteria.SHIFT_OVERLAPPING]: `${ShiftBlockErrorPrefix} it is overlapping with another shift/shift-block.`,
  [BookabilityUnmetCriteria.SHIFT_OVERWORKING]: `${ShiftBlockErrorPrefix} of overworking shifts.`,
  [BookabilityUnmetCriteria.WORKER_ACCOUNT_IN_REVIEW]: `${ShiftBlockErrorPrefix} your account is in review.`,
  [BookabilityUnmetCriteria.WORKER_CANT_CLAIM_SHIFT]: `${ShiftBlockErrorPrefix} you cannot claim this shift.`,
  [BookabilityUnmetCriteria.WORKER_DEACTIVATED]: `${ShiftBlockErrorPrefix} your account is deactivated.`,
  [BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT]: `${ShiftBlockErrorPrefix} your account is a test account.`,
  [BookabilityUnmetCriteria.WORKER_MISSING_LICENSE]: `${ShiftBlockErrorPrefix} you are missing a required license.`,
  [BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS]: `${ShiftBlockErrorPrefix} you are missing requirements.`,
  [BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED]: `${ShiftBlockErrorPrefix} you are not enrolled on stripe.`,
  [BookabilityUnmetCriteria.WORKER_PENDING_DOCUMENTS]: `${ShiftBlockErrorPrefix} you have a pending required document.`,
  [BookabilityUnmetCriteria.WORKER_RESTRICTED]: `${ShiftBlockErrorPrefix} your account is restricted.`,
  [BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT]: `${ShiftBlockErrorPrefix} you have not signed the latest contractor agreement.`,
  [BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES]: `${ShiftBlockErrorPrefix} you have not passed the quiz for workplace rules.`,
  [BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS]: `${ShiftBlockErrorPrefix} you have not passed the assessment.`,
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_DNR_LIMIT]: `${ShiftBlockErrorPrefix} you have reached the maximum DNR limit.`,
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_HOURS_PER_WEEK]: `${ShiftBlockErrorPrefix} you have reached the maximum number of worked hours per week.`,
  [BookabilityUnmetCriteria.WORKER_HAS_REACHED_MAX_CONSECUTIVE_HOURS]: `${ShiftBlockErrorPrefix} you have reached the maximum number of consecutive hours worked`,
};

export function isShiftBlockBookedByAgentId(
  shiftBlock: ShiftBlockWithMappedDetails,
  agentId: string
) {
  return shiftBlock.shifts.some((shift) => isDefined(shift.agentId) && shift.agentId === agentId);
}

export const SHIFT_BLOCK_ACTIONS = {
  BLOCK_LIST_VIEWED: "block-list-viewed",
  VIEW_BLOCK_DETAILS_TAPPED: "view-block-details-tapped",
  BLOCK_DETAILS_VIEWED: "block-details-viewed",
  FACILITY_PROFILE_CARD_TAPPED: "facility-profile-card-tapped",
  FACILITY_CHAT_TAPPED: "facility-chat-tapped",
  BOOK_BLOCK_TAPPED: "book-block-tapped",
  UPLOAD_REQUIRED_DOCUMENTS_TAPPED: "upload-required-documents-tapped",
  REQUEST_THIS_BLOCK_TAPPED: "request-this-block-tapped",
  MORE_ACTION_TAPPED: "more-action-tapped",
  CANCEL_BLOCK_TAPPED: "cancel-block-tapped",
  KEEP_BLOCK_TAPPED: "keep-block-tapped",
  YES_CANCEL_TAPPED: "yes-cancel-tapped",
  DISCARD_BLOCK_BOOKING_TAPPED: "discard-block-booking-tapped",
  DISCARD_REQUEST_THIS_BLOCK_TAPPED: "discard-request-this-block-tapped",
  CONFIRM_BOOK_BLOCK_TAPPED: "confirm-book-block-tapped",
  CONFIRM_REQUEST_THIS_BLOCK_TAPPED: "confirm-request-this-block-tapped",
  PROCEED_TO_MISSING_DOCUMENTS_TAPPED: "proceed-to-missing-documents-tapped",
  CLOSE_MISSING_DOCUMENTS_POPUP_TAPPED: "close-missing-documents-popup-tapped",
} as const;
