import {
  formatDateTimeWithTimeZone,
  formatShortDateWithWeekday,
} from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack } from "@mui/material";
import { AttendanceScoreColor } from "@src/appV2/AttendanceScore/utils";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import { capitalize } from "lodash";

// API returns null values here so that's what the type is going to be
export interface ScoreAdjustmentRowProps {
  id: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  newScore?: number | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  adjustmentAmount?: number | null;
  adjustmentDate: Date;
  adjustmentReason: string;
  adjustmentLocation?: string;
  adjustmentShiftStart?: Date;
  expanded: boolean;
  handleChange: (id: string, isExpanded: boolean) => void;
}

function getActivityAdjustmentColor(adjustmentAmount: number) {
  if (adjustmentAmount > 0) {
    return AttendanceScoreColor.GREEN;
  }

  if (adjustmentAmount < 0) {
    return AttendanceScoreColor.RED;
  }

  return AttendanceScoreColor.BLACK;
}

export function ScoreAdjustmentRow(props: ScoreAdjustmentRowProps) {
  const {
    id,
    newScore,
    adjustmentAmount,
    adjustmentDate,
    adjustmentReason,
    adjustmentLocation,
    adjustmentShiftStart,
    expanded,
    handleChange,
  } = props;
  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={(_, isExpanded) => {
        handleChange(id, isExpanded);
      }}
    >
      <AccordionSummary
        expandIcon={<CbhIcon type="chevron-down" />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "#DEE2E6",
        }}
      >
        <Stack
          direction="row"
          flexGrow={1}
          paddingLeft={1}
          gap={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Text variant="subtitle1">{formatShortDateWithWeekday(adjustmentDate)}</Text>
            <Text italic>{capitalize(adjustmentReason)}</Text>
          </Stack>
          <Stack
            divider={<Divider flexItem orientation="vertical" variant="middle" />}
            direction="row"
            justifyContent="space-between"
            spacing={1}
            paddingRight={1}
          >
            {isDefined(adjustmentAmount) && (
              <Text
                bold
                sx={{
                  color: getActivityAdjustmentColor(adjustmentAmount),
                }}
              >
                {adjustmentAmount > 0 ? "+" : ""}
                {adjustmentAmount}
              </Text>
            )}
            <Text
              italic
              textAlign="end"
              sx={{
                minWidth: "2rem",
              }}
            >
              {newScore}
            </Text>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "#DEE2E6",
        }}
      >
        <Stack direction="column" padding={1} flexGrow={1} gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Text>Applied At</Text>
            <Text>{formatDateTimeWithTimeZone(adjustmentDate)}</Text>
          </Stack>
          {isDefined(adjustmentShiftStart) && (
            <Stack direction="row" justifyContent="space-between">
              <Text>Shift Start Time</Text>
              <Text>{formatDateTimeWithTimeZone(adjustmentShiftStart)}</Text>
            </Stack>
          )}
          {isDefined(adjustmentLocation) && (
            <Stack direction="row" justifyContent="space-between">
              <Text>Location</Text>
              <Text>{adjustmentLocation}</Text>
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
