import { isDefined } from "@clipboard-health/util-ts";
import { Alert, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  Channel,
  type RenderCustomMessageProps,
  sendBirdSelectors,
  useSendbirdStateContext,
} from "sendbird-uikit";

import { DeprecatedGlobalAppV1Paths } from "../../App/paths";
import { CustomMessageTypes } from "../../Chat/type";
import { useGetChatChannels } from "../../Facilities/Chat/api/useGetChatChannels";
import { APP_V2_APP_EVENTS, AppBarHeader, BackButtonLink, PageWithHeader } from "../../lib";
import { useLogEffect } from "../../lib/analytics/useLogEffect";
import { useSearchParams } from "../../lib/utils/useSearchParams";
import { useDefinedWorker } from "../../Worker/useDefinedWorker";
import { transformPlacementData, useGetPlacement } from "../Placements/api/useGetPlacement";
import { getRedesignTheme } from "../theming";
import { ChatSkeleton } from "./components/ChatSkeleton";
import { PlacementChatHeader } from "./components/PlacementChatHeader";
import { PlacementChatMessage } from "./components/PlacementChatMessage";

export function PlacementChatPage() {
  const { placementId, workplaceId } = useParams<{
    placementId: string;
    workplaceId: string;
  }>();
  const { source } = useSearchParams();
  const worker = useDefinedWorker();

  const {
    data: placement,
    isLoading: isPlacementLoading,
    isSuccess: isPlacementSuccess,
  } = useGetPlacement(
    { placementId, workerId: worker.userId },
    { enabled: isDefined(placementId) && isDefined(worker.userId) }
  );
  const placementDetails = isPlacementSuccess ? transformPlacementData(placement) : undefined;

  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdSelectors.getSdk(sendBirdState);
  const sendBirdGroupChannelSdk = sdk?.GroupChannel;
  const {
    isLoading: isLoadingChatChannels,
    data: chatChannelsData,
    error: chatChannelsError,
  } = useGetChatChannels({
    enabled: isDefined(sendBirdGroupChannelSdk) && isDefined(placementId),
  });
  const currentChannel = chatChannelsData?.channels?.find(
    (channel) => channel.url === `${workplaceId}_${worker.userId}_${placementId}`
  );

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_CHAT_PAGE_VIEWED,
    {
      placementId,
      workplaceId,
      workerId: worker.userId,
      source,
      channelUrl: currentChannel?.url,
      hasApplied: isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0,
      currentPlacementStatus: placementDetails?.applications?.[0]?.attributes?.status ?? "unknown",
    },
    { enabled: isDefined(currentChannel) && isDefined(worker) && isDefined(placementDetails) }
  );

  const showChatLoader =
    (isLoadingChatChannels || isPlacementLoading) && !isDefined(sendBirdGroupChannelSdk);
  const showChatError = isDefined(sendBirdGroupChannelSdk) && chatChannelsError;
  const showChatNotFound =
    isDefined(sendBirdGroupChannelSdk) && !isLoadingChatChannels && !isDefined(currentChannel);

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Chat"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS} />}
        />
      }
    >
      <ThemeProvider theme={getRedesignTheme()}>
        {showChatLoader && <ChatSkeleton />}
        {showChatError && (
          <Alert severity="error">
            {chatChannelsError.message ?? "Error loading chat channels"}
          </Alert>
        )}
        {showChatNotFound && <Alert severity="error">Could not find chat channel</Alert>}
        {isDefined(currentChannel) && (
          <div className="chat-container">
            <Channel
              channelUrl={currentChannel.url}
              renderChatHeader={() => (
                <PlacementChatHeader channel={currentChannel} placement={placementDetails} />
              )}
              renderCustomMessage={(message, channel): RenderCustomMessageProps => {
                if (message.customType === CustomMessageTypes.PLACEMENT_APPLICATION) {
                  return (() =>
                    PlacementChatMessage({
                      message,
                      channel,
                    })) as unknown as RenderCustomMessageProps;
                }

                if (
                  message.customType ===
                  CustomMessageTypes.PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE
                ) {
                  // do not render this message in the mobile app
                  return (() => null) as unknown as RenderCustomMessageProps;
                }

                // this causes the message to render by default
                return null as unknown as RenderCustomMessageProps;
              }}
            />
          </div>
        )}
      </ThemeProvider>
    </PageWithHeader>
  );
}
