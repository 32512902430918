import { type QueryClient } from "@tanstack/react-query";

import { invalidateAgentShifts } from "../OpenShifts/api/useAgentShifts";
import { invalidateOpenShiftCount } from "../OpenShifts/api/useOpenShiftCount";
import { invalidateOpenShifts } from "../OpenShifts/api/useOpenShifts";
import { invalidateAgentPendingShiftInvites } from "../Shifts/ShiftInvites/api/useAgentPendingShiftInvites";
import { invalidateGetOpenShifts } from "./Shift/Open/useGetOpenShifts";

export async function invalidateShifts(queryClient: QueryClient): Promise<void> {
  await Promise.all([
    invalidateGetOpenShifts(queryClient),
    invalidateAgentShifts(queryClient),
    invalidateOpenShiftCount(queryClient),
    invalidateOpenShifts(queryClient),
    invalidateAgentPendingShiftInvites(queryClient),
  ]);
}
