import { Image, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Skeleton, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import { useQrCodeDataUrl } from "@src/appV2/lib/utils/useQrCodeDataUrl";
import { useEffect } from "react";

import {
  touchpointCodeGenerator,
  type TouchpointCodeGeneratorProps,
} from "./touchpointCodeGenerator";

export function TouchpointQrDialog(props: {
  modalState: UseModalState;
  kioskLocation: string;
  qrCodeProps: TouchpointCodeGeneratorProps;
  onCancel: () => void;
  onContinue: () => void;
}) {
  const { modalState, kioskLocation, qrCodeProps, onCancel, onContinue } = props;
  const {
    data: referralCodeDataImageLink,
    isLoading: isLoadingReferralCodeDataImage,
    isSuccess: isReferralCodeDataImageSuccess,
  } = useQrCodeDataUrl(
    {
      data: touchpointCodeGenerator(qrCodeProps),
      qrOptions: { margin: 1, scale: 10 },
    },
    {
      enabled: modalState.modalIsOpen,
      meta: {
        userErrorMessage: "Failed to generate Touchpoint integration QR code",
      },
    }
  );

  const { secondsLeft, startTimer } = useCountdownTimer({
    durationInSeconds: 10,
  });

  useEffect(() => {
    if (modalState.modalIsOpen) {
      startTimer();
    }
  }, [modalState.modalIsOpen, startTimer]);

  return (
    <CommonDialog
      disableActionsSpacing
      modalState={modalState}
      scroll="body"
      actions={
        <>
          <Button
            fullWidth
            variant="contained"
            disabled={secondsLeft > 0}
            color="primary"
            onClick={onContinue}
          >
            Continue
          </Button>
          <Button fullWidth variant="text" onClick={onCancel}>
            Go back
          </Button>
        </>
      }
      title="Scan QR code at the TouchPoint kiosk"
      onClose={() => {
        onCancel();
      }}
    >
      <Stack spacing={1} alignItems="center">
        <Text>The kiosk is located {kioskLocation}.</Text>
        {isLoadingReferralCodeDataImage && (
          <Skeleton variant="rectangular" width="100%" height="300px" />
        )}
        {isReferralCodeDataImageSuccess && (
          <Image
            src={referralCodeDataImageLink}
            alt="Referral QR Code"
            width="80%"
            maxWidth="50vh"
          />
        )}
        {!isLoadingReferralCodeDataImage && !isReferralCodeDataImageSuccess && (
          <Text>Error generating QR. Please close this popup and try again.</Text>
        )}
        <Text>
          Tap continue after scanning the QR code to proceed with in-app{" "}
          {qrCodeProps.punchType === "clock-in" ? "clock in" : "clock out"}. Failure to scan may
          result in penalties.
        </Text>
        <Text
          width="100%"
          // Only hide the text to prevent layout shift
          visibility={secondsLeft > 0 ? "visible" : "hidden"}
          // Needed to alleviate the empty space after the text is hidden
          sx={{ lineHeight: "0.5rem" }}
        >
          You may continue in {secondsLeft} seconds.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
