import { Text } from "@clipboard-health/ui-react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { isString, uniqueId } from "lodash";
import { type ReactNode } from "react";

export interface BulletedTextListProps {
  items: Array<ReactNode | string>;
}

export function BulletedTextList(props: BulletedTextListProps) {
  const { items } = props;
  return (
    <Box>
      <List dense sx={{ listStyleType: "disc", pl: 2 }}>
        {items.map((item) => (
          <ListItem
            key={uniqueId("bulleted-text-list-")}
            disablePadding
            sx={{ display: "list-item" }}
          >
            <ListItemText
              primaryTypographyProps={{
                color: (theme) => theme.palette.text.primary,
              }}
              primary={isString(item) ? <Text>{item.trim()}</Text> : item}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
