import { mergeSxProps } from "@clipboard-health/ui-react";
import { Skeleton, type SxProps, type Theme } from "@mui/material";

import type { PillSize } from "./Pill";

interface PillSkeletonProps {
  width?: string | number;
  height?: string | number;
  size?: PillSize;
  sx?: SxProps<Theme>;
}

const pillWidthBySize: Record<PillSize, number> = {
  small: 150,
  medium: 200,
};

export function PillSkeleton(props: PillSkeletonProps) {
  const { width, height, size = "medium", sx } = props;
  return (
    <Skeleton
      variant="rounded"
      sx={mergeSxProps(
        (theme) => ({
          borderRadius: theme.borderRadius?.small,
          width: width ?? pillWidthBySize[size],
          height: height ?? theme.size?.pill[size],
        }),
        sx
      )}
    />
  );
}
