import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

declare module "@mui/material/Card" {
  interface CardOwnProps {
    /**
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    outlined?: "true" | "false";
  }

  interface CardPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

export const muiCardOverrides: Components<Theme> = {
  MuiCardActionArea: {
    styleOverrides: {
      // by default Mui adds a focus highlight element that is rendered above the component
      focusHighlight: {
        display: "none",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
      variant: "tertiary",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.borderRadius?.medium,
      }),
    },
    variants: [
      {
        props: { outlined: "true" },
        style: ({ theme }) => ({
          border: `1px solid ${String(theme.palette.border?.primary)}`,
        }),
      },
    ],
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "1rem",

        "&:last-child": {
          paddingBottom: "1rem",
        },
      },
    },
  },
};
