import { Span, useModalState } from "@clipboard-health/ui-react";
import { IonAlert, IonPage } from "@ionic/react";
import { Badge, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { AccountPageBanners } from "@src/app/account/AccountPageBanners";
import { useLicenseCount } from "@src/app/licenseManager/hooks/useLicenseCount";
import { getLicensesSummaryText } from "@src/app/licenseManager/utils";
import { AppVersionInfo } from "@src/appV2/Accounts/AppVersionInfo/AppVersionInfo";
import { ACCOUNTS_CLIPBOARD_RESUME_FULL_PATH } from "@src/appV2/Accounts/ClipboardResume/paths";
import { useGetRequirementStatus } from "@src/appV2/Accounts/Documents";
import LogoutConfirmationDialog from "@src/appV2/Accounts/Logout/LogoutConfirmationDialog";
import { REFER_AND_EARN_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/ReferAndEarn/paths";
import { WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/WorkplaceReferrals";
import { useWorkerToWorkplaceAffiliateExperiment } from "@src/appV2/Accounts/WorkplaceReferrals/hooks/useWorkerToWorkplaceAffiliateExperiment";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { ATTENDANCE_SCORE, CLIPBOARD_SCORE } from "@src/appV2/AttendanceScore/constants";
import { DEBUG_BASE_PATH } from "@src/appV2/Debug/paths";
import { environmentConfig, isDevelopmentNodeEnvironment } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useGetPlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/api/useGetPlacementCandidate";
import { useIsShiftDiscoveryEnabled } from "@src/appV2/redesign/ShiftDiscovery/useIsShiftDiscoveryEnabled";
import { SKILLS_ASSESSMENT_BASE_PATH } from "@src/appV2/SkillsAssessment/paths";
import { SkillsAssessmentAccountMenuItem } from "@src/appV2/SkillsAssessment/SkillsAssessmentAccountMenuItem";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { REFERRALS, USER_EVENTS } from "@src/constants";
import { logApiFailureEvent } from "@src/lib/analytics";
import { useDefinedEnv } from "@store/helperHooks/sessionHelpers";
import { Store } from "@store/store.model";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import request from "superagent";

import { updateShowReferralAlert } from "./api";
import { MyAccountProps } from "./model";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { setNotificationPreference } from "../privacyPolicy/notificationPreference/api";
import { getAuthHeader } from "../superagent";

export function MyAccount(props: MyAccountProps) {
  const { logout, isLoggingOut } = props;
  const history = useHistory();
  const match = useRouteMatch();
  const logoutModal = useModalState();
  const [isClosed, setClosed] = useState<boolean>(false);
  const [pnChecked, setPNChecked] = useState(false);
  const [pushFlag, setPushFlag] = useState<boolean>(false);
  const [isNotificationAlertShown, setNotificationAlertShown] = useState<boolean>(true);
  const worker = useDefinedWorker();
  const { userId } = worker;
  const env = useDefinedEnv();
  const isShiftDiscoveryEnabled = useIsShiftDiscoveryEnabled();
  const { unreadCount, hasUnreadSupportMessage } = useSelector(
    (state: Store) => state.notificationStore
  );

  const updateWorkerCache = useUpdateWorkerCache();

  const shouldBeOnboardedInPaymentService = useShouldBeOnboarded();
  const { licensesCount } = useLicenseCount();

  const {
    totalCountOfMandatoryRequirements = 0,
    countOfPendingOrCompletedMandatoryRequirements = 0,
  } = useGetRequirementStatus({
    workerId: userId,
  });

  const ldFlags = useCbhFlags();

  const workplaceReferralsConfig = ldFlags[CbhFeatureFlag.WORKPLACE_REFERRALS] ?? {};
  const isWorkerToWorkplaceReferralsEnabled = workplaceReferralsConfig.workerToWorkplace ?? false;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();

  const enabledWorkerToWorkerReferralBanners = useCbhFlag(
    CbhFeatureFlag.WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS,
    {
      defaultValue: { enterReferralCodeBannerIsVisible: false, referAndEarnBannerIsVisible: false },
    }
  );

  const flagSettings = { defaultValue: false };

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, flagSettings);
  const isJobsEnabled = useCbhFlag(CbhFeatureFlag.ENABLE_JOBS, flagSettings);
  const isPassportEnabled = useCbhFlag(
    CbhFeatureFlag.PLACEMENT_CANDIDATE_RESUME_PARSING,
    flagSettings
  );
  const mayShowPassportMenuItem = isJobsEnabled && isPassportEnabled;
  const { data: placementCandidate } = useGetPlacementCandidate(
    { workerId: userId },
    { enabled: mayShowPassportMenuItem }
  );

  const showDebugPageMenuItemForTesting =
    useCbhFlag(CbhFeatureFlag.SHOW_DEBUG_PAGE_MENU_ITEM_IN_MY_ACCOUNT, flagSettings) ||
    isDevelopmentNodeEnvironment();

  const { referAndEarnBannerIsVisible } = enabledWorkerToWorkerReferralBanners;

  const pendingActions =
    totalCountOfMandatoryRequirements !== countOfPendingOrCompletedMandatoryRequirements;

  useEffect(() => {
    if (!env) {
      return;
    }
    setPNChecked(Boolean(env?.notificationPreferences?.push));
    setPushFlag(Boolean(env?.PushNotificationOnAccountTab));
    setClosed(true);
  }, [env]);

  useEffect(() => {
    if (worker.showReferralAlert) {
      updateShowReferralAlert(worker.userId, { showReferralAlert: false }).then(() => {
        updateWorkerCache({ showReferralAlert: false });
      });
    }
    setNotificationAlertShown(Boolean(worker?.isNotificationAlertShown));
  }, [updateWorkerCache, worker]);

  useLogEffect(USER_EVENTS.VIEWED_ACCOUNT);

  const licensesSummaryText = useMemo(() => {
    return getLicensesSummaryText(licensesCount);
  }, [licensesCount]);

  const logMenuItemTapped = (menuItemName: string) => {
    logEvent(USER_EVENTS.TAPPED_ACCOUNT_MENU_ITEM, { menuItemName });
  };

  const setNotificationPopUpShown = async () => {
    const { body: agent } = await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/setNotificationAlertShown`)
      .set(await getAuthHeader());

    updateWorkerCache({
      ...agent,
    });
  };

  const dismissAlert = async () => {
    try {
      setClosed(false);
      setNotificationPopUpShown();
      await setNotificationPreference({
        notificationPreference: { key: "push", value: true },
      });
    } catch (error) {
      logApiFailureEvent(error);
    }
  };

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={<AppBarHeader title="My Account" />}
        containerVariant="without-margin"
      >
        <AccountPageBanners workerId={userId} />
        <List
          sx={{
            "& li": {
              height: 55,
              padding: 0,
              "& div": {
                paddingY: 1,
              },
            },
          }}
        >
          {showDebugPageMenuItemForTesting && (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Debug");
                    history.push(DEBUG_BASE_PATH);
                  }}
                >
                  <ListItemText
                    primary="Debug"
                    secondary={
                      <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                        Internal testing
                      </Span>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
          {isShiftDiscoveryEnabled && (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Account Status");
                    history.push(DeprecatedGlobalAppV1Paths.RATINGS);
                  }}
                >
                  <ListItemText
                    primary="Account Status"
                    secondary={
                      <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                        {isClipboardScoreEnabled ? CLIPBOARD_SCORE : ATTENDANCE_SCORE} & Priority
                        Access
                      </Span>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
          {placementCandidate?.passportUrl && (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Clipboard Resume");
                    history.push(`${ACCOUNTS_CLIPBOARD_RESUME_FULL_PATH}`);
                  }}
                >
                  <ListItemText primary="Your Updated Resume" />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Earnings");
                history.push(`${match.url}/payroll`);
              }}
            >
              <ListItemText
                primary="Earnings"
                sx={{
                  color: (theme) =>
                    shouldBeOnboardedInPaymentService ? theme.palette.error.main : undefined,
                }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Documents");
                history.push(`${match.url}/documents`);
              }}
            >
              <ListItemText
                primary="Documents"
                secondary={
                  pendingActions ? (
                    <Span sx={{ color: (theme) => theme.palette.error.main }}>
                      Some documents are missing or expired!
                    </Span>
                  ) : (
                    <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                      Manage your documents
                    </Span>
                  )
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem
            secondaryAction={
              <Span sx={{ paddingRight: 1 }}>
                {hasUnreadSupportMessage && <Badge color="error" badgeContent={unreadCount} />}
              </Span>
            }
          >
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Help Center");
                // TODO:
                // We've merged the separate 'logout' and 'help center' tap events into a single event named 'tapped account menu item'
                // Once we update our Metabase metrics (if having), which currently relies on these events, we can safely remove this
                logEvent(USER_EVENTS.OPENED_HELP_CENTER);
                history.push(`${match.url}/needhelp`);
              }}
            >
              <ListItemText
                primary="Help Center"
                secondary={
                  <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                    Read help articles and contact us
                  </Span>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Profile");
                history.push(`${match.url}/profile`);
              }}
            >
              <ListItemText
                primary="Profile"
                secondary={
                  worker.isProfilePictureUploaded ? (
                    <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                      View your profile
                    </Span>
                  ) : (
                    <Span sx={{ color: (theme) => theme.palette.error.main }}>
                      Your profile photo is missing!
                    </Span>
                  )
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Licenses & Qualifications");
                history.push(`${match.url}/licenses`);
              }}
            >
              <ListItemText
                primary="Licenses & Qualifications"
                secondary={
                  licensesSummaryText?.length ? (
                    licensesSummaryText.map((summaryText, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={index} style={{ fontSize: "14px" }}>
                        {!!index && ", "}
                        <span style={{ whiteSpace: "nowrap" }}>{summaryText}</span>
                      </span>
                    ))
                  ) : (
                    <Span sx={{ color: (theme) => theme.palette.grey[600] }}>Manage Licenses</Span>
                  )
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Address");
                history.push(`${match.url}/address`);
              }}
            >
              <ListItemText
                primary="Address"
                secondary={
                  <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                    Manage your home address
                  </Span>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          {isWorkerToWorkplaceReferralsEnabled && isWorkerToWorkplaceAffiliateProgramEnabled && (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Facility Affiliate Program");
                    history.push(WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH);
                  }}
                >
                  <ListItemText
                    primary="Workplace Affiliate Program"
                    secondary={
                      <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                        Earn $1,000 for every workplace you refer
                      </Span>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
          {isWorkerToWorkplaceReferralsEnabled && !isWorkerToWorkplaceAffiliateProgramEnabled ? (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Refer and Earn");
                    history.push(REFER_AND_EARN_WITH_ACCOUNTS_PATH);
                  }}
                >
                  <ListItemText
                    primary="Refer a Friend or Workplace"
                    secondary={
                      <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                        Earn money on your referrals
                      </Span>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          ) : (
            <>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    logMenuItemTapped("Refer a Friend");
                    history.push(
                      referAndEarnBannerIsVisible
                        ? DeprecatedGlobalAppV1Paths.REFERRAL_V2
                        : DeprecatedGlobalAppV1Paths.REFERRAL
                    );
                  }}
                >
                  <ListItemText
                    primary={REFERRALS.REFER_A_FRIEND}
                    secondary={
                      <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                        Earn a bonus when they work their first shift
                      </Span>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped(REFERRALS.REDEEM_REFERRAL_CODE);
                history.push(DeprecatedGlobalAppV1Paths.CO_WORKER_REFERRAL);
              }}
            >
              <ListItemText
                primary={REFERRALS.REDEEM_REFERRAL_CODE}
                secondary={
                  <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                    Enter a code before working your first shift
                  </Span>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Work with Friends");
                history.push(`${match.url}/friends`);
              }}
            >
              <ListItemText
                primary="Work with Friends"
                secondary={
                  <Span sx={{ color: (theme) => theme.palette.grey[600] }}>
                    Add friends so you can see the shifts they booked
                  </Span>
                }
              />
            </ListItemButton>
          </ListItem>
          <SkillsAssessmentAccountMenuItem
            worker={worker}
            onClick={() => {
              logMenuItemTapped("Skills Assessment");
              history.push(SKILLS_ASSESSMENT_BASE_PATH);
            }}
          />
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Privacy and Notifications");
                history.push(`${match.url}/privacy-notification-setting`);
              }}
            >
              <ListItemText primary="Privacy and Notifications" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton
              onClick={() => {
                logMenuItemTapped("Logout");
                // TODO:
                // We've merged the separate 'logout' and 'help center' tap events into a single event named 'tapped account menu item'
                // Once we update our Metabase metrics (if having), which currently relies on these events, we can safely remove this
                logEvent(USER_EVENTS.TAPPED_LOGOUT);
                logoutModal.openModal();
              }}
            >
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>

        <AppVersionInfo />

        <LogoutConfirmationDialog
          modalState={logoutModal}
          onLogout={async () => {
            await logout();
          }}
          isLoading={isLoggingOut}
        />

        <IonAlert
          isOpen={!pushFlag && !isNotificationAlertShown && !pnChecked && isClosed}
          onDidDismiss={dismissAlert}
          header="Notifications"
          message="For this app to work, we'll need push notifications to notify you of your upcoming shifts, open shifts and raffles."
          backdropDismiss={false}
          buttons={["Ok"]}
        />
      </PageWithHeader>
    </IonPage>
  );
}
