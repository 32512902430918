/**
 * This function do not check if the selected qualification is allowed for the shift.
 * It only decides which qualification to send between user selected or shift qualification for document check API
 *
 * Why this needed ?
 * NURSE is not a real qualification, it's a catch all qualification for both RN and LVN.
 *
 * So when we send the shift qualification for a NURSE shift, the document check will fail.
 * That's why we need to send the selected worker qualification in that case.
 * */
export function getQualificationForDocumentCheck(params: {
  shiftQualificationRequirement: string;
  selectedWorkerQualification: string;
}) {
  const { shiftQualificationRequirement, selectedWorkerQualification } = params;

  // If the shift is a nurse shift, we need to send the selected worker qualification as NURSE shift can be booked by
  // both RN and LVN and there is no actual nurse qualification.
  if (shiftQualificationRequirement.toLowerCase() === "nurse") {
    return selectedWorkerQualification;
  }

  return shiftQualificationRequirement;
}

/**
 * FIXME:
 *
 * This is a non-ideal solution:
 *
 * This function only determines which qualification to send between the user-selected qualifications
 * and the shift qualification for the document check API. It is primarily used after bookability checks.
 * When the bookability check indicates missing documents, we need to send the relevant qualification
 * to the documents API to retrieve the list of missing documents. This function helps determine
 * the appropriate qualification to send.
 *
 * Note: This function does not validate whether the selected qualification is allowed for the shift.
 *
 * Why is this needed?
 * "NURSE" is not an actual qualification - it's a catch-all category that includes both RN and LVN.
 *
 * When we send the shift qualification for a NURSE shift, the document check fails.
 * Therefore, we need to send the appropriate qualification from the worker's selected
 * qualifications in this case.
 *
 * Ideal solution:
 * This function should not need to exist. Instead, we should have improved backend APIs
 * to handle this logic. While this function currently helps determine the correct qualification
 * for the documents API after bookability checks indicate missing documents, the ideal
 * approach would be for the bookability API to directly return the missing documents list.
 * */
export function getDocumentCheckQualificationFromMultipleQualifications(params: {
  shiftQualificationRequirement?: string | undefined;
  selectedWorkerQualifications: string[];
}) {
  const { shiftQualificationRequirement, selectedWorkerQualifications } = params;

  if (!shiftQualificationRequirement) {
    return undefined;
  }

  // Non-ideal hardcoding. See main comment for ideal solution.
  if (shiftQualificationRequirement.toLowerCase() === "nurse") {
    const qualificationPriority = ["RN", "LVN"];

    // If worker has chosen RN we return RN, otherwise LVN. Ideally this won't send undefined as only RN and LVN can see NURSE shifts.
    return qualificationPriority.find((qualification) =>
      selectedWorkerQualifications.includes(qualification)
    );
  }

  return shiftQualificationRequirement;
}
