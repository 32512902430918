import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { MuiThemeProvider } from "@src/appV2/lib/Theme";
import { useGetQuestions } from "@src/appV2/Reviews/api/useGetQuestions";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { ReviewQuestionnaireDialog } from "@src/appV2/Reviews/components/Dialogs/ReviewQuestionnaire";
import { useHistory } from "react-router-dom";

import { SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import type { WorkplaceProfile } from "../types";

interface LegacyLeaveReviewContainerProps {
  workplace: WorkplaceProfile;
  modalState: UseModalState;
}

export function LegacyLeaveReviewContainer(props: LegacyLeaveReviewContainerProps) {
  const { workplace, modalState } = props;
  const { userId: workplaceId, type, name } = workplace;

  const history = useHistory();

  const { data: ratingAggregates } = useGetRatingAggregates(String(workplaceId));

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { data: questions = { data: [] } } = useGetQuestions({
    workplaceType: String(type),
    workplaceId: String(workplaceId),
  });

  if (questions.data.length === 0 || !isDefined(ratingAggregates)) {
    return null;
  }

  return (
    <MuiThemeProvider>
      <ReviewQuestionnaireDialog
        modalState={modalState}
        workPlaceDetails={{
          id: String(workplaceId),
          name,
          type: String(type),
        }}
        questions={questions.data}
        rating={ratingAggregates.avgRating}
        onSuccess={() => {
          modalState.closeModal();
          history.push(
            generateModalPath(SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH, {
              workplaceId: String(workplaceId),
            })
          );
        }}
      />
    </MuiThemeProvider>
  );
}
