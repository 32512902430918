import { type ForwardedRef, forwardRef } from "react";

import { DateCell, type DateCellProps } from "../../Calendar/DateCell";
import { type TimeSlot } from "../Open/types";
import { ShiftDateCellAvailabilityInfo } from "./ShiftDateCellAvailabilityInfo";

export interface ShiftDateCellProps extends DateCellProps {
  isDateAvailable: boolean;
  bookedShiftTimeSlots: TimeSlot[];
}

function BaseShiftDateCell(props: ShiftDateCellProps, ref: ForwardedRef<HTMLButtonElement>) {
  const { size, isDateAvailable, bookedShiftTimeSlots, ...restProps } = props;

  return (
    <DateCell ref={ref} size={size} {...restProps}>
      <ShiftDateCellAvailabilityInfo
        bookedShiftTimeSlots={bookedShiftTimeSlots}
        isDateAvailable={isDateAvailable}
        size={size}
      />
    </DateCell>
  );
}

export const ShiftDateCell = forwardRef(BaseShiftDateCell);
