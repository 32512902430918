import { Box } from "@mui/material";
import { usePullToRefresh } from "@src/appV2/lib/PullToRefresh/usePullToRefresh";

import { LoadingSpinner } from "../../components/LoadingSpinner";

const MAX_HEIGHT = 120;
const NORMAL_HEIGHT = 45;
const LOADER_MARGIN = 16;
const ELASTIC_COEFFICIENT = 0.6;

function applySpringTension(distance: number): number {
  return MAX_HEIGHT * (1 - Math.exp((-ELASTIC_COEFFICIENT * distance) / MAX_HEIGHT));
}

interface PullToRefreshProps {
  onRefresh: () => Promise<any>;
  scrollRef: React.RefObject<HTMLDivElement>;
}

// Taken mostly from `src/appV2/lib/PullToRefresh/PullToRefresh.tsx`
export function PullToRefresh(props: PullToRefreshProps) {
  const { onRefresh, scrollRef } = props;
  const { dragDistance, isRefreshing, isPulling, percentage } = usePullToRefresh(
    onRefresh,
    scrollRef
  );
  const height = isRefreshing ? NORMAL_HEIGHT : applySpringTension(dragDistance);

  return (
    <Box
      sx={{
        width: "100%",
        height: Math.max(height, 0),
        transition: isPulling ? undefined : "all 0.2s",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          opacity: isRefreshing ? 1 : 2 * percentage,
          top: isRefreshing ? LOADER_MARGIN : LOADER_MARGIN * percentage,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner size="small" />
      </Box>
    </Box>
  );
}
