import { useCbhFlag } from "@src/appV2/FeatureFlags";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { useInvalidateGetBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useInvalidateGetBookabilityStatusForShifts";
import { useShiftDiscoveryUserFiltersContext } from "@src/appV2/redesign/ShiftDiscovery/Filters/useUserFiltersContext";

import { useGetMissingRequirementsForShift } from "../../../Booking/useGetMissingRequirementsForShift";
import { type ShiftWithType } from "../../../types";
import { isDocumentInstantReview } from "../isDocumentInstantReview";

interface UseDocumentSubmittedBottomSheetProps {
  shift?: ShiftWithType;
  bookabilityStatus?: BookabilityStatusItem;
}

export function useDocumentSubmittedBottomSheet(props: UseDocumentSubmittedBottomSheetProps) {
  const { shift, bookabilityStatus } = props;
  const { facility: workplace } = shift ?? {};

  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const {
    missingDocumentsSet,
    expiringDocumentsSet,
    pendingDocumentsSet,
    isLoading: isMissingDocumentsLoading,
    isFetching: isFetchingMissingRequirements,
  } = useGetMissingRequirementsForShift({
    shift,
    workplace,
    bookabilityStatus,
    selectedWorkerQualifications:
      selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
  });

  useInvalidateGetBookabilityStatusForShifts();

  const isHelloSignEmbeddedFlowEnabled = useCbhFlag(
    CbhFeatureFlag.HELLO_SIGN_EMBEDDED_FLOW_INTEGRATION,
    {
      defaultValue: false,
    }
  );

  const missingAndExpiringDocuments = [...missingDocumentsSet, ...expiringDocumentsSet];
  const missingAndExpiringDocumentsCount = missingAndExpiringDocuments.length;
  const areAllBlockingDocumentsInstantReview = missingAndExpiringDocuments.every((document) =>
    isDocumentInstantReview({ document, isHelloSignEmbeddedFlowEnabled })
  );
  const instantReviewBlockingDocuments = missingAndExpiringDocuments.filter((document) =>
    isDocumentInstantReview({ document, isHelloSignEmbeddedFlowEnabled })
  );
  const isAnyBlockingDocumentInstantReview = instantReviewBlockingDocuments.length > 0;

  return {
    missingDocumentsSet,
    expiringDocumentsSet,
    pendingDocumentsSet,
    instantReviewBlockingDocuments,
    missingAndExpiringDocumentsCount,
    areAllBlockingDocumentsInstantReview,
    isAnyBlockingDocumentInstantReview,
    isMissingDocumentsLoading,
    isFetchingMissingRequirements,
  };
}
