import { Text } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";

interface FilterSectionWrapperProps extends StackProps {
  sectionTitle: string;
}

export function FilterSectionWrapper(props: FilterSectionWrapperProps) {
  const { children, sectionTitle, ...restProps } = props;

  return (
    <Stack spacing={5} {...restProps}>
      <Text semibold variant="body1">
        {sectionTitle}
      </Text>

      {children}
    </Stack>
  );
}
