import { isDefined } from "@clipboard-health/util-ts";
import { useToast } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { isArray, isEmpty } from "lodash";

import { useGetRequirementStatus } from "../../Documents/api/useGetRequirementStatus";
import {
  type DocumentRequirementType,
  DocumentRequirementType as DocumentRequirementTypeValues,
} from "../../Documents/types";
import { useGetDocument } from "../api/useGetDocument";
import { useGetRejectedDocumentNotification } from "../api/useGetRejectedDocumentNotification";
import { useGetRequirementExpiry } from "../api/useGetRequirementExpiry";

export interface RefetchDocumentPropertiesProps {
  includeRejectedDocumentNotification?: boolean;
}
interface DocumentProperties {
  requirementId?: string;
  requirementType?: DocumentRequirementType;
  facilityId?: string;
  qualification?: string;
}

export function useDocumentProperties(props: DocumentProperties) {
  const { requirementId, requirementType, facilityId, qualification } = props;

  const { showErrorToast } = useToast();
  const worker = useDefinedWorker();

  const isRejectedDocument = requirementType === DocumentRequirementTypeValues.REJECTED;

  const getRejectedDocumentNotificationResponse = useGetRejectedDocumentNotification(
    {
      hcpId: worker.userId,
      requirementId,
    },
    {
      select: (data) => {
        return data.data.rejectedDocument?.notification;
      },
      enabled: isDefined(requirementId) && isRejectedDocument,
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getDocumentResponse = useGetDocument(
    {
      hcpId: worker.userId,
      requirementId,
    },
    {
      select: (data) => {
        const {
          data: { documentList },
        } = data;

        if (isArray(documentList) && !isEmpty(documentList)) {
          const document = documentList[0];
          return {
            documentId: document._id,
            subtype: document.subType ?? undefined,
            fileStorageRootFolder: document.fileStorageRootFolder,
            fileStorageFileKey: document.fileStorageFileKey,
          };
        }

        return {};
      },
      enabled: isDefined(requirementId),
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getRequirementExpiryResponse = useGetRequirementExpiry(
    {
      hcpId: worker.userId,
      requirementId,
    },
    {
      select: (data) => {
        const {
          data: { hcpRequirementList },
        } = data;

        if (!isArray(hcpRequirementList) || isEmpty(hcpRequirementList)) {
          return {};
        }

        const [hcpRequirement] = hcpRequirementList;

        return {
          expiry: hcpRequirement?.expiry,
          expiryType: hcpRequirement?.baseRequirement?.expiryType,
        };
      },
      enabled: isDefined(requirementId),
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );

  const getRequirementStatusResponse = useGetRequirementStatus(
    {
      workerId: worker.userId,
      ...(isDefined(facilityId) ? { facilitiesId: [facilityId] } : {}),
      ...(isDefined(qualification) ? { qualifications: [qualification] } : {}),
    },
    {
      onError: () => {
        showErrorToast("Something went wrong while fetching the document");
      },
    }
  );
  const selectedRequirement = isDefined(requirementId)
    ? getRequirementStatusResponse.hcpRequirementStatus.requirements[requirementId]
    : undefined;

  return {
    documentProperties: {
      selectedRequirement,
      expiry: getRequirementExpiryResponse.data?.expiry,
      expiryType: getRequirementExpiryResponse.data?.expiryType,
      documentId: getDocumentResponse.data?.documentId,
      submittedDocumentSubtype: getDocumentResponse.data?.subtype,
      rejectedDocumentNotification: getRejectedDocumentNotificationResponse.data,
      fileStorageRootFolder: getDocumentResponse.data?.fileStorageRootFolder,
      fileStorageFileKey: getDocumentResponse.data?.fileStorageFileKey,
    },
    isLoading:
      getRequirementStatusResponse.isLoading ||
      getRequirementExpiryResponse.isLoading ||
      getDocumentResponse.isLoading ||
      (getRejectedDocumentNotificationResponse.isLoading &&
        getRejectedDocumentNotificationResponse.fetchStatus !== "idle"),
    refetchDocumentProperties: async (props: RefetchDocumentPropertiesProps) => {
      const { includeRejectedDocumentNotification = true } = props;
      await Promise.all([
        getRequirementStatusResponse.refetch(),
        getRequirementExpiryResponse.refetch(),
        getDocumentResponse.refetch(),
        includeRejectedDocumentNotification
          ? getRejectedDocumentNotificationResponse.refetch()
          : Promise.resolve(),
      ]);
    },
  };
}
