import { ModalRoute } from "../Navigation/ModalRoute";
import { ModalTransitionSwitch } from "../Navigation/ModalTransitionSwitch";
import { ShiftDatePicker } from "../Shift/Calendar/ShiftDatePicker";
import { ShiftDiscoveryFiltersBottomSheet } from "./Filters/BottomSheet/Sheet";
import { SHIFT_DISCOVERY_CALENDAR_PATH, SHIFT_DISCOVERY_FILTERS_PATH } from "./paths";

interface CommonModalRoutesProps {
  baseNavigationPath: string;
}

export function CommonModalRoutes(props: CommonModalRoutesProps) {
  const { baseNavigationPath } = props;

  return (
    <ModalTransitionSwitch>
      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_CALENDAR_PATH}`}
        render={({ modalState }) => <ShiftDatePicker modalState={modalState} />}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_FILTERS_PATH}`}
        render={({ modalState }) => <ShiftDiscoveryFiltersBottomSheet modalState={modalState} />}
      />
    </ModalTransitionSwitch>
  );
}
