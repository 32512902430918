import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

// Users generally will get to the urgent shifts page by clicking on the
// urgent shifts callout, so an empty state is unlikely to be shown.
// However, we might still show an empty state if the user navigates to
// the urgent shifts page directly.
export function UrgentShiftsEmptyState() {
  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title
        textAlign="center"
        variant="h4"
        component="h3"
        // TODO: Add semibold to Title
        sx={(theme) => ({
          fontWeight: theme.typography.h4.fontWeight,
          textWrap: "balance",
        })}
      >
        There are currently no urgent shifts
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        We&apos;ll send you a notification when new urgent shifts are available
      </Text>
    </Stack>
  );
}
