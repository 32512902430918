export enum AttendanceScoreColor {
  GREEN = "#009e0f",
  YELLOW = "#f1c232",
  RED = "#cc0000",
  BLACK = "#000000",
}

export function getScoreColor(score: number) {
  if (score >= 70) {
    return AttendanceScoreColor.GREEN;
  }

  if (score >= 30) {
    return AttendanceScoreColor.YELLOW;
  }

  if (score >= 1) {
    return AttendanceScoreColor.RED;
  }

  return AttendanceScoreColor.BLACK;
}
