import { useLocalStorage } from "@src/appV2/lib/utils";
import { isEqual } from "lodash";
import { useCallback } from "react";

import { USER_FILTERS_LOCAL_STORAGE_KEY } from "./constants";
import { type StoredUserFilters } from "./types";

const defaultStoredFilters: StoredUserFilters = {
  shiftBookingTypes: [],
  shiftTimeSlots: [],
  licenses: [],
};

export function useStoredUserFilters() {
  const [storedFilters, setStoredFilters] = useLocalStorage<StoredUserFilters>(
    USER_FILTERS_LOCAL_STORAGE_KEY,
    defaultStoredFilters
  );

  const clearStoredFilters = useCallback(() => {
    setStoredFilters(defaultStoredFilters);
  }, [setStoredFilters]);

  const setStoredFilter = useCallback(
    (key: keyof StoredUserFilters, value: StoredUserFilters[typeof key]) => {
      setStoredFilters((filters) => ({ ...filters, [key]: value }));
    },
    [setStoredFilters]
  );

  const modifiedStoredFiltersCount = Object.keys(storedFilters).filter(
    (key) =>
      !isEqual(
        storedFilters[key as keyof StoredUserFilters],
        defaultStoredFilters[key as keyof StoredUserFilters]
      )
  ).length;

  return {
    storedFilters,
    setStoredFilters,
    setStoredFilter,
    clearStoredFilters,
    modifiedStoredFiltersCount,
  };
}
