import { type Theme } from "@mui/material";
import { type Components } from "@mui/material/styles";

declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    xSmall: true;
  }

  interface IconButtonOwnProps {
    /**
     * Inverts the button colors.
     *
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    invert?: "true" | "false";
  }
}

/**
 * Use IconButton from src/appV2/ShiftDiscovery/components/IconButton.tsx
 * instead of MUI IconButton.
 */
export const muiIconButtonOverrides: Components<Theme> = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        // MUI's IconButton has default padding for the icon,
        // but we are using our custom CBHIcon component,
        // so we need to clean that up.
        padding: 0,

        variants: [
          {
            props: { size: "xSmall" },
            style: {
              width: theme.size?.iconButton.xSmall,
              height: theme.size?.iconButton.xSmall,

              // The icon sizes in the designs don't match CbhIcon sizes,
              // so we need to override them here.
              "& svg": {
                width: "0.875rem", // 14px
                height: "0.875rem", // 14px
              },
            },
          },
          {
            props: { size: "small" },
            style: {
              width: theme.size?.iconButton.small,
              height: theme.size?.iconButton.small,

              "& svg": {
                width: "1.25rem", // 20px
                height: "1.25rem", // 20px
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              width: theme.size?.iconButton.medium,
              height: theme.size?.iconButton.medium,

              // These sizes match CbhIcon size ICON_SIZE_MEDIUM,
              // but still leaving them here to centralize all IconButton's icon sizes.
              "& svg": {
                width: "1.5rem", // 24px
                height: "1.5rem", // 24px
              },
            },
          },
          {
            props: { size: "large" },
            style: {
              width: theme.size?.iconButton.large,
              height: theme.size?.iconButton.large,

              "& svg": {
                width: "1.5rem", // 24px
                height: "1.5rem", // 24px
              },
            },
          },
        ],
      }),

      // Used for "contained" variant
      colorPrimary: ({ theme }) => ({
        color: theme.palette.text.invertPrimary,
        backgroundColor: theme.palette.primary.main,

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },

          "&:active": {
            backgroundColor: theme.palette.primary.dark,
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.icon?.disabled,
          backgroundColor: theme.palette.disabled?.tertiary,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.icon?.primary,
              backgroundColor: theme.palette.background?.tertiary,

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  backgroundColor: theme.palette.background?.secondary,
                },
              },

              "@media (hover: none)": {
                "&:hover": {
                  backgroundColor: theme.palette.background?.tertiary,
                },

                "&:active": {
                  backgroundColor: theme.palette.background?.secondary,
                },
              },
            },
          },
        ],
      }),

      // Used for "outlined" variant
      colorSecondary: ({ theme }) => ({
        color: theme.palette.icon?.primary,
        border: `1px solid ${String(theme.palette.border?.primary)}`,
        backgroundColor: theme.palette.background?.tertiary,
        boxShadow: theme.shadows[2],

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            border: `1px solid ${theme.palette.primary.main}`,
            // Override default hover background color.
            backgroundColor: theme.palette.background?.tertiary,
          },
        },

        "@media (hover: none)": {
          // Override default hover styles on mobile to look like default styles.
          // Even though mobile doesn't support hover, it still shows
          // hover styles when pressed.
          "&:hover": {
            border: `1px solid ${String(theme.palette.border?.primary)}`,
            backgroundColor: theme.palette.background?.tertiary,
          },

          "&:active": {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.background?.tertiary,
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.icon?.disabled,
          border: `1px solid ${String(theme.palette.disabled?.secondary)}`,
          backgroundColor: theme.palette.background?.tertiary,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.icon?.lightInvert,
              border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,
              backgroundColor: "transparent",
              boxShadow: "none",

              "&.Mui-disabled": {
                color: theme.palette.icon?.disabled,
                border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,
                backgroundColor: "transparent",
              },

              "@media (hover: hover)": {
                "&:hover, &:focus": {
                  border: `1px solid ${String(theme.palette.icon?.lightInvert)}`,
                  // Override default hover background color.
                  backgroundColor: "transparent",
                },
              },

              "@media (hover: none)": {
                // Override default hover styles on mobile to look like default styles.
                // Even though mobile doesn't support hover, it still shows
                // hover styles when pressed.
                "&:hover": {
                  border: `1px solid ${String(theme.palette.border?.primaryInvert)}`,
                  backgroundColor: "transparent",
                },

                "&:active": {
                  border: `1px solid ${String(theme.palette.icon?.lightInvert)}`,
                },
              },
            },
          },
        ],
      }),
    },
  },
};
