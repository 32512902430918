import { BaseLink } from "@clipboard-health/ui-react";
import { Badge, BottomNavigation, BottomNavigationAction, ThemeProvider } from "@mui/material";
import { DeprecatedGlobalAppV1Paths, RootPaths } from "@src/appV2/App/paths";
import { CbhIcon } from "@src/appV2/redesign/components/CbhIcon";
import { useIsJobsEnabled } from "@src/appV2/redesign/PlacementCandidate/useIsJobsEnabled";
import { SHIFT_DISCOVERY_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { getRedesignTheme } from "@src/appV2/redesign/theming";
import { type ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { useGetChatChannels } from "../Facilities/Chat/api/useGetChatChannels";

export function Navbar(): ReactElement {
  const location = useLocation();
  const isJobsEnabled = useIsJobsEnabled();
  const theme = getRedesignTheme();
  const { data: chatChannelsData } = useGetChatChannels({
    refetchInterval: (data) => {
      /* We need to wait until the SDK is initialized, but SendBird does not provide
       * any out-of-the-box way to wait and perform operations after initialization. Hence
       * we're using an immediate refetching as a workaround which solves the issue. We're
       * using the lowest non-zero interval to refetch which is 1 ms. We're using this to
       * show the badge for unread chat messages in the navbar. Note that if we swap this
       * out with 'retries' instead, for some reason the SDK never initializes.
       */
      if (!data?.isSdkInitialized) {
        return 1;
      }

      return 0;
    },
  });

  const unreadChatsCount = (chatChannelsData?.channels ?? [])
    .map((channel) => channel.unreadMessageCount)
    .reduce((accumulator, count) => accumulator + count, 0);

  const hasUnreadChats = unreadChatsCount > 0;

  const shiftsPath = `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_PATH}`;

  let currentLocation = location.pathname;

  // make sure we include all nested paths for shift discovery so navigation is properly selected
  // example: /shift-discovery/list and /shift-discovery/map should both be treated as /shift-discovery
  // TODO: This needs a rework so it works for all other root paths like account, etc
  if (currentLocation.startsWith(`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_PATH}`)) {
    currentLocation = shiftsPath;
  }

  return (
    <ThemeProvider theme={theme}>
      <BottomNavigation value={currentLocation}>
        <BottomNavigationAction
          component={BaseLink}
          to={shiftsPath}
          value={shiftsPath}
          icon={<CbhIcon type="search" size="large" />}
          aria-label={isJobsEnabled ? "Find Work" : "Find Shifts"}
        />
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
          value={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
          icon={<CbhIcon type="clipboard" size="large" />}
          aria-label="My Shifts"
        />
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST}
          value={DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST}
          icon={
            <Badge variant="dot" invisible={!hasUnreadChats} color="info">
              <CbhIcon type="chat" size="large" />
            </Badge>
          }
          aria-label="Status"
        />
        <BottomNavigationAction
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.ACCOUNT}
          value={DeprecatedGlobalAppV1Paths.ACCOUNT}
          icon={<CbhIcon type="user-mini" size="large" />}
          aria-label="My Account"
        />
      </BottomNavigation>
    </ThemeProvider>
  );
}
