import { Li, LoadingButton, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Link, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, openBrowser } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment } from "../../types";
import { adjustMinimumDatePickerTime } from "../../utils/adjustMinimumDatePickerTime";
import { DateTimePicker } from "../DateTimePicker";

interface Props {
  assessment: SkillsAssessment;
  onSuccess?: () => void;
  nextSection?: string;
}

export function OnboardingScheduleAssessment(props: Props) {
  const { assessment, onSuccess = () => undefined, nextSection } = props;
  const history = useHistory();

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const { minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours } =
    scheduledAssessmentConfig;

  const [scheduledFor, setScheduledFor] = useState<string>();

  const worker = useDefinedWorker();
  const { mutateAsync: scheduleAssessment, isLoading, isError } = useScheduleAssessment();

  useEffect(() => {
    if (isError && isDefined(nextSection)) {
      history.push(nextSection);
    }
  }, [isError, history, nextSection]);

  const [minDate, setMinDate] = useState(
    addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)
  );
  const [maxDate, setMaxDate] = useState(
    addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)
  );

  // update the min/max time every 10 seconds, otherwise the date picker will show dates that are no longer valid if the user spends a long time on the page
  useEffect(() => {
    const secondsInterval = 10;
    const intervalId = setInterval(() => {
      setMinDate(addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours));
      setMaxDate(addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours));
    }, secondsInterval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours]);

  return (
    <Stack justifyContent="space-between" width="100%" height="100%">
      <Stack spacing={4}>
        <Title component="h1">Schedule your assessment</Title>
        <Text>
          Pick a time to complete your video assessment. It&apos;s quick and straightforward.
        </Text>
        <Ul style={{ marginTop: 10 }}>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Takes just {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px", cursor: "pointer" }}>
              <Link
                onClick={() => {
                  void openBrowser(skillsAssessmentConfig.supportArticleLink);
                }}
              >
                Learn more about the assessment.
              </Link>
            </Text>
          </Li>
        </Ul>
        <Stack spacing={3}>
          {isDefined(scheduledFor) ? (
            <Text sx={{ lineHeight: "24px" }}>You are scheduling your assessment for:</Text>
          ) : (
            <Text sx={{ lineHeight: "24px" }}>
              Schedule your assessment for the following time:
            </Text>
          )}
          <DateTimePicker
            minDateTime={adjustMinimumDatePickerTime(minDate)}
            maxDateTime={maxDate}
            disabled={isLoading}
            onConfirm={(value) => {
              setScheduledFor(value.toISOString());
            }}
          />
        </Stack>
      </Stack>
      <LoadingButton
        variant="contained"
        sx={{ fontSize: 16, borderRadius: 84 }} // Override font size to match design
        disabled={isLoading || !isDefined(scheduledFor)}
        isLoading={isLoading}
        onClick={async () => {
          if (!isDefined(scheduledFor)) {
            return;
          }

          await scheduleAssessment({
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor,
          });
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_SCHEDULED_EVENT, {
            assessmentId: assessment.id,
            workerId: worker.userId,
            scheduledFor,
            hoursUntilScheduled: differenceInHours(parseISO(scheduledFor), new Date()),
          });

          onSuccess();
        }}
      >
        Confirm
      </LoadingButton>
    </Stack>
  );
}
