import { mergeSxProps } from "@clipboard-health/ui-react";
import { type SxProps, type Theme } from "@mui/material";

import { HorizontalScrollView } from "../components/HorizontalScrollView";
import { ShiftType } from "../Shift/constants";
import { ShiftInvitesCallout } from "../Shift/Invite/Callout";
import { type OpenShift } from "../Shift/Open/types";
import { PriorityShiftsCallout } from "../Shift/Priority/Callout";
import { resolveShiftType } from "../Shift/resolveShiftType";
import { UrgentShiftsCallout } from "../Shift/Urgent/Callout";
import { AccountDeactivatedCallout } from "../Worker/AccountDeactivatedCallout";
import { AccountRestrictedCallout } from "../Worker/AccountRestrictedCallout";
import { WorkerAvailabilityCallout } from "../Worker/AvailabilityCallout";

interface ShiftDiscoveryCalloutsContainerProps {
  unfilteredOpenShifts: OpenShift[];
  sx?: SxProps<Theme>;
}

export function ShiftDiscoveryCalloutsContainer(props: ShiftDiscoveryCalloutsContainerProps) {
  const { unfilteredOpenShifts, sx } = props;

  const urgentShiftsCount = unfilteredOpenShifts.filter(
    (shift) =>
      resolveShiftType({
        isUrgent: shift.attributes.isUrgent,
        priorityTill: shift.attributes.priorityTill,
        window: shift.attributes.window,
      }) === ShiftType.URGENT
  ).length;

  const priorityShiftsCount = unfilteredOpenShifts.filter((shift) => {
    const shiftType = resolveShiftType({
      isUrgent: shift.attributes.isUrgent,
      priorityTill: shift.attributes.priorityTill,
      window: shift.attributes.window,
    });

    return shiftType === ShiftType.PRIORITY || shiftType === ShiftType.A_TEAM;
  }).length;

  return (
    <HorizontalScrollView
      stretch
      sx={mergeSxProps(
        {
          "& > *": {
            width: "fit-content",
            minWidth: "auto",
          },

          // If there are more than two callouts, let them take up the width they need.
          // We're setting min of 35% to make sure that they fill the whole width of the screen
          // and indicate there is more content to scroll
          "&:has(> :nth-child(3))": {
            "> *": {
              minWidth: "35%",
            },
          },

          // If there are exactly two callouts, display them side by side
          // filling the available space
          "&:has(> :nth-child(2):last-child) > *": {
            width: "fit-content",
            minWidth: "50%",
          },
        },
        sx
      )}
    >
      <AccountRestrictedCallout />
      <AccountDeactivatedCallout />
      <UrgentShiftsCallout count={urgentShiftsCount} />
      <PriorityShiftsCallout count={priorityShiftsCount} />
      <ShiftInvitesCallout />
      <WorkerAvailabilityCallout />
    </HorizontalScrollView>
  );
}
