import { Browser } from "@capacitor/browser";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack } from "@mui/material";
import { PageWithHeader } from "@src/appV2/lib";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { useGetPlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/api/useGetPlacementCandidate";
import { PlacementCandidateError } from "@src/appV2/redesign/PlacementCandidate/components/PlacementCandidateError";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { ACCOUNT_ROUTE_V1 } from "../Documents/constants";
import { type LocationState } from "../Documents/types";

export function ClipboardResumePage(): ReactElement {
  const { state: locationState } = useLocation<LocationState>();

  const worker = useDefinedWorker();

  const { data: placementCandidate, isLoading: isPlacementCandidateLoading } =
    useGetPlacementCandidate({ workerId: worker.userId }, { enabled: isDefined(worker.userId) });

  const passportUrl = placementCandidate?.passportUrl ?? undefined;

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Clipboard Resume"
          leftCta={
            <BackButtonLink
              defaultBackTo={locationState?.returnUrl ?? ACCOUNT_ROUTE_V1}
              alwaysUseDefaultBackTo={Boolean(locationState?.returnUrl)}
            />
          }
        />
      }
    >
      {isPlacementCandidateLoading && (
        <Stack
          spacing={3}
          sx={{
            paddingY: 2,
          }}
        >
          <div>Loading...</div>
        </Stack>
      )}
      {!isPlacementCandidateLoading &&
        ((!placementCandidate && (
          <>
            <PullToRefresh
              onRefresh={async () => {
                window.location.reload();
              }}
            />
            <PlacementCandidateError />
          </>
        )) || (
          <Stack>
            <Box>
              We&apos;ve given your resume a makeover, and attached it for you below. Your resume
              contains a link to your candidate profile with all of your approved pre-employment
              requirements. Use this resume to show facilities how prepared you are for the job and
              improve your chances of receiving a high paying offer!
            </Box>

            {isDefined(passportUrl) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    void Browser.open({ url: passportUrl });
                  }}
                >
                  Download Clipboard Resume
                </Button>
              </Box>
            )}
          </Stack>
        ))}
    </PageWithHeader>
  );
}
