import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, Stack } from "@mui/material";

import { CbhIcon, type IconType } from "../components/CbhIcon";

interface MapMarkerClusterProps {
  primaryCount: number;
  secondaryCount?: number;
  iconType: IconType;
  onClick?: () => void;
}

export function MapMarkerCluster(props: MapMarkerClusterProps) {
  const { primaryCount, secondaryCount, iconType, onClick } = props;

  return (
    <ButtonBase
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: theme.palette.components?.clusterMarker?.background,
        borderRadius: theme.borderRadius?.xLarge,
        boxShadow: theme.shadows[2],
        width: "fit-content",
        padding: theme.spacing(4),
        gap: theme.spacing(3),
      })}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CbhIcon type={iconType} size="small" />
        <Text semibold variant="body1" color={(theme) => theme.palette.text?.primary}>
          {primaryCount > 99 ? "99+" : primaryCount}
        </Text>
      </Stack>
      {isDefined(secondaryCount) && secondaryCount > 0 && (
        <Box
          sx={(theme) => ({
            bgcolor: theme.palette.components?.clusterMarker?.foreground,
            borderRadius: theme.borderRadius?.xLarge,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "2px 6px",
          })}
        >
          <Text semibold variant="caption" color={(theme) => theme.palette.text?.invertPrimary}>
            {secondaryCount > 99 ? "99+" : secondaryCount}
          </Text>
        </Box>
      )}
    </ButtonBase>
  );
}
