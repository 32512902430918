import { type Facility } from "@src/appV2/Facilities/types";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { CopyToClipboardProvider } from "../../components/CopyToClipboard/useCopyToClipboard";
import { MapWithAddress } from "../../GeoLocation/MapWithAddress";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetFacilityMapLocationCardProps {
  workplaceId: string;
  facilityLocation: Facility["geoLocation"];
}

export function ShiftBottomSheetFacilityMapLocationCard(
  props: ShiftBottomSheetFacilityMapLocationCardProps
) {
  const { workplaceId, facilityLocation } = props;
  const { coordinates } = facilityLocation;

  const location: Location = { lat: coordinates[1], lng: coordinates[0] };

  return (
    <ShiftBottomSheetInnerCard>
      <CopyToClipboardProvider>
        <MapWithAddress workplaceId={workplaceId} location={location} />
      </CopyToClipboardProvider>
    </ShiftBottomSheetInnerCard>
  );
}
