import { Li, LoadingButton, Text, Title, Ul } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert, Link, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { openBrowser } from "@src/appV2/lib/utils/capacitor/openBrowser";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addHours, differenceInHours, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import pluralize from "pluralize";
import { useEffect, useState } from "react";

import { useScheduleAssessment } from "../../api/useScheduleAssessment";
import { useSkillsAssessmentFeatureFlags } from "../../api/useSkillsAssessmentFeatureFlags";
import { type SkillsAssessment, type SkillsAssessmentStatus } from "../../types";
import { adjustMinimumDatePickerTime } from "../../utils/adjustMinimumDatePickerTime";
import { checkIsWorkerAllowedToRescheduleAssessment } from "../../utils/checkIsWorkerAllowedToRescheduleAssessment";
import { DateTimePicker } from "../DateTimePicker";

interface Props {
  assessment: SkillsAssessment;
  assessmentStatus: SkillsAssessmentStatus;
  scheduledFor: string;
  dueDate?: string;
  qualification?: string;
  onReschedule?: () => void;
}
export function SkillsAssessmentStatusScheduled(props: Props) {
  const {
    assessment,
    assessmentStatus,
    scheduledFor,
    dueDate,
    qualification,
    onReschedule = () => undefined,
  } = props;

  const { skillsAssessmentConfig, scheduledAssessmentConfig } = useSkillsAssessmentFeatureFlags();
  const {
    minimumLeadTimeToScheduleAssessmentInHours,
    maximumLeadTimeToScheduleAssessmentInHours,
    rescheduleLeadTimeInMinutes,
  } = scheduledAssessmentConfig;

  const isAllowedToReschedule = checkIsWorkerAllowedToRescheduleAssessment({
    assessmentType: assessmentStatus.attributes.type,
    rescheduleLeadTimeInMinutes,
    assessmentScheduledFor: scheduledFor,
    assessmentStatus: assessmentStatus.attributes.status,
  });

  const [rescheduledFor, setRescheduledFor] = useState<string>();

  const worker = useDefinedWorker();
  const {
    mutateAsync: scheduleAssessment,
    isLoading,
    isError,
  } = useScheduleAssessment({
    useErrorBoundary: (error) => error.response?.status !== 400,
  });

  const rescheduleLeadTimeInHours = Math.floor(rescheduleLeadTimeInMinutes / 60);

  const [minDate, setMinDate] = useState(
    addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours)
  );
  const [maxDate, setMaxDate] = useState(
    addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours)
  );

  // update the min/max time every 10 seconds, otherwise the date picker will show dates that are no longer valid if the user spends a long time on the page
  useEffect(() => {
    const secondsInterval = 10;
    const intervalId = setInterval(() => {
      setMinDate(addHours(new Date(), minimumLeadTimeToScheduleAssessmentInHours));
      setMaxDate(addHours(new Date(), maximumLeadTimeToScheduleAssessmentInHours));
    }, secondsInterval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [minimumLeadTimeToScheduleAssessmentInHours, maximumLeadTimeToScheduleAssessmentInHours]);

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack spacing={4}>
        <Title component="h1">
          You&apos;ve booked an assessment for{" "}
          <b>
            {format(parseISO(scheduledFor), "MMMM do")} at{" "}
            {format(parseISO(scheduledFor), "h:mmaaa z")}
          </b>
          . Return here at that time to take it.
        </Title>
        <Ul>
          <Li>
            <Text bold sx={{ lineHeight: "24px" }}>
              You need to pass the assessment{" "}
              {isDefined(dueDate) ? `by ${format(parseISO(scheduledFor), "MMMM do")}` : ""} to
              continue booking shifts.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              You&apos;ll answer questions pertaining to your knowledge and experience
              {isDefined(qualification) ? ` as a ${qualification}` : ""} over video.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              The assessment takes about{" "}
              {assessment.attributes.videoAsk.estimatedTimeToCompleteInMinutes} minutes.
            </Text>
          </Li>
          <Li>
            <Text sx={{ lineHeight: "24px" }}>
              Tap{" "}
              <Link
                onClick={() => {
                  void openBrowser(skillsAssessmentConfig.supportArticleLink);
                }}
              >
                here
              </Link>{" "}
              for additional details.
            </Text>
          </Li>
        </Ul>
        {isAllowedToReschedule && (
          <Stack spacing={2}>
            <Text sx={{ lineHeight: "24px" }}>
              To reschedule, select a new time below. You can reschedule up to{" "}
              {rescheduleLeadTimeInHours} {pluralize("hour", rescheduleLeadTimeInHours)} before your
              call.
            </Text>

            <DateTimePicker
              minDateTime={adjustMinimumDatePickerTime(minDate)}
              maxDateTime={maxDate}
              disabled={isLoading}
              onConfirm={(value) => {
                setRescheduledFor(value.toISOString());
              }}
            />
          </Stack>
        )}
        {isError && (
          <Alert severity="error">
            The selected time must be at least {minimumLeadTimeToScheduleAssessmentInHours} hours in
            the future and at most {Math.round(maximumLeadTimeToScheduleAssessmentInHours / 24)}{" "}
            days in the future.
          </Alert>
        )}
      </Stack>
      {isAllowedToReschedule && (
        <LoadingButton
          variant="contained"
          disabled={isLoading || !isDefined(rescheduledFor)}
          isLoading={isLoading}
          onClick={async () => {
            await scheduleAssessment({
              assessmentId: assessment.id,
              workerId: worker.userId,
              scheduledFor: rescheduledFor!,
            });
            logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_RESCHEDULED, {
              assessmentId: assessment.id,
              workerId: worker.userId,
              originalScheduledFor: scheduledFor,
              newScheduledFor: rescheduledFor!,
              hoursUntilOriginalScheduledFor: differenceInHours(parseISO(scheduledFor), new Date()),
              hoursUntilNewScheduledFor: differenceInHours(parseISO(rescheduledFor!), new Date()),
            });

            onReschedule();
          }}
        >
          Reschedule
        </LoadingButton>
      )}
    </Stack>
  );
}
