// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
/* eslint complexity: ["error", 25] */
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  ListItemButton,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory, useParams } from "react-router-dom";

import { DeprecatedGlobalAppV1Paths } from "../../App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "../../lib";
import { formatCentsAsUsd } from "../../lib/Money/utils/currency";
import { formatDistanceInMiles } from "../../lib/utils/distance";
import { CbhIcon } from "../components/CbhIcon";
import { useCreateOrGetPlacementCandidate } from "../PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { jobTypeOptions, shiftTypeOptions } from "../PlacementCandidate/constants";
import { getRedesignTheme } from "../theming";
import { transformPlacementData, useGetPlacement } from "./api/useGetPlacement";
import { PlacementApplicationStatusChip } from "./components/PlacementApplicationStatusChip";
import { PlacementApplyButtonContainer } from "./components/PlacementApplyButtonContainer";
import { PlacementDetailsSkeleton } from "./components/PlacementDetailsSkeleton";
import { PlacementLocationMap } from "./components/PlacementLocationMap";
import { PLACEMENT_PERKS, PlacementPerk } from "./types/fetchPlacements.schema";
import { formatPayRange, getPayRangeText } from "./utils";

export function PlacementDetailsPage() {
  const { placementId, placementCandidateId } = useParams<{
    placementId: string;
    placementCandidateId: string;
  }>();
  const worker = useDefinedWorker();

  const {
    data: placement,
    isLoading: isPlacementLoading,
    isSuccess: isPlacementSuccess,
    refetch: refetchPlacement,
  } = useGetPlacement(
    { placementId, workerId: worker.userId },
    { enabled: isDefined(placementId) && isDefined(worker.userId) }
  );
  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  const placementDetails = isPlacementSuccess ? transformPlacementData(placement) : undefined;
  const hasApplied =
    isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0;
  const history = useHistory();

  const isLoading = isPlacementLoading || isPlacementCandidateLoading;
  const isSuccess = isPlacementSuccess && isPlacementCandidateSuccess;

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Job Details"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS} />}
        />
      }
      containerVariant="without-margin"
    >
      <ThemeProvider theme={getRedesignTheme()}>
        <Stack
          sx={{
            paddingY: 4,
            paddingX: 6,
            overflowY: "auto",
            paddingBottom:
              hasApplied || placementDetails?.isLicenseEligible !== false ? "200px" : "250px",
            backgroundColor: isLoading ? "inherit" : (theme) => theme.palette.background.primary,
            minHeight: "100%",
          }}
          flex={1}
          spacing={6}
        >
          {isLoading && <PlacementDetailsSkeleton />}

          {isSuccess && placementDetails && (
            <Box>
              <Stack spacing={7}>
                <Stack spacing={2}>
                  <Text semibold variant="h3">
                    {placementDetails.title}
                  </Text>

                  <Text variant="h5" color="primary">
                    {getPayRangeText(placementDetails.payRange)}{" "}
                    {formatPayRange(placementDetails.payRange)}
                  </Text>
                  <Text variant="body2" color="text.primary">
                    Available for - {placementDetails.workerTypes?.join(", ")}
                  </Text>
                </Stack>
                <Stack spacing={3}>
                  <Stack direction="row" gap={3} flexWrap="wrap">
                    {placementDetails.shiftTypes.map((shiftType) => {
                      const shiftTypeOption = shiftTypeOptions.find(
                        (option) => option.value === shiftType
                      );

                      return (
                        <Chip
                          key={shiftType}
                          label={shiftTypeOption?.label ?? shiftType}
                          variant="outlined"
                          sx={{
                            backgroundColor: (theme) => theme.palette.background.tertiary,
                          }}
                        />
                      );
                    })}
                  </Stack>

                  <Stack direction="row" gap={3} flexWrap="wrap">
                    {placementDetails.jobTypes.map((type) => {
                      const jobTypeOption = jobTypeOptions.find((option) => option.value === type);

                      return (
                        <Chip
                          key={type}
                          label={jobTypeOption?.label ?? type}
                          variant="outlined"
                          sx={{
                            backgroundColor: (theme) => theme.palette.background.tertiary,
                          }}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                {hasApplied && (
                  <Card
                    variant="elevation"
                    sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
                  >
                    <CardContent>
                      <Stack direction="row" spacing={3} alignItems="center">
                        <Box>
                          <CbhIcon type="plane" size="small" />
                        </Box>
                        <Text variant="caption">
                          You&apos;ve successfully applied for this job at{" "}
                          {placementDetails.workplace.name}. If your application is approved,
                          you&apos;ll receive an email with an interview invite from the facility!{" "}
                        </Text>
                      </Stack>
                    </CardContent>
                  </Card>
                )}
                <Divider sx={{ borderColor: (theme) => theme.palette.divider }} />
                <Stack spacing={2}>
                  <ListItemButton
                    sx={{ padding: 0 }}
                    onClick={() => {
                      history.push(
                        `/home-v2/placements/workplaces/${placementDetails.workplace.id}/profile`
                      );
                    }}
                  >
                    <Stack spacing={2} flex={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Text semibold variant="h5">
                          {placementDetails.workplace.name}
                        </Text>
                        <CbhIcon type="chevron-right" />
                      </Stack>
                      <Text variant="body2" color="text.secondary">
                        {formatDistanceInMiles(placementDetails.distance, "long")} from home
                      </Text>
                    </Stack>
                  </ListItemButton>
                  {isDefined(placementDetails.applications) &&
                    placementDetails.applications.length > 0 && (
                      <PlacementApplicationStatusChip
                        status={placementDetails.applications[0].attributes.status}
                      />
                    )}
                </Stack>

                <Card variant="outlined">
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <PlacementLocationMap
                        coordinates={placementDetails.coordinates}
                        formattedAddress={placementDetails.address.formatted ?? ""}
                        placementId={placementId}
                        placementCandidateId={placementCandidateId}
                      />
                    </Stack>
                  </Stack>
                </Card>

                {placementDetails.description && (
                  <Card
                    variant="elevation"
                    sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
                  >
                    <CardContent>
                      <Stack spacing={7}>
                        <Text semibold variant="h5" sx={{ mb: 2 }}>
                          Job Description
                        </Text>
                        <Text variant="body2" color="text.secondary">
                          {placementDetails.description}
                        </Text>
                      </Stack>
                    </CardContent>
                  </Card>
                )}

                {isDefined(placementDetails.perks) && placementDetails.perks.length > 0 && (
                  <Card
                    variant="elevation"
                    sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
                  >
                    <CardContent>
                      <Stack spacing={7}>
                        <Text semibold variant="h5">
                          Highlights
                        </Text>

                        <Stack spacing={2} alignItems="flex-start">
                          {isDefined(placementDetails.signOnBonus) &&
                            isDefined(placementDetails.signOnBonus.amountInMinorUnits) && (
                              <Chip
                                label={`Sign-on Bonus: ${formatCentsAsUsd(
                                  placementDetails.signOnBonus.amountInMinorUnits
                                )}`}
                                variant="filled"
                                color="default"
                                icon={<CbhIcon type="extra-pay" size="small" />}
                              />
                            )}
                          {placementDetails.perks
                            .filter(
                              (perk) =>
                                !(
                                  perk === PlacementPerk.SIGN_ON_BONUS &&
                                  isDefined(placementDetails.signOnBonus?.amountInMinorUnits)
                                )
                            )
                            .map((perk) => {
                              const perkOption = PLACEMENT_PERKS.find(
                                (option) => option.value === perk
                              );

                              return (
                                <Chip
                                  key={perk}
                                  label={perkOption?.label ?? perk}
                                  variant="filled"
                                  color="default"
                                  icon={
                                    perkOption?.icon && (
                                      <CbhIcon type={perkOption.icon} size="small" />
                                    )
                                  }
                                />
                              );
                            })}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                )}
              </Stack>
            </Box>
          )}
        </Stack>
        {isSuccess && placementDetails && placementCandidate && (
          <PlacementApplyButtonContainer
            placementDetails={placementDetails}
            placementCandidate={placementCandidate}
            refetchPlacement={refetchPlacement}
          />
        )}
      </ThemeProvider>
    </PageWithHeader>
  );
}
