import { isDefined } from "@clipboard-health/util-ts";
import { logEvent } from "@src/appV2/lib/analytics";
import { type CbhAppInfo, isCapacitorPlatform } from "@src/appV2/lib/index";
import { USER_EVENTS } from "@src/constants";

import {
  type HcfTimeclockComplianceRollout,
  HcfTimeclockComplianceRolloutState,
} from "../Shift/ShiftState/types";
import { isEnabledForAppVersionAndOta } from "./isSolveUnpaidBreaksFeatureEnabled";

interface IsHcfTimeclockComplianceEnabled {
  hcfTimeclockComplianceRollout?: HcfTimeclockComplianceRollout;
  appInfo?: CbhAppInfo;
}

export function checkIsHcfTimeclockComplianceEnabled(
  parameters: IsHcfTimeclockComplianceEnabled
): boolean {
  const { hcfTimeclockComplianceRollout, appInfo } = parameters;

  try {
    if (!isDefined(appInfo) || !isDefined(hcfTimeclockComplianceRollout)) {
      return false;
    }

    const { state, minimalOtaVersion, minimalStoreVersion } = hcfTimeclockComplianceRollout;

    if (
      isCapacitorPlatform() &&
      !isEnabledForAppVersionAndOta({
        minimalOtaVersion: String(minimalOtaVersion),
        minimalStoreVersion,
        appInfo,
      })
    ) {
      return false;
    }

    return state === HcfTimeclockComplianceRolloutState.ENABLED;
  } catch {
    logEvent(USER_EVENTS.SOLVE_UNPAID_BREAKS_VERSION_ERROR, {
      hcfTimeclockComplianceRollout,
    });
    return false;
  }
}
