import { useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { SkillsAssessmentHavingIssuesDialog } from "./HavingIssuesDialog";

export function SkillsAssessmentHavingIssuesButton() {
  const modalState = useModalState();

  return (
    <>
      <Button
        fullWidth
        variant="text"
        sx={(theme) => ({
          textDecoration: "underline",
          ":hover": {
            textDecoration: "underline",
          },
          color: theme.palette.common.black,
          lineHeight: "24px",
        })}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_CLICKED_HAVING_ISSUES_CALL_BUTTON);
          modalState.openModal();
        }}
      >
        Having issues?
      </Button>
      <SkillsAssessmentHavingIssuesDialog modalState={modalState} />
    </>
  );
}
