import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { CbhIcon } from "../../components/CbhIcon";
import { SHIFT_DISCOVERY_FILTERS_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useShiftDiscoveryUserFiltersContext } from "./useUserFiltersContext";

export function OpenFiltersButton() {
  const { appliedFiltersCount } = useShiftDiscoveryUserFiltersContext();
  const { navigateToModal } = useShiftModalsDataContext();

  return (
    <Button
      invert
      aria-label="Open Filters Button"
      size="medium"
      variant="contained"
      sx={(theme) => ({
        boxShadow: theme.shadows[3],
        border:
          appliedFiltersCount > 0
            ? `${theme.borderWidth.thick} solid ${theme.palette.primary.main}`
            : undefined,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_VIEWED);
        navigateToModal(SHIFT_DISCOVERY_FILTERS_PATH);
      }}
    >
      {appliedFiltersCount === 0 ? (
        <CbhIcon type="slider-settings" sx={{ marginRight: 3 }} />
      ) : (
        <Badge
          count={appliedFiltersCount}
          sx={{
            // Overriding default badge styles
            // because it's positioned absolutely and we want
            // to align it in place of the icon
            "& .MuiBadge-badge": {
              position: "static",
              transform: "translate(0, 0)",
              marginRight: 3,
            },
          }}
        />
      )}
      Filter
    </Button>
  );
}
