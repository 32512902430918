import { isDefined } from "@clipboard-health/util-ts";
import { HelloSignErrorReason } from "@src/appV2/Accounts/DocumentDetails/hooks/hellosign/constants";
import { helloSignClient } from "@src/appV2/HelloSign/helloSignClient";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useCallback, useEffect } from "react";

interface UseHelloSignClientEventsProps {
  onSign: () => void;
  onError: (errorCode: string) => void;
  onCancel: () => void;
  requirementId?: string;
  helloSignSessionId?: string;
  helloSignOpenStartTime?: number;
}

export function useHelloSignClientEvents(props: UseHelloSignClientEventsProps) {
  const { onSign, onError, onCancel, requirementId, helloSignSessionId, helloSignOpenStartTime } =
    props;

  const getTimeSinceStartHelloSign = useCallback(
    () => (isDefined(helloSignOpenStartTime) ? Date.now() - helloSignOpenStartTime : undefined),
    [helloSignOpenStartTime]
  );

  useEffect(() => {
    helloSignClient.on("sign", () => {
      helloSignClient.close();
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_SIGN_EVENT, {
        requirementId,
        helloSignSessionId,
        timeToFillForm: getTimeSinceStartHelloSign(),
      });
      onSign();
    });

    helloSignClient.on("error", (data) => {
      helloSignClient.close();
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_ERROR_EVENT, {
        errorReason: HelloSignErrorReason.HELLOSIGN_ERROR,
        helloSignErrorCode: data.code,
        requirementId,
        helloSignSessionId,
        timeToError: getTimeSinceStartHelloSign(),
      });
      onError(data.code);
    });

    helloSignClient.on("cancel", () => {
      logEvent(APP_V2_APP_EVENTS.HELLO_SIGN_CANCEL_EVENT, {
        requirementId,
        helloSignSessionId,
        timeToAbandon: getTimeSinceStartHelloSign(),
      });
      onCancel();
    });

    return () => {
      helloSignClient.off("sign");
      helloSignClient.off("error");
      helloSignClient.off("cancel");
    };
  }, [onSign, onError, onCancel, requirementId, helloSignSessionId, getTimeSinceStartHelloSign]);
}
