import {
  type GetOpenShiftsRequestQuery,
  type GetOpenShiftsResponse,
} from "@clipboard-health/contract-worker-app-bff";
import { type DateRange } from "@src/appV2/lib";
import { differenceInDays, endOfDay, startOfDay } from "date-fns";
import uniqBy from "lodash/uniqBy";

import { getIncludedOpenShiftsData } from "./getIncludedOpenShiftsData";
import { useGetOpenShifts } from "./useGetOpenShifts";
import { useGetPaginatedOpenShifts } from "./useGetPaginatedOpenShifts";

interface UseGetOpenShiftsForDatesProps {
  filter: Omit<GetOpenShiftsRequestQuery["filter"], "start">;
  /**
   * Date range to load open shifts for.
   */
  dateRange: DateRange;
  /**
   * Specific dates to load open shifts for
   */
  dates: Date[];
  enabled: boolean;
}
/**
 * This hooks adaptively fetches open shifts data based on the date filter applied.
 * It might either use paginated query or a static query, depending what is the date filter applied.
 * 1. If the date filter is applied, it uses static query. We will just load all the shifts for the dates and won't allow for infinite scroll.
 * 2. If the date filter is not applied, it uses paginated query and allows for infinite scroll.
 */
export function useGetOpenShiftsForDates(props: UseGetOpenShiftsForDatesProps) {
  const { dates, filter, dateRange, enabled } = props;

  const staticDateFilters = dates.map((date) => ({
    gte: startOfDay(date).toISOString(),
    lte: endOfDay(date).toISOString(),
  }));

  const isDateFilterApplied = dates.length > 0;

  // We want list view to have infinite scroll when the date filter is not active, so we fetch paginated shifts.
  const { data: paginatedOpenShiftsData, ...paginatedOpenShiftsDataResult } =
    useGetPaginatedOpenShifts(
      {
        initialDateRange: dateRange,
        filter,
        include: ["workplace", "rate-negotiation"],
      },
      { enabled: enabled && !isDateFilterApplied }
    );

  const consolidatedPaginatedOpenShiftsData: GetOpenShiftsResponse | undefined =
    paginatedOpenShiftsData
      ? {
          data: paginatedOpenShiftsData.pages.flatMap((page) => page.data),
          included: paginatedOpenShiftsData.pages.flatMap((page) => page.included),
        }
      : undefined;

  const daysCount =
    paginatedOpenShiftsData && paginatedOpenShiftsData.pages.length > 0
      ? differenceInDays(
          paginatedOpenShiftsData.pages.at(-1)!.dateRange.endDate,
          paginatedOpenShiftsData.pages[0].dateRange.startDate
        )
      : differenceInDays(dateRange.endDate, dateRange.startDate);

  // When the date filter is active, we get a static list of shifts for the dates selected, no pagination required.
  const { data: openShiftsData, ...openShiftsDataResult } = useGetOpenShifts(
    {
      filter: {
        ...filter,
        start: staticDateFilters,
      },
      include: ["workplace", "rate-negotiation"],
    },
    { enabled: enabled && isDateFilterApplied }
  );

  const data = isDateFilterApplied ? openShiftsData : consolidatedPaginatedOpenShiftsData;

  const shifts = data?.data;
  // The backend currently doesn't always return distinct shifts, this is a temporary fix until that is resolved
  const dedupedShifts = uniqBy(shifts, (shift) => shift.id);

  const { workplacesMap } = getIncludedOpenShiftsData(data);

  const isLoading = isDateFilterApplied
    ? openShiftsDataResult.isLoading
    : paginatedOpenShiftsDataResult.isLoading;
  const isError = isDateFilterApplied
    ? openShiftsDataResult.isError
    : paginatedOpenShiftsDataResult.isError;
  const isSuccess = isDateFilterApplied
    ? openShiftsDataResult.isSuccess
    : paginatedOpenShiftsDataResult.isSuccess;

  return {
    shifts: dedupedShifts,
    workplacesMap,
    daysCount,
    isLoading,
    isError,
    isSuccess,
    pagination: isDateFilterApplied
      ? {
          isFetchingNextPage: paginatedOpenShiftsDataResult.isFetchingNextPage,
          hasNextPage: paginatedOpenShiftsDataResult.hasNextPage,
          fetchNextPage: paginatedOpenShiftsDataResult.fetchNextPage,
        }
      : undefined,
  };
}
