import type { Facility } from "@src/appV2/Facilities/types";
import { convertToGeoLocation } from "@src/appV2/Location";

import { DistanceText } from "../../components/DistanceText";
import { ListItemButton, ListItemIcon, ListItemText } from "../../components/List";
import { useDistanceToWorkplace } from "../../Workplace/useDistanceToWorkplace";

interface ShiftBottomSheetFacilityInfoListItemProps {
  facilityName: string;
  facilityGeoLocation: Facility["geoLocation"];
  onClick?: () => void;
}

export function ShiftBottomSheetFacilityInfoListItem(
  props: ShiftBottomSheetFacilityInfoListItemProps
) {
  const { facilityName, facilityGeoLocation, onClick } = props;

  const {
    formattedDistance,
    isLoading: distanceIsLoading,
    isError: distanceIsError,
  } = useDistanceToWorkplace({
    workplaceGeoLocation: convertToGeoLocation(facilityGeoLocation.coordinates),
  });

  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon iconType="map-pin" />

      <ListItemText
        primary={facilityName}
        secondary={
          <DistanceText
            isError={distanceIsError}
            isLoading={distanceIsLoading}
            formattedDistance={formattedDistance}
            variant="body2"
          />
        }
        secondaryTypographyProps={{
          component: "span",
        }}
      />
    </ListItemButton>
  );
}
