import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { getDocumentCheckQualificationFromMultipleQualifications } from "@src/appV2/Accounts/Documents/helpers";
import {
  ACCOUNTS_DOCUMENTS_FULL_PATH,
  ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH,
} from "@src/appV2/Accounts/Documents/paths";
import { ACCOUNTS_PATH } from "@src/appV2/Accounts/paths";
import { RootPaths } from "@src/appV2/App/paths";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction/types";
import { useShiftDiscoveryUserFiltersContext } from "@src/appV2/redesign/ShiftDiscovery/Filters/useUserFiltersContext";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import { LegacyMissingDocumentsDialog } from "../../Bookability/Documents/LegacyMissingDocumentsDialog";
import { type ShiftWithType } from "../../types";
import { ShiftBottomSheetBlockingDocumentsCard } from "./Card";
import { type ShiftBlockingDocuments } from "./types";

interface ShiftBottomSheetBlockingDocumentsCardsContainerProps {
  blockingDocuments: ShiftBlockingDocuments;
  bookabilityStatus: BookabilityStatusItem;
  shift: ShiftWithType;
}

export function ShiftBottomSheetBlockingDocumentsCardsContainer(
  props: ShiftBottomSheetBlockingDocumentsCardsContainerProps
) {
  const { blockingDocuments, shift } = props;
  const { _id: shiftId = "" } = shift ?? {};

  const { missingDocumentsSet, expiringDocumentsSet, pendingDocumentsSet } = blockingDocuments;
  const history = useHistory();
  const location = useLocation();
  const missingDocumentsModalState = useModalState();
  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  return (
    <>
      <ShiftBottomSheetBlockingDocumentsCard
        {...props}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_VIEW_REQUIREMENTS, {
            shiftId,
            count: missingDocumentsSet.size + expiringDocumentsSet.size,
          });
          missingDocumentsModalState.openModal();
        }}
      />
      <LegacyMissingDocumentsDialog
        pendingDocuments={[...pendingDocumentsSet.values()]}
        missingDocuments={[...missingDocumentsSet.values()]}
        expiringDocuments={[...expiringDocumentsSet.values()]}
        modalState={missingDocumentsModalState}
        onConfirm={() => {
          if (isDefined(shift) && isDefined(shift.facility)) {
            logEvent(APP_V2_USER_EVENTS.MISSING_DOCUMENTS_ALERT_UPLOAD_BUTTON_CLICKED, {
              shiftId,
              count: missingDocumentsSet.size,
            });

            const path = generatePath(
              `${RootPaths.APP_V2_HOME}/${ACCOUNTS_PATH}/${ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH}`,
              {
                hcfId: shift.facilityId ?? shift.facility.userId ?? "",
                hcfName: shift.facility?.name,
              }
            );
            const qualification = getDocumentCheckQualificationFromMultipleQualifications({
              selectedWorkerQualifications:
                selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses,
              shiftQualificationRequirement: shift.agentReq,
            });

            history.push(`${path}?qualification=${qualification ?? shift.agentReq}`, {
              returnUrl: location.pathname,
            });
            return;
          }

          history.push(ACCOUNTS_DOCUMENTS_FULL_PATH, {
            // this account page has a logic for back button that goes back to
            // location.state.returnUrl if provided so we're using that
            returnUrl: history.location.pathname,
          });
        }}
      />
    </>
  );
}
