import { useLoadScript } from "@react-google-maps/api";
import { type Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import constate from "constate";
import { useEffect } from "react";

import { environmentConfig } from "../../environment/config";
import { APP_V2_APP_EVENTS, logError, logEvent } from "../analytics";

const libraries: Libraries = ["places", "geometry"];

function useGoogleMapsSdk() {
  const { isLoaded: isSdkLoaded, loadError: sdkLoadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: environmentConfig.REACT_APP_GOOGLE_MAP_API,
    preventGoogleFontsLoading: false,
    language: "en",
    region: "US",
    version: "3.59",
    libraries,
  });

  useEffect(() => {
    if (isSdkLoaded) {
      logEvent(APP_V2_APP_EVENTS.GOOGLE_MAPS_SDK_LOADED);
    } else if (sdkLoadError) {
      logError(APP_V2_APP_EVENTS.GOOGLE_MAPS_SDK_LOAD_ERROR, {
        error: sdkLoadError,
      });
    }
  }, [isSdkLoaded, sdkLoadError]);

  return {
    isSdkLoaded,
    sdkLoadError,
  };
}

export const [GoogleMapsSdkProvider, useGoogleMapsSdkContext] = constate(useGoogleMapsSdk);
