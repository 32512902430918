import { useGetExternalPaymentAccounts } from "@src/appV2/Agents/api/useGetExternalPaymentAccounts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, openBrowserDeprecated } from "@src/appV2/lib";
import { BannerLink } from "@src/appV2/lib/Alert";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

function DebitCardExpirationAboutToExpireBanner() {
  return (
    <BannerLink
      severity="warning"
      to=""
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.TAPPED_DEBIT_CARD_ABOUT_TO_EXPIRE_BANNER);
        // BannerLink only supports internal links, so we need to open the browser
        openBrowserDeprecated(
          "https://support.clipboardhealth.com/hc/en-us/articles/4643349117335-How-to-change-your-payment-options-on-Stripe"
        );
      }}
    >
      Your debit card is about to expire, tap here to learn how to update your payment information.
    </BannerLink>
  );
}

function DebitCardExpirationExpiredBanner() {
  return (
    <BannerLink
      severity="error"
      to=""
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.TAPPED_DEBIT_CARD_EXPIRED_BANNER);
        // BannerLink only supports internal links, so we need to open the browser
        openBrowserDeprecated(
          "https://support.clipboardhealth.com/hc/en-us/articles/4643349117335-How-to-change-your-payment-options-on-Stripe"
        );
      }}
    >
      Your debit card has expired, tap here to learn how to update your payment information.
    </BannerLink>
  );
}

function DebitCardExpirationBannerContent() {
  const worker = useDefinedWorker();

  const { data: externalPaymentAccounts, status: externalPaymentAccountsStatus } =
    useGetExternalPaymentAccounts({
      workerId: worker.userId,
    });

  if (externalPaymentAccountsStatus !== "success") {
    return null;
  }

  const debitCardExpirationStatus = externalPaymentAccounts.data.find(
    (account) => account.attributes.type === "card"
  )?.attributes.debitCardExpirationStatus;

  switch (debitCardExpirationStatus) {
    case "expiring": {
      return <DebitCardExpirationAboutToExpireBanner />;
    }

    case "expired": {
      return <DebitCardExpirationExpiredBanner />;
    }

    default: {
      return null;
    }
  }
}

export function DebitCardExpirationBanner() {
  const showBanner = useCbhFlag(CbhFeatureFlag.SHOW_DEBIT_CARD_EXPIRATION_BANNER, {
    defaultValue: false,
  });

  if (!showBanner) {
    return null;
  }

  return <DebitCardExpirationBannerContent />;
}
