import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { USER_EVENTS } from "@src/constants";

import { logEvent } from "../../lib/analytics";
import { ShiftDiscoverySearchMode } from "../../OpenShifts/ShiftAction/api/useClaimShift";
import { Button } from "../components/Button";
import { OpenFiltersButton } from "./Filters/OpenFiltersButton";
import { SHIFT_DISCOVERY_LIST_PATH, SHIFT_DISCOVERY_MAP_PATH } from "./paths";
import { useIsShiftDiscoverySearchMode } from "./useShiftDiscoverySearchMode";

export const SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT = 10;
export const SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM = 3;

export function ShiftDiscoverySearchModeSwitcher() {
  const isMapMode = useIsShiftDiscoverySearchMode(ShiftDiscoverySearchMode.MAP);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={(theme) => ({
        position: "absolute",
        left: 0,
        right: 0,
        bottom: theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM),
        height: theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT),
        margin: "0 auto",
      })}
    >
      {isMapMode ? (
        <Button
          size="medium"
          variant="contained"
          sx={(theme) => ({ boxShadow: theme.shadows[3] })}
          href={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`}
        >
          View List
        </Button>
      ) : (
        <Button
          size="medium"
          variant="contained"
          sx={(theme) => ({ boxShadow: theme.shadows[3] })}
          href={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}`}
          onClick={() => {
            logEvent(USER_EVENTS.VIEW_OPEN_SHIFT_MAP, { trigger: "switchToMapView" });
          }}
        >
          View Map
        </Button>
      )}

      <OpenFiltersButton />
    </Stack>
  );
}
