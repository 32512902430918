import { environmentConfig } from "@src/appV2/environment";
import { type z } from "zod";

import { type createPlacementCandidateResponseSchema } from "./useCreateOrGetPlacementCandidate";
import { type placementCandidateResponseSchema } from "./useGetPlacementCandidate";

export const placementCandidateApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates`;

export function transformResponseIntoPlacementCandidate(
  response:
    | z.infer<typeof placementCandidateResponseSchema>
    | z.infer<typeof createPlacementCandidateResponseSchema>
) {
  const placementCandidate = Array.isArray(response.data) ? response.data[0] : response.data;
  const worker = response.included.find(
    (item) => item.type === "workers" && item.id === placementCandidate.relationships.worker.data.id
  );
  if (!worker) {
    throw new Error("Candidate details not found");
  }

  return {
    ...placementCandidate.attributes,
    id: placementCandidate.id,
    worker: { ...worker.attributes, id: worker.id },
  };
}

export function getPlacementCandidateQueryKey(workerId: string) {
  return [placementCandidateApiUrl, workerId];
}
