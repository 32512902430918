import { isDefined } from "@clipboard-health/util-ts";
import { ModalRoute } from "@src/appV2/redesign/Navigation/ModalRoute";
import { ModalTransitionSwitch } from "@src/appV2/redesign/Navigation/ModalTransitionSwitch";
import { type WorkplaceModalRoutePathParams } from "@src/appV2/redesign/ShiftDiscovery/types";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import { useGetWorkplaceProfile } from "@src/appV2/redesign/Workplace/useGetWorkplaceProfile";
import { matchPath, useLocation } from "react-router-dom";

import { WorkplaceCommentModal } from "../Workplace/Review/Comment/Modal";
import { LegacyLeaveReviewContainer } from "../Workplace/Review/LegacyLeaveReviewContainer";
import { WorkplaceReviewsModal } from "../Workplace/Review/Modal";
import {
  PLACEMENT_WORKPLACE_JOBS_PATH,
  PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH,
  PLACEMENT_WORKPLACE_PROFILE_PATH,
  PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH,
  PLACEMENT_WORKPLACE_REVIEWS_PATH,
} from "./paths";
import { WorkplacePlacementsModal } from "./Workplace/WorkplacePlacementsModal";
import { WorkplaceProfileModal } from "./Workplace/WorkplaceProfileModal";

const modalKeys = [
  PLACEMENT_WORKPLACE_PROFILE_PATH,
  PLACEMENT_WORKPLACE_JOBS_PATH,
  PLACEMENT_WORKPLACE_REVIEWS_PATH,
  PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH,
  PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH,
];

export function WorkplaceModalRoutes() {
  const location = useLocation();
  const { workplace, baseNavigationPath } = useWorkplaceModalsDataContext();

  const modalKey = modalKeys.find((key) =>
    matchPath(location.pathname, {
      path: `${baseNavigationPath}/${key}`,
    })
  );

  const match = matchPath<WorkplaceModalRoutePathParams>(location.pathname, {
    path: modalKeys.map((key) => `${baseNavigationPath}/${key}`),
  });

  const workplaceId = match?.params.workplaceId;

  const { data: fetchedWorkplaceProfile, isInitialLoading: workplaceProfileIsLoading } =
    useGetWorkplaceProfile(
      {
        workplaceId: workplaceId ?? "",
      },
      {
        enabled: isDefined(workplaceId),
      }
    );

  const workplaceProfile = fetchedWorkplaceProfile ?? workplace;
  const placementsPath = "/home/openShifts?shiftBookingTab=jobs";
  const rootPath = workplaceId
    ? `${baseNavigationPath}/workplaces/${workplaceId}/profile`
    : placementsPath;

  return (
    <ModalTransitionSwitch modalKey={modalKey}>
      <ModalRoute
        closeModalPath={placementsPath}
        path={`${baseNavigationPath}/${PLACEMENT_WORKPLACE_PROFILE_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplaceId)) {
            return null;
          }

          return (
            <WorkplaceProfileModal
              workplace={workplaceProfile}
              workplaceId={workplaceId}
              modalState={modalState}
              isLoading={workplaceProfileIsLoading}
            />
          );
        }}
      />
      <ModalRoute
        closeModalPath={placementsPath}
        path={`${baseNavigationPath}/${PLACEMENT_WORKPLACE_JOBS_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplaceId)) {
            return null;
          }

          return (
            <WorkplacePlacementsModal
              workplaceId={workplaceId}
              isLoading={workplaceProfileIsLoading}
              modalState={modalState}
              workplace={workplaceProfile}
            />
          );
        }}
      />
      <ModalRoute
        exact
        closeModalPath={rootPath}
        path={`${baseNavigationPath}/${PLACEMENT_WORKPLACE_REVIEWS_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplaceId)) {
            return null;
          }

          return <WorkplaceReviewsModal workplaceId={workplaceId} modalState={modalState} />;
        }}
      />

      <ModalRoute
        closeModalPath={rootPath}
        path={`${baseNavigationPath}/${PLACEMENT_WORKPLACE_REVIEWS_COMMENT_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplaceId)) {
            return null;
          }

          return <WorkplaceCommentModal workplaceId={workplaceId} modalState={modalState} />;
        }}
      />

      <ModalRoute
        closeModalPath={rootPath}
        path={`${baseNavigationPath}/${PLACEMENT_WORKPLACE_LEAVE_REVIEW_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(workplaceProfile)) {
            return null;
          }

          return (
            <LegacyLeaveReviewContainer workplace={workplaceProfile} modalState={modalState} />
          );
        }}
      />
    </ModalTransitionSwitch>
  );
}
