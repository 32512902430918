import {
  convertFromGoogleMapsLocation,
  convertToGoogleMapsLocation,
  type GeoLocation,
} from "@src/appV2/Location";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";

const LOCATION_FILTER_CHANGE_DEBOUNCE_TIME = 1000;
const METER_TO_MILE_RATIO = 0.000_621_371;

// Although we have calculateGeoDistanceInMiles from src/appV2/Location/utils/geoDistance.ts,
// we use Google Maps API here because it's more reliable.
function getDistanceBetweenLatLngsInMiles(
  latLng1: google.maps.LatLng | google.maps.LatLngLiteral,
  latLng2: google.maps.LatLng | google.maps.LatLngLiteral
) {
  return (
    google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2) * METER_TO_MILE_RATIO
  );
}

export function useLocationFilter() {
  const [locationFilter, setLocationFilter] = useState<GeoLocation | undefined>(undefined);

  const onChangeLocationFilter = useCallback((mapCenter: google.maps.LatLngLiteral) => {
    setLocationFilter(convertFromGoogleMapsLocation(mapCenter));
  }, []);

  const debouncedChangeLocationFilter = useMemo(
    () => debounce(onChangeLocationFilter, LOCATION_FILTER_CHANGE_DEBOUNCE_TIME),
    [onChangeLocationFilter]
  );

  function onChangeLocationFilterAfterDistance(
    distanceFilter: number,
    mapCenter?: google.maps.LatLng
  ) {
    if (!locationFilter || !mapCenter) {
      return;
    }

    const movedDistance = getDistanceBetweenLatLngsInMiles(
      convertToGoogleMapsLocation(locationFilter),
      mapCenter
    );

    // Only update the map center if the user has moved
    // far enough away, as soon as the distance filter is passed.
    // This is to prevent the map from being updated too often,
    // which can cause performance issues, and a jittery experience
    // when the user is just panning around.
    if (movedDistance > distanceFilter) {
      debouncedChangeLocationFilter(mapCenter.toJSON());
    }
  }

  return { locationFilter, onChangeLocationFilter, onChangeLocationFilterAfterDistance };
}
