import { LoadingButton, ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

interface ErrorDialogProps {
  isLoading: boolean;
  error?: Error | CameraPermissionDeniedError;
  onRetry: () => void;
  onClose: () => void;
}

export function ErrorDialog(props: ErrorDialogProps) {
  const { isLoading, error, onRetry, onClose } = props;
  const modalState = useModalState(ModalStatus.OPEN);

  const isPermissionDeniedError = error instanceof CameraPermissionDeniedError;

  return (
    <CommonDialog
      modalState={modalState}
      title={isPermissionDeniedError ? "Camera Permission Denied" : "Error Taking Photo"}
      actions={
        <>
          <LoadingButton fullWidth variant="contained" isLoading={isLoading} onClick={onRetry}>
            Retry
          </LoadingButton>
          <Button fullWidth onClick={onClose}>
            Cancel
          </Button>
        </>
      }
      onClose={onClose}
    >
      <Text>
        {isPermissionDeniedError
          ? "Camera permission is required to take a photo. Please enable camera access for Clipboard Health app in your device settings."
          : "There was an error taking your photo. Please try again."}
      </Text>
    </CommonDialog>
  );
}
