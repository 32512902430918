import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { useGetMissingRequirementsForWorkplaces } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { GroupedVirtuoso } from "react-virtuoso";

import { getMissingAndPendingDocumentsForQualification } from "../../Shift/Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { ShiftListGroupWrapper } from "../../Shift/Open/GroupWrapper";
import { ShiftListDateLabel } from "../../Shift/Open/ListDateLabel";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { LoadingMoreIndicator } from "../../Shift/Open/LoadingMoreIndicator";
import { ShiftListTopItemList } from "../../Shift/Open/TopItemList";
import { type OpenShift, type OpenShiftWorkplace } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { WorkplaceOpenShiftsEmptyState } from "./EmptyState";

interface WorkplaceOpenShiftsListProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  workplace: OpenShiftWorkplace;
  scrollRef: React.RefObject<HTMLDivElement>;
  loadMore: () => void;
  canLoadMore: boolean;
  isLoadingMore: boolean;
}

export function WorkplaceOpenShiftsList(props: WorkplaceOpenShiftsListProps) {
  const {
    isError,
    isLoading,
    isSuccess,
    workplace,
    scrollRef,
    loadMore,
    canLoadMore,
    isLoadingMore,
  } = props;

  const { navigateToModal } = useShiftModalsDataContext();
  const { userId: workerId, licensesData } = useDefinedWorker();
  const { groupSortedShifts, shiftsByDateCount, dateGroups } = useVirtualShiftListContext();

  const hasShifts = groupSortedShifts.length > 0;

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    workplace
      ? [
          {
            id: workplace.id,
            type: workplace.attributes.type,
            msa: workplace.attributes.location.msa,
            state: workplace.attributes.location.state,
            region: workplace.attributes.location.region,
          },
        ]
      : [],
    licensesData?.map((l) => l.qualification),
    {
      enabled: hasShifts,
    }
  );

  if (isError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying shifts at this facility",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts at this location",
          "Unlocking your next great opportunity at this facility",
        ]}
      />
    );
  }

  if (isSuccess && !hasShifts) {
    return <WorkplaceOpenShiftsEmptyState />;
  }

  return (
    <GroupedVirtuoso
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      groupCounts={shiftsByDateCount}
      endReached={() => {
        if (canLoadMore) {
          loadMore();
        }
      }}
      components={{
        TopItemList: ShiftListTopItemList,
        Group: ShiftListGroupWrapper,
        Footer: () => (
          <Box sx={{ paddingTop: 4, paddingBottom: 3 }}>
            {isLoadingMore ? <LoadingMoreIndicator sx={{ paddingBottom: 4 }} /> : undefined}
          </Box>
        ),
      }}
      groupContent={(index) => {
        const label = dateGroups[index];

        return <ShiftListDateLabel label={label} shiftCount={shiftsByDateCount[index]} />;
      }}
      itemContent={(index) => {
        const shift = groupSortedShifts[index] as OpenShift;

        const { missingDocuments, pendingDocuments } =
          getMissingAndPendingDocumentsForQualification(
            workplace.id,
            shift.attributes.qualification,
            missingRequirements.results
          );

        return (
          <OpenShiftCard
            key={shift.id}
            shift={shift}
            sx={{
              marginBottom: index < groupSortedShifts.length - 1 ? 4 : 0,
            }}
            workplace={workplace}
            missingDocuments={missingDocuments}
            pendingDocuments={pendingDocuments}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, { shiftId: shift.id });
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: shift.id });
            }}
          />
        );
      }}
    />
  );
}
