import { createTheme, type Shadows, type Theme } from "@mui/material";

import { muiThemePalette } from "./colors";
import { muiThemeComponents } from "./components";
import { muiThemeTypography } from "./typography";

declare module "@mui/material/styles" {
  interface CustomBorderRadius {
    borderRadius?: {
      small: string;
      medium: string;
      large: string;
      xLarge: string;
    };
  }

  interface CustomSize {
    size?: {
      avatar: {
        small: string;
        medium: string;
        large: string;
      };
      button: {
        small: {
          height: string;
          iconSize: string;
        };
        medium: {
          height: string;
          iconSize: string;
        };
        large: {
          height: string;
          iconSize: string;
        };
      };
      dateCell: {
        small: {
          width: string;
          height: string;
        };
        medium: {
          width: string;
          height: string;
        };
      };
      icon: {
        xSmall: string;
        small: string;
        medium: string;
        large: string;
        xLarge: string;
      };
      iconButton: {
        xSmall: string;
        small: string;
        medium: string;
        large: string;
      };
      pill: {
        small: string;
        medium: string;
      };
      input: {
        small: string;
        medium: string;
        large: string;
      };
      tabs: {
        height: string;
        lineHeight: string;
      };
      shiftCard: {
        minHeight: string;
      };
      bottomSheet: {
        loading: {
          minHeight: string;
        };
        review: {
          minHeight: string;
        };
      };
    };
  }

  interface CustomBorderWidth {
    borderWidth: {
      thin: string;
      regular: string;
      thick: string;
    };
  }

  interface CustomProps extends CustomBorderRadius, CustomSize, CustomBorderWidth {}

  interface Theme extends CustomProps {}
  interface ThemeOptions extends CustomProps {}
}

export function getRedesignTheme(): Theme {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    transitions: {
      duration: {
        leavingScreen: 225,
        enteringScreen: 225,
      },
    },
    borderRadius: {
      small: "16px",
      medium: "24px",
      large: "32px",
      xLarge: "100px",
    },
    size: {
      avatar: {
        small: "2rem", // 32px
        medium: "4rem", // 64px
        large: "6.5rem", // 104px
      },
      button: {
        small: {
          height: "2.5rem", // 40px
          iconSize: "1rem", // 16px
        },
        medium: {
          height: "3rem", // 48px
          iconSize: "1.25rem", // 20px
        },
        large: {
          height: "4rem", // 64px
          iconSize: "1.25rem", // 20px
        },
      },
      dateCell: {
        small: {
          width: "2.875rem", // 46px
          height: "4rem", // 64px
        },
        medium: {
          width: "4rem", // 64px
          height: "4.875rem", // 78px
        },
      },
      iconButton: {
        xSmall: "1.25rem", // 20px
        small: "2.5rem", // 40px
        medium: "3rem", // 48px
        large: "4rem", // 64px
      },
      icon: {
        xSmall: "0.875rem", // 14px
        small: "1rem", // 16px
        medium: "1.5rem", // 24px
        large: "2rem", // 32px
        xLarge: "3.5rem", // 56px
      },
      pill: {
        small: "1.75rem", // 28px
        medium: "2rem", // 32px
      },
      input: {
        small: "2.5rem", // 40px
        medium: "3rem", // 48px
        large: "4rem", // 64px
      },
      tabs: {
        height: "2.5rem", // 40px
        lineHeight: "1rem", // 16px
      },
      shiftCard: {
        minHeight: "12.875rem", // 206px
      },
      bottomSheet: {
        loading: {
          minHeight: "24.1875rem", // 387px
        },
        review: {
          minHeight: "35.625rem", // 570px
        },
      },
    },
    borderWidth: {
      thin: "0.5px",
      regular: "1px",
      thick: "2px",
    },
    zIndex: {
      // TODO: Check if this is still relevant
      appBar: 2000,
    },
    spacing: [0, 4, 6, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64],
    shadows: [
      "none",
      "0px 0px 1px 0px rgba(0, 0, 0, 0.2)",
      "0px 4px 24px 0px rgba(0, 0, 0, 0.04)",
      "0px 4px 40px 0px rgba(0, 0, 0, 0.15)",
      "0px -10px 20px 0px rgba(0, 0, 0, 0.05)",
      // we don't support higher elevations than 4
      ...Array.from<string>({ length: 20 }).fill("none"),
    ] as Shadows,
    typography: muiThemeTypography,
    palette: muiThemePalette,
    components: muiThemeComponents,
  });
}
