import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { DialogHeader } from "../../components/DialogHeader";
import { SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import type { WorkplaceProfile } from "../types";
import { WorkplaceProfileModalContent } from "./ModalContent";
import { WorkplaceProfileModalContentSkeleton } from "./ModalContentSkeleton";

interface WorkplaceProfileModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading?: boolean;
}

export function WorkplaceProfileModal(props: WorkplaceProfileModalProps) {
  const { modalState, workplaceId, workplace, isLoading } = props;

  const { generateModalPath } = useWorkplaceModalsDataContext();

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0 }}>
        <DialogHeader />

        {isDefined(workplace) && <WorkplaceProfileModalContent workplaceProfile={workplace} />}

        {isLoading && !isDefined(workplace) && <WorkplaceProfileModalContentSkeleton />}
      </DialogContent>

      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => String(theme.palette.background.tertiary)}
        onClose={() => {
          modalState.closeModal();
        }}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ maxWidth: "60%" }}
          href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
            workplaceId,
          })}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_OPEN_SHIFTS_OPENED, {
              workplaceId,
              trigger: "Workplace Profile Modal",
            });
          }}
        >
          View open shifts
        </Button>
      </DialogFooter>
    </FullScreenDialog>
  );
}
