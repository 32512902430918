import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Input } from "@src/appV2/redesign/components/Input";
import { SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useWorkplaceModalsDataContext";
import {
  useWorkplaceComments,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { WorkplaceReviewCommentCard } from "./Card";
import {
  updateCommentListCacheWithReaction,
  updateSingleCommentCacheWithReaction,
} from "./queryCache";

interface WorkplaceCommentListProps {
  workplaceId: string;
}

export function WorkplaceCommentList(props: WorkplaceCommentListProps) {
  const { workplaceId } = props;

  const history = useHistory();
  const queryClient = useQueryClient();

  const { generateModalPath } = useWorkplaceModalsDataContext();
  const { data: comments } = useWorkplaceComments({ workplaceId });

  const inputRef = useRef<HTMLInputElement>(null);

  const [filteredComments, setFilteredComments] = useState<WorkplaceCommentType[]>(comments ?? []);

  const debouncedFilter = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!comments) {
          return;
        }

        const sortedComments = comments.sort((a, b) => {
          return (
            parseISO(b.attributes.createdAt).getTime() - parseISO(a.attributes.createdAt).getTime()
          );
        });

        if (!searchTerm) {
          setFilteredComments(sortedComments);
          return;
        }

        const lowercaseSearch = searchTerm.toLowerCase();

        const filtered = lowercaseSearch
          ? sortedComments.filter((comment) =>
              comment.attributes.text.toLowerCase().includes(lowercaseSearch)
            )
          : sortedComments;

        setFilteredComments(filtered);
      }, 300),
    [comments]
  );

  useEffect(() => {
    debouncedFilter(inputRef.current?.value ?? "");
  }, [debouncedFilter]);

  return (
    <Stack spacing={7}>
      <Text semibold variant="h5" sx={{ paddingLeft: 5 }}>
        {/* TODO: This should be h6. https://linear.app/clipboardhealth/issue/SPRTA-916/add-h6-typography-variant */}
        Reviews
      </Text>

      <Input
        ref={inputRef}
        iconType="search"
        placeholder="Search reviews..."
        size="small"
        onChange={(event) => {
          debouncedFilter(event.target.value);
        }}
      />

      <Stack spacing={3}>
        {comments?.length === 0 && (
          <Text variant="h5" textAlign="center" sx={{ paddingTop: 7 }}>
            {/* TODO: This should be h6. https://linear.app/clipboardhealth/issue/SPRTA-916/add-h6-typography-variant */}
            No reviews yet. Be the first to leave one!
          </Text>
        )}

        {comments?.length !== 0 && filteredComments.length === 0 && (
          <Text variant="h5" textAlign="center" sx={{ paddingTop: 7 }}>
            {/* TODO: This should be h6. https://linear.app/clipboardhealth/issue/SPRTA-916/add-h6-typography-variant */}
            No reviews matching your search
          </Text>
        )}

        {filteredComments.map((comment) => (
          <WorkplaceReviewCommentCard
            key={comment.id}
            comment={comment}
            workplaceId={String(workplaceId)}
            onClick={() => {
              history.push(
                generateModalPath(SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH, {
                  workplaceId,
                  commentId: comment.id,
                })
              );
            }}
            onLikeChanged={(reaction) => {
              updateCommentListCacheWithReaction({
                queryClient,
                commentId: comment.id,
                workplaceId: String(workplaceId),
                reaction,
              });

              updateSingleCommentCacheWithReaction({
                queryClient,
                commentId: comment.id,
                workplaceId: String(workplaceId),
                reaction,
              });
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
