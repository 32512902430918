import { ApolloProvider } from "@apollo/client";
import * as braze from "@braze/web-sdk";
import { App as CapacitorApp } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { datadogRum } from "@datadog/browser-rum";
import { createAnimation, isPlatform, setupConfig } from "@ionic/core";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { AppV2PublicRoutes } from "@src/appV2";
import { cbhQueryClient } from "@src/appV2/api/queryClient";
import { EnvironmentRibbon } from "@src/appV2/App/EnvironmentRibbon/EnvironmentRibbon";
import { AppStateProvider } from "@src/appV2/App/useAppState";
import { environmentConfig } from "@src/appV2/environment";
import { LD_USER_KIND } from "@src/appV2/FeatureFlags/cbhUserContext";
import { LegacyStateProvider } from "@src/appV2/LegacyStateBridge/context";
import { MuiSnackBarProvider } from "@src/appV2/lib";
import { GoogleMapsSdkProvider } from "@src/appV2/lib/GoogleMaps/context";
import { MaterialUiProviders } from "@src/appV2/mui";
import { OpenShiftProvider as OpenShiftV2Provider } from "@src/appV2/OpenShifts";
import { setupPdfViewer } from "@src/appV2/PdfViewer/setupPdfViewer";
import { LockScreenOrientation } from "@src/appV2/ScreenOrientation";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import "./app.scss";
// Rule disabled since this is where we initialize LaunchDarkly
// eslint-disable-next-line no-restricted-imports
import { LDEvaluationDetail, withLDProvider } from "launchdarkly-react-client-sdk";
import { Provider, useDispatch } from "react-redux";
import { Route } from "react-router-dom";

import { ErrorBoundary } from "src/appV2/Errors/ErrorBoundary";

import handleStripePopup from "./appStateChangeHandlers/handleStripePopup";
import { GoogleMapProvider } from "./context/googleMapContext";
import { GraphQlClient } from "./graphql";
import { useRegisterLocationTracking } from "./locationTracking/useRegisterLocationTracking";
import { OpenShiftProvider } from "./openShifts/context";
import { PopupAlerts } from "./popups";
import { AppRoutes } from "./routing";
import { GlobalRouterPath } from "./routing/constant/globalRoute";
import { store } from "./store";
import { useSession } from "./store/helperHooks";
import { ActionType } from "./store/session";
import { useOtaUpdate } from "./useOtaUpdate";

CapacitorApp.addListener("appStateChange", ({ isActive }) => {
  handleStripePopup(isActive as boolean);
});

if (isPlatform(window, "capacitor")) {
  const isAndroid = isPlatform(window, "android");

  ZendeskSDK.initialize({
    channelKey: isAndroid
      ? environmentConfig.REACT_APP_ZENDESK_SDK_CHANNEL_KEY_ANDROID
      : environmentConfig.REACT_APP_ZENDESK_SDK_CHANNEL_KEY_IOS,
  });
}

Preferences.migrate();

/**
 * pdf.worker.min.mjs is copied from node_modules to our public folder
 * Check out CopyWebpackPlugin configuration in webpack.config.js for more info
 */
setupPdfViewer("/assets/scripts/pdf.worker.min.mjs");

if (
  !braze.initialize(environmentConfig.REACT_APP_HCP_WEB_BRAZE_KEY as string, {
    baseUrl: environmentConfig.REACT_APP_HCP_WEB_BRAZE_SDK_ENDPOINT as string,
  })
) {
  throw new Error("Braze web SDK initialization failed");
}
/**
 * FIXME Replace IonReactRouter and this Ionic config once all routing and components move to `react-router-dom`
 * Technically, we don't need to add `setupConfig`, but `IonReactRouter` appears to sometimes wait
 * for animations to complete.
 */
setupConfig({
  mode: "ios",
  navAnimation: () => {
    /**
     * A dummy animation to speed up the routing without odd transition delays.
     */
    return createAnimation();
  },
});

const MainApp = () => {
  const session = useSession();
  const dispatch = useDispatch();

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });
  const { userId: workerId } = worker ?? {};

  useRegisterLocationTracking(workerId);
  useOtaUpdate(workerId);

  return (
    <IonApp>
      <LockScreenOrientation />
      <IonReactRouter>
        <MuiSnackBarProvider>
          <AppStateProvider>
            <OpenShiftV2Provider>
              <OpenShiftProvider>
                <AppRoutes />
              </OpenShiftProvider>
            </OpenShiftV2Provider>
          </AppStateProvider>
          <div className="cbhAppV2">
            <Route path={GlobalRouterPath.APP_V2}>
              <LegacyStateProvider
                session={session}
                setIsSignUp={(authModeIsSignUp) => {
                  /**
                   * Legacy code uses localStorage in several places to propagate `isSignup` state
                   * This implicit binding is fragile, since it binds a hidden global browser state throughout
                   * the app.
                   * Note also, since the property lingers, it is quite possible for an existing user to
                   * encounter this value on a shared device.
                   * Note also, This won't work when users open the app across devices.
                   * For example, if they sign up on a desktop, and then complete the flow on
                   * their phone.
                   */
                  localStorage.setItem("isSignup", authModeIsSignUp ? "true" : "false");
                  dispatch({
                    type: ActionType.SET_IS_SIGNUP,
                    data: {
                      isSignUp: authModeIsSignUp,
                    },
                  });
                }}
              >
                <AppV2PublicRoutes />
              </LegacyStateProvider>
            </Route>
          </div>
          <div className="cbhAppV1">
            <PopupAlerts />
          </div>
          <EnvironmentRibbon />
        </MuiSnackBarProvider>
      </IonReactRouter>

      {(localStorage.getItem("areDevToolsVisible") ?? "true") === "true" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </IonApp>
  );
};

const App = () => {
  return (
    <MaterialUiProviders>
      <ErrorBoundary>
        <GoogleMapsSdkProvider>
          <GoogleMapProvider>
            <ApolloProvider client={GraphQlClient}>
              <Provider store={store}>
                <QueryClientProvider client={cbhQueryClient}>
                  <MainApp />
                </QueryClientProvider>
              </Provider>
            </ApolloProvider>
          </GoogleMapProvider>
        </GoogleMapsSdkProvider>
      </ErrorBoundary>
    </MaterialUiProviders>
  );
};

export default withLDProvider({
  clientSideID: environmentConfig.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  context: { anonymous: true, kind: LD_USER_KIND },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  options: {
    evaluationReasons: true,
    inspectors: [
      {
        type: "flag-used",
        name: "flag-inspector",
        method: async (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(App);
